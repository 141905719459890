import React from 'react'
import {Grid} from '@material-ui/core'
import IconButton from '../IconButton'

class Row extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      service: props.service,
    }
    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.service !== this.state.service) {
      this.setState({edit: false, service: nextProps.service})
    }
  }

  onChange(e, field) {
    e.persist()
    this.setState(state => {
      return {service: {...state.service, [field]: e.target.value}}
    })
  }

  render() {
    const {classes, hideHeader, service} = this.props

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={4} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Code</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{service.productServiceCodeDisplay}</p>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Product and Service</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{service.productServiceDisplay}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="delete"
                    onClick={() => {
                      this.props.deleteService(service.id, service.application)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Row
