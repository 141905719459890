import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import responseStatus from './common/redux/reducers.responseStatus'
import auth from './auth/redux/reducers'
import forgotPw from './auth/redux/reducers.forgotPw'
import home from './home/redux/reducers'
import profile from './profile/redux/reducers'
import publicProfile from './publicUser/redux/reducers'
import notifications from './common/redux/reducers.notifications'
import menu from './common/redux/reducers.menu'
import signup from './auth/redux/reducers.signup'
import contact from './contactus/redux/reducers'
import careers from './careers/redux/reducers'
import ctPages from './construction_trenchless/redux/reducers'
import cippCalculator from './cipp_calculator_request/redux/reducers'
import videos from './videos/redux/reducers'
import news from './common/redux/reducers.news'
import gallery from './gallery/redux/reducers'
import contractor from './contractor/redux/reducers'
import address from './contractor/address/redux/reducers'
import contractorContact from './contractor/contact/redux/reducers'
import service from './contractor/service/redux/reducers'
import businessClassification from './contractor/business_classification/redux/reducers'
import businessLine from './contractor/business_line/redux/reducers'
import supplierClassification from './contractor/supplier_classification/redux/reducers'
import regionInterest from './contractor/region_interest/redux/reducers'
import projectInterest from './contractor/project_interest/redux/reducers'
import attachment from './contractor/attachment/redux/reducers'

const rootReducer = combineReducers({
  routing: routerReducer,
  responseStatus,
  auth,
  forgotPw,
  home,
  profile,
  notifications,
  signup,
  publicProfile,
  menu,
  contact,
  careers,
  ctPages,
  cippCalculator,
  videos,
  news,
  gallery,
  contractor,
  address,
  contractorContact,
  service,
  businessClassification,
  businessLine,
  regionInterest,
  projectInterest,
  attachment,
  supplierClassification,
})

export default rootReducer
