import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class BusinessLineApi {
  static createBusinessLine(payload) {
    return axios.post(`${settings.apiBaseURL}/lines-of-business`, payload)
  }

  static getBusinessLines(params) {
    return axios.get(`${settings.apiBaseURL}/lines-of-business`, {params})
  }

  static updateBusinessLine(id, data) {
    return axios.patch(
      `${settings.apiBaseURL}/lines-of-business/${id}?app_id=${data.application}`,
      data,
    )
  }

  static deleteBusinessLine(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/lines-of-business/${id}?app_id=${appId}`)
  }

  static getBusinessLineData() {
    return axios.get(`${settings.apiBaseURL}/business-lines`)
  }
}
