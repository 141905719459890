import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Hidden from '@material-ui/core/es/Hidden/Hidden'
import PageUtils from '../utils/PageUtils'
import styles from './styles/Page.styles'
import president from '../../assets/president-full.png'

class About extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.title}>Welcome to Lanzo Construction Company</h2>
              <hr className={classes.titleLine} style={{width: 450}} />
            </Grid>
            <Grid item xs={12} style={{marginTop: 15, marginBottom: 50}}>
              <Grid container justify="center">
                <Grid item xs={12} md={8}>
                  <p>
                    Infrastructure needs have changed since Lanzo first entered the industry nearly
                    50 years ago. As you'll see in our newly developed website, we've made changes
                    in our corporate structure and embraced new technology to meet those needs. Our
                    goal is to continue building and restoring sustainable, eco-friendly
                    infrastructure to support the needs of our communities. We want to accomplish
                    this with as little impact on the environment as possible.
                  </p>
                  <p>
                    Whether it is rehabilitating existing infrastructure using cutting-edge,
                    eco-friendly materials and technologies, replacing failing infrastructure, or
                    installing new infrastructure to accommodate the growing population, we are able
                    to manage projects of all sizes safely, efficiently, and within budget.{' '}
                  </p>
                  <p>
                    Our clients trust Lanzo to oversee more challenging and environmentally
                    sensitive projects, as well. Working together, we develop and implement creative
                    solutions that balance the needs of the client with the needs of the
                    environment. Using eco-friendly materials and technologies on the jobsite, and
                    then implementing small changes in the office - like our recycling and sorting
                    programs - allows us to do our part in taking care of the environment.{' '}
                  </p>
                  <p>
                    Please take a moment to explore our website and learn more about the history of
                    Lanzo and the services we provide. We appreciate your interest. If you have any
                    questions, or would like to discuss an upcoming project, please feel free to
                    contact us.{' '}
                  </p>
                  <p>
                    Sincerely, <br />
                    Quirino (Gary) D'Alessandro <br />
                    Founder
                  </p>
                </Grid>
                <Hidden smDown={true}>
                  <Grid item md={4} style={{paddingLeft: 30}}>
                    <div className={classes.imageContainer}>
                      <img src={president} style={{height: '100%'}} />
                    </div>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

About.displayName = 'About'

export default withStyles(styles)(About)
