import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* businessLineFlow() {
  yield all([
    takeEvery(Types.CREATE_SUPPLIER_CLASSIFICATION, createSupplierClassification),
    takeEvery(Types.GET_SUPPLIER_CLASSIFICATIONS, getSupplierClassifications),
    takeEvery(Types.UPDATE_SUPPLIER_CLASSIFICATION, updateSupplierClassification),
    takeEvery(Types.DELETE_SUPPLIER_CLASSIFICATION, deleteSupplierClassification),
    takeEvery(Types.GET_SUPPLIER_CLASSIFICATIONS_DATA, getSupplierClassificationData),
  ])
}

function* createSupplierClassification(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createSupplierClassification, payload)
    yield put(Actions.createSupplierClassificationSuccess(response))
  } catch (error) {
    yield put(Actions.createSupplierClassificationFailure(error))
  }
}

function* getSupplierClassifications(action) {
  const {params} = action
  try {
    const response = yield call(Api.getSupplierClassifications, params)
    yield put(Actions.getSupplierClassificationsSuccess(response))
  } catch (error) {
    yield put(Actions.getSupplierClassificationsFailure(error))
  }
}

function* updateSupplierClassification(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateSupplierClassification, id, data)
    yield put(Actions.updateSupplierClassificationSuccess(response))
  } catch (error) {
    yield put(Actions.updateSupplierClassificationFailure(error))
  }
}

function* deleteSupplierClassification(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteSupplierClassification, id, appId)
    yield put(Actions.deleteSupplierClassificationSuccess(id))
  } catch (error) {
    yield put(Actions.deleteSupplierClassificationFailure(error))
  }
}

function* getSupplierClassificationData() {
  try {
    const response = yield call(Api.getSupplierClassificationData)
    console.log(response)
    yield put(Actions.getSupplierClassificationDataSuccess(response))
  } catch (error) {
    yield put(Actions.getSupplierClassificationDataFailure(error))
  }
}
