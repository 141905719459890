import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../../common/redux/actions.notifications'

export default function* serviceFlow() {
  yield all([
    takeEvery(Types.CREATE_SERVICE, createService),
    takeEvery(Types.GET_SERVICES, getServices),
    takeEvery(Types.GET_SERVICE_OPTIONS, getServiceOptions),
    takeEvery(Types.UPDATE_SERVICE, updateService),
    takeEvery(Types.DELETE_SERVICE, deleteService),
  ])
}

function* createService(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createService, payload)
    yield put(Actions.createServiceSuccess(response))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.createServiceFailure(error))
  }
}

function* getServices(action) {
  const {params} = action
  try {
    const response = yield call(Api.getServices, params)
    yield put(Actions.getServicesSuccess(response))
  } catch (error) {
    yield put(Actions.getServicesFailure(error))
  }
}

function* getServiceOptions(action) {
  const {params} = action
  try {
    const response = yield call(Api.getServiceOptions, params)
    yield put(Actions.getServiceOptionsSuccess(response))
  } catch (error) {
    yield put(Actions.getServiceOptionsFailure(error))
  }
}

function* updateService(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateService, id, data)
    yield put(Actions.updateServiceSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.updateServiceFailure(error))
  }
}

function* deleteService(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteService, id, appId)
    yield put(Actions.deleteServiceSuccess(id))
    yield put(NotificationActions.createSuccess('Deleted successfully'))
  } catch (error) {
    yield put(Actions.deleteServiceFailure(error))
  }
}
