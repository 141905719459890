import * as Types from './actions.types'

export function createBusinessClassification(payload) {
  return {type: Types.CREATE_BUSINESS_CLASSIFICATION, payload}
}

export function createBusinessClassificationSuccess(payload) {
  return {type: Types.CREATE_BUSINESS_CLASSIFICATION_SUCCESS, payload}
}

export function createBusinessClassificationFailure(error) {
  return {type: Types.CREATE_BUSINESS_CLASSIFICATION_FAILURE, error}
}

export function bulkCreateBusinessClassification(payload) {
  return {type: Types.BULK_CREATE_BUSINESS_CLASSIFICATION, payload}
}

export function bulkCreateBusinessClassificationSuccess(payload) {
  return {type: Types.BULK_CREATE_BUSINESS_CLASSIFICATION_SUCCESS, payload}
}

export function bulkCreateBusinessClassificationFailure(error) {
  return {type: Types.BULK_CREATE_BUSINESS_CLASSIFICATION_FAILURE, error}
}

export function getBusinessClassifications(params) {
  return {type: Types.GET_BUSINESS_CLASSIFICATIONS, params}
}

export function getBusinessClassificationsSuccess(payload) {
  return {type: Types.GET_BUSINESS_CLASSIFICATIONS_SUCCESS, payload}
}

export function getBusinessClassificationsFailure(error) {
  return {type: Types.GET_BUSINESS_CLASSIFICATIONS_FAILURE, error}
}

export function getBusinessClassificationData(params) {
  return {type: Types.GET_BUSINESS_CLASSIFICATION_DATA, params}
}

export function getBusinessClassificationDataSuccess(payload) {
  return {type: Types.GET_BUSINESS_CLASSIFICATION_DATA_SUCCESS, payload}
}

export function getBusinessClassificationDataFailure(error) {
  return {type: Types.GET_BUSINESS_CLASSIFICATION_DATA_FAILURE, error}
}

export function updateBusinessClassification(id, data) {
  return {type: Types.UPDATE_BUSINESS_CLASSIFICATION, id, data}
}

export function updateBusinessClassificationSuccess(payload) {
  return {type: Types.UPDATE_BUSINESS_CLASSIFICATION_SUCCESS, payload}
}

export function updateBusinessClassificationFailure(error) {
  return {type: Types.UPDATE_BUSINESS_CLASSIFICATION_FAILURE, error}
}

export function bulkUpdateBusinessClassification(appId, data) {
  return {type: Types.BULK_UPDATE_BUSINESS_CLASSIFICATION, appId, data}
}

export function bulkUpdateBusinessClassificationSuccess(payload) {
  return {type: Types.BULK_UPDATE_BUSINESS_CLASSIFICATION_SUCCESS, payload}
}

export function bulkUpdateBusinessClassificationFailure(error) {
  return {type: Types.BULK_UPDATE_BUSINESS_CLASSIFICATION_FAILURE, error}
}

export function deleteBusinessClassification(id, appId) {
  return {type: Types.DELETE_BUSINESS_CLASSIFICATION, id, appId}
}

export function deleteBusinessClassificationSuccess(id) {
  return {type: Types.DELETE_BUSINESS_CLASSIFICATION_SUCCESS, id}
}

export function deleteBusinessClassificationFailure(error) {
  return {type: Types.DELETE_BUSINESS_CLASSIFICATION_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_BUSINESS_CLASSIFICATION_ERRORS}
}

export function setMinorityOption(payload) {
  return {type: Types.SET_MINORITY_OPTION, payload}
}
