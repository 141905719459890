import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import withWidth from '@material-ui/core/withWidth'
import * as Actions from './redux/actions.news'

const FPS = 32
const STEP = 2
const TIMEOUT = (1 / FPS) * 1000

const styles = () => ({
  container: {
    backgroundColor: 'rgba(23,23,23,0.65)',
    marginTop: 0,
    overflow: 'hidden',
    maxWidth: 1400,
    height: 32,
    position: 'relative',
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      whiteSpace: 'nowrap',
      position: 'absolute',
      top: 0,
    },
    '& li': {
      margin: 0,
      color: 'white',
      display: 'block',
      textAlign: 'center',
      padding: '5px 40px 0 41px',
      flex: '0 1 auto',
      listStyleType: 'none',
    },
    '& li:before': {
      content: "''",
      display: 'block',
      float: 'left',
      width: 11,
      height: 11,
      margin: '5px 15px 0 -30px',
      backgroundColor: '#E63B43',
    },
    '& a': {
      color: 'white',
    },
  },
})

export class NewsMarquee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      animatedWidth: 0,
      overflowWidth: 0,
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  componentDidMount() {
    this.props.getNews()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.news.length != this.props.news.length) {
      this._measureText()
      this._startAnimation()
    }
  }

  handleMouseEnter() {
    const {width} = this.props
    const mobile = !!(width === 'xs' || width === 'sm')
    if (mobile) {
      return
    }
    if (this.state.overflowWidth > 0) {
      clearTimeout(this._marqueeTimer)
    }
  }

  handleMouseLeave() {
    const {width} = this.props
    const mobile = !!(width === 'xs' || width === 'sm')
    if (mobile) {
      return
    }
    if (this.state.overflowWidth > 0) {
      this._startAnimation()
    }
  }

  _startAnimation() {
    clearTimeout(this._marqueeTimer)
    const animate = () => {
      const {overflowWidth} = this.state
      const containerWidth = this.container.offsetWidth
      let animatedWidth = this.state.animatedWidth + STEP
      const isRoundOver = animatedWidth > containerWidth

      if (isRoundOver) {
        animatedWidth = 0
        this._marqueeTimer = setTimeout(() => {
          this.setState({
            animatedWidth: overflowWidth * -1 - containerWidth,
          })

          this._marqueeTimer = setTimeout(animate, TIMEOUT)
        }, TIMEOUT)
      } else {
        this.setState({
          animatedWidth,
        })

        this._marqueeTimer = setTimeout(animate, TIMEOUT)
      }
    }

    this._marqueeTimer = setTimeout(animate, TIMEOUT)
  }

  _measureText() {
    if (this.container && this.node) {
      const containerWidth = this.container.offsetWidth
      const textWidth = this.node.offsetWidth
      const overflowWidth = textWidth - containerWidth

      if (overflowWidth !== this.state.overflowWidth) {
        this.setState({
          overflowWidth,
          animatedWidth: overflowWidth * -1 - containerWidth,
        })
      }
    }
  }

  render() {
    const {classes, news} = this.props
    const {animatedWidth} = this.state

    return (
      <div
        ref={node => {
          this.container = node
        }}
        className={classes.container}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <ul
          ref={node => {
            this.node = node
          }}
          style={{right: animatedWidth}}
        >
          {news.map(item => (
            <li>
              <a href={item.url} target="blank">
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.news,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withWidth(),
)(NewsMarquee)
