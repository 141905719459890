import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class ServiceApi {
  static createService(payload) {
    return axios.post(`${settings.apiBaseURL}/product-services`, payload)
  }

  static getServices(params) {
    return axios.get(`${settings.apiBaseURL}/product-services`, {params})
  }

  static getServiceOptions(params) {
    return axios.get(`${settings.apiBaseURL}/product-service-options`, {params})
  }

  static updateService(id, data) {
    return axios.patch(
      `${settings.apiBaseURL}/product-services/${id}?app_id=${data.application}`,
      data,
    )
  }

  static deleteService(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/product-services/${id}?app_id=${appId}`)
  }
}
