import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import PageUtils from '../utils/PageUtils'
import styles from './styles/Page.styles'

class Terms extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.title}>Terms & Conditions</h2>
              <hr className={classes.titleLine} style={{width: 287}} />
            </Grid>
            <Grid item xs={12} style={{marginTop: 15, marginBottom: 50}}>
              <p>
                If you do not agree with the terms or have any questions, contact us before
                proceeding and we will be pleased to assist you. By choosing to access and use this
                site, you are expressly agreeing to be bound by these terms.
              </p>

              <p className={classes.sectionTitle}>License and Site Access</p>
              <p>
                Lanzo or third parties granting rights to Lanzo hold title to the materials on this
                site, which are the copyrighted work of Lanzo or such third parties. Lanzo grants
                you a limited license to access and make personal, noncommercial use of this site,
                including the right to download and make a single electronic or hard copy of limited
                portions (including text, photos, videos, and graphics), provided that the Lanzo
                copyright is acknowledged on each page copied. However, you may not modify it or any
                portion of it. This license does not include any commercial use of this site or its
                contents other than our e-commerce services. It does not include any collection and
                use of any product listings, descriptions, or prices; any derivative use of this
                site or its contents; or any use of data mining, robots, or similar data gathering
                and extraction tools.
              </p>
              <p>
                Neither this Web site nor any portion thereof may be reproduced, duplicated, copied,
                sold, or otherwise exploited for any commercial purpose. You may not frame or use
                framing techniques to enclose any portion of this site. You may not use meta tags or
                any other hidden text using Lanzo’s name or trademarks. Any unauthorized use
                terminates the license to use granted herein.
              </p>
              <p className={classes.sectionTitle}>Changes to Site</p>
              <p>
                In order to improve our service to you, we reserve the right to make changes in the
                access, operation, and content of this site at any time without notice. We also
                reserve the right to make changes in the Terms and the Privacy Policy, so please
                check before each use for changes.
              </p>
              <p className={classes.sectionTitle}>Confidential Information.</p>
              <p>
                Lanzo discourages you from sending or posting to the site materials or information
                that you consider to be confidential or proprietary. Please note that if you do send
                such information, Lanzo will assume that it is not confidential. By sending or
                posting such information or material, you grant Lanzo an unrestricted license to
                use, reproduce, and distribute those materials or information, and you agree that
                Lanzo may use any ideas, concepts, know-how or techniques that you send or post for
                any purpose.
              </p>
              <p className={classes.sectionTitle}>No Warranties </p>
              <p>
                The information on this site is provided for general information purposes only.
                While Lanzo strives to provide only accurate information on this Web site, you may
                discover some inadvertent inaccuracies in the information provided. Please bring
                them to our attention by using the Contact Us link at the top right of the screen.
                Since such mistakes may occur, LANZO MAKES NO GUARANTEES, WARRANTIES, OR
                REPRESENTATIONS CONCERNING THE ACCURACY, RELIABILITY, FITNESS FOR ANY PARTICULAR
                PURPOSE, OR COMPLETENESS OF ANY INFORMATION ON THIS SITE. WE ARE PROVIDING THE
                INFORMATION ON AN “AS IS, WHERE IS” BASIS, AND ALL WARRANTIES (EXPRESS OR IMPLIED)
                ARE DISCLAIMED.
              </p>
              <p className={classes.sectionTitle}>Links to Other Sites </p>
              <p>
                As convenience, this Web site may contain links to other sites that are not
                controlled by, or affiliated or associated with, Lanzo. Accordingly, Lanzo does not
                make any representations concerning the privacy practices or terms of use of such
                sites, nor does Lanzo control or guarantee the accuracy, integrity, or quality of
                the information and materials available on such sites.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Terms.displayName = 'Terms'

export default withStyles(styles)(Terms)
