import React from 'react'
import {compose} from 'redux'
import {Grid, ButtonBase, Button, Hidden} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'

import Link from 'react-router/es/Link'
import ImageUtils from '../utils/ImageUtils'

import imgProjectsImage1 from '../../assets/projects-image-1.png'
import imgProjectsImage2 from '../../assets/projects-image-2.png'
import imgProjectsImage3 from '../../assets/projects-image-3.png'
import imgProjectsImage4 from '../../assets/projects-image-4.png'
import imgProjectsImage5 from '../../assets/projects-image-5.png'
import imgProjectsImage6 from '../../assets/projects-image-6.png'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'black',
      marginBottom: -1,
    },
    '& h2': {
      fontSize: '42px',
      fontWeight: 'bold',
      color: '#000',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        color: 'white',
      },
    },
  },
  categoriesButtonsContainer: {
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 30px 20px',
      margin: 0,
    },
  },
  categoriesButton: {
    fontSize: '17px',
    fontWeight: 600,
    margin: '5px 10px',
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      margin: '10px 15px',
      fontWeight: 400,
    },
  },
  projectsContainer: {
    maxWidth: 1400,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'black',
      padding: '0 20px',
    },
  },
  projectItem: {
    backgroundSize: 'cover',
    position: 'relative',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      height: '260px !important',
      marginBottom: 20,
    },
  },
  projectItemTextContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 15,
    minHeight: 115,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      minHeight: 80,
    },
    '& h3': {
      fontWeight: 'bold',
      fontSize: '17px',
      margin: '0 0 10px 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3.8vw',
        margin: '0 -20px 5px 0',
      },
      padding: 0,
    },
    '& p': {
      fontSize: '13px',
      margin: 0,
      padding: 0,
    },
  },
  buttonAllProjectsContainer: {
    textAlign: 'center',
    marginTop: 60,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 30,
    },
  },
  orangeButton: {
    color: 'white',
    backgroundColor: '#FB3F47',
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: 3,
    paddingLeft: 30,
    paddingRight: 30,
    minHeight: 40,
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  questionsContainer: {
    color: 'white',
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      backgroundImage: 'url(' + ImageUtils.getProjectsQuestionsBg() + ')',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      color: 'black',
      textAlign: 'center',
    },
    '& h3': {
      fontSize: '46px',
      margin: '0 0 15px 0',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '29px',
        fontWeight: 400,
      },
    },
    '& p': {
      fontSize: '17px',
      lineHeight: '26px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
        padding: '0 10px',
      },
      margin: '0 0 60px 0',
      padding: 0,
    },
  },
  questionsSubContainer: {
    maxWidth: 1400,
    margin: '0 auto',
    paddingTop: 40,
    paddingBottom: 80,
    paddingRight: 40,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px 40px 20px',
    },
  },
  questionsImage: {
    width: '100%',
    marginBottom: 20,
  },
  divider: {
    height: 2,
    width: '30%',
    margin: '25px auto',
  },
})

// eslint-disable-next-line react/prefer-stateless-function
class Projects extends React.Component {
  render() {
    const {classes, width} = this.props

    const categories = [
      {
        link: 'http://www.lanzo.net/projects.html',
        title: 'All',
      },
      {
        link: '#',
        title: 'Construction',
      },
      {
        link: '#',
        title: 'Trenchless',
      },
      {
        link: '#',
        title: 'Asphalt',
      },
      {
        link: '#',
        title: 'Manufacturing',
      },
      {
        link: '#',
        title: 'Emergency Services',
      },
      {
        link: '#',
        title: 'Plant Work',
      },
      {
        link: '#',
        title: 'Engineering Services',
      },
      {
        link: '#',
        title: 'Tunnel & Directional Boring',
      },
    ]

    const projects = [
      {
        title: 'Treatment Plant Design and Construction',
        description:
          'Our services include Design Build, Both Raw and Transmission Water Treatment Facilities, Sewage Treatment and experience with 100 MM Gallon per Day Capacity, Pump Station, Lift Station, as well as, Large Diameter Pipeline Construction and Deep Well Injection applications.',
        image: imgProjectsImage1,
        height: 450,
      },
      {
        title: 'Fully Integrated Construction/Design Build Team',
        description:
          'To serve all your utility construction, roadbuilding and treatment plant needs.',
        image: imgProjectsImage2,
        height: 450,
      },
      {
        title: 'Infrastructure Renewal',
        description:
          'WWTP Design, Construction, and Rehabilitation services for all your Water, Sewer or Transmission Pump Station Needs.',
        image: imgProjectsImage3,
        height: 370,
      },
      {
        title: 'Trenchless Technologies/Pipe Lining ',
        description:
          'Lanzo has pioneered the use of Stand Alone Structural Cured in Place Pipe Lining having installed over 15,000,000 feet during these past twenty five years. Our use of Styrene Free and Epoxy resins allows for "Green" applications such as NSF 61 watermain, as well as, Pressure, Large Bore and Non Circular Rehabilitation.',
        image: imgProjectsImage4,
        height: 530,
      },
      {
        title: 'Pressured Transmission Systems',
        description:
          'Water, Sewer or Oilfield applications where competent and dependable construction services are required.',
        image: imgProjectsImage5,
        height: 450,
      },
      {
        title: 'Asphalt & Concrete Division',
        description:
          'A fully integrated Paving Operation with experience ranging from Harmonized Concrete Work to DOT Roadway Construction. Award Winning Specialty Design, Irrigation and Landscaping for your Turn Key Neighborhood, Private or Municipal Improvement.',
        image: imgProjectsImage6,
        height: 450,
      },
    ]

    const spacing = width !== 'xs' && width !== 'sm' ? 16 : 0

    if (!spacing) {
      let i = 0
      const limit = 130
      for (i = 0; i < projects.length; i++) {
        if (projects[i].description.length > limit) {
          projects[i].description = projects[i].description.substring(0, limit) + '...'
        }
      }
    }

    const categoriesElements = categories.map((item, index) => (
      <Grid item>
        <ButtonBase
          className={classes.categoriesButton}
          component="a"
          href={item.link}
          style={
            index === 0 && spacing
              ? {color: '#FB3F47'}
              : index === 0 && !spacing
                ? {backgroundColor: '#FB3F47', padding: '5px 10px', marginTop: 4}
                : {}
          }
        >
          {item.title}
        </ButtonBase>
      </Grid>
    ))

    const projectsElements = projects.map(item => (
      <Link to="/soon">
        <div
          className={classes.projectItem}
          style={{
            backgroundImage: 'url(' + item.image + ')',
            height: item.height,
          }}
        >
          <div className={classes.projectItemTextContainer}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </div>
      </Link>
    ))

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <h2>Projects</h2>
          <Hidden mdUp={true}>
            <div
              className={classes.divider}
              style={{backgroundColor: 'white', marginTop: -20, marginBottom: 20, width: '20%'}}
            />
          </Hidden>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="center"
          className={classes.categoriesButtonsContainer}
        >
          {categoriesElements}
        </Grid>
        <Grid item xs={12} container spacing={spacing} className={classes.projectsContainer}>
          <Grid item xs={12} md={4}>
            {projectsElements[0]}
            {projectsElements[spacing ? 1 : 2]}
          </Grid>
          <Grid item xs={12} md={4}>
            {projectsElements[spacing ? 2 : 4]}
            {projectsElements[spacing ? 3 : 1]}
          </Grid>
          <Grid item xs={12} md={4}>
            {projectsElements[spacing ? 4 : 3]}
            {projectsElements[5]}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.buttonAllProjectsContainer}>
          <Button
            className={classes.orangeButton}
            component="a"
            href="http://www.lanzo.net/projects.html"
          >
            ALL PROJECTS
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.questionsContainer}>
          <Grid container justify="flex-end" className={classes.questionsSubContainer}>
            <Grid item xs={12} md={4}>
              <h3>Have Questions?</h3>
              <Hidden mdUp={true}>
                <div className={classes.divider} style={{backgroundColor: 'black'}} />
                <img
                  src={ImageUtils.getProjectsQuestionsBg()}
                  alt="Questions?"
                  className={classes.questionsImage}
                />
              </Hidden>
              <p style={{marginBottom: 30}}>
                The information you provide will be directed to the appropiate construction leader
                at Lanzo. We are committed to contacting you in a timely manner with a suitable
                response to your inquiry.
              </p>
              <p> Thank you.</p>
              <Button className={classes.orangeButton} component={Link} to="/contactus">
                SEND A MESSAGE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Projects.displayName = 'Projects'

export default compose(
  withStyles(styles),
  withWidth(),
)(Projects)
