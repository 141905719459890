import React from 'react'
import {compose} from 'redux'
import {Grid, Paper} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    '& h2': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 24,
      fontWeight: 'bold',
    },
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 16,
    },
  },
  containerCard: {
    backgroundColor: '#F2F2F2',
    padding: 20,
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
  },
})

class Contractor extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.container} justify="center">
        <Grid item xs={10} style={{marginTop: 120}}>
          <Paper className={classes.containerCard}>{this.props.children}</Paper>
        </Grid>
      </Grid>
    )
  }
}

Contractor.displayName = 'Contractor'

export default compose(withStyles(styles))(Contractor)
