import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* galleryFlow() {
  yield all([takeEvery(Types.GET_GALLERY_IMAGES, getGallery)])
}

function* getGallery() {
  try {
    const response = yield call(Api.getGallery)
    yield put(Actions.getGallerySuccess(response))
  } catch (error) {
    yield put(Actions.getGalleryFailure(error))
  }
}
