import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../../common/redux/actions.notifications'

export default function* attachmentFlow() {
  yield all([
    takeEvery(Types.CREATE_ATTACHMENT, createAttachment),
    takeEvery(Types.GET_ATTACHMENTS, getAttachments),
    takeEvery(Types.UPDATE_ATTACHMENT, updateAttachment),
    takeEvery(Types.DELETE_ATTACHMENT, deleteAttachment),
  ])
}

function* createAttachment(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createAttachment, payload)
    yield put(Actions.createAttachmentSuccess(response.data))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.createAttachmentFailure(error))
  }
}

function* getAttachments(action) {
  const {params} = action
  try {
    const response = yield call(Api.getAttachments, params)
    yield put(Actions.getAttachmentsSuccess(response))
  } catch (error) {
    yield put(Actions.getAttachmentsFailure(error))
  }
}

function* updateAttachment(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateAttachment, id, data)
    yield put(Actions.updateAttachmentSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.updateAttachmentFailure(error))
  }
}

function* deleteAttachment(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteAttachment, id, appId)
    yield put(Actions.deleteAttachmentSuccess(id))
    yield put(NotificationActions.createSuccess('Deleted successfully'))
  } catch (error) {
    yield put(Actions.deleteAttachmentFailure(error))
  }
}
