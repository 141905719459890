import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class ContactApi {
  static createContact(payload) {
    return axios.post(`${settings.apiBaseURL}/contacts/with-purpose`, payload)
  }

  static getContacts(params) {
    return axios.get(`${settings.apiBaseURL}/contacts`, {params})
  }

  static updateContact(id, data) {
    return axios.patch(`${settings.apiBaseURL}/contacts/${id}?app_id=${data.application}`, data)
  }

  static deleteContact(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/contacts/${id}?app_id=${appId}`)
  }

  static getPurposeOptions() {
    return axios.get(`${settings.apiBaseURL}/contact-purpose-options`)
  }

  static createContactPurpose(contact, purpose) {
    return axios.post(`${settings.apiBaseURL}/contact-purposes`, {contact, purpose})
  }

  static deleteContactPurpose(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/contact-purposes/${id}?app_id=${appId}`)
  }
}
