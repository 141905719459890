import * as Types from './actions.types'

export function createProjectInterest(payload) {
  return {type: Types.CREATE_PROJECT_INTEREST, payload}
}

export function createProjectInterestSuccess(payload) {
  return {type: Types.CREATE_PROJECT_INTEREST_SUCCESS, payload}
}

export function createProjectInterestFailure(error) {
  return {type: Types.CREATE_PROJECT_INTEREST_FAILURE, error}
}

export function getProjectInterests(params) {
  return {type: Types.GET_PROJECT_INTERESTS, params}
}

export function getProjectInterestsSuccess(payload) {
  return {type: Types.GET_PROJECT_INTERESTS_SUCCESS, payload}
}

export function getProjectInterestsFailure(error) {
  return {type: Types.GET_PROJECT_INTERESTS_FAILURE, error}
}

export function updateProjectInterest(id, data) {
  return {type: Types.UPDATE_PROJECT_INTEREST, id, data}
}

export function updateProjectInterestSuccess(payload) {
  return {type: Types.UPDATE_PROJECT_INTEREST_SUCCESS, payload}
}

export function updateProjectInterestFailure(error) {
  return {type: Types.UPDATE_PROJECT_INTEREST_FAILURE, error}
}

export function deleteProjectInterest(id, appId) {
  return {type: Types.DELETE_PROJECT_INTEREST, id, appId}
}

export function deleteProjectInterestSuccess(id) {
  return {type: Types.DELETE_PROJECT_INTEREST_SUCCESS, id}
}

export function deleteProjectInterestFailure(error) {
  return {type: Types.DELETE_PROJECT_INTEREST_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_PROJECT_INTEREST_ERRORS}
}

export function getProjectInterestData() {
  return {type: Types.GET_PROJECT_INTERESTS_DATA}
}

export function getProjectInterestDataSuccess(payload) {
  return {type: Types.GET_PROJECT_INTERESTS_DATA_SUCCESS, payload}
}

export function getProjectInterestDataFailure(error) {
  return {type: Types.GET_PROJECT_INTERESTS_DATA_FAILURE, error}
}
