import React from 'react'
import {withStyles, CircularProgress} from '@material-ui/core'

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    margin: theme.spacing.unit * 5,
  },
})
const Loading = ({classes}) => (
  <div className={classes.container}>
    <CircularProgress size={100} className={classes.progress} />
  </div>
)
Loading.displayName = 'Loading'
export default withStyles(styles)(Loading)
