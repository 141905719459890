import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class AddressApi {
  static createAddress(payload) {
    return axios.post(`${settings.apiBaseURL}/addresses/with-purpose`, payload)
  }

  static getAddresses(params) {
    return axios.get(`${settings.apiBaseURL}/addresses`, {params})
  }

  static updateAddress(id, data) {
    return axios.patch(`${settings.apiBaseURL}/addresses/${id}?app_id=${data.application}`, data)
  }

  static deleteAddress(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/addresses/${id}?app_id=${appId}`)
  }

  static getPurposeOptions() {
    return axios.get(`${settings.apiBaseURL}/address-purpose-options`)
  }

  static createAddressPurpose(address, purpose) {
    return axios.post(`${settings.apiBaseURL}/address-purposes`, {address, purpose})
  }

  static deleteAddressPurpose(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/address-purposes/${id}?app_id=${appId}`)
  }
}
