import * as Types from './actions.types'

export function getJobs() {
  return {type: Types.JOBS}
}

export function getJobsSuccess(payload) {
  return {type: Types.JOBS_SUCCESS, payload}
}

export function getJobsFailure(error) {
  return {type: Types.JOBS_FAILURE, error}
}

export function submitApplication(payload) {
  return {type: Types.SUBMIT_APPLICATION, payload}
}

export function submitApplicationSuccess() {
  return {type: Types.SUBMIT_APPLICATION_SUCCESS}
}

export function submitApplicationFailure(error) {
  return {type: Types.SUBMIT_APPLICATION_FAILURE, error}
}

export function submitApplicationReset() {
  return {type: Types.SUBMIT_APPLICATION_RESET}
}
