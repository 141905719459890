import React from 'react'
import {withStyles} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    margin: theme.spacing.unit * 5,
  },
})

const Modal = ({open, handleClose, title, actionContent, children}) => (
  <React.Fragment>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {actionContent && <DialogActions>{actionContent}</DialogActions>}
    </Dialog>
  </React.Fragment>
)

Modal.displayName = 'Modal'
export default withStyles(styles)(Modal)
