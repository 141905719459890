import React from 'react'
import {compose, bindActionCreators} from 'redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import StepProgressBar from './StepProgressBar'
import SaveForLaterModal from './SaveForLaterModal'
import ContractorHeader from './ContractorHeader'
import ContractorFooter from './ContractorFooter'
import {SectionHeader} from './BasicInfo'
import RoundedInput from '../common/RoundedInput'
import InputValidator from '../common/InputValidator'
import AddressBook from './address/AddressBook'
import ContactDirectory from './contact/ContactDirectory'
import Service from './service/Service'
import BusinessClassification from './business_classification/BusinessClassification'
import PageUtils from '../utils/PageUtils'
import * as Actions from './redux/actions'
import {createError} from '../common/redux/actions.notifications'

const styles = theme => ({
  headerRight: {
    textAlign: 'right',
    padding: 5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  valueLeft: {
    padding: 5,
  },
})

class CompanyDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.saveLaterSubmit = false
    this.saveLater = this.saveLater.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.params && this.props.params.id && !this.props.application.id) {
      this.props.getApplication(this.props.params.id, {next: 'details'})
    }
    PageUtils.scrollToTop()
  }

  saveLater(state) {
    this.saveLaterSubmit = state
  }

  onChange(e, field) {
    e.persist()
    this.setState({[field]: e.target.value})
  }

  onSubmit(e) {
    const {contractorContact, address, service} = this.props
    e.preventDefault()

    if (
      contractorContact.results.length === 0 ||
      address.results.length === 0 ||
      service.results.length === 0
    ) {
      this.props.createError('One or more required data are missing')
      return
    }

    this.props.updateApplication(this.props.application.appId, this.state, {
      saveLater: this.saveLaterSubmit,
      next: 'additional',
    })
  }

  render() {
    const {classes, modalOpen, application} = this.props

    return (
      <Grid container>
        <StepProgressBar percent={34} />
        <SaveForLaterModal
          closeModal={this.props.closeModal}
          application={application}
          modalOpen={modalOpen}
          next="additional"
        />
        <form onSubmit={this.onSubmit}>
          <ContractorHeader saveLater={this.saveLater} />
          <Grid container justify="center">
            <Grid item xs={12}>
              <SectionHeader>Prospective Supplier Registration: Additional Details</SectionHeader>
              <p>Blank label for instruction text</p>
            </Grid>

            <Grid item md={8} xs={12}>
              <Grid container justify="center">
                <Grid item md={6} xs={6} className={classes.headerRight}>
                  <b>Company Name</b>
                </Grid>
                <Grid item md={6} xs={6} className={classes.valueLeft}>
                  <span>{application.companyName}</span>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={6} className={classes.headerRight}>
                  <b>Tax Country</b>
                </Grid>
                <Grid item md={6} xs={6} className={classes.valueLeft}>
                  <span>{application.taxCountry}</span>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={6} className={classes.headerRight}>
                  <b>Tax Registration Number</b>
                </Grid>
                <Grid item md={6} xs={6} className={classes.valueLeft}>
                  <span>{application.taxNumber}</span>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={6} className={classes.headerRight}>
                  <b>Taxpayer ID</b>
                </Grid>
                <Grid item md={6} xs={6} className={classes.valueLeft}>
                  {application.taxpayerId}
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={6} className={classes.headerRight}>
                  <b>DUNS Number</b>
                </Grid>
                <Grid item md={6} xs={6} className={classes.valueLeft}>
                  <span>{application.dunsNumber}</span>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={12} className={classes.headerRight}>
                  <b>Alternate Supplier Name</b>
                </Grid>
                <Grid item md={6} xs={12} className={classes.valueLeft}>
                  <InputValidator className={classes.fieldsContainer}>
                    <RoundedInput
                      value={this.state.alternateSupplierName || application.alternateSupplierName}
                      onChange={e => {
                        this.onChange(e, 'alternateSupplierName')
                      }}
                    />
                  </InputValidator>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={12} className={classes.headerRight}>
                  <b>Note to Buyer</b>
                </Grid>
                <Grid item md={6} xs={12} className={classes.valueLeft}>
                  <InputValidator className={classes.fieldsContainer}>
                    <RoundedInput
                      rows={3}
                      multiline={true}
                      value={this.state.noteToBuyer || application.noteToBuyer}
                      onChange={e => {
                        this.onChange(e, 'noteToBuyer')
                      }}
                    />
                  </InputValidator>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid item md={6} xs={12} className={classes.headerRight}>
                  <b>Note to Supplier</b>
                </Grid>
                <Grid item md={6} xs={12} className={classes.valueLeft}>
                  <InputValidator className={classes.fieldsContainer}>
                    <RoundedInput
                      rows={3}
                      multiline={true}
                      value={this.state.noteToSupplier || application.noteToSupplier}
                      onChange={e => {
                        this.onChange(e, 'noteToSupplier')
                      }}
                    />
                  </InputValidator>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SectionHeader>Address Book</SectionHeader>
              <p>At least one entry is required.</p>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <AddressBook appId={this.props.params && this.props.params.id} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader>Contact Directory</SectionHeader>
              <p>At least one entry is required.</p>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <ContactDirectory appId={this.props.params && this.props.params.id} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader>Business Classifications</SectionHeader>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <BusinessClassification appId={this.props.params && this.props.params.id} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader>Products and Services</SectionHeader>
              <p>At least one entry is required.</p>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Service appId={this.props.params && this.props.params.id} />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{marginTop: 50}}>
              <Grid container justify="flex-end">
                <Grid item xs={12} lg={6} md={12}>
                  <ContractorFooter
                    saveLater={this.saveLater}
                    previousLink={`/contractor/register/${application.appId}`}
                    currentPage={2}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    )
  }
}

CompanyDetail.displayName = 'CompanyDetail'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, createError}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractor,
    address: state.address,
    contractorContact: state.contractorContact,
    service: state.service,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(CompanyDetail)
