import {withStyles} from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'

export default withStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
  select: {
    width: 'calc(100% - 16px)',
  },
}))(Select)
