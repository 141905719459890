import React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Grid} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    [theme.breakpoints.down('sm')]: {},
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -60,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 40,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      height: 280,
    },
  },
  headerTitleContainer: {
    maxWidth: 1400,
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    '& h2': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      backgroundColor: '#E63B43',
      color: 'white',
      padding: '40px 120px 25px 50px',
      margin: 0,
      fontSize: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        width: '100%',
        textAlign: 'left',
        padding: '10px 10px 10px 25px',
      },
    },
  },
  middleContainer: {
    maxWidth: 1400,
    margin: '0 auto',
    fontSize: '15px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 30px)',
      marginTop: 10,
    },
    '& h3': {
      fontSize: '25px',
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      padding: 0,
      margin: 0,
      marginBottom: 30,
      paddingLeft: 22,
      fontSize: '14px',
    },
    '& li:before': {
      content: "''",
      display: 'block',
      float: 'left',
      width: 11,
      height: 11,
      margin: '4px 15px 0 -20px',
      backgroundColor: '#E63B43',
    },
  },
  containerLeft: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  containerRight: {
    '& div': {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
      padding: '7px 20px',
      fontStyle: 'italic',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 40,
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  supportImage: {
    width: '100%',
    height: 'auto',
    marginBottom: 20,
  },
})

class Page extends React.Component {
  render() {
    const {classes, construction, trenchless} = this.props
    const {slug} = this.props.params
    const {path} = this.props.route

    let page = false
    if (path.indexOf('/construction') === 0) {
      page = construction.filter(item => item.urlTitleSlug == slug)
      if (page.length) {
        page = page[0]
      }
    } else if (path.indexOf('/trenchless') === 0) {
      page = trenchless.filter(item => item.urlTitleSlug == slug)
      if (page.length) {
        page = page[0]
      }
    }

    if (!page) {
      return null
    }

    return (
      <Grid container className={classes.container}>
        <Grid
          item
          xs={12}
          className={classes.header}
          style={{backgroundImage: `url(${page.headerImage.fullSize})`}}
        >
          <div className={classes.headerTitleContainer}>
            <h2>{page.title}</h2>
          </div>
        </Grid>
        <Grid item xs={12} container className={classes.middleContainer} justify="space-between">
          <Grid item xs={12} md={5} className={classes.containerLeft}>
            {page.image1 && (
              <img src={page.image1.fullSize} alt="Support" className={classes.supportImage} />
            )}
            {page.image2 && (
              <img src={page.image2.fullSize} alt="Support" className={classes.supportImage} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            dangerouslySetInnerHTML={{__html: page.content}}
            className={classes.containerRight}
          />
        </Grid>
      </Grid>
    )
  }
}

Page.displayName = 'Construction & Trenchless Page'

function mapStateToProps(state) {
  return {
    ...state.ctPages,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withWidth(),
)(Page)
