import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid, InputLabel} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cipp from '../../assets/cipp.png'
import RoundedInput from '../common/RoundedInput'
import RoundedSelect from '../common/RoundedSelect'
import RoundedButton from '../common/RoundedButton'
import Contries from '../utils/Countries'
import InputValidator from '../common/InputValidator'
import * as Actions from './redux/actions'
import PageUtils from '../utils/PageUtils'
import UserSession from '../auth/UserSession'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& h1': {
      textAlign: 'center',
      marginTop: -264,
      color: 'white',
      fontSize: 44,
    },
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 300,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
      height: 150,
      '& h1': {
        fontSize: 28,
        marginTop: -103,
      },
    },
  },
  headerImage: {
    width: '100%',
    height: '466',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150',
      marginTop: 49,
    },
  },
  leftText: {
    width: '100%',
    marginLeft: 200,
    fontFamily: 'Century-Gothic',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 28,
      marginTop: 80,
      '& h1': {
        fontSize: 23,
      },
    },
  },
  cippFormItemContainer: {
    marginBottom: 10,
    fontSize: 16,
  },
  formRequired: {
    color: '#FF0000',
  },
  cippFieldsContainer: {
    padding: 20,
    backgroundColor: '#FFFFFF',
    marginRight: 200,
    width: 447,
    float: 'right',
    marginTop: -273,
    boxShadow: '0 2px 11px 3px rgba(0,0,0,0.12)',
    marginBottom: 100,
    '& label': {
      color: 'black',
      fontSize: '16px',
      fontWeight: 600,
      position: 'relative',
      transform: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      order: 1,
      marginTop: 20,
      maxWidth: 'calc(100% - 40px)',
      marginRight: 21,
      marginBottom: 35,
    },
  },
  bottomRequiredSpan: {
    textAlign: 'right',
    fontSize: '16px',
    color: '#FF0000',
    lineHeight: '20px',
    letterSpacing: '0.34px',
  },
})

class CIPPCalculatorRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      payload: {
        country: 'USA',
      },
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    PageUtils.scrollToTop()
    if (UserSession.getRequestSubmitted()) {
      this.props.router.push('/cipp-calculator')
    }
  }

  onChange(e, fieldName) {
    e.persist()
    this.setState(state => {
      return {payload: {...state.payload, [fieldName]: e.target.value}}
    })
  }

  onSubmit() {
    const {payload} = this.state
    this.props.cippCalculator(payload)
  }

  render() {
    const {classes, isFetching, errors} = this.props

    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.header}>
            <img src={cipp} className={classes.headerImage} />
            <h1>CIPP Calculator</h1>
          </Grid>
          <Grid item xs={12} className={classes.middleSection}>
            <Grid item xs={12} className={classes.leftText}>
              <h1>Calculator Use Request</h1>
              <p>
                125 S.E. 5th Court
                <br />
                Deerfield Beach, Florida 33441-4749
                <br />
                Phone:
                <a href="tel:1-954-979-0802">(954)-979-0802</a>
                <br />
                Fax:
                <a href="fax:1-954-979-9897">(954)-979-9897</a>
              </p>
              <p>
                3800 Woodward Avenue
                <br />
                Suite 410
                <br />
                Detroit, MI 48210
                <br />
                Phone:
                <a href="tel:1-313-831-2904">(313)-831-2904</a>
                <br />
                Fax:
                <a href="fax=313-831-2905">(313)-831-2905</a>
              </p>
              <p>
                Email: <a href="mailto:fredt@lanzo.org">Fred Tingberg</a>
              </p>
            </Grid>
            <Grid item container xs={12} className={classes.cippFieldsContainer}>
              <Grid item container xs={12} spacing={8}>
                <Grid item xs={12}>
                  <InputValidator errors={errors.name} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="name">
                      <span className={classes.formRequired}>*</span>
                      Name
                    </InputLabel>
                    <RoundedInput
                      id="name"
                      onChange={e => {
                        this.onChange(e, 'name')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12}>
                  <InputValidator errors={errors.title} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="title">
                      Title
                    </InputLabel>
                    <RoundedInput
                      id="title"
                      onChange={e => {
                        this.onChange(e, 'title')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12}>
                  <InputValidator errors={errors.company} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="company">
                      <span className={classes.formRequired}>*</span>
                      Company
                    </InputLabel>
                    <RoundedInput
                      id="company"
                      onChange={e => {
                        this.onChange(e, 'company')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12}>
                  <InputValidator errors={errors.email} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="email">
                      <span className={classes.formRequired}>*</span>
                      Email
                    </InputLabel>
                    <RoundedInput
                      id="email"
                      onChange={e => {
                        this.onChange(e, 'email')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12}>
                  <InputValidator errors={errors.address} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="address">
                      <span className={classes.formRequired}>*</span>
                      Address
                    </InputLabel>
                    <RoundedInput
                      id="address"
                      onChange={e => {
                        this.onChange(e, 'address')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={8} md={6}>
                  <InputValidator errors={errors.city} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="city">
                      <span className={classes.formRequired}>*</span>
                      City
                    </InputLabel>
                    <RoundedInput
                      id="city"
                      onChange={e => {
                        this.onChange(e, 'city')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={4} md={2}>
                  <InputValidator errors={errors.state} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="state">
                      State
                    </InputLabel>
                    <RoundedInput
                      id="state"
                      onChange={e => {
                        this.onChange(e, 'state')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputValidator errors={errors.country} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="country">
                      <span className={classes.formRequired}>*</span>
                      Country
                    </InputLabel>
                    <RoundedSelect
                      native
                      onChange={e => {
                        this.onChange(e, 'country')
                      }}
                      input={<RoundedInput name="country" id="country" />}
                    >
                      {Contries.map(country => (
                        <option value={country.name}>{country.name}</option>
                      ))}
                    </RoundedSelect>
                  </InputValidator>
                </Grid>
                <Grid item xs={12}>
                  <InputValidator errors={errors.zipcode} className={classes.cippFormItemContainer}>
                    <InputLabel shrink htmlFor="zipcode">
                      <span className={classes.formRequired}>*</span>
                      Zip Code
                    </InputLabel>
                    <RoundedInput
                      id="zipcode"
                      onChange={e => {
                        this.onChange(e, 'zipcode')
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12}>
                  <RoundedButton disabled={isFetching} onClick={this.onSubmit}>
                    {isFetching ? 'I Agree' : 'I Agree'}
                  </RoundedButton>
                </Grid>
                <Grid item xs={12} className={classes.bottomRequiredSpan}>
                  <span>* Required</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
      </div>
    )
  }
}

CIPPCalculatorRequest.displayName = 'CIPP-Calculator-Request'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.cippCalculator,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(CIPPCalculatorRequest)
