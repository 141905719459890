import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../../common/redux/actions.notifications'

export default function* classificationFlow() {
  yield all([
    takeEvery(Types.CREATE_BUSINESS_CLASSIFICATION, createBusinessClassification),
    takeEvery(Types.GET_BUSINESS_CLASSIFICATIONS, getBusinessClassifications),
    takeEvery(Types.UPDATE_BUSINESS_CLASSIFICATION, updateBusinessClassification),
    takeEvery(Types.DELETE_BUSINESS_CLASSIFICATION, deleteBusinessClassification),
    takeEvery(Types.BULK_CREATE_BUSINESS_CLASSIFICATION, bulkCreateBusinessClassification),
    takeEvery(Types.BULK_UPDATE_BUSINESS_CLASSIFICATION, bulkUpdateBusinessClassification),
    takeEvery(Types.GET_BUSINESS_CLASSIFICATION_DATA, getBusinessClassificationData),
  ])
}

function* createBusinessClassification(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createBusinessClassification, payload)
    yield put(Actions.createBusinessClassificationSuccess(response))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.createBusinessClassificationFailure(error))
  }
}

function* bulkCreateBusinessClassification(action) {
  const {payload} = action
  try {
    const response = yield call(Api.bulkCreateBusinessClassification, payload)
    yield put(Actions.bulkCreateBusinessClassificationSuccess(response))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.bulkCreateBusinessClassificationFailure(error))
  }
}

function* getBusinessClassifications(action) {
  const {params} = action
  try {
    const response = yield call(Api.getBusinessClassifications, params)
    yield put(Actions.getBusinessClassificationsSuccess(response))
  } catch (error) {
    yield put(Actions.getBusinessClassificationsFailure(error))
  }
}

function* getBusinessClassificationData(action) {
  const {params} = action
  try {
    const response = yield call(Api.getBusinessClassificationData, params)
    yield put(Actions.getBusinessClassificationDataSuccess(response))
  } catch (error) {
    yield put(Actions.getBusinessClassificationDataFailure(error))
  }
}

function* updateBusinessClassification(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateBusinessClassification, id, data)
    yield put(Actions.updateBusinessClassificationSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.updateBusinessClassificationFailure(error))
  }
}

function* bulkUpdateBusinessClassification(action) {
  const {appId, data} = action
  try {
    const response = yield call(Api.bulkUpdateBusinessClassification, appId, data)
    yield put(Actions.bulkUpdateBusinessClassificationSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.bulkUpdateBusinessClassificationFailure(error))
  }
}

function* deleteBusinessClassification(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteBusinessClassification, id, appId)
    yield put(Actions.deleteBusinessClassificationSuccess(id))
    yield put(NotificationActions.createSuccess('Deleted successfully'))
  } catch (error) {
    yield put(Actions.deleteBusinessClassificationFailure(error))
  }
}
