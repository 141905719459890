export const CREATE_SUPPLIER_CLASSIFICATION = 'CREATE_SUPPLIER_CLASSIFICATION'
export const CREATE_SUPPLIER_CLASSIFICATION_SUCCESS = 'CREATE_SUPPLIER_CLASSIFICATION_SUCCESS'
export const CREATE_SUPPLIER_CLASSIFICATION_FAILURE = 'CREATE_SUPPLIER_CLASSIFICATION_FAILURE'

export const GET_SUPPLIER_CLASSIFICATIONS = 'GET_SUPPLIER_CLASSIFICATIONS'
export const GET_SUPPLIER_CLASSIFICATIONS_SUCCESS = 'GET_SUPPLIER_CLASSIFICATIONS_SUCCESS'
export const GET_SUPPLIER_CLASSIFICATIONS_FAILURE = 'GET_SUPPLIER_CLASSIFICATIONS_FAILURE'

export const UPDATE_SUPPLIER_CLASSIFICATION = 'UPDATE_SUPPLIER_CLASSIFICATION'
export const UPDATE_SUPPLIER_CLASSIFICATION_SUCCESS = 'UPDATE_SUPPLIER_CLASSIFICATION_SUCCESS'
export const UPDATE_SUPPLIER_CLASSIFICATION_FAILURE = 'UPDATE_SUPPLIER_CLASSIFICATION_FAILURE'

export const DELETE_SUPPLIER_CLASSIFICATION = 'DELETE_SUPPLIER_CLASSIFICATION'
export const DELETE_SUPPLIER_CLASSIFICATION_SUCCESS = 'DELETE_SUPPLIER_CLASSIFICATION_SUCCESS'
export const DELETE_SUPPLIER_CLASSIFICATION_FAILURE = 'DELETE_SUPPLIER_CLASSIFICATION_FAILURE'

export const RESET_SUPPLIER_CLASSIFICATION_ERRORS = 'RESET_SUPPLIER_CLASSIFICATION_ERRORS'

export const GET_SUPPLIER_CLASSIFICATIONS_DATA = 'GET_SUPPLIER_CLASSIFICATIONS_DATA'
export const GET_SUPPLIER_CLASSIFICATIONS_DATA_SUCCESS = 'GET_SUPPLIER_CLASSIFICATIONS_DATA_SUCCESS'
export const GET_SUPPLIER_CLASSIFICATIONS_DATA_FAILURE = 'GET_SUPPLIER_CLASSIFICATIONS_DATA_FAILURE'
