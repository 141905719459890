import * as Types from './actions.types'

export function cippCalculator(payload) {
  return {type: Types.CIPP_CALCULATOR, payload}
}

export function cippCalculatorSuccess() {
  return {type: Types.CIPP_CALCULATOR_SUCCESS}
}

export function cippCalculatorFailure(error) {
  return {type: Types.CIPP_CALCULATOR_FAILURE, error}
}
