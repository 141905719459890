import imgLogo from '../../assets/logo.png'
import imgFooterBackground from '../../assets/footer-background.png'
import imgIconLanzoCares from '../../assets/lanzo-cares.png'
import imgLogoFbBig from '../../assets/logo-fb-big.svg'
import imgLogoInBig from '../../assets/logo-in-big.svg'
import imgLogoTwitterBig from '../../assets/logo-twitter-big.svg'
import imgProjectsQuestionsBg from '../../assets/projects-questions-bg.png'
import imgAngleRight from '../../assets/icons/angle-right.svg'

export default class ImageUtils {
  static getAppLogoUrl() {
    return imgLogo
  }

  static getFooterBgUrl() {
    return imgFooterBackground
  }

  static getIconLanzoCares() {
    return imgIconLanzoCares
  }

  static getLogoFbBig() {
    return imgLogoFbBig
  }

  static getLogoInBig() {
    return imgLogoInBig
  }

  static getLogoTwitterBig() {
    return imgLogoTwitterBig
  }

  static getProjectsQuestionsBg() {
    return imgProjectsQuestionsBg
  }

  static getAngleRight() {
    return imgAngleRight
  }
}
