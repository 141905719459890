import React from 'react'

class Masonry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {columns: 3}
  }

  mapChildren() {
    const col = []
    const numC = this.state.columns
    for (let i = 0; i < numC; i++) {
      col.push([])
    }
    return this.props.children.reduce((p, c, i) => {
      p[i % numC].push(c)
      return p
    }, col)
  }

  render() {
    return (
      <div className="masonry">
        {this.mapChildren().map((col, ci) => {
          return (
            <div className="column" key={ci}>
              {col.map((child, i) => {
                return <div key={i}>{child}</div>
              })}
            </div>
          )
        })}
      </div>
    )
  }
}

export default Masonry
