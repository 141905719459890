const styles = theme => ({
  page: {
    padding: '0px 0 0 0',
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '92%',
    },
  },
  title: {
    textAlign: 'center',
    margin: '0 0 40px 0',
  },
  card: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  cardCentered: {
    maxWidth: '700px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardActions: {
    marginTop: '-50px',
    borderTop: '1px solid #ccc',
    justifyContent: 'center',
  },
  cardContent: {
    padding: '100px',
  },
  confirmButton: {
    marginTop: '20px',
  },
  inputField: {
    background: '#F0F0F0',
    padding: 16,
    border: 0,
  },
  button: {
    height: 45,
    backgroundColor: '#EA221E',
    color: '#FFF',
    fontFamily: 'Source Sans Pro',
    fontSize: '17px',
    fontWeight: 600,
    borderRadius: 0,
  },
  login: {
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
})

export default styles
