import {createMuiTheme} from '@material-ui/core/styles'

export default createMuiTheme({
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'],
    fontSize: 14,
    useNextVariants: true,
    subtitle1: {
      fontWeight: '500',
    },
  },
  SvgIcon: {
    width: '2em',
  },
  palette: {
    primary: {main: '#E63B43'},
    secondary: {main: '#393939'},
    background: {default: '#FFF'},
  },
})
