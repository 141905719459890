import React from 'react'
import {Grid, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {Link} from 'react-router'
import PageUtils from '../utils/PageUtils'
import corporate from '../../assets/corporateHeader.png'
import downArrow from '../../assets/downArrow.png'

const columnWidth = 831

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 300,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
  headerImage: {
    width: '100%',
    height: '500',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150',
      marginTop: 48,
    },
  },
  downArrow: {
    margin: '50 auto 10',
    [theme.breakpoints.down('sm')]: {
      margin: '20 auto 20',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 193,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    fontSize: 34,
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 20,
      top: 0,
      left: 0,
      width: '100%',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #FFFFFF',
      [theme.breakpoints.down('sm')]: {
        fontSize: 27,
      },
    },
    '& p': {
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '18pt',
      },
    },
  },
  blurb: {
    margin: '0 auto',
    padding: '30px 0 35px',
    fontSize: '15',
    fontFamily: 'century-gothic, sans-serif',
    lineHeight: '1.3',
    width: '1700',
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #e63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '.34',
      fontStyle: 'normal',
      fontWeight: 'normal',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginLeft: 0,
      },
    },
  },
  blurbText: {
    margin: '0 auto',
    width: columnWidth,
    lineHeight: '24px',
    '& li::before': {
      top: -3,
    },
    '& ul': {
      listStyle: 'none',
    },
    '& h4': {
      marginBottom: 10,
    },
    '& h3': {
      marginTop: 25,
      marginBottom: -10,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: '0 20 0 20',
    },
  },
  black: {
    backgroundColor: '#171717',
    color: 'white',
  },
  white: {
    backgroundColor: '#fff',
  },
  topBlurb: {
    margin: '322 auto 20',
    '& p': {
      fontSize: 15,
      fontFamily: 'century-gothic, sans-serif',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginTop: 20,
      marginBottom: 20,
    },
  },
  buttonAndHeader: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
      marginBottom: 89,
      '& h3': {
        fontSize: 14,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  },
  buttonRisk: {
    marginLeft: '50%',
    marginTop: -20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  customList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      padding: 0,
      margin: 0,
      marginBottom: 30,
      paddingLeft: 30,
    },
    '& li:before': {
      content: "''",
      display: 'block',
      float: 'left',
      width: 11,
      height: 11,
      margin: '5px 15px 0 -30px',
      backgroundColor: '#E63B43',
    },
  },
})

class CorporatePolicies extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={corporate} className={classes.headerImage} />
          <div className={classes.headerFloatContainer}>
            <h2>General Risk Control and Management Policy</h2>
            <p>
              The Board of Directors of Lanzo Construction Company (“LANZO” or the “Company”)
              oversees the management of LANZO and its business with a view to enhance the long-term
              value of LANZO for its shareholders. The Board of Directors of LANZO (the “Board of
              Directors”) has adopted this General Risk Control and Management Policy (the “Policy”)
              to assist in exercising its responsibilities to LANZO and its shareholders.{' '}
            </p>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} className={classes.downArrow} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.topBlurb}>
              <div className={classes.blurbText}>
                <p>
                  This Policy is subject to periodic review and modification by the Board of
                  Directors from time to time. This Policy and the Company’s Certificate of
                  Incorporation, By Laws, Corporate Governance Guidelines and other policies
                  pertaining to corporate governance and regulatory compliance, risk, sustainable
                  development, and social responsibility (collectively, the “Corporate Governance
                  System”) form the framework of governance of LANZO and its subsidiaries
                  (collectively, the “LANZO Group”). LANZO’s Corporate Governance System is inspired
                  by and based on a commitment to ethical principles transparency and leadership in
                  the application of best practices in good governance and is designed to be a
                  working structure for principled actions, effective decision-making and
                  appropriate monitoring of both compliance and performance.{' '}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
          <div className={classes.blurbText}>
            <h2>Purpose</h2>
            <h3>Purpose</h3>
            <p>
              The purpose of this Policy is to establish the basic principles and general framework
              for the control and management of all kinds of risks facing the Company and the LANZO
              Group, and which must be applied in accordance with the mission, vision and values of
              the LANZO Group approved by the LANZO Board of Directors. This Policy is further
              developed and supplemented by the Corporate Risk Policies and the Specific Risk
              Policies that may be established for certain businesses and/or companies within the
              LANZO Group. The heads of Lanzo’s divisions must approve within their corresponding
              management decision-making, the specific risk limits applicable to each of them and
              implement the control systems required to ensure compliance therewith.
            </p>
            <h3>Scope</h3>
            <p>
              This Policy applies to all companies and divisions that make up the LANZO Group.
              Excluded from the scope of this policy are listed subsidiaries, if any, which have
              their own Corporate Policies approved by their competent bodies. At those companies in
              which LANZO has an interest and to which this Policy does not apply, LANZO shall
              promote principles, guidelines, and risk limits consistent with those established in
              the Policy and in its supplemental Risk Policies and shall maintain appropriate
              channels of information to ensure a proper understanding of risks.
            </p>
            <h3>Risk Factors--Definitions</h3>
            <p>
              From a general viewpoint, a risk is considered to be any threat that an event, action,
              or omission may prevent the LANZO Group from reaching its objectives and successfully
              carrying out its strategies.
            </p>
            <p>The risk factors to which the LANZO Group is subjec generally are listed below:</p>
            <ul>
              <h4>a) Corporate Governance Risks:</h4>
              <li>
                LANZO accepts the need to achieve the fulfilment of the corporate interest and the
                sustained maximization of the economic value of LANZO and its long-term success, in
                accordance with the LANZO Group’s corporate interest, culture, and corporate vision,
                taking into account the legitimate public and private interests that converge in the
                conduct of all business activities, particularly those of the various General Risk
                Control Management Policy. A fundamental requirement for the foregoing is compliance
                with the LANZO’s Corporate Governance System, which is inspired by the good
                governance recommendations generally recognized in international markets and
                consists of LANZO’s By-Laws, Corporate Policies, the Internal Corporate Governance
                Rules, and the Other Internal Codes and Procedures approved by the competent
                decision-making bodies of the Company.
              </li>
              <h4>b) Market Risks:</h4>
              <li>
                Defined as the exposure of the LANZO Group’s results and net worth to changes in
                market prices and variables, such as exchange rates, interest rates, commodity
                prices (electricity, gas, CO2 emission allowances, other fuel, etc.), prices of
                financial assets, and others.
              </li>
              <h4>c) Credit Risks:</h4>
              <li>
                Defined as the possibility that a counterparty fails to perform its contractual
                obligations, thus causing an economic or financial loss to the LANZO Group.
                Counterparties can be end customers, counterparties in financial or energy markets,
                partners, suppliers, or contractors.
              </li>
              <h4>Business Risks:</h4>
              <li>
                Defined as the uncertainty regarding the performance of key variables inherent in
                the business, such as the characteristics of demand, weather conditions, and the
                strategies of different stakeholders.
              </li>
              <h4>e) Regulatory Risks:</h4>
              <li>
                Defined as those arising from regulatory changes made by the various regulators,
                such as changes in compensation of regulated activities or in the required
                conditions of supply, or in environmental or tax regulations.{' '}
              </li>
              <h4>f) Operational, Technological, Environmental, and Social Risks:</h4>
              <li>
                Defined as those related to direct or indirect economic losses resulting from
                external events, inadequate internal procedures, technical failures, human error
                and/or fraud, including the risk related to climate change, information technology,
                cybersecurity and technological obsolescence.
              </li>
              <h4>g) Reputational Risks:</h4>
              <li>
                Includes the potential public negative impact on the company value and brand of
                LANZO and its subsidiaries resulting from internal or external factors that degrade
                expectations created among various stakeholders. This includes the potential loss in
                revenue, brand degradation and/or the destruction of shareholder value by
                operational, environmental, cyber, regulatory or other factors associated with
                potential reputational damage.
              </li>
            </ul>
            <h3>Basic Principles</h3>
            <p>
              The LANZO Construction Company is subject to various risks inherent in the different
              industries and markets in which it does business, and in the activities, it carries
              out, which may prevent it from achieving its objectives and successfully implementing
              its strategies.
            </p>
            <p>
              Aware of the significance of this issue, the Board of Directors will develop all of
              its capabilities in order to adequately identify, measure, manage and control
              significant corporate risks to all the activities and businesses of the LANZO Group.
              It will establish, through this Policy, the mechanisms and basic principles for
              appropriate management of the risk/opportunity ratio, at a risk level that makes it
              possible to:
            </p>
            <ul>
              <li>
                a) attain the strategic objectives formulated by the LANZO Construction Company with
                controlled volatillity;
              </li>
              <br />
              <li>b) provide the maximum level of assurance to the shareholders;</li>
              <br />
              <li>c) protect the results and reputation of the LANZO Group;</li>
              <br />
              <li>
                d) defend the interests of customers, employees, shareholders, other groups
                interested in the progress of LANZO, and society in general; and
              </li>
              <br />
              <li>
                e) ensure corporate stability and financial strength in a sustained fashion over
                time.
              </li>
            </ul>
            <p>
              In the implementation of the aforementioned commitment, the Board of Directors has the
              cooperation of the Audit and Compliance officers, which, as a consultative body,
              monitors and reports upon the appropriateness of the system for assessment and
              internal control of significant risks, acting in coordination with the audit and
              compliance officers existing at the LANZO Group.
            </p>
            <p>
              All actions aimed at controlling and mitigating risks shall conform to the following
              basic principles:
            </p>
            <ul>
              <li>
                a) Integrate the risk/opportunity vision into LANZO’s management, through a
                definition of the strategy and the risk appetite and the incorporation of this
                variable into strategic and operating decisions.
              </li>
              <br />
              <li>
                b) Segregate functions, at the operating level, between risk-taking areas and areas
                responsible for the analysis, control, and monitoring of such risks, ensuring an
                appropriate level of independence.
              </li>
              <br />
              <li>
                {' '}
                c) Guarantee the proper use of risk-hedging instruments and the maintenance of
                records thereof as required by applicable law.{' '}
              </li>
              <br />
              <li>
                d) Inform regulatory agencies and the principal external players, in a transparent
                fashion, regarding the risks facing the LANZO Group and the operation of the systems
                developed to monitor such risks, maintaining suitable channels that favor
                communication.{' '}
              </li>
              <br />
              <li>
                e) Ensure appropriate compliance with the corporate governance rules established by
                the LANZO through its Corporate Governance System. Update and continuously improve
                this system within the framework of the best international practices regarding
                transparency and good governance and implement the monitoring and measurement
                thereof.{' '}
              </li>
              <br />
              <li>
                f) Act at all times in compliance with the law and LANZO’s Corporate Governance
                System and, specifically, in accordance with the values and standards reflected in
                the Code of Business Conduct and Ethics and under the principle of “zero tolerance”
                for the commission of unlawful acts and situations of fraud set forth in the Crime
              </li>
            </ul>
            <h3>Risk Policies and Limits</h3>
            <p>
              This Policy is further developed and supplemented by the Corporate Risk Policies and
              the Specific Risk Policies established in connection with certain businesses of the
              LANZO Group, which are listed below and are also subject to approval by the Board of
              Directors.
            </p>
            <p>
              The following policies will be developed/modified and upgraded on a regular basis.
              Corporate Risk Policies:
            </p>
            <ul className={classes.customList}>
              <li>Corporate Credit Risk Policy</li>
              <li>Corporate Market Risk Policy</li>
              <li>Operational Market Transaction Risk Policy</li>
              <li>Operational Insurance Risk Policy</li>
              <li>Investment Risk Policy</li>
              <li>Financing and Financial Risk Policy</li>
              <li>Reputational Risk Policy</li>
              <li>Informaiton Technology Risk Policy</li>
              <li>Cybersecurity Risk Policy</li>
              <li>Purchasing Risk Policy</li>
            </ul>
            <div className={classes.buttonAndHeader}>
              {' '}
              <h3>Corporate Policies - Risks and Code of Conduct</h3>
              <Button
                className={classes.buttonRisk}
                component={Link}
                to="/corporate-risks-conduct"
                variant="contained"
                color="primary"
              >
                <span>Click Here</span>
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

CorporatePolicies.displayName = 'Corporate-Policies'

export default withStyles(styles)(CorporatePolicies)
