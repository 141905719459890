import settings from '../../../config/settings'
import axios from '../api/axios'
import fileAxios from '../api/fileAxios'

export default class CareersApi {
  static jobs() {
    return axios.get(`${settings.apiBaseURL}/jobs`)
  }

  static submitApplication(payload) {
    if (payload.phone) {
      let parsedPhone = payload.phone.replace(/[()-_]/g, '').replace(/ /g, '')
      if (parsedPhone) {
        if (!parsedPhone.includes('+')) {
          parsedPhone = '+1' + parsedPhone
        }
        payload.phone = parsedPhone
      } else {
        delete payload.phone
      }
    }
    if (!payload.coverLetterFile) {
      delete payload.coverLetterFile
    }

    return fileAxios.post(`${settings.apiBaseURL}/job-applications`, payload)
  }
}
