import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {Button, Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import PageUtils from '../utils/PageUtils'
import ContractorHeader from './ContractorHeader'
import RoundedInput from '../common/RoundedInput'
import * as Actions from './redux/actions'
import InputValidator from '../common/InputValidator'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    '& h2': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 24,
      fontWeight: 'bold',
    },
    '& h1': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 34,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 16,
    },
  },
})

class RegistrationContinue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appId: '',
    }
    this.continueRegistration = this.continueRegistration.bind(this)
  }

  continueRegistration() {
    this.props.getApplication(this.state.appId)
  }

  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes, errors} = this.props

    return (
      <Grid container className={classes.container} justify="center">
        <ContractorHeader hideSaveLater={true} />
        <Grid item md={6} xs={12} style={{marginTop: 90}}>
          <Grid container justify="center">
            <h1 style={{textAlign: 'center'}}>
              Welcome to the Lanzo Prospective Supplier Registration
            </h1>
            <p>Enter Application ID to continue where you left off:</p>
            <Grid container justify="center">
              <Grid item md={8} xs={12}>
                <Grid container justify="center">
                  <InputValidator
                    errors={errors.detail ? 'Invalid Application ID' : errors.message}
                  >
                    <RoundedInput
                      style={{boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5);'}}
                      placeholder="e.g 9e064666-522d-4254-90dd-63963b3b338c"
                      onChange={e => {
                        this.setState({appId: e.target.value})
                      }}
                    />
                  </InputValidator>
                </Grid>
                <Grid container justify="center">
                  <Grid item md={8} xs={12} style={{marginTop: 20}}>
                    <Button
                      disabled={!this.state.appId}
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={this.continueRegistration}
                    >
                      CONTINUE
                    </Button>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Grid container justify="center" style={{marginTop: 10, marginBottom: 10}}>
                      <p>OR</p>
                    </Grid>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Button
                      component={Link}
                      to="/contractor/register"
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      START NEW APPLICATION
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

RegistrationContinue.displayName = 'RegistrationContinue'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractor,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(RegistrationContinue)
