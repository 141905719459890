import React from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import {withStyles, IconButton, Icon} from '@material-ui/core'

const imageType = /^image\//
const styles = () => ({
  image: {
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
const cropLocal = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
}

class ImageEditor extends React.Component {
  constructor() {
    super()

    this.state = {
      localFileUrl: null,
      isEditMode: false,
    }
  }

  componentWillMount() {
    const {crop} = this.props

    this.setState({crop})
  }

  render() {
    const {localFileUrl, isEditMode, crop} = this.state
    const {src, fallbackImage, disableCrop, classes} = this.props

    return (
      <div className="image-editor">
        {!isEditMode && (
          <div className="image-editor-non-edit">
            <img className={classes.image} src={src || fallbackImage} />
          </div>
        )}

        {isEditMode &&
          !disableCrop && (
            <div className="image-editor-edit">
              {localFileUrl || src ? (
                <div>
                  <ReactCrop
                    src={localFileUrl || src}
                    crop={crop}
                    onChange={this.onCropChange.bind(this)}
                    onImageLoaded={this.onCropComplete.bind(this)}
                    onComplete={this.onCropComplete.bind(this)}
                  />
                </div>
              ) : null}
            </div>
          )}

        {isEditMode &&
          disableCrop && (
            <div className="image-editor-non-edit">
              <img className={classes.image} src={localFileUrl || src || fallbackImage} />
            </div>
          )}
        <div className={classes.buttons}>
          <IconButton component="label">
            <Icon aria-label="Upload Image" className="fa fa-upload" />
            <input
              className={classes.input}
              type="file"
              ref={node => {
                this.file = node
              }}
              accept="image/*"
              onChange={this.onFileChange.bind(this)}
            />
          </IconButton>
          <IconButton onClick={this.onReset.bind(this)}>
            <Icon aria-label="Delete Image" className="fa fa-trash" />
          </IconButton>
        </div>
      </div>
    )
  }

  onCropChange(crop) {
    this.setState({crop})
  }

  onReset(e) {
    if (e) {
      e.preventDefault()
    }

    this.file.value = null
    this.setState({localFileUrl: null})
    this.props.onReset()
  }

  onFileChange(e) {
    const file = e.target.files.item(0)
    if (!file || !imageType.test(file.type)) {
      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      this.setState({
        localFileUrl: e.target.result,
        crop: cropLocal,
        isEditMode: true,
      })

      if (this.props.disableCrop && this.props.onComplete) {
        this.props.onComplete(e.target.result)
      }
    }
    reader.readAsDataURL(file)
  }

  onCropComplete(crop) {
    let image = null
    image = document.querySelector('.ReactCrop__image-copy')
    const blob = this.getCropBlob(image, crop)
    if (this.props.onCropComplete) {
      this.props.onCropComplete({blob})
    }
  }

  getCropBlob(loadedImg, crop) {
    const imageWidth = loadedImg.naturalWidth
    const imageHeight = loadedImg.naturalHeight

    const cropX = (crop.x / 100) * imageWidth
    const cropY = (crop.y / 100) * imageHeight

    const cropWidth = (crop.width / 100) * imageWidth
    const cropHeight = (crop.height / 100) * imageHeight

    const canvas = document.createElement('canvas')
    canvas.width = cropWidth
    canvas.height = cropHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(loadedImg, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight)

    if (HTMLCanvasElement.prototype.toBlob) {
      console.info(
        'It looks like Chrome now supports HTMLCanvasElement.toBlob.. time to uncomment some code!',
      )
    }

    //canvas.toBlob will be faster and non-blocking but is currently only supported in FF.
    // canvas.toBlob((blob) => {
    //   const url = URL.createObjectURL(blob);
    //
    //   imgDest.onload = function () {
    //     URL.revokeObjectURL(url);
    //     this.ready();
    //   };
    //
    //   imgDest.src = url;
    // });

    return canvas.toDataURL('image/png')
  }
}

ImageEditor.displayName = 'ImageEditor'
ImageEditor.propTypes = {
  src: PropTypes.string,
  fallbackImage: PropTypes.string,
  crop: PropTypes.object,
  onCropComplete: PropTypes.func,
  onComplete: PropTypes.func,
  disableCrop: PropTypes.bool,
}
ImageEditor.defaultProps = {
  crop: {
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  },
  disableCrop: false,
}

export default withStyles(styles)(ImageEditor)
