import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* constructionTrenchlessFlow() {
  yield all([takeEvery(Types.GET_VIDEOS, getVideos)])
}

function* getVideos() {
  try {
    const response = yield call(Api.getVideos)
    yield put(Actions.getVideosSuccess(response))
  } catch (error) {
    yield put(Actions.getVideosFailure(error))
  }
}
