export const CREATE_PROJECT_INTEREST = 'CREATE_PROJECT_INTEREST'
export const CREATE_PROJECT_INTEREST_SUCCESS = 'CREATE_PROJECT_INTEREST_SUCCESS'
export const CREATE_PROJECT_INTEREST_FAILURE = 'CREATE_PROJECT_INTEREST_FAILURE'

export const GET_PROJECT_INTERESTS = 'GET_PROJECT_INTERESTS'
export const GET_PROJECT_INTERESTS_SUCCESS = 'GET_PROJECT_INTERESTS_SUCCESS'
export const GET_PROJECT_INTERESTS_FAILURE = 'GET_PROJECT_INTERESTS_FAILURE'

export const UPDATE_PROJECT_INTEREST = 'UPDATE_PROJECT_INTEREST'
export const UPDATE_PROJECT_INTEREST_SUCCESS = 'UPDATE_PROJECT_INTEREST_SUCCESS'
export const UPDATE_PROJECT_INTEREST_FAILURE = 'UPDATE_PROJECT_INTEREST_FAILURE'

export const DELETE_PROJECT_INTEREST = 'DELETE_PROJECT_INTEREST'
export const DELETE_PROJECT_INTEREST_SUCCESS = 'DELETE_PROJECT_INTEREST_SUCCESS'
export const DELETE_PROJECT_INTEREST_FAILURE = 'DELETE_PROJECT_INTEREST_FAILURE'

export const RESET_PROJECT_INTEREST_ERRORS = 'RESET_PROJECT_INTEREST_ERRORS'

export const GET_PROJECT_INTERESTS_DATA = 'GET_PROJECT_INTERESTS_DATA'
export const GET_PROJECT_INTERESTS_DATA_SUCCESS = 'GET_PROJECT_INTERESTS_DATA_SUCCESS'
export const GET_PROJECT_INTERESTS_DATA_FAILURE = 'GET_PROJECT_INTERESTS_DATA_FAILURE'
