import * as Types from './actions.types'

export function getCTPages() {
  return {type: Types.GET_CT_PAGES}
}

export function getCTPagesSuccess(payload) {
  return {type: Types.GET_CT_PAGES_SUCCESS, payload}
}

export function getCTPagesFailure(error) {
  return {type: Types.GET_CT_PAGES_FAILURE, error}
}
