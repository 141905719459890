import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import PageUtils from '../utils/PageUtils'
import coreValues from '../../assets/coreValues.png'
import downArrow from '../../assets/downArrow.png'

const columnWidth = 950

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'century-gothic, sans-serif',
    },
  },

  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      backgroundSize: 'contain',
      width: '100%',
    },
  },

  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 279,
    bottom: 0,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
      height: 290,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      padding: '20',
      top: 0,
      left: 0,
      downArrow: {
        marginTop: '0',
      },
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '40px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 1,
      borderBottom: '7px solid #FFFFFF',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'Left',
        fontSize: '30px',
      },
    },
    '& p': {
      fontSize: '30px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'Left',
        fontSize: '15px',
        lineHeight: '22px',
      },
      '& div': {
        marginTop: '0px',
      },
    },
  },
  headerImage: {
    width: '100%',
    height: 500,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: 220,
    },
  },

  blurb: {
    margin: '0 auto',
    maxWidth: columnWidth,
    display: 'block',
    padding: '40px 37px 10px 37px',
    fontSize: '16',
    fontFamily: 'century-gothic',
    lineHeight: '1.3',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '25px 25px 0px 25px',
    },
    '& h2': {
      margin: '0 auto',
      display: 'inline-block',
      fontFamily: 'century-gothic',
      fontSize: '40px',
      fontStyle: 'italic',
      fontWeight: 'bold',
      marginBottom: 15,
      padding: '0',
      paddingBottom: 8,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        maxWidth: '100%',
        fontSize: '18px',
        margin: '0 auto 20 0',
        paddingTop: 12,
        paddingBottom: 10,
      },
    },
    '& p': {
      fontSize: '16px',
      fontFamily: 'century-gothic',
      lineHeight: '21px',
      letterSpacing: '.34',
      fontStyle: 'normal',
      fontWeight: 'normal',
      margin: 0,
      padding: 0,
      marginTop: 20,
    },
  },
  black: {
    backgroundColor: '#171717',
    color: 'white',
    display: 'block',
    margin: '0 auto',
    maxWidth: columnWidth,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      fontSize: '18px',
    },
  },
  white: {
    display: 'block',
    margin: '0 auto',
    backgroundColor: '#fff',
    maxWidth: columnWidth,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '100%',
    },
  },

  blurbText: {
    margin: '0 auto',
    paddingBottom: 0,
    '& h2': {
      borderBottom: '7px solid #e63B43',
      display: 'inline-block',
      fontFamily: 'century-gothic',
      fontSize: '29',
      [theme.breakpoints.down('sm')]: {
        fontSize: '23',
      },
    },
  },

  blurbList: {
    maxWidth: '100%',
    margin: '0 auto',
    fontSize: 16,
    '& h2': {
      borderBottom: '7px solid #e63B43',
      fontSize: '29px',
    },
  },

  sectionItem: {
    margin: '30px auto 50px',
    fontSize: '16',
    fontFamily: 'centuryGothicPro',
    lineHeight: '1.3',
    maxWidth: columnWidth,
    [theme.breakpoints.down('sm')]: {
      width: columnWidth,
    },
  },

  customList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      padding: 0,
      margin: 0,
      marginBottom: 30,
      paddingLeft: 30,
    },
    '& li:before': {
      content: "''",
      display: 'block',
      float: 'left',
      width: 11,
      height: 11,
      margin: '5px 15px 0 -30px',
      backgroundColor: '#E63B43',
    },
  },

  lanzoCaresContainer: {
    backgroundColor: '#fffff',
    color: '#171717',
    textAlign: 'center',
    fontSize: '29px',
    padding: '30px 0',
    '& span': {
      lineHeight: '72px',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: 0,
      padding: 0,
    },
    '& img': {
      marginRight: 25,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      padding: '20px 15px',
      '& span': {
        lineHeight: '15px',
        textAlign: 'left',
        marginBottom: 15,
      },
      '& img': {
        marginRight: 10,
      },
    },
  },
})

class Values extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={coreValues} className={classes.headerImage} />
          <div className={classes.headerFloatContainer}>
            <h2>Core Values</h2>
            <p>We Bring These Core Values to Every Project </p>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} style={{margin: '50 auto 20'}} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.blurb, classes.white)}>
              <div className={classNames(classes.blurbText)}>
                <h2>Safety</h2>
                <ul className={classNames(classes.white, classes.list, classes.customList)}>
                  <li>
                    At Lanzo; we are committed to the safety of our customers, our collegues, and
                    the communities we serve.{' '}
                  </li>
                  <li>
                    We are all entitled to go to a workplace where we can return safely to our
                    families at the end of the day.{' '}
                  </li>
                  <li>
                    No Job is that important, no reward great enough to risk ourselves or each
                    other.
                  </li>
                  <li>Safety is attitude, awareness, and commitment to best practices.</li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
              <div className={classes.blurbText}>
                <h2>Get it Done Right: Whatever it Takes</h2>
                <ul className={classNames(classes.black, classes.list, classes.customList)}>
                  <li>
                    As a recognized Major Leader in OUR Industry for over Fifty (50) Years we have
                    earned our reputation for delivering difficult, challenging, and quality
                    projects.
                  </li>
                  <li>
                    The history of our company includes many cases where an individual or group of
                    individuals labored tirelessly in adverse conditions to see a job through to a
                    successful completion; whether we delivered a Treatment Plant, a Tough to build
                    Water main or Large Diameter Sewer rehabilitation, the result was Done Right,
                    whatever it takes.
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.blurb, classes.white)}>
              <div className={classes.blurbText}>
                <h2>Teamwork, Smart Work, Hard Work</h2>
                <ul className={classNames(classes.white, classes.list, classes.customList)}>
                  <li>
                    At Lanzo; Teamwork, Smart Work and Hard Work define who we are. It is what we
                    offer our customers in exchange for their Trust, Loyalty, and Good Faith.
                  </li>
                  <li>
                    Hard work, commitment to completion, and reliance on each other as members of
                    the Lanzo Team drives our Success. A Team of five people is capable of far more
                    than five individuals working independently.
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
              <div className={classes.blurbText}>
                <h2>Respectful, Courteous and Professional</h2>
                <ul className={classNames(classes.black, classes.list, classes.customList)}>
                  <li>
                    We treat others the way we would want to be treated. It is about better
                    listening, giving the benefit of the doubt and resisting the urge to be
                    argumentative.
                  </li>
                  <li>
                    We believe that a smile is contagious, respect gets paid back "in kind" and
                    Professionals are who we all must strive to be. This is not only for our own
                    wellbeing but to serve the Greater Good of the Company. This creates a workplace
                    which is "Fun" to be at, a Job worth driving to, more rewarding than just the
                    value of our paycheck, a place where we can "make a difference".
                  </li>
                  <li>
                    Learn about our solutions to some of the toughest, complex sustainability
                    challenges across the globe including helping our customers deliverÂ cleaner
                    energy; supporting meaningfulÂ economic transitionsÂ when our projects are
                    complete; helping addressÂ water security; and creating smarter, greener
                    infrastructure to meet the demands ofÂ increased urbanization.
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.blurb, classes.white)}>
              <div className={classes.blurbText}>
                <h2>Open, Honest and Accountable</h2>
                <ul className={classNames(classes.white, classes.list, classes.customList)}>
                  <li>
                    We must be open and honest with each other. We strive to communicate our
                    strengths and weaknesses so that the Team may be given every opportunity to
                    prevail.
                  </li>
                  <li>
                    We take ownership of our responsibilities... No Hidden Agendas. We honor our
                    commitments individually and as a Company.
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
              <div className={classes.blurbText}>
                <h2>Innovate, Be Creative, Think “Outside the Box”</h2>
                <ul className={classNames(classes.black, classes.list, classes.customList)}>
                  <li>
                    The proven Lanzo way is to "Get it Done Right" through a combination of
                    creativity and innovation.
                  </li>
                  <li>
                    As we perpetuate our company, it will take this legacy of creativity and
                    innovation to adapt to ongoing changes within the industries, communities, and
                    markets in which we serve.
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Values.displayName = 'Values'

export default withStyles(styles)(Values)
