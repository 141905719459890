import * as Types from './actions.types'

export function createBusinessLine(payload) {
  return {type: Types.CREATE_BUSINESS_LINE, payload}
}

export function createBusinessLineSuccess(payload) {
  return {type: Types.CREATE_BUSINESS_LINE_SUCCESS, payload}
}

export function createBusinessLineFailure(error) {
  return {type: Types.CREATE_BUSINESS_LINE_FAILURE, error}
}

export function getBusinessLines(params) {
  return {type: Types.GET_BUSINESS_LINES, params}
}

export function getBusinessLinesSuccess(payload) {
  return {type: Types.GET_BUSINESS_LINES_SUCCESS, payload}
}

export function getBusinessLinesFailure(error) {
  return {type: Types.GET_BUSINESS_LINES_FAILURE, error}
}

export function updateBusinessLine(id, data) {
  return {type: Types.UPDATE_BUSINESS_LINE, id, data}
}

export function updateBusinessLineSuccess(payload) {
  return {type: Types.UPDATE_BUSINESS_LINE_SUCCESS, payload}
}

export function updateBusinessLineFailure(error) {
  return {type: Types.UPDATE_BUSINESS_LINE_FAILURE, error}
}

export function deleteBusinessLine(id, appId) {
  return {type: Types.DELETE_BUSINESS_LINE, id, appId}
}

export function deleteBusinessLineSuccess(id) {
  return {type: Types.DELETE_BUSINESS_LINE_SUCCESS, id}
}

export function deleteBusinessLineFailure(error) {
  return {type: Types.DELETE_BUSINESS_LINE_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_BUSINESS_LINE_ERRORS}
}

export function getBusinessLineData() {
  return {type: Types.GET_BUSINESS_LINES_DATA}
}

export function getBusinessLineDataSuccess(payload) {
  return {type: Types.GET_BUSINESS_LINES_DATA_SUCCESS, payload}
}

export function getBusinessLineDataFailure(error) {
  return {type: Types.GET_BUSINESS_LINES_DATA_FAILURE, error}
}
