import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Hidden from '@material-ui/core/es/Hidden/Hidden'
import PageUtils from '../utils/PageUtils'
import styles from './styles/Page.styles'
import hat from '../../assets/hat.png'
import question from '../../assets/question.png'

class Safety extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.title}>Safety at Lanzo</h2>
              <hr className={classes.titleLine} />
            </Grid>
            <Grid item xs={12} style={{marginTop: 15, marginBottom: 50}}>
              <Grid container justify="center">
                <Grid item xs={12} md={8}>
                  <Hidden mdUp={true}>
                    <div>
                      <img src={hat} style={{width: '100%'}} />
                    </div>
                  </Hidden>
                  <p>
                    On every project, we’re committed to zero accidents. We owe it to ourselves, our
                    Lanzo family, and to every family waiting at home, to always place safety ahead
                    of a desire to finish a job faster or cheaper.
                  </p>
                  <p>No exceptions! </p>
                  <p>
                    Our zero accidents mindset means everyone does their part to eliminate every
                    injury or incident, no matter how seemingly minor.{' '}
                  </p>
                  <p>
                    As early as the design phase, we look for ways to make the work safer to build.
                    We review our plans for potential safety concerns and address them before the
                    work can get started. Training is developed and delivered on the job site and in
                    the office to ensure we remain sharp and focused on our efforts.
                  </p>
                  <p>
                    We routinely evaluate our project and corporate level safety processes to verify
                    they’re being executed and confirm that they work.
                  </p>
                  <p className={classes.sectionTitle}>
                    As an organization, we’re pushing ourselves to make not only Lanzo, but our
                    entire industry safer.
                  </p>
                  <Hidden mdUp={true}>
                    <div>
                      <img src={question} style={{width: '100%'}} />
                    </div>
                  </Hidden>
                  <h2 className={classes.redCapTitle} style={{marginTop: 45, marginBottom: 0}}>
                    LANZO COMMITMENT TO SAFETY
                  </h2>
                  <p style={{marginTop: 25, marginBottom: 40}}>
                    Our commitment to safety and our pursuit of Zero Accidents Policy is defined by:
                  </p>
                  <ul className="custom-list">
                    <li>
                      Collaborating at all levels to lead safety efforts on our projects. We work
                      together to foster an environment where everyone is empowered and expected to
                      own safety. Our Safety programs ensures that people who are building the work
                      are taking the reins and driving our safety culture. They do so with the
                      support of our project management teams.{' '}
                    </li>
                    <li>
                      Executing our safety fundamentals Routine trainings, analyses and assessments
                      make up the fundamentals of every Lanzo project’s safety efforts.{' '}
                    </li>

                    <li>
                      Site-specific orientations and trainings are developed and delivered to
                      prepare our teams for the work.{' '}
                    </li>

                    <li>
                      A hazard analysis is required before any operation can begin, and each shift
                      starts with a safety meeting with foremen and crews.{' '}
                    </li>

                    <li>
                      Project safety inspection and assessments bring our teams together to identify
                      areas for improvement and best practices.{' '}
                    </li>

                    <li>
                      Reporting accidents of any nature allows us to learn from our safety trends
                      and make changes to ensure the zero-accident policy success.
                    </li>
                  </ul>
                  <p>
                    We’re diligent in our efforts to review, assess and improve our safety programs.
                    At our project levels, we complete execution assessments to analyze our
                    effectiveness. Further, corporate cold-eye reviews bring in individuals less
                    attached to the work for a fresh evaluation of the safety programs in place.
                    Executives safety tours, meetings and discussions ensure that leaders at the
                    highest level of our organization have ownership of and accountability for
                    Lanzo’s safety performance.
                  </p>
                  <p
                    className={classes.sectionTitleCenterMobile}
                    style={{marginTop: 20, marginBottom: 20}}
                  >
                    For questions or suggestions please contact our safety Department at:
                  </p>
                  <p className={classes.centerMobile}>
                    Lanzo Safety Director
                    <br />
                    Lanzo Construction Company
                    <br />
                    125 SE 5th Court <br />
                    Deerfield Beach, FL 33441
                    <br />
                    <a href="https://www.lanzo.net"> www.lanzo.net</a> <br />
                    <a href="mailto:safety@lanzo.org"> safety@lanzo.org</a>
                  </p>
                </Grid>
                <Hidden smDown={true}>
                  <Grid item md={4} style={{paddingLeft: 30}}>
                    <div className={classes.imageContainer}>
                      <img src={hat} style={{width: '100%'}} />
                    </div>
                    <div className={classes.imageContainer} style={{marginTop: 100}}>
                      <img src={question} style={{width: '100%'}} />
                    </div>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Safety.displayName = 'Safety'

export default withStyles(styles)(Safety)
