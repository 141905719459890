import {withStyles} from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

export default withStyles(() => ({
  root: {
    'label + &': {
      marginTop: 10,
    },
    width: '100%',
  },
  input: {
    borderRadius: 5,
    border: '1px solid #DFDFDF',
    backgroundColor: '#F9F9F9',
    padding: 8,
  },
}))(InputBase)
