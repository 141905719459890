import React from 'react'
import {Button, Grid} from '@material-ui/core'
import {Link} from 'react-router'
import PageUtils from '../utils/PageUtils'
import constImg from '../../assets/under-construction.svg'
import logo from '../../assets/logow_slogan.png'

class Soon extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    return (
      <Grid container justify="center">
        <Grid item xs={10}>
          <Grid
            container
            justify="center"
            style={{
              alignItems: 'center',
              color: 'white',
            }}
          >
            <Grid item xs={12}>
              <Grid container justify="center">
                <div style={{width: 330, height: 'auto'}}>
                  <img src={logo} style={{width: '100%'}} />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container justify="center">
                <h2
                  style={{
                    fontSize: 36,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    width: 500,
                    color: '#000',
                  }}
                >
                  Sorry, we’re doing some construction work on the site.
                </h2>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  to="/"
                  component={Link}
                  style={{width: 220, marginBottom: 60}}
                >
                  Home
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <img src={constImg} style={{width: '100%'}} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Soon.displayName = 'Soon'

export default Soon
