import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import PageUtils from '../utils/PageUtils'
import engineering from '../../assets/engineeringDivisions.png'
import downArrow from '../../assets/downArrow.png'

const columnWidth = 831

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 300,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
  headerImage: {
    width: '100%',
    height: '500',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150',
      marginTop: 48,
    },
  },
  downArrow: {
    margin: '50 auto 10',
    [theme.breakpoints.down('sm')]: {
      margin: '20 auto 20',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 260,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    fontSize: 34,
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 20,
      top: 0,
      left: 0,
      width: '100%',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #FFFFFF',
      [theme.breakpoints.down('sm')]: {
        fontSize: 27,
      },
    },
    '& p': {
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '18pt',
      },
    },
  },
  blurb: {
    margin: '0 auto',
    padding: '30px 0 35px',
    fontSize: '15',
    fontFamily: 'century-gothic, sans-serif',
    lineHeight: '1.3',
    width: '1700',
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #e63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '.34',
      fontStyle: 'normal',
      fontWeight: 'normal',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginLeft: 0,
      },
    },
  },
  blurbText: {
    margin: '0 auto',
    width: columnWidth,
    '& h3': {
      marginTop: 25,
      marginBottom: -10,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: '0 20 0 20',
    },
  },
  black: {
    backgroundColor: '#171717',
    color: 'white',
  },
  white: {
    backgroundColor: '#fff',
  },
  topBlurb: {
    margin: '220 auto 20',
    '& p': {
      fontSize: 15,
      fontFamily: 'century-gothic, sans-serif',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginTop: 20,
      marginBottom: 20,
    },
  },
})

class EngineeringServices extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={engineering} className={classes.headerImage} />
          <div className={classes.headerFloatContainer}>
            <h2>Construction-Driven Engineering</h2>
            <p>At Lanzo, we always begin with the end in mind. </p>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} className={classes.downArrow} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.topBlurb}>
              <div className={classes.blurbText}>
                <p>
                  Constructibility is at the heart of all our engineers — because we know what we’re
                  designing for you so it can be built.{' '}
                </p>
                <p>
                  With construction-driven engineering, we create solutions for our clients that
                  seamlessly transition from blueprints to real, completed projects. Taking
                  constructibility under consideration at every step ensures our clients have the
                  high quality, cost-effective designs they expect. As part of a large construction
                  and engineering firm, our engineers understand the crucial role that contractors
                  have in the overall development and delivery of every project — from start to
                  finish. It’s what separates Lanzo engineers and designers from the pack and
                  provides our clients an advantage that better controls budget, quality and cost
                  throughout a project’s lifecycle.{' '}
                </p>
                <p>
                  As part of a large construction and engineering firm, our engineers understand the
                  crucial role that contractors have in the overall development and delivery of
                  every project — from start to finish. It’s what separates Lanzo engineers and
                  designers from the pack and provides our clients an advantage that better controls
                  budget, quality and cost throughout a project’s lifecycle.{' '}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
          <div className={classes.blurbText}>
            <h2>Less Risk, More Certainty</h2>
            <p>
              One of the biggest causes for change orders in the construction phase occurs when
              design plans don’t mesh with the realities that contractors encounter in the field.
              Construction-driven engineering is a proactive approach that leads to the simplest,
              most cost-effective solutions for even the most complex projects, reducing the risks
              of schedule delays and design-driven cost overruns that adversely affect delivery.
            </p>
            <p>
              Our construction-driven engineering mindset helps position us as an
              engineer-procure-construct (EPC) and design-build partner of choice in any market.
            </p>
            <p>Pick a market and discover more about Lanzo’s engineering capabilities.</p>
          </div>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.white)}>
          <div className={classes.blurbText}>
            <h2>Infrastructure Engineering Solutions</h2>
            <p>
              From roadways to bridges to rail, Lanzo’s construction-driven engineers create
              transportation infrastructure solutions that seamlessly transition from blueprints to
              completed projects. We understand the realities that contractors face in the field and
              deliver designs that reduce the risk of construction delays and cost overruns, while
              maximizing value for clients. It’s why Lanzo is the design-build partner of choice in
              the transportation infrastructure market.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
          <div className={classes.blurbText}>
            <h2>Our Team</h2>
            <p>
              Our transportation infrastructure experts are a multi-disciplined engineering group
              and a hybrid of design and construction experts whose skills align with clients’
              goals. Our structural, geotechnical, civil roadway, hydraulics, drainage and materials
              science experts are actively involved in hundreds of design-build transportation
              infrastructure projects across North America.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.white)}>
          <div className={classes.blurbText}>
            <h2>Our Services</h2>
            <h3>Preliminary Design Services</h3>
            <p>
              We develop design concepts that meet infrastructure clients’ requirements and take
              constructibility into account throughout. These preliminary design concepts can be
              developed into detailed designs that result in successful projects — delivered on time
              and within budget.
            </p>
            <h3>Final Design Services</h3>
            <p>
              With our final design services, infrastructure clients face fewer risks, benefiting
              from engineers who have the expertise to deliver design solutions that make
              construction safer and more expeditious, reduce disruptions to the public and minimize
              maintenance and life cycle costs.
            </p>
            <h3>Construction Engineering Services</h3>
            <p>
              We develop, analyze and design cost-effective temporary works that enable project to
              be built more safely and efficiently. Lanzo designs and builds temporary works such as
              cofferdams, excavation support systems and crane lifts, which are crucial to quality
              project design, cost control and construction excellence.
            </p>
            <h3>Design Optimization</h3>
            <p>
              We develop design solutions that meet contract requirements while efficiently
              optimizing construction schedule and cost value for clients. We accomplish this by
              bringing clients an integrated, unified design and construction partner to brainstorm
              solutions, identify and develop technical concepts and analyze impacts to cost and
              schedule. All of this empowers clients to choose the best solution for their projects
            </p>
            <h3>Design-Build Management</h3>
            <p>
              Clients are turning to Lanzo during all stages of design-build projects to manage
              their multi-disciplined design teams to improve schedule, control costs and minimize
              risk. Our construction-driven engineers analyze root causes and resolve design issues
              before they impact schedule and budget. Particularly on large design-build projects,
              clients benefit from the proven processes and tools used by Lanzo to minimize project
              risk.
            </p>
          </div>
        </Grid>
      </Grid>
    )
  }
}

EngineeringServices.displayName = 'Engineering-Services'

export default withStyles(styles)(EngineeringServices)
