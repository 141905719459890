import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import PageUtils from '../utils/PageUtils'
import * as Actions from './redux/actions'
import ourHistoryHeader from '../../assets/ourhistoryheader.png'
import ourHistory from '../../assets/ourhistory.png'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    [theme.breakpoints.down('sm')]: {},
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 119,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      backgroundSize: 'contain',
      width: '100%',
    },
    '& h2': {
      fontFamily: 'century-gothic, sans-serif',
      position: 'absolute',
      top: '40%',
      left: '140',
      display: 'inline-block',
      fontSize: '49px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #E63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 31,
        position: 'relative',
        transform: 'none',
        marginLeft: -125,
        top: -130,
      },
    },
  },
  headerImage: {
    width: '100%',
    height: '500',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '151',
      width: '134%',
      marginTop: 49,
      marginBottom: -5,
    },
  },
  headerFloatContained: {
    [theme.breakpoints.up('md')]: {
      marginTop: '-275px',
      marginBottom: '25px',
      zIndex: '1',
    },
  },
  headerFloatContainer: {
    padding: '30px 30px 40px 30px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      width: 448,
      boxShadow: '0 0px 18px 0px rgba(0, 0, 0, 0.5)',
      '& p': {
        color: 'white',
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '90%',
      margin: '0 auto',
      display: 'block',
      top: -20,
      left: 0,
      backgroundColor: '#FFFFFF',
      boxShadow: '0 2px 18px 2px rgba(0, 0, 0, 0.17)',
      '& p': {
        color: 'black',
      },
    },
    '& p': {
      fontSize: '17px',
      lineHeight: '22px',
      fontFamily: 'century-gothic, sans-serif',
      marginTop: 0,
      padding: 0,
    },
  },
  topBlurb: {
    maxWidth: 1400,
    textAlign: 'center',
    margin: '0 auto 40px auto',
    '& h3': {
      fontSize: '14px',
    },
    '& p': {
      fontSize: '15px',
      margin: 0,
      padding: 0,
      marginBottom: 15,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      padding: '0 12px',
      margin: '0 auto 20px auto',
    },
  },
  middleSection: {
    '& img': {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '90%',
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
      },
    },
  },
  bottomSection: {
    position: 'relative',
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
    '& h2': {
      marginTop: 5,
      width: '100%',
      padding: '0 20',
      color: '#E63B43',
      [theme.breakpoints.down('sm')]: {
        color: 'black',
        marginBottom: -5,
        fontSize: 23,
      },
    },
    '& p': {
      width: '100%',
      padding: '0 20',
      fontSize: 15,
    },
  },
  construction: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#000000',
      padding: '0 20',
    },
    '& h2': {
      [theme.breakpoints.up('md')]: {
        marginTop: -213,
      },
      [theme.breakpoints.down('sm')]: {
        color: 'white',
      },
      fontFamily: 'century-gothic, sans-serif',
      color: '#0068AA',
      fontSize: '30px',
      fontWeight: 'bold',
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '22px',
      fontFamily: 'century-gothic, sans-serif',
      color: '#000000',
      [theme.breakpoints.down('sm')]: {
        color: '#FFFFFF',
      },
      marginTop: 0,
      padding: 0,
    },
  },
  bottomList: {
    margin: '20 20 0 20',
    '& li': {
      left: 0,
    },
  },
  sectionItem: {
    boxShadow: '0 2px 18px 2px rgba(0, 0, 0, 0.17)',
    padding: 2,
    paddingLeft: 14,
    '& .black-bg': {
      [theme.breakpoints.down('sm')]: {
        color: '#FFFFFF',
        marginLeft: -23,
      },
    },
    '& li': {
      padding: '0 5 0 0',
      margin: 0,
      lineHeight: '25px',
      left: 0,
    },
    '& li:before': {
      content: "''",
      display: 'block',
      width: 11,
      height: 11,
      margin: '23px 15px 0 -30px',
      backgroundColor: '#E63B43',
      left: 0,
    },
  },
  marginAuto: {
    margin: '0 auto',
  },
})

class OurHistory extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.header} justify="center">
          <img src={ourHistoryHeader} className={classes.headerImage} />
          <Grid item xs={12} md={6} justify="center">
            <h2>Our History</h2>
          </Grid>
        </Grid>
        <Grid item xs={12} justify="center" className={classes.headerFloatContained}>
          <Grid container xs={12} md={10} className={classes.marginAuto}>
            <Grid item xs={12} md={6} justify="center">
              <div className={classes.headerFloatContainer}>
                <p>
                  For nearly 50 years, Lanzo Construction has been providing infrastructure
                  solutions to municipalities and communities throughout North America. As the
                  industry’s needs changed, so did our approach to meeting those needs.
                </p>
                <p>
                  What started as Lanzo Construction in 1968 has since become Lanzo Companies, an
                  umbrella organization that includes Lanzo Construction Company and Lanzo
                  Trenchless Technologies.
                </p>
                <p>
                  Please take a moment to learn more about each of our companies or read more in the{' '}
                  <a href="https://www.lanzo.net/pdf/lanzo-brochure.pdf" target="blank">
                    Lanzo Companies Brochure.
                  </a>
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.middleSection} justify="center">
          <Grid container xs={12} md={10}>
            <Grid item xs={12} md={6} justify="center">
              <img src={ourHistory} />
            </Grid>
            <Grid item xs={12} md={6} className={classes.construction}>
              <h2>Construction</h2>
              <p>
                Quirino (Gary) D'Alessandro started Lanzo Construction with sidewalk and driveway
                repair jobs. Over time, the Lanzo began contracting larger civil utility projects in
                earnest, working throughout the state of Michigan and branching into Florida, Texas
                and North Carolina.
              </p>

              <p>
                By 1980 Lanzo Construction had established itself in Florida as a reputable civil
                construction contractor. Work spanned from the west coast of Florida, across the
                state and south to Miami-Dade county and Key West. The company continues to have a
                strong presence in Florida and throughout the state of Michigan
              </p>

              <p>Lanzo Construction Company services include:</p>
              <Grid item xs={12} md={9}>
                <div className={classes.sectionItem}>
                  <ul className="custom-list black-bg">
                    <li>Construction Management</li>
                    <li>Engineering Design Build</li>
                    <li>Road & Highway Construction</li>
                    <li>Marine & Subaqueous Construction</li>
                    <li>Water/Wastewater Treatment Facilities</li>
                    <li>Civil Construction</li>
                    <li>Water Transmission & Wastewater Collection Systems</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} md={10} className={classes.bottomSection}>
            <h2>Trenchless Technologies</h2>
            <p>
              Lanzo Trenchless Technologies (formerly Lanzo Lining Services) was established in 1993
              to provide a cost-effective, less invasive alternative to replacing failing
              infrastructure.{' '}
            </p>
            <p>
              We were one of the first companies worldwide to use trenchless technology, and we
              continue to lead the industry with our expertise and experience. To date, Lanzo
              Trenchless Technologies has installed more than ten million linear feet of pipelines -
              in diameters as large as 120" - throughout North America. We are expanding our
              services to include Central and South America.{' '}
            </p>
            <p>
              As always, our commitment to the environment is reflected in the eco-friendly
              materials and technologies we use. We have pioneered technology that offers
              alternative materials and processes that are earth friendly and sustainable.
            </p>
            <p>Lanzo Trenchless Technologies Services include:</p>
            <Grid item xs={12} md={6}>
              <div className={classNames(classes.sectionItem, classes.bottomList)}>
                <ul className="custom-list">
                  <li>Large Sewer, Storm Drain & Non-Circular Pipeline Renovation</li>
                  <li>Manhole & Tunnel Repair</li>
                  <li>Cured-in-Place Pipe Lining Rehab (CIPP)</li>
                  <li>Pressure, Gas, Force Main Repair</li>
                  <li>Pipeline Cleaning & CCTV Inspection</li>
                  <li>NSF 61 Water Main Rehabilitation</li>
                  <li>Micro Tunneling</li>
                  <li>Directional Drilling/Jack & Bore</li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

OurHistory.displayName = 'Our History'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.careers,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withWidth(),
)(OurHistory)
