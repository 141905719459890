import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {Modal, Input, Button, Typography, Grid, withStyles} from '@material-ui/core'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import InputValidator from '../common/InputValidator.jsx'

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
})
class SocialAuthEmailPopup extends React.Component {
  constructor() {
    super()

    this.state = {
      email: '',
      isOpen: true,
    }
  }

  render() {
    const {classes} = this.props
    const {email, isOpen} = this.state
    return (
      <div>
        <Modal open={isOpen} onClose={() => this.setState({isOpen: false})}>
          <Grid container className={classes.paper} spacing={32}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Please provide an email address for your account:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputValidator fieldName="Email">
                <Input
                  type="email"
                  required
                  value={email}
                  onChange={e => {
                    this.setState({email: e.target.value})
                  }}
                  placeholder="Account Email*"
                />
              </InputValidator>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={this.sendEmail.bind(this)}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </div>
    )
  }

  sendEmail() {
    const {isComponentValid, onSendAuthEmail} = this.props
    if (isComponentValid()) {
      onSendAuthEmail(this.state.email)
    }
  }
}

SocialAuthEmailPopup.propTypes = {
  onSendAuthEmail: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  ValidatedComponent,
)(SocialAuthEmailPopup)
