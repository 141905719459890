import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid, ButtonBase} from '@material-ui/core'
import {Link} from 'react-router'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'
import imgSplash1 from '../../assets/carrers-splash-01.png'
import imgSplash2 from '../../assets/carrers-splash-02.jpg'
import ImageUtils from '../utils/ImageUtils'
import PageUtils from '../utils/PageUtils'
import * as Actions from './redux/actions'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
  },
  header: {
    position: 'relative',
    height: 600,
    marginTop: -60,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center -120px',
    backgroundImage: `url(${imgSplash1})`,
    [theme.breakpoints.down('sm')]: {
      height: 500,
      backgroundPosition: 'center -60px',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: 20,
    top: '50%',
    left: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.46)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-650px, -60%)',
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      backgroundColor: 'white',
      color: 'black',
      marginTop: 250,
      top: 0,
      left: 0,
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '44px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      paddingBottom: 5,
      borderBottom: '7px solid #E63B43',
    },
    '& p': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      marginTop: 20,
      paddingBottom: 30,
    },
  },
  middleContainer: {
    margin: '0 auto 60px auto',
    fontFamily: 'century-gothic, sans-serif',
    maxWidth: 1400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
    '& h3': {
      fontSize: '32px',
      margin: 0,
      padding: '20px 0 20px 30px',
      color: 'white',
      backgroundColor: '#E63B43',
      fontWeight: 'normal',
    },
    '& h4': {
      fontSize: '25px',
      margin: '40px 0 30px 30px',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 30,
      },
    },
    '& p': {
      fontSize: '14px',
      margin: '0 0 30px 30px',
      padding: '0 20px 0 0',
      lineHeight: '21px',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 30,
      },
    },
  },
  gridRightText: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      margin: '45px 0 30px 0',
    },
  },
  gridLeftImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url(${imgSplash2})`,
    maxWidth: 750,
    height: 750,
    [theme.breakpoints.down('sm')]: {
      height: 300,
      order: 2,
    },
  },
  jobsContainer: {
    maxWidth: 1400,
    margin: '0 auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
    '& h4': {
      fontSize: '32px',
      margin: 0,
      padding: 0,
      marginBottom: 20,
    },
    '& h5': {
      fontStyle: 'italic',
      fontSize: '18px',
      margin: 0,
      padding: 0,
      display: 'block',
      height: 55,
      marginTop: 20,
    },
    '& img': {
      width: '100%',
    },
  },
  jobItem: {
    height: 450,
    position: 'relative',
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '20%',
    },
  },
  jobsDivider: {
    width: '50%',
    height: 8,
    backgroundColor: '#E63B43',
  },
  jobsButton: {
    backgroundColor: '#E63B43',
    width: 'calc(100% - 16px)',
    height: 27,
    position: 'absolute',
    bottom: 0,
  },
  jobsButtonText: {
    color: 'white',
    fontSize: '13px',
    position: 'absolute',
    fontWeight: 'bold',
    left: 10,
  },
  jobsButttonArrow: {
    display: 'block',
    height: 27,
    width: 27,
    backgroundColor: '#5F5F5F',
    position: 'absolute',
    right: 0,
    '& img': {
      width: 13,
      height: 13,
      marginTop: 7,
      marginLeft: 7,
    },
  },
})

class Home extends React.Component {
  componentWillMount() {
    this.props.getJobs()
  }

  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes, jobs} = this.props

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.header}>
          <div className={classes.headerFloatContainer}>
            <h2>Careers</h2>
            <p>
              At Lanzo, we keep our legacy in motion by living our core beliefs. We act. We learn.
              We get better. We are never content with just being satisfied, therefore we strive to
              be more tomorrow than we are today. Some companies are retreating - we are moving our
              culture and our opportunities forward to embrace the future.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} container className={classes.middleContainer}>
          <Grid item xs={12} md={6} className={classes.gridLeftImage} />
          <Grid item xs={12} md={6} className={classes.gridRightText}>
            <h3>Lanzo Construction Company</h3>
            <h4>Build Your Future with Lanzo</h4>
            <p>
              Whatever the experience that first sparked your interest in construction, we are
              pleased that you are interested in Lanzo Construction Company.
            </p>
            <p>
              Every Lanzo project starts with a team of dedicated employees. We are looking for
              individuals who are eager to develop their skills and apply them together to create
              the desirable working environment that fits the Lanzo culture and core values.
            </p>
            <h4>More than Construction</h4>
            <p>
              Lanzo is interested not only in candidates with backgrounds related to construction,
              management or engineering, but also those with skills in finance, accounting, human
              resources and business development. We know it takes a diverse team to build the
              future and we are looking for all types of talent to contribute.
            </p>
            <h4>A Great Place to Work</h4>
            <p>Lanzo is an Affirmative Action and Equal Opportunity Employer.</p>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.jobsContainer}>
          <h4>Employment opportunities</h4>
          <Grid container spacing={16}>
            {jobs.map(item => {
              if (item.description && item.description.length > 160) {
                item.description = item.description.substring(0, 160) + '...'
              }
              const uri = item.title.replace(' ', '-').toLowerCase()
              return (
                <Grid item xs={12} md={2} className={classes.jobItem}>
                  <img src={item.imageThumbnail} alt={item.title} />
                  <h5>{item.title}</h5>
                  <div className={classes.jobsDivider} />
                  <p>{item.description}</p>
                  <ButtonBase
                    className={classes.jobsButton}
                    component={Link}
                    to={'/careers/' + encodeURIComponent(uri) + '-' + item.id}
                  >
                    <span className={classes.jobsButtonText}>LEARN MORE</span>
                    <span className={classes.jobsButttonArrow}>
                      <img src={ImageUtils.getAngleRight()} alt="Arrow" />
                    </span>
                  </ButtonBase>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Home.displayName = 'Careers Home'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.careers,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withWidth(),
)(Home)
