import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

export default function contractor(state = getInitialState(), action) {
  switch (action.type) {
    case Types.CREATE_APPLICATION:
    case Types.UPDATE_APPLICATION:
    case Types.SUBMIT_APPLICATION:
      return {
        ...state,
        isSaving: true,
      }
    case Types.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
      }
    case Types.OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
      }
    case Types.CREATE_APPLICATION_SUCCESS:
    case Types.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isSaving: false,
        application: {
          ...action.payload,
        },
      }
    case Types.SUBMIT_APPLICATION_SUCCESS:
      return getInitialState()
    case Types.GET_APPLICATION:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        application: {
          ...action.payload,
        },
      }
    case Types.UPDATE_APPLICATION_FAILURE:
    case Types.CREATE_APPLICATION_FAILURE:
    case Types.SUBMIT_APPLICATION_FAILURE:
    case Types.GET_APPLICATION_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors,
      }
    }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    isSaving: false,
    modalOpen: false,
    errors: {},
    application: {
      contacts: [],
    },
    businessLineData: [],
  }
}
