import * as Types from './actions.types'

export function createAttachment(payload) {
  return {type: Types.CREATE_ATTACHMENT, payload}
}

export function createAttachmentSuccess(payload) {
  return {type: Types.CREATE_ATTACHMENT_SUCCESS, payload}
}

export function createAttachmentFailure(error) {
  return {type: Types.CREATE_ATTACHMENT_FAILURE, error}
}

export function getAttachments(params) {
  return {type: Types.GET_ATTACHMENTS, params}
}

export function getAttachmentsSuccess(payload) {
  return {type: Types.GET_ATTACHMENTS_SUCCESS, payload}
}

export function getAttachmentsFailure(error) {
  return {type: Types.GET_ATTACHMENTS_FAILURE, error}
}

export function updateAttachment(id, data) {
  return {type: Types.UPDATE_ATTACHMENT, id, data}
}

export function updateAttachmentSuccess(payload) {
  return {type: Types.UPDATE_ATTACHMENT_SUCCESS, payload}
}

export function updateAttachmentFailure(error) {
  return {type: Types.UPDATE_ATTACHMENT_FAILURE, error}
}

export function deleteAttachment(id, appId) {
  return {type: Types.DELETE_ATTACHMENT, id, appId}
}

export function deleteAttachmentSuccess(id) {
  return {type: Types.DELETE_ATTACHMENT_SUCCESS, id}
}

export function deleteAttachmentFailure(error) {
  return {type: Types.DELETE_ATTACHMENT_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_ATTACHMENT_ERRORS}
}
