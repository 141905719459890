import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
`

export const SectionTitle = styled.h2`
  color: #000000;
  font-family: 'century-gothic, sans-serif';
  font-size: 21px;
  letter-spacing: 0.45px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  text-align: left;
  padding: 10px;
`

export const Image = styled.img`
  width: 100%;
  ${p => (p.height ? 'object-fit: contain;' : '')};
`
