import React from 'react'
import {Grid} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import IconButton from '../IconButton'
import RoundedInput from '../../common/RoundedInput'
import InputValidator from '../../common/InputValidator'
import RoundedSelect from '../../common/RoundedSelect'

class InputRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      application: props.appId,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(e, field) {
    this.setState({[field]: e.target.value})
  }

  render() {
    const {hideHeader, classes, errors, regionInterestData} = this.props

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={4} xs={12} className={classes.sectionColumn}>
          <div className={classes.sectionHeader}>
            <p>Region of Interest</p>
          </div>
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>Interested Geographical Areas for Supply of Material and/or Work Performance.</p>
            <InputValidator errors={errors.line} className={classes.fieldsContainer}>
              <RoundedSelect
                className={classes.fieldsContainer}
                native
                onChange={e => {
                  this.onChange(e, 'region')
                }}
                input={<RoundedInput name="region" />}
              >
                <option value="">Select region</option>
                {regionInterestData.map(region => (
                  <option value={region.id} key={region.id}>
                    {region.regionName}
                  </option>
                ))}
              </RoundedSelect>
            </InputValidator>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} className={classes.sectionColumn}>
          <div className={classes.sectionHeader}>
            <p>Comments</p>
          </div>
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.comment} className={classes.fieldsContainer}>
              <RoundedInput
                rows={3}
                multiline={true}
                onChange={e => {
                  this.onChange(e, 'comment')
                }}
              />
            </InputValidator>
          </Grid>
        </Grid>
        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="save"
                    onClick={() => {
                      this.props.createRegionInterest(this.state)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <Cancel
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      this.props.hideForm()
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default InputRow
