import React from 'react'
import {Grid} from '@material-ui/core'
import _ from 'lodash'
import RoundedInput from '../../common/RoundedInput'
import IconButton from '../IconButton'
import RoundedSelect from '../../common/RoundedSelect'
import InputValidator from '../../common/InputValidator'
import Api from './api'
import SearchSelect from '../../common/SearchSelect'
import ChooseDate from '../../common/ChooseDate'

class Row extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      businessClassification: props.businessClassification,
    }
    this.onChange = this.onChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.businessClassification !== this.state.businessClassification) {
      this.setState({businessClassification: nextProps.businessClassification})
    }
  }

  onChange(e, field) {
    e.persist()
    this.setState(state => {
      return {businessClassification: {...state.businessClassification, [field]: e.target.value}}
    })
  }

  onDateChange(value) {
    this.setState(state => {
      const d = new Date(value)
      return {
        businessClassification: {
          ...state.businessClassification,
          expirationDate: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
        },
      }
    })
  }

  render() {
    const {
      classes,
      hideHeader,
      businessClassification,
      classificationData,
      minorityOptions,
      setMinorityOption,
      errors,
    } = this.props
    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Classification</p>
            </div>
          )}
          <Grid
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <InputValidator errors={errors.classification} className={classes.fieldsContainer}>
                <SearchSelect
                  value={{label: businessClassification.classificationDisplay}}
                  getData={Api.getBusinessClassificationData}
                  onChange={option => {
                    setMinorityOption(option.minorityOptions)
                    if (option.minorityOptions.length > 0) {
                      this.setState(state => {
                        return {
                          businessClassification: {
                            ...state.businessClassification,
                            classification: option.id,
                            minority: businessClassification.minority,
                          },
                        }
                      })
                    } else {
                      this.setState(state => {
                        return {
                          businessClassification: {
                            ...state.businessClassification,
                            classification: option.id,
                            minority: null,
                          },
                        }
                      })
                    }
                  }}
                  options={classificationData.map(cl => ({label: cl.name, ...cl}))}
                />
              </InputValidator>
            ) : (
              <p>{businessClassification.classificationDisplay}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Minority Type</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit && minorityOptions.length > 0 ? (
              <InputValidator errors={errors.minority} className={classes.fieldsContainer}>
                <RoundedSelect
                  className={classes.fieldsContainer}
                  native
                  defaultValue={businessClassification.minority}
                  onChange={e => {
                    this.onChange(e, 'minority')
                  }}
                  input={<RoundedInput name="minority" />}
                >
                  <option value="">Select minority</option>
                  {minorityOptions.map(cl => (
                    <option value={cl.id} key={cl.id}>
                      {cl.name}
                    </option>
                  ))}
                </RoundedSelect>
              </InputValidator>
            ) : (
              <p>
                {this.state.businessClassification.minority &&
                  businessClassification.minorityDisplay}
              </p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Certificate Number</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <RoundedInput
                defaultValue={businessClassification.certificateNumber}
                onChange={e => {
                  this.onChange(e, 'certificateNumber')
                }}
              />
            ) : (
              <p>{businessClassification.certificateNumber}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Certificate Agency</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <RoundedInput
                defaultValue={businessClassification.certifyingAgency}
                onChange={e => {
                  this.onChange(e, 'certifyingAgency')
                }}
              />
            ) : (
              <p>{businessClassification.certifyingAgency}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Expiration Date</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <ChooseDate
                defaultValue={businessClassification.expirationDate}
                onChange={this.onDateChange}
              />
            ) : (
              <p>{businessClassification.expirationDate}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {!this.state.edit && (
              <Grid item xs={4}>
                <Grid justify="center" container>
                  <p>
                    <IconButton
                      type="edit"
                      onClick={() => {
                        const cl = _.find(classificationData, {
                          id: parseInt(businessClassification.classification, 0),
                        })
                        if (cl) {
                          setMinorityOption(cl.minorityOptions)
                        }
                        this.setState({edit: true})
                      }}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            {this.state.edit && (
              <Grid item xs={4}>
                <Grid justify="center" container>
                  <p>
                    <IconButton
                      type="save"
                      onClick={() => {
                        this.props.updateBusinessClassification(
                          businessClassification.id,
                          this.state.businessClassification,
                        )
                        setMinorityOption([])
                        this.setState({edit: false})
                      }}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="delete"
                    onClick={() => {
                      this.props.deleteBusinessClassification(
                        businessClassification.id,
                        businessClassification.application,
                      )
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Row
