import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

export default function contact(state = getInitialState(), action) {
  switch (action.type) {
    case Types.CONTACT:
      return {
        ...getInitialState(),
        isFetching: true,
      }
    case Types.CONTACT_SUCCESS:
      return {
        ...getInitialState(),
        success: true,
      }
    case Types.CONTACT_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...getInitialState(),
        errors,
      }
    }
    case Types.CONTACT_RESET:
      return {
        ...getInitialState(),
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    success: false,
    errors: {},
  }
}
