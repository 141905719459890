import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid, InputLabel, FormLabel, FormGroup, FormControlLabel, Modal} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Recaptcha from 'react-recaptcha'
import RoundedInput from '../common/RoundedInput'
import RoundedSelect from '../common/RoundedSelect'
import RoundedCheckbox from '../common/RoundedCheckbox'
import RoundedButton from '../common/RoundedButton'
import imgHeader from '../../assets/header-contact-us.jpg'
import imgModal from '../../assets/contact-modal-bg.jpg'
import Contries from '../utils/Countries'
import InputValidator from '../common/InputValidator'
import settings from '../../../config/settings'
import * as Actions from './redux/actions'
import PageUtils from '../utils/PageUtils'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
  },
  header: {
    height: 400,
    marginTop: -60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${imgHeader})`,
    '& h2': {
      fontFamily: 'century-gothic, sans-serif',
      color: 'white',
      fontSize: '44px',
      margin: 0,
      padding: 0,
      marginTop: 60,
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 250,
    },
  },
  contactContainer: {
    maxWidth: 1400,
    margin: '0 auto',
    '& label': {
      color: 'black',
      fontSize: '16px',
      fontWeight: 600,
      position: 'relative',
      transform: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 40px)',
    },
  },
  contactLeftContainer: {
    marginTop: 40,
    '& h3': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: '30px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginBottom: 30,
    },
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: '15px',
      margin: 0,
      padding: 0,
      marginBottom: 40,
    },
    [theme.breakpoints.down('sm')]: {
      order: 2,
      marginTop: 30,
    },
  },
  contactFieldsContainer: {
    boxShadow: '0 2px 11px 3px rgba(0, 0, 0, 0.12)',
    padding: 20,
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      order: 1,
      marginTop: 20,
    },
  },
  contactFormItemContainer: {
    marginBottom: 10,
  },
  formRequired: {
    color: '#FF0000',
  },
  bottomRequiredSpan: {
    textAlign: 'right',
    fontSize: '16px',
    color: '#FF0000',
    lineHeight: '20px',
    letterSpacing: '0.34px',
  },
  modalContainer: {
    position: 'absolute',
    maxWidth: 800,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    color: 'white',
    textAlign: 'center',
    backgroundSize: 'cover',
    padding: 30,
    backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87) ), url(${imgModal})`,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
    '& h3': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: '47px',
      margin: 0,
      padding: 0,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: '21px',
      margin: 0,
      padding: 0,
      marginBottom: 40,
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
  },
})

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      payload: {
        country: 'USA',
        commentsArray: [],
        recaptchaResponse: false,
      },
    }
    this.onChange = this.onChange.bind(this)
    this.onChangeComments = this.onChangeComments.bind(this)
    this.onReCaptchaVerify = this.onReCaptchaVerify.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onModalContinue = this.onModalContinue.bind(this)
    this.onChangePhone = this.onChangePhone.bind(this)
  }

  componentDidMount() {
    PageUtils.scrollToTop()
  }

  onChange(e, fieldName) {
    e.persist()
    this.setState(state => {
      return {payload: {...state.payload, [fieldName]: e.target.value}}
    })
  }

  onChangeComments(e) {
    const {commentsArray} = this.state.payload
    const value = e.target.value
    const index = commentsArray.indexOf(value)
    if (index >= 0) {
      commentsArray.splice(index, 1)
    } else {
      commentsArray.push(value)
    }
    this.setState(state => {
      return {payload: {...state.payload, commentsArray}}
    })
  }

  onChangePhone(e) {
    let parsedPhone = e.target.value.replace(/[()-]/g, '').replace(/ /g, '')
    if (!parsedPhone.includes('+') && this.state.payload.country === 'USA') {
      parsedPhone = '+1' + parsedPhone
    }
    this.setState(state => {
      return {payload: {...state.payload, phone: parsedPhone}}
    })
  }

  onReCaptchaVerify(response) {
    this.setState(state => {
      return {payload: {...state.payload, recaptchaResponse: response}}
    })
  }

  onSubmit() {
    const {payload} = this.state
    payload.comments = payload.commentsArray.join(' | ')
    this.props.contact(payload)
    this.setState(
      state => {
        return {payload: {...state.payload, recaptchaResponse: ''}}
      },
      () => {
        if (this.recaptchaInstance) {
          this.recaptchaInstance.reset()
        }
      },
    )
  }

  onModalContinue() {
    this.props.contactReset()
    this.props.router.push('/')
  }

  render() {
    const {classes, width, errors, isFetching, success} = this.props
    const {recaptchaResponse, commentsArray} = this.state.payload

    const mobile = !!(width === 'xs' || width === 'sm')

    const commentItems = [
      'Engineering/Design Build/Design Services',
      'CIPP Pipelining',
      'Infrastructure Repair',
      'Road & Highway Construction',
      'Water & Wastewater Treatment Facilities',
      'Other',
    ]

    return (
      <Grid container className={classes.container}>
        <Modal open={success} BackdropProps={{invisible: true}}>
          <Grid container xs={12} className={classes.modalContainer} justify="center">
            <Grid item xs={12}>
              <h3>Congratulations</h3>
            </Grid>
            <Grid item xs={12}>
              <p>Your message has been sent. We have sent you a confirmation email.</p>
            </Grid>
            <Grid item xs={6}>
              <RoundedButton onClick={this.onModalContinue}>CONTINUE</RoundedButton>
            </Grid>
          </Grid>
        </Modal>
        <Grid item xs={12} className={classes.header}>
          <h2>Contact Us</h2>
        </Grid>
        <Grid item container xs={12} className={classes.contactContainer}>
          <Grid item xs={12} md={5} className={classes.contactLeftContainer}>
            <h3>Contact Lanzo Construction Company</h3>
            <p>
              125 S.E. 5th Court
              <br />
              Deerfield Beach, Florida 33441-4749
              <br />
              Phone: <a href="tel:+19549790802">(954) 979-0802</a>
              <br />
              Fax: <a href="tel:+19549799897">(954) 979-9897</a>
            </p>
            <p>
              28135 Groesbeck Hwy
              <br />
              Roseville, MI 48066-2344
              <br />
              Phone: <a href="tel:+15867755819">(586) 775-5819</a>
              <br />
              Fax: <a href="tel:+15867758924">(586) 775-8924</a>
              <br />
            </p>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={7}
            spacing={mobile ? 0 : 16}
            className={classes.contactFieldsContainer}
          >
            <Grid item container xs={12} md={6} spacing={8}>
              <Grid item xs={12}>
                <InputValidator errors={errors.name} className={classes.contactFormItemContainer}>
                  <InputLabel shrink htmlFor="name">
                    <span className={classes.formRequired}>*</span>
                    Name
                  </InputLabel>
                  <RoundedInput
                    id="name"
                    onChange={e => {
                      this.onChange(e, 'name')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={12}>
                <InputValidator errors={errors.title} className={classes.contactFormItemContainer}>
                  <InputLabel shrink htmlFor="title">
                    Title
                  </InputLabel>
                  <RoundedInput
                    id="title"
                    onChange={e => {
                      this.onChange(e, 'title')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={12}>
                <InputValidator
                  errors={errors.company}
                  className={classes.contactFormItemContainer}
                >
                  <InputLabel shrink htmlFor="company">
                    <span className={classes.formRequired}>*</span>
                    Company
                  </InputLabel>
                  <RoundedInput
                    id="company"
                    onChange={e => {
                      this.onChange(e, 'company')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={8} md={6}>
                <InputValidator errors={errors.city} className={classes.contactFormItemContainer}>
                  <InputLabel shrink htmlFor="city">
                    <span className={classes.formRequired}>*</span>
                    City
                  </InputLabel>
                  <RoundedInput
                    id="city"
                    onChange={e => {
                      this.onChange(e, 'city')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={4} md={2}>
                <InputValidator errors={errors.state} className={classes.contactFormItemContainer}>
                  <InputLabel shrink htmlFor="state">
                    State
                  </InputLabel>
                  <RoundedInput
                    id="state"
                    onChange={e => {
                      this.onChange(e, 'state')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputValidator
                  errors={errors.country}
                  className={classes.contactFormItemContainer}
                >
                  <InputLabel shrink htmlFor="country">
                    <span className={classes.formRequired}>*</span>
                    Country
                  </InputLabel>
                  <RoundedSelect
                    native
                    onChange={e => {
                      this.onChange(e, 'country')
                    }}
                    input={<RoundedInput name="country" id="country" />}
                  >
                    {Contries.map(country => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </RoundedSelect>
                </InputValidator>
              </Grid>
              <Grid item xs={12}>
                <InputValidator errors={errors.phone} className={classes.contactFormItemContainer}>
                  <InputLabel shrink htmlFor="phone">
                    <span className={classes.formRequired}>*</span>
                    Phone
                  </InputLabel>
                  <RoundedInput
                    id="phone"
                    type="tel"
                    onChange={e => {
                      this.onChangePhone(e)
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={12}>
                <InputValidator
                  errors={errors.bestTimeToContact}
                  className={classes.contactFormItemContainer}
                >
                  <InputLabel shrink htmlFor="bestTimeToContact">
                    The best time to contact me is
                  </InputLabel>
                  <RoundedInput
                    id="bestTimeToContact"
                    onChange={e => {
                      this.onChange(e, 'bestTimeToContact')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={12}>
                <InputValidator errors={errors.email} className={classes.contactFormItemContainer}>
                  <InputLabel shrink htmlFor="email">
                    <span className={classes.formRequired}>*</span>
                    Email
                  </InputLabel>
                  <RoundedInput
                    id="email"
                    type="email"
                    onChange={e => {
                      this.onChange(e, 'email')
                    }}
                  />
                </InputValidator>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} spacing={8}>
              <Grid item xs={12}>
                <FormLabel component="label">Comment/Request</FormLabel>
                <FormGroup style={{marginTop: 10}}>
                  {commentItems.map(item => (
                    <FormControlLabel
                      control={
                        <RoundedCheckbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={commentsArray.includes(item)}
                          value={item}
                          onChange={this.onChangeComments}
                        />
                      }
                      label={item}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <InputValidator errors={errors.commentsDetails}>
                  <InputLabel shrink htmlFor="commentsDetails">
                    Comment/Request
                  </InputLabel>
                  <RoundedInput
                    id="commentsDetails"
                    multiline
                    rows="6"
                    onChange={e => {
                      this.onChange(e, 'commentsDetails')
                    }}
                  />
                </InputValidator>
              </Grid>
              <Grid item xs={12}>
                <Recaptcha
                  sitekey={settings.reCaptchaSiteKey}
                  verifyCallback={this.onReCaptchaVerify}
                  render="explicit"
                  onloadCallback={() => {}}
                  ref={e => {
                    this.recaptchaInstance = e
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <RoundedButton disabled={isFetching || !recaptchaResponse} onClick={this.onSubmit}>
                  {isFetching ? 'Sending ...' : 'Submit'}
                </RoundedButton>
              </Grid>
              <Grid item xs={12} className={classes.bottomRequiredSpan}>
                <span>* Required</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Contact.displayName = 'Contact'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contact,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withWidth(),
)(Contact)
