import axios from 'axios'
import humps from 'humps'
import UserSession from '../auth/UserSession'

const fileAxios = axios.create()

fileAxios.interceptors.request.use(request => {
  const authToken = UserSession.getToken()
  if (authToken) {
    if (request.headers && !request.headers.Authorization) {
      request.headers.Authorization = `Token ${authToken}`
    }
  }
  if (request.data) {
    const form = new FormData()
    Object.keys(request.data).forEach(key => {
      form.append(humps.decamelize(key), request.data[key])
    })
    request.data = form
  }
  return request
})

export default fileAxios
