import React from 'react'
import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import {createMuiTheme} from '@material-ui/core'
import {MuiThemeProvider} from '@material-ui/core/styles'

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E63B43',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#E63B43',
      },
      isSelected: {
        backgroundColor: '#E63B43',
      },
      current: {
        color: '#E63B43',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#E63B43',
      },
    },
  },
})

class ChooseDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpened: false,
      selectedDate: props.defaultValue ? new Date(props.defaultValue) : null,
    }
    this.closeMenu = this.closeMenu.bind(this)
    this.openMenu = this.openMenu.bind(this)
  }

  openMenu() {
    this.setState({menuOpened: true})
  }

  closeMenu() {
    this.setState({menuOpened: false})
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={materialTheme}>
          <DatePicker
            keyboard
            clearable
            autoOk
            fullWidth
            format={this.props.monthSelector ? 'MMM, YYYY' : 'YYYY-MM-DD'}
            className="datepicker"
            variant="outlined"
            views={this.props.monthSelector ? ['year', 'month'] : ['year', 'month', 'day']}
            value={this.state.selectedDate}
            style={{color: 'white !important'}}
            onChange={value => {
              if (this.props.onChange) {
                this.props.onChange(value)
              }
              this.setState({selectedDate: value})
            }}
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    )
  }
}

export default ChooseDate
