import React from 'react'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import SvgIcon from '@material-ui/core/SvgIcon'
import toRenderProps from 'recompose/toRenderProps'
import withState from 'recompose/withState'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import MenuList from '@material-ui/core/MenuList'

const WithState = toRenderProps(withState('open', 'updateOpen', false))

function SlideMenu({activeIcon, menus = [], context}) {
  return (
    <WithState>
      {({open, updateOpen}) => {
        const toggle = state => {
          updateOpen(state)
        }

        return (
          <React.Fragment>
            <Button
              style={{
                fontSize: 15,
                fontWeight: 600,
                position: 'relative',
                width: '100%',
                borderRadius: 0,
                justifyContent: 'left',
                backgroundColor: '#E63B43',
                color: 'white',
                textTransform: 'none',
                height: 50,
              }}
              onClick={() => {
                toggle(!open)
              }}
            >
              {activeIcon}{' '}
              {!open ? (
                <KeyboardArrowRight style={{position: 'absolute', right: 20, top: 13}} />
              ) : (
                <KeyboardArrowDown style={{position: 'absolute', right: 20, top: 13}} />
              )}
            </Button>
            <div style={{display: open ? 'block' : 'none', marginTop: -8, marginBottom: -8}}>
              <MenuList>
                {menus.map((menu, i) => {
                  return (
                    <MenuItem
                      key={i}
                      style={{
                        fontWeight: 600,
                        fontSize: 13,
                        backgroundColor: '#000',
                        color: 'white',
                      }}
                      onClick={() => {
                        toggle(!open)
                        if (menu.type && menu.type === 'external') {
                          window.open(menu.url, '_blank')
                        } else {
                          context.router.push(menu.url)
                          window.scrollTo(0, 0)
                        }
                        if (menu.onOptionSelect) {
                          menu.onOptionSelect()
                        }
                      }}
                    >
                      {menu.svgPath && (
                        <SvgIcon style={{margin: '7px 10px 0 0'}}>
                          <path fill="#FFFFFF" d={menu.svgPath} />
                        </SvgIcon>
                      )}
                      {menu.label}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </div>
          </React.Fragment>
        )
      }}
    </WithState>
  )
}

export default SlideMenu
