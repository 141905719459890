import isNode from 'detect-node'
import Cookies from 'cookies-js'

class UserSession {
  constructor() {
    this.token = null
    this.requestSubmitted = false
  }

  setToken(token) {
    this.token = token

    if (!isNode) {
      if (token) {
        window.localStorage.setItem('token', token)
        Cookies.set('Authorization', token)
      } else {
        window.localStorage.removeItem('token')
        Cookies.expire('Authorization')
      }
    }
  }

  setRequestSubmitted(requestSubmitted) {
    this.requestSubmitted = requestSubmitted

    if (!isNode) {
      if (requestSubmitted) {
        window.localStorage.setItem('requestSubmitted', requestSubmitted)
      } else {
        window.localStorage.removeItem('requestSubmitted')
      }
    }
  }

  setClientAuthCookie() {
    if (!isNode) {
      const token = window.localStorage.getItem('token')
      if (token) {
        Cookies.set('Authorization', token)
      } else {
        Cookies.expire('Authorization')
      }
    }
  }

  getToken() {
    if (!isNode) {
      return window.localStorage.getItem('token')
    }

    return this.token
  }

  getRequestSubmitted() {
    if (!isNode) {
      return window.localStorage.getItem('requestSubmitted')
    }

    return this.requestSubmitted
  }
}

const userSession = new UserSession()
export default userSession
