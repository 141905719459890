import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* regionFlow() {
  yield all([
    takeEvery(Types.CREATE_REGION_INTEREST, createRegionInterest),
    takeEvery(Types.GET_REGION_INTERESTS, getRegionInterests),
    takeEvery(Types.UPDATE_REGION_INTEREST, updateRegionInterest),
    takeEvery(Types.DELETE_REGION_INTEREST, deleteRegionInterest),
    takeEvery(Types.GET_REGION_INTERESTS_DATA, getRegionInterestData),
  ])
}

function* createRegionInterest(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createRegionInterest, payload)
    yield put(Actions.createRegionInterestSuccess(response))
  } catch (error) {
    yield put(Actions.createRegionInterestFailure(error))
  }
}

function* getRegionInterests(action) {
  const {params} = action
  try {
    const response = yield call(Api.getRegionInterests, params)
    yield put(Actions.getRegionInterestsSuccess(response))
  } catch (error) {
    yield put(Actions.getRegionInterestsFailure(error))
  }
}

function* updateRegionInterest(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateRegionInterest, id, data)
    yield put(Actions.updateRegionInterestSuccess(response))
  } catch (error) {
    yield put(Actions.updateRegionInterestFailure(error))
  }
}

function* deleteRegionInterest(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteRegionInterest, id, appId)
    yield put(Actions.deleteRegionInterestSuccess(id))
  } catch (error) {
    yield put(Actions.deleteRegionInterestFailure(error))
  }
}

function* getRegionInterestData() {
  try {
    const response = yield call(Api.getRegionInterestData)
    yield put(Actions.getRegionInterestDataSuccess(response))
  } catch (error) {
    yield put(Actions.getRegionInterestDataFailure(error))
  }
}
