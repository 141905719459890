export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT'
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS'
export const CREATE_ATTACHMENT_FAILURE = 'CREATE_ATTACHMENT_FAILURE'

export const GET_ATTACHMENTS = 'GET_ATTACHMENTS'
export const GET_ATTACHMENTS_SUCCESS = 'GET_ATTACHMENTS_SUCCESS'
export const GET_ATTACHMENTS_FAILURE = 'GET_ATTACHMENTS_FAILURE'

export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT'
export const UPDATE_ATTACHMENT_SUCCESS = 'UPDATE_ATTACHMENT_SUCCESS'
export const UPDATE_ATTACHMENT_FAILURE = 'UPDATE_ATTACHMENT_FAILURE'

export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS'
export const DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE'

export const RESET_ATTACHMENT_ERRORS = 'RESET_ATTACHMENT_ERRORS'
