import React from 'react'
import {Grid} from '@material-ui/core'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import ContractorHeader from './ContractorHeader'
import StepProgressBar from './StepProgressBar'
import PageUtils from '../utils/PageUtils'
import SaveForLaterModal from './SaveForLaterModal'
import {SectionHeader} from './BasicInfo'
import * as Actions from './redux/actions'
import Attachment from './attachment/Attachment'
import ContractorFooter from './ContractorFooter'
import AlertDialog from '../common/AlertDialog'

class Attachments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {openDialog: false}
    this.saveLaterSubmit = false
    this.saveLater = this.saveLater.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.submitApplication = this.submitApplication.bind(this)
  }

  componentDidMount() {
    if (this.props.params && this.props.params.id && !this.props.application.id) {
      this.props.getApplication(this.props.params.id, {next: 'attachments'})
    }
    PageUtils.scrollToTop()
  }

  saveLater(state) {
    this.saveLaterSubmit = state
  }

  submitApplication() {
    this.setState({openDialog: false})
    this.props.submitApplication(this.props.params.id)
  }

  onSubmit(e) {
    e.preventDefault()
    if (!this.saveLaterSubmit) {
      this.setState({openDialog: true})
      return
    }
    this.props.updateApplication(this.props.application.appId, this.state, {
      saveLater: this.saveLaterSubmit,
      next: 'attachments',
    })
  }

  render() {
    const {application, modalOpen} = this.props

    return (
      <Grid container>
        <StepProgressBar percent={100} />
        <AlertDialog
          title="Application submission"
          description="You are about to submit your application. Submitted application cannot be edited"
          question="Submit application now?"
          okLabel="Yes, Submit now!"
          cancelLabel="No"
          confirm
          onConfirm={this.submitApplication}
          onCancel={() => {
            this.setState({openDialog: false})
          }}
          open={this.state.openDialog}
        />
        <SaveForLaterModal
          closeModal={this.props.closeModal}
          application={application}
          modalOpen={modalOpen}
          next="attachments"
        />
        <form onSubmit={this.onSubmit} style={{width: '100%'}}>
          <ContractorHeader />
          <Grid container justify="center">
            <Grid item xs={12}>
              <SectionHeader>Attachments</SectionHeader>
            </Grid>

            <Attachment appId={this.props.params && this.props.params.id} />

            <Grid item xs={12} style={{marginTop: 50}}>
              <Grid container justify="flex-end">
                <Grid item xs={12} lg={6} md={12}>
                  <ContractorFooter
                    saveLater={this.saveLater}
                    previousLink={`/contractor/register/${application.appId}/additional`}
                    currentPage={4}
                    nextLabel="Finish"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    )
  }
}

Attachments.displayName = 'Attachments'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractor,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Attachments)
