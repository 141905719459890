import * as Types from './actions.news.types'

export function getNews() {
  return {type: Types.GET_NEWS}
}

export function getNewsSuccess(payload) {
  return {type: Types.GET_NEWS_SUCCESS, payload}
}

export function getNewsFailure(error) {
  return {type: Types.GET_NEWS_FAILURE, error}
}
