import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid, ButtonBase} from '@material-ui/core'
import {withResizeDetector} from 'react-resize-detector'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Row from './Row'
import InputRow from './InputRow'
import * as Actions from './redux/actions'
import PageUtils from '../../utils/PageUtils'

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  column: {
    flex: 1,
    borderRight: '1px solid black',
  },
  actionColumn: {
    width: 100,
    justifyContent: 'flex-end',
  },
  columnContent: {
    padding: 10,
  },
  columnHeader: {
    backgroundColor: '#d8d8d8',
    padding: 5,
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 16,
      fontWeight: 'bold',
      margin: 0,
    },
  },
  sectionHeader: {
    backgroundColor: '#d8d8d8',
    padding: 5,
    '& p': {
      margin: 0,
      fontWeight: 'bold',
    },
  },
  sectionColumn: {
    borderRight: '1px solid #979797',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
  tableContainer: {
    border: '1px solid #979797',
    borderBottom: 0,
    width: '100%',
    '& p': {
      fontSize: 12,
    },
  },
  sectionInputContainer: {
    padding: 20,
    minHeight: 30,
  },
  createButton: {
    backgroundColor: '#E63B43',
    width: 65,
    height: 25,
    marginLeft: 19,
    marginTop: 19,
    marginBottom: 19,
    borderRadius: 4,
    fontSize: 12,
    color: 'white',
    fontWeight: 600,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
    width: '95%',
  },
})
const mobileSize = 768

export const Divider = ({classes, width}) => {
  if (width > mobileSize) return null

  return (
    <Grid container justify="center">
      <hr className={classes.divider} />
    </Grid>
  )
}

class RegionInterest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addNew: false,
    }
    this.hideForm = this.hideForm.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.results.length > this.props.results.length) {
      this.setState({addNew: false})
    }
  }

  componentDidMount() {
    if (this.props.appId) {
      this.props.getRegionInterests({appId: this.props.appId})
    }
    this.props.getRegionInterestData()
    PageUtils.scrollToTop()
  }

  hideForm() {
    this.setState({addNew: false})
  }

  render() {
    const {classes, width, results, regionInterestData} = this.props

    return (
      <div className={classes.tableContainer}>
        <ButtonBase
          className={classes.createButton}
          onClick={() => {
            this.setState({addNew: true})
          }}
        >
          <span>CREATE</span>
        </ButtonBase>
        {this.state.addNew && (
          <React.Fragment>
            <InputRow
              {...this.props}
              hideForm={this.hideForm}
              regionInterestData={regionInterestData}
            />
            <Divider classes={classes} width={width} />
          </React.Fragment>
        )}
        {results.map((regionInterest, i) => {
          return (
            <React.Fragment key={regionInterest.id}>
              <Row
                {...this.props}
                regionInterest={regionInterest}
                hideHeader={width > mobileSize && i !== 0}
              />
              <Divider classes={classes} width={width} />
            </React.Fragment>
          )
        })}
        {results.length === 0 && (
          <Grid
            container
            style={{borderTop: '1px solid #979797', borderBottom: '1px solid #979797'}}
          >
            <p style={{padding: 10}}>No Results Found.</p>
          </Grid>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.regionInterest,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withResizeDetector,
)(RegionInterest)
