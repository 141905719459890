import * as Types from './actions.types'

export default function gallery(state = getInitialState(), action) {
  switch (action.type) {
    case Types.GET_GALLERY_IMAGES:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_GALLERY_IMAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case Types.GET_GALLERY_IMAGES_FAILURE: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    results: [],
  }
}
