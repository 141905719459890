import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import Helmet from 'react-helmet'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Header from './common/Header.jsx'
import Footer from './common/Footer.jsx'
import NotificationContainer from './common/NotificationContainer.jsx'
import {getProfile} from './profile/redux/actions'
import UserSession from './auth/UserSession'
import ImageUtils from './utils/ImageUtils'
import PageUtils from './utils/PageUtils'
import {GridContainerPage, GridItemPage} from './App.styles.js'
import bg from '../assets/landing-bg.png'
import * as CTActions from './construction_trenchless/redux/actions'

const headerlessLocations = ['confirm-email', 'soon', 'login']
const footerlessLocations = ['confirm-email', 'login']
const whiteLanzoCaresLocations = ['core-values']

const styles = theme => ({
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: 995,
    zIndex: -1,
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left -120px',
    backgroundSize: '100% auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showHeader: true,
      showFooter: true,
      addBg: false,
    }
  }

  componentWillMount() {
    if (UserSession.getToken() && !this.props.profile) {
      this.props.getProfile()
    }
    this.props.getCTPages()
  }

  componentDidMount() {
    this.setHeaderFooterState(this.props.location)

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      _.get(nextProps, 'location.pathname') !== _.get(this.props, 'location.pathname')
    if (hasLocationChanged) {
      this.setHeaderFooterState(nextProps.location)
    }
  }

  setHeaderFooterState(location) {
    const showHeader = headerlessLocations.indexOf(location.pathname.split('/').slice(-1)[0]) < 0
    const showFooter = footerlessLocations.indexOf(location.pathname.split('/').slice(-1)[0]) < 0

    if (showHeader !== this.state.showHeader || showFooter !== this.state.showFooter) {
      this.setState({showHeader, showFooter, addBg: location.pathname === '/'})
    } else {
      this.setState({addBg: location.pathname === '/'})
    }
  }

  render() {
    const {auth, profile, classes, location} = this.props
    const {showHeader, showFooter, addBg} = this.state

    return (
      <GridContainerPage
        id="appContain"
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <CssBaseline />
        <Helmet
          defaultTitle={PageUtils.getTitle()}
          titleTemplate={`${PageUtils.getTitle()} - %s`}
          meta={[
            {
              name: 'google-site-verification',
              content: 'Tuzb6QysDuzIrVwV8Eq6NgqRtfxukTkwmlnWmS4HfTI',
            },
            {name: 'twitter:card', content: 'summary'},
            {property: 'og:title', content: PageUtils.getTitle()},
            {property: 'og:site_name', content: PageUtils.getTitle()},
            {property: 'og:image', content: ImageUtils.getAppLogoUrl()},
            {
              property: 'og:description',
              content:
                'Infrastructure needs have changed since Lanzo first entered the industry nearly 50 years ago.',
            },
            {
              name: 'description',
              content:
                'Infrastructure needs have changed since Lanzo first entered the industry nearly 50 years ago.',
            },
            {name: 'keywords', content: 'Construction, trenchless Technology'},
            {name: 'city', content: 'Deerfield Beach'},
            {name: 'state', content: 'FL'},
            {name: 'zipcode', content: '33441'},
          ]}
        />
        {showHeader ? (
          <Header auth={auth} profile={profile} onHomeClick={() => {}} location={location} />
        ) : null}
        <GridItemPage item container justify="center" spacing={32}>
          <Grid item xs={12} style={{padding: 0, marginTop: showHeader ? 221 : 50}}>
            {this.props.children}
          </Grid>
        </GridItemPage>
        {showFooter ? (
          <Footer
            whiteLanzoCares={whiteLanzoCaresLocations.includes(
              location.pathname.split('/').slice(-1)[0],
            )}
          />
        ) : null}
        <NotificationContainer />
        {addBg && <div className={classes.backgroundImage} />}
      </GridContainerPage>
    )
  }
}

App.displayName = 'App'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...CTActions, getProfile}, dispatch)
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    menu: state.menu,
    profile: _.get(state.profile, 'profile', null),
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(App))
