import React from 'react'
import {Link} from 'react-router'
import Grid from '@material-ui/core/Grid'
import {ButtonBase, Hidden} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import ImageUtils from '../utils/ImageUtils'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
    background: theme.palette.background.paper,
  },
  lanzoCaresContainer: {
    backgroundColor: '#171717',
    color: 'white',
    textAlign: 'center',
    fontSize: '29px',
    padding: '30px 0',
    '& span': {
      lineHeight: '72px',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: 0,
      padding: 0,
      marginLeft: 10,
    },
    '& img': {
      marginRight: 25,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      padding: '20px 15px',
      '& span': {
        lineHeight: '15px',
        textAlign: 'left',
        marginBottom: 15,
      },
      '& img': {
        marginRight: 10,
      },
    },
  },
  lanzoCaresButton: {
    backgroundColor: '#E63B43',
    borderRadius: 5,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '40px',
    padding: '0 50px',
    marginLeft: '30px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '40px',
      height: '40px',
      marginLeft: 10,
    },
  },
  topContainer: {
    backgroundColor: 'rgba(0.09, 0.09, 0.09)',
    color: 'white',
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'cover',
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.82)), url(' +
        ImageUtils.getFooterBgUrl() +
        ')',
    },
  },
  linkButtonsGroupContainer: {
    maxWidth: 1300,
    margin: '80px auto 0 auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  linkButtonsContainer: {
    marginBottom: 20,
  },
  linkButton: {
    fontSize: '20px',
    fontWeight: 300,
    textAlign: 'left',
    '& strong': {
      fontWeight: 600,
    },
    '& span': {
      fontWeight: 300,
    },
    marginBottom: 20,
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
      margin: '0',
      paddingTop: 20,
      paddingBottom: 20,
      borderBottom: '1px solid rgba(255, 255, 255, 0.16)',
    },
  },
  contactContainer: {
    marginBottom: 20,
    '& p': {
      fontSize: '18px',
      fontWeight: 300,
      margin: '0 0 20px 0',
      padding: 0,
    },
    '& p strong': {
      fontSize: '15px',
      fontWeight: 600,
    },
    '& a': {
      margin: 0,
      padding: 0,
      verticalAlign: 'top',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '0 20px',
    },
  },
  socialIconButtons: {
    '& img': {
      width: 30,
      height: 30,
    },
    marginTop: 40,
    marginBottom: 40,
  },
  termsConditions: {
    textAlign: 'center',
    fontSize: '14px',
    padding: '10px 0',
  },
})

class Footer extends React.Component {
  backToTop(e) {
    e.preventDefault()

    const cosParameter = window.scrollY / 2

    let scrollCount = 0

    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }

  render() {
    const {classes, whiteLanzoCares} = this.props

    const links = [
      {
        label: 'About Us',
        href: '/about-us',
        component: {Link},
        children: [
          {
            label: 'Experience',
            href: 'https://www.lanzo.net/resource-project-profiles.html',
            component: 'a',
          },
          {
            label: 'Our Values',
            to: '/core-values',
            component: {Link},
          },
          {
            label: 'Our History',
            component: {Link},
            to: '/our-history',
          },
          {
            label: 'Lanzo Academy',
            to: '/soon',
            component: {Link},
          },
          {
            label: 'Safety At Lanzo',
            to: '/safety',
            component: {Link},
          },
        ],
      },
      {
        label: 'Projects',
        href: 'https://www.lanzo.net/projects.html',
        component: 'a',
        children: [],
      },
      {
        label: 'Sustainability',
        to: '/sustainability',
        component: {Link},
        children: [
          {
            label: 'Overview',
            to: '/soon',
            component: {Link},
          },
          {
            label: 'Environment',
            component: 'a',
            href: 'https://www.lanzo.net/protecting-environment.html',
          },
          {
            label: 'Employee Engagement',
            to: '/soon',
            component: {Link},
          },
          {
            label: 'Corporate Policies',
            to: '/corporate-policies',
            component: {Link},
          },
        ],
      },
      {
        label: 'Services',
        to: '/soon',
        component: {Link},
        children: [
          {
            label: 'Construction',
            href: '/construction/construction',
            component: 'a',
          },
          {
            label: 'Trenchless',
            href: '/trenchless/trenchless',
            component: 'a',
          },
          {
            label: 'Asphalt',
            href: '/soon',
            component: 'a',
          },
          {
            label: 'Plant Work',
            to: '/soon',
            component: {Link},
          },
          {
            label: 'Manufacturing',
            to: '/soon',
            component: {Link},
          },
          {
            label: 'Emergency Services',
            to: '/soon',
            component: {Link},
          },
          {
            label: 'Engineering Services',
            to: '/engineering-services',
            component: {Link},
          },
          {
            label: 'Tunnels & Directional Boring',
            to: '/soon',
            component: {Link},
          },
        ],
      },
      {
        label: 'Resources',
        to: '/soon',
        component: {Link},
        children: [
          {
            label: 'Gallery',
            to: '/gallery',
            component: {Link},
          },
          {
            label: 'Videos',
            to: '/videos',
            component: {Link},
          },
        ],
      },
      {
        label: 'Blog',
        to: '/soon',
        component: {Link},
        children: [],
      },
      {
        label: 'Careers',
        to: '/careers',
        component: {Link},
        children: [
          {
            label: 'Employees',
            href: 'https://www.lanzo.net/business.html',
            component: 'a',
          },
        ],
      },
      {
        label: 'Customers',
        to: '/login',
        component: 'a',
        target: '_blank',
        children: [],
      },
      {
        label: 'Become a Subcontractor',
        to: '/subcontractor',
        component: {Link},
        children: [],
      },
      {
        label: 'Contact Us',
        noBorder: true,
        to: '/contactus',
        component: {Link},
        children: [],
      },
    ]

    const desktopButtons = links.map(link => {
      return (
        <Grid container className={classes.linkButtonsContainer}>
          <Grid item xs={12}>
            <ButtonBase
              className={classes.linkButton}
              component={link.href ? 'a' : Link}
              href={link.href || false}
              to={link.to || false}
              target={link.target || false}
            >
              <span>
                <strong>{link.label}</strong>
              </span>
            </ButtonBase>
          </Grid>
          {link.children.map(child => (
            <Grid item xs={12}>
              <ButtonBase
                className={classes.linkButton}
                component={child.href ? 'a' : Link}
                href={child.href || false}
                to={child.to || false}
                target={child.target || false}
              >
                <span>{child.label}</span>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      )
    })

    const mobileButtons = links.map(link => {
      return (
        <Grid item xs={12}>
          <ButtonBase
            className={classes.linkButton}
            style={{borderBottom: link.noBorder ? 0 : ''}}
            component={link.href ? 'a' : Link}
            href={link.href || false}
            to={link.to || false}
            target={link.target || false}
          >
            <span>
              <strong>{link.label}</strong>
            </span>
          </ButtonBase>
        </Grid>
      )
    })

    const contactBlock = (
      <Grid item xs={12} md={2} className={classes.contactContainer}>
        <Hidden smDown={true}>
          <ButtonBase className={classes.linkButton} component={Link} to="/contactus">
            <span>
              <strong>Contact Us</strong>
            </span>
          </ButtonBase>
        </Hidden>
        <p style={{marginTop: 20}}>
          <strong>Ground, underground and underwater construction</strong>
        </p>

        <p>
          125 S.E. 5th Court
          <br />
          Deerflied Beach, Florida 33441-4749
        </p>
        <p>
          <strong>
            Phone FL:{' '}
            <ButtonBase component="a" href="tel:+19549790802">
              (954) 979-0802
            </ButtonBase>
          </strong>
        </p>
        <p>
          <strong>
            For information on CIPP technologies or underground infraestructure rehabilitation
            solutions.
          </strong>
        </p>
        <p>
          28135 Groesbeck Hwy
          <br />
          Roseville MI, 48066-2344
        </p>
        <p>
          <strong>
            Phone MI:{' '}
            <ButtonBase component="a" href="tel:+15867757566">
              (586) 775-7566
            </ButtonBase>
          </strong>
        </p>
        <Grid container className={classes.socialIconButtons}>
          <Grid item xs={4} style={{marginLeft: -5}}>
            <ButtonBase component="a" href="https://www.facebook.com/LanzoCARES" target="_blank">
              <img src={ImageUtils.getLogoFbBig()} alt="Facebook" />
            </ButtonBase>
          </Grid>
          <Grid item xs={4}>
            <ButtonBase
              component="a"
              href="https://www.linkedin.com/company/lanzo-companies/"
              target="_blank"
            >
              <img src={ImageUtils.getLogoInBig()} alt="LinkedIn" />
            </ButtonBase>
          </Grid>
          <Grid item xs={4}>
            <ButtonBase
              component="a"
              style={{marginTop: 3}}
              href="https://twitter.com/LanzoCARES"
              target="_blank"
            >
              <img src={ImageUtils.getLogoTwitterBig()} alt="Twitter" />
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <footer>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.lanzoCaresContainer}
            style={whiteLanzoCares ? {backgroundColor: 'white'} : {}}
          >
            <ButtonBase component="a" href="http://lanzocares.com" target="_blank">
              <Hidden mdDown={true}>
                <span>
                  <img src={ImageUtils.getIconLanzoCares()} alt="Lanzo Cares" />
                </span>
                <span style={whiteLanzoCares ? {color: 'black'} : {}}>
                  <strong>Lanzo Cares:</strong> Click Here to Learn More
                </span>
                <div className={classes.lanzoCaresButton}>LANZO CARES</div>
              </Hidden>
              <Hidden smUp={true}>
                <Grid container>
                  <Grid item xs={3}>
                    <img src={ImageUtils.getIconLanzoCares()} alt="Lanzo Cares" />
                  </Grid>
                  <Grid item container xs={9}>
                    <Grid item>
                      <span style={whiteLanzoCares ? {color: 'black'} : {}}>
                        <strong>Lanzo Cares:</strong> Click Here to Learn More
                      </span>
                    </Grid>
                    <Grid item>
                      <div className={classes.lanzoCaresButton}>LANZO CARES</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </ButtonBase>
          </Grid>
          <Grid item xs={12} className={classes.topContainer}>
            <Grid container className={classes.linkButtonsGroupContainer}>
              <Hidden mdDown={true}>
                <Grid item xs={2}>
                  {desktopButtons[0]}
                  {desktopButtons[1]}
                </Grid>
                <Grid item xs={2}>
                  {desktopButtons[2]}
                </Grid>
                <Grid item xs={2}>
                  {desktopButtons[3]}
                </Grid>
                <Grid item xs={2}>
                  {desktopButtons[4]}
                  {desktopButtons[5]}
                </Grid>
                <Grid item xs={2}>
                  {desktopButtons[6]}
                  {desktopButtons[7]}
                  {desktopButtons[8]}
                </Grid>
                {contactBlock}
              </Hidden>
              <Hidden smUp={true}>
                {mobileButtons}
                {contactBlock}
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.termsConditions}>
            <ButtonBase component={Link} to="/privacy" style={{textDecoration: 'underline'}}>
              Privacy Policy{' '}
            </ButtonBase>{' '}
            —{' '}
            <ButtonBase component={Link} to="/terms" style={{textDecoration: 'underline'}}>
              Terms & Conditions
            </ButtonBase>
          </Grid>
        </Grid>
      </footer>
    )
  }
}

Footer.displayName = 'Footer'

export default withStyles(styles)(Footer)
