import settings from '../../../config/settings'
import axios from '../api/axios'

export default class ContactUsApi {
  static createApplication(payload) {
    return axios.post(`${settings.apiBaseURL}/applications/with-contact`, payload)
  }

  static getApplication(id) {
    return axios.get(`${settings.apiBaseURL}/applications/${id}`)
  }

  static updateApplication(id, data) {
    return axios.patch(`${settings.apiBaseURL}/applications/${id}`, data)
  }

  static submitApplication(id) {
    return axios.post(`${settings.apiBaseURL}/applications/${id}/submit`)
  }
}
