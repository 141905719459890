import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import PageUtils from '../utils/PageUtils'
import corporate from '../../assets/corporateHeader.png'
import downArrow from '../../assets/downArrow.png'

const columnWidth = 831

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 300,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
  headerImage: {
    width: '100%',
    height: '500',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150',
      marginTop: 48,
    },
  },
  downArrow: {
    margin: '50 auto 10',
    [theme.breakpoints.down('sm')]: {
      margin: '20 auto 20',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 193,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    fontSize: 34,
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 20,
      top: 0,
      left: 0,
      width: '100%',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #FFFFFF',
      [theme.breakpoints.down('sm')]: {
        fontSize: 27,
      },
    },
    '& p': {
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '18pt',
      },
    },
  },
  blurb: {
    margin: '0 auto',
    padding: '30px 0 35px',
    fontSize: '15',
    fontFamily: 'century-gothic, sans-serif',
    lineHeight: '1.3',
    width: '1700',
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #e63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '.34',
      fontStyle: 'normal',
      fontWeight: 'normal',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginLeft: 0,
      },
    },
  },
  blurbText: {
    margin: '0 auto',
    width: columnWidth,
    lineHeight: '24px',
    '& h2': {
      lineHeight: '30px',
    },
    '& li::before': {
      top: -3,
    },
    '& ul': {
      listStyle: 'none',
      marginTop: 25,
    },
    '& h4': {
      marginBottom: 10,
    },
    '& h3': {
      marginTop: 25,
      marginBottom: -10,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: '0 20 0 20',
    },
  },
  black: {
    backgroundColor: '#171717',
    color: 'white',
  },
  white: {
    backgroundColor: '#fff',
  },
  topBlurb: {
    margin: '322 auto 20',
    '& p': {
      fontSize: 15,
      fontFamily: 'century-gothic, sans-serif',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginTop: 20,
      marginBottom: 20,
    },
  },
  buttonAndHeader: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 64,
      marginBottom: 89,
      '& h3': {
        fontSize: 14,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  },
  buttonRisk: {
    marginLeft: '50%',
    marginTop: -20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  question: {
    color: '#69A458',
  },
  secondaryTitle: {
    marginTop: 50,
  },
  customList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      padding: 0,
      margin: 0,
      marginBottom: 30,
      paddingLeft: 30,
    },
    '& li:before': {
      content: "''",
      display: 'block',
      float: 'left',
      width: 11,
      height: 11,
      margin: '5px 15px 0 -30px',
      backgroundColor: '#E63B43',
    },
  },
})

class CorporateRisksConduct extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={corporate} className={classes.headerImage} />
          <div className={classes.headerFloatContainer}>
            <h2>Corporate Policies - Risks and Code of Conduct</h2>
            <p>
              The purpose of the code is to provide LANZO Construction Company employees with
              straightforward and useful guidance for the way we conduct business at our company.
              The code can be found on Lanzo internet site. Employees are required to comply with
              the entire code, and so you should be familiar with it in its entirety.{' '}
            </p>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} className={classes.downArrow} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.topBlurb}>
              <div className={classes.blurbText}>
                <p>
                  The code applies to all employees and sets forth our expectation of everyone
                  working on our behalf, including consultants, agents, business partners and
                  suppliers. This document does not offer all the answers, nor does it attempt to do
                  so.{' '}
                  <p>
                    The code, although very comprehensive, cannot anticipate and address every
                    ethical situation you may encounter on the job. No code or policy can ever
                    completely substitute for the sound judgment and common sense that all employees
                    are expected to use. Instead, the LANZO code outlines our more important
                    standards and policies for employees. It helps employees deal with common
                    dilemmas. Where necessary, it points to other resources for further information.
                    You are responsible for learning the code and the rules, policies and laws that
                    govern our business, in particular those that apply to your specific job.{' '}
                  </p>
                  <p>
                    When in doubt, ask questions. You or your manager should contact the appropriate
                    management group responsible for the compliance area. Experts in numerous areas
                    are available to help you, such as Legal, Compliance and Ethics, Security, Human
                    Resources, Health and Safety, Environmental and Internal Audit. Equally
                    important, you are responsible for reporting known or suspected ethical, legal
                    or regulatory violations, including violations of the Code of Business Conduct
                    and Ethics. Such concerns may be reported upward through your internal chain of
                    management.{' '}
                  </p>
                  <p>
                    If you are unable or unwilling to report through your department’s management,
                    you may contact the Compliance Officer. Human Resources and other members of
                    leadership are also available to you. The code and our company’s policies,
                    procedures and other rules exist to ensure a safe and productive working
                    environment for all employees. They are also intended to enable positive
                    relationships with customers, business partners and other stakeholders.{' '}
                  </p>
                  <p>
                    All of us are responsible for ensuring these goals. Please take the time to
                    better understand the Code of Business Conduct and Ethics. Do not hesitate to
                    ask questions and seek further information. The code is not intended to form an
                    express or implied contract between LANZO and any employee. The company’s
                    Compliance Division is responsible for and has the authority to interpret the
                    code.{' '}
                  </p>
                  <p>
                    The Code of Business Conduct and Ethics is reviewed by the Compliance Officer at
                    least once a year. It is subject to change or modification by LANZO at any time.
                  </p>{' '}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
          <div className={classes.blurbText}>
            <h2>
              The Code of Business Conduct and Ethics has its foundation: The Mission, Vision, and
              Values of LANZO
            </h2>
            <h3>Mission</h3>
            <p>
              Our mission is to create sustainable value for our stakeholders by providing quality,
              safe, reliable service for our customers through the use of environmentally friendly
              energy sources and innovation, while reducing our corporate carbon footprint, and with
              a commitment to the well-being of our communities.
            </p>
            <h3>Vision</h3>
            <p>
              We aspire to be the preferred construction company because of our commitment to the
              creation of value, quality of life, the safety of people and of supply, the protection
              of the environment and customer focus.
            </p>
            <h3>Values</h3>
            <p>
              This vision is based on 12 values representing firm commitments of the LANZO
              Construction Company:
            </p>
            <ul className={classes.customList}>
              <li>
                Sustainable creation of value: We want to lead the creation of value in a
                sustainable manner, with a long-term vision that achieves a better future for
                employees, customers, shareholders and other stakeholders without compromising
                present results.{' '}
              </li>
              <li>
                {' '}
                Ethical principles: We adhere to principles of integrity, honesty and social
                responsibility. Our actions should reflect the highest standards of ethical behavior
                and compliance with the law.
              </li>
              <li>
                {' '}
                Corporate governance and transparency: We engage communities, customers, suppliers,
                employees and shareholders in the governance of our company, demonstrating
                leadership and transparency in our actions.
              </li>
              <li>
                Development of our workforce: Our employees are our most strategic asset. We must
                offer a good working environment, fostering development, training, work-life balance
                and equal opportunity. We work to recruit, promote and retain talent and encourage
                professional growth, making our workforce participants in a successful business
                enterprise.
              </li>{' '}
              <li>
                Social commitment: We seek the sustainable development of the communities in which
                we do business. We foster local economic development, generating employment and
                prosperity.
              </li>
              <li>
                {' '}
                Sense of belonging: We involve all stakeholders in our company, engaging in
                continuous and constructive dialogue to understand their expectations and build
                strong bonds. We generate trust and a sense of belonging as an integral part of our
                company.
              </li>
              <li>
                {' '}
                Quality: High-quality management of our processes and resources is essential in the
                creation of value for our customers, shareholders, employees and the other
                stakeholders.
              </li>
              <li>
                {' '}
                Safety and reliability: We work to offer high-quality service and energy to our
                customers within a safe and reliable environment. The safety of our employees and
                customers is an unwavering priority, requiring the adoption of best practices in
                safety and occupational health.
              </li>
              <li>
                Innovation: Innovation is a strategic variable that affects all the activities we
                engage in. We want to be leaders in innovation in the construction sector, with that
                innovation focused on sustainable development, the promotion of renewable energy and
                the development of opportunities offered in digitalization, automation, and new
                technologies and business models.
              </li>
              <li>
                {' '}
                Respect for the environment: We must lead in the fight against climate change, the
                development of clean energy and respect for the environment. These factors will
                distinguish us in the construction sector as a leading company worldwide.{' '}
              </li>
              <li>
                Customer focus: We must understand the needs and expectations of our customers to
                offer them better solutions within a free market, continuously working to maintain
                and increase satisfaction and connection to our company.{' '}
              </li>
              <li>
                Institutional loyalty: Our interactions with government agencies should be based on
                respect for the law, trust, professionalism, collaboration, reciprocity and good
                faith. Payments to tax authorities are a principal contribution to sustaining public
                services and should be valued as contributions to society.
              </li>
            </ul>

            <h3>We expect employees that …</h3>
            <ul className={classes.customList}>
              <li>
                Understand and comply with LANZO policies and applicable regulatory and statutory
                requirements relevant to their jobs.
              </li>
              <li>
                Adhere to ethical business practices and speak out if they become aware of
                potentially unethical practices by others.
              </li>
              <li>
                {' '}
                Place LANZO’s interest in any business transaction ahead of their own. Do not use
                their position in LANZO or knowledge of operations to gain any personal benefit.
              </li>
              <li>
                Resolve potential conflicts of interest, which can arise in situations such as gift
                exchanges, personal relationships, outside activities and past or future employment.
              </li>{' '}
              <li>Comply with financial management and purchasing policies and procedures.</li>
              <li>Maintain accurate records and comply with internal controls.</li>
              <li>Communicate clearly and work to resolve conflict.</li>
              <li>
                Report concerns to their supervisor, another member of leadership, the appropriate
                management group (e.g., Legal, Compliance and Ethics, Security, Human Resources,
                Health and Safety, Environmental, Internal Audit) or the Helpline.
              </li>
            </ul>
            <h3>Employees, must at all times ...</h3>
            <p>
              Conduct their relationships with other employees, co-workers, customers, business
              partners, investors, regulators, suppliers, consultants and the community in an
              ethical manner. This includes maintaining high standards for professionalism and
              integrity.
            </p>
            <h3>Employees must not ...</h3>
            <ul className={classes.customList}>
              <li>
                Use their positions with LANZO or knowledge of our operations to gain any favor,
                advantage or benefit for themselves personally.
              </li>
              <li>
                Use their position with LANZO to gain any unfair advantage or benefit for LANZO or
                themselves through deception, misrepresentation or fraud.{' '}
              </li>
              <li>
                Improperly influence or attempt to influence others in the exercise of their duties
                by offering gifts, rewards or other benefits. Never solicit or accept such benefits
                from others. .
              </li>
              <li>
                Take advantage of anyone through an unfair practice, such as manipulation,
                concealment, abuse of privileged information or misrepresentation of material facts
              </li>
            </ul>
            <h3>Supervisors, Managers, Directors and Executives must, at all times ...</h3>
            <ul className={classes.customList}>
              <li>Set a strong ethical example.</li>
              <li>
                Be held to high standards and set strong ethical examples as leaders to other
                employees.
              </li>
              <li>Make sure their employees understand and follow the rules.</li>
              <li>
                Create a positive, respectful work environment that encourages employees to speak up
                about concerns.
              </li>
              <li>
                Promptly communicate concerns and reports of potential misconduct made by employees
                to the appropriate management group.
              </li>
            </ul>
            <h3>Expectations of Lanzo's Management</h3>
            <p>
              Although all employees must meet high standards for honest and ethical behavior,
              LANZO’s management employees, as leaders, including but not limited to LANZO’s CEO,
              COO, CFO and other members of the Management staff, are subject to a higher standard
              for actively promoting and modeling such behavior. As part of this commitment to the
              highest possible honest and ethical conduct, LANZO’s management employees shall:{' '}
            </p>
            <ul className={classes.customList}>
              <li>
                Demonstrate ethics in the handling of actual or apparent conflicts of interest. Any
                transactions or relationships that may give rise to a conflict of interest should be
                avoided and disclosed to the Compliance Officer.
              </li>
              <li>
                Actively promote the protection of confidential information about the company and
                its stakeholders.
              </li>
              <li>
                Ensure that the company’s assets and resources are used responsibly and for business
                purposes only.
              </li>
              <li>
                Make disclosures and reports that are complete, fair, accurate and timely,
                particularly those that impact reports to the authorities, other regulators and
                public communications.
              </li>
              <li>
                Comply with this code and all laws, rules and regulations, and promptly report
                suspected fraud and other violations.
              </li>
            </ul>
            <h3>Non-Discrimination and Professional Development</h3>
            <p>
              LANZO is committed to a workplace where all employees are guaranteed equal employment
              opportunities. This commitment requires an objective hiring and selection program,
              based on the academic, personal and professional qualifications of candidates and the
              needs of the company. Candidates for open positions should be assessed rigorously and
              objectively, based on their professional merits.
            </p>
            <p>
              LANZO invests in programs that promote training, equal opportunities for advancement
              and professional career development. We believe that these efforts enhance employees’
              ability to contribute to the company’s objectives. Employees are encouraged to
              continuously update their professional knowledge and to take advantage of offered
              training programs.
            </p>
            <h3>Equal Employment Opportunity</h3>
            <p>
              Employment decisions must be made without regard to categories protected by applicable
              state or federal law. These categories include, without limitation, a person’s race,
              color, religion, age, gender, gender identity, sexual orientation, national origin,
              physical or mental disability, marital status, veteran status, genetic information or
              any other personal characteristic protected by local, state or federal law.
              “Employment decisions” generally include those related to hiring, recruiting,
              training, promotion, compensation, discipline and termination. Other activities may be
              implicated as well. The company takes this commitment very seriously and has adopted
              strong policies, including its Equal Opportunity Policy available on the portal
              intended to prohibit illegal discrimination. Any suspected violation should
              immediately be reported to Human Resources or the Compliance Officer.
            </p>
            <h3>Harassment</h3>
            <p>
              Mutual respect is a fundamental requirement for a harassment-free and
              discrimination-free work environment where employees can contribute to their fullest
              potential. Workplace harassment is prohibited under the code and includes offensive
              conduct that results in a work environment that a reasonable person would consider
              intimidating, hostile or abusive. If the behavior is based on discrimination against a
              protected class, it may also be illegal.
            </p>
            <p>
              Harassment against a person in retaliation for filing a discrimination charge,
              protesting against illegal discrimination or participating in an investigation may
              also be illegal. Workplace harassment will result in disciplinary action up to and
              including termination of employment. Harassment can come in many forms. Verbal
              harassment may include offensive remarks, statements and even inappropriate sounds.
              Offensive slurs, jokes and comments are examples of prohibited behavior. Physical
              harassment may include conduct such as hitting, pushing, blocking someone’s way,
              brushing against another employee, vulgar movements and inappropriate touching. Visual
              examples may include displaying materials with offensive content such as calendars,
              pictures or other objects.{' '}
            </p>
            <p>
              Offensive written notes and email messages and the use of company property (such as
              computers and cell phones) to display, store, retain or distribute such material are
              strictly prohibited. Sexual harassment is a form of discrimination. It includes
              (without limitation) unwelcome sexual advances, requests for sexual favors and other
              actions of a sexually offensive nature where submission to such actions is implicitly
              or explicitly a condition of employment, is a basis for employment decisions, could
              interfere with work performance or could create an intimidating, hostile or offensive
              work environment.{' '}
            </p>
            <p>
              Displaying posters, calendars or other sexually suggestive materials and sexually
              offensive comments, even if intended as jokes, may also constitute sexual harassment.
              Employees are required to take training to recognize prohibited harassment and
              discrimination. Any employee who experiences or witnesses such behavior is expected to
              immediately report the incident so that the company can take action. Refer to LANZO’s
              Sexual Harassment Prevention Policy for more information. Reports may be directed to a
              supervisor, a member of senior management, a Human Resources representative or the
              Compliance Officer.
            </p>
            <h3>Violence</h3>
            <p>
              All employees deserve a workplace free from threats and acts of violence. This
              includes violence in any form, including physical, sexual and psychological. We will
              not tolerate such actions. Violations may result in termination of employment and even
              support criminal prosecution in extreme cases. Abuse of authority and intimidation are
              also prohibited in the workplace. If you observe an act or threat of violence, you
              should call law enforcement right away. You should also immediately report the matter
              to your supervisor, Human Resources or Corporate Safety Director.
            </p>
            <p className={classes.question}>Q: What is illegal harassment?</p>
            <p>
              A: Harassment is pervasive conduct that denigrates or demonstrates hostility or
              aversion toward an individual because he or she is a member of a legally protected
              class. To be “pervasive,” conduct must be persistent and usually more than an isolated
              incident.
            </p>
            <p className={classes.question}>Q: What is discrimination?</p>
            <p>
              A: Discrimination occurs when a person or group of people is treated differently from
              another person or group of people because of being a member of a legally protected
              class.
            </p>
            <p className={classes.question}>
              Q: I regularly joke with an older employee about his inability to keep up due to his
              age. Could this be considered harassment even though I’m only kidding?
            </p>
            <p>
              A: Yes. The employee who is the target of the joke may find this offensive, and it may
              interfere with his work performance. He may not tell you that he is offended and
              instead report the matter or just keep it to himself. Someone else may also report
              you. Regardless, you should show respect for the person and not joke about his being a
              member of a protected class.
            </p>
            <p className={classes.question}>
              Q: I told a sexually suggestive joke in a meeting and everyone laughed, both men and
              women. Isn’t it okay?
            </p>
            <p>
              A: No. Any joke of a sexual nature is prohibited. Sometimes people laugh to cover up
              their embarrassment or discomfort. You should not assume that a joke is acceptable
              just because no one objects at the time.
            </p>
            <h3>Reconciliation of Work and Family Life</h3>
            <p>
              LANZO respects the personal and family lives of its employees through the promotion of
              programs intended to encourage a balance between personal and work responsibilities.
              For example, managers should limit work related emails to their employees outside of
              work hours.
            </p>
            <p>
              In addition, LANZO expects that all employees will take responsibility for ensuring
              that we all work in a safe, healthy environment.
            </p>
            <h3>Right to Privacy</h3>
            <p>
              LANZO considers the privacy and security of its employees’ non-public personal
              information to be of the utmost importance, including protected personally
              identifiable information such as employees’ medical and financial information. All
              related laws and regulations related to this area should be respected and complied
              with.{' '}
            </p>
            <h3>Workplace Health and Safety</h3>
            <p>
              LANZO is committed to providing a safe and healthy workplace that promotes a no-harm
              culture where we never compromise on safety. No job is so important or urgent that we
              cannot take the time to perform our work safely. We will identify, evaluate and
              control risks to protect both people and business assets and work proactively to
              prevent health- and safety-related incidents before they occur by implementing
              effective safety programs, learning from our experiences and implementing sustainable
              actions and process improvements. All of us play a part by utilizing the tools,
              equipment and training provided to perform our work safely.
            </p>
            <p>
              Our expectations for employees in the area of workplace health and safety include:
            </p>
            <ul className={classes.customList}>
              <li>Supporting a culture where safety is a core value.</li>
              <li>
                Compliance with applicable company safety and health policies, laws and regulations.
              </li>
              <li>
                Actively engaging in programs and efforts to continuously improve health and safety
                performance.
              </li>
              <li>
                Prompt reporting of all incidents, unsafe acts and conditions including near misses,
                property damage and personal injuries. Creating and maintaining the safest possible
                work environment requires a commitment from all employees. An excellent health and
                safety culture not only protect our employees, contractors and the public but is
                vital to our business stability and prosperity.
              </li>
            </ul>
            <h3>Protecting the Environment</h3>
            <p>
              Our activities should always demonstrate the company’s respect for the environment.
              This includes complying with or exceeding standards established in applicable
              environmental laws and regulations. This priority is supported by policies and
              procedures adopted by the company. Consequently, we should act to minimize the impact
              of our activities on the environment by engaging in sound environmental practices and
              supporting environmental stewardship. Areas of focus include reducing waste and
              pollution, conserving natural resources, promoting energy savings and sponsoring
              research and development.
            </p>
            <p>
              There are numerous ways in which we are expected to demonstrate our support for
              protecting the environment:
            </p>
            <ul className={classes.customList}>
              <li>Compliance with applicable environmental laws and regulations.</li>
              <li>
                Providing adequate resources to meet environmental commitments and obligations.{' '}
              </li>
              <li>Working with environmental regulatory agencies to resolve issues.</li>
              <li>
                Continuously improving our environmental performance and corporate social
                responsibility initiatives.
              </li>
              <li> Compliance with environmental permits and licenses. </li>
              <li>
                Hiring reputable and certified services for environmentally sensitive activities,
                such as disposal of hazardous materials.{' '}
              </li>
              <li>
                Accurately maintaining and preserving records required by environmental laws and
                regulations.{' '}
              </li>
            </ul>
            <div className={classes.secondaryTitle}>
              <h2>Corporate Policies - Risks and Code of Conduct</h2>
              <h3>Acceptance of Gifts</h3>
              <p>
                Vendors, suppliers and customers often acknowledge valued professional relationships
                with gifts. While such gifts are often inexpensive tokens of appreciation, they can
                sometimes be problematic under our Code of Business Conduct and Ethics. Should you
                be presented with a gift at any time throughout the year, consider the following
                discussion before accepting it. An employee who accepts an inappropriate gift may
                cause others to question his or her objectivity and commitment to ethical business
                conduct.
              </p>
              <p>
                For example, an employee who accepts a valuable gift may be accused of favoritism or
                obligation toward the giver. Even if the employee has not been influenced, the
                simple appearance can compromise the reputations of the employee and the company.
                Our Code of Business Conduct and Ethics prohibits the acceptance of gifts intended
                to induce or reward a breach of trust, impartiality or good faith.{' '}
              </p>
              <p>
                This prohibition is further enforced by our company’s Gift and Hospitality Rule,
                available on the company’s intranet site. Employees should review the Gift and
                Hospitality Rule for additional standards. Any gift or hospitality that could be
                viewed as improperly influencing the recipient’s decision-making should be avoided.{' '}
              </p>
              <p>As a general rule, employees should never accept any gift unless: </p>
              <ul className={classes.customList}>
                <li>The gift is of insignificant or symbolic financial value;</li>
                <li>The gift is a sign of courtesy or a customary business gift or token; </li>
                <li>
                  The gift is not forbidden by law or generally accepted business practices.
                  Employees should never accept or offer cash gifts or monetary loans of any amount.
                  Bear in mind that an “insignificant or symbolic” value (a “nominal” value) cannot
                  be precisely defined, and we must rely on common sense and judgment to determine
                  whether a gift’s value is nominal.{' '}
                </li>
              </ul>
              <p>
                Thus, it is advisable to consult with your direct supervisor when a gift is offered.
              </p>
              <p className={classes.question}>
                Q: A vendor has season tickets to a local sports team and has offered to take me to
                a game. This is a one-time offer, and we will be responsible for our own
                transportation, food and beverages. The tickets are relatively inexpensive. Although
                the client representative and I work closely, all vendor selection and purchasing
                decisions, as well as evaluations of the contractor’s work, are handled through
                other departments. Can I accept?
              </p>
              <p>
                A: You should speak with your supervisor first. He or she should determine whether
                the expense is reasonable and whether your attendance will provide some benefit to
                the company. Other factors should also be considered, such as whether reciprocal
                offers are ever extended, whether there is an open bid involving the contractor and
                whether you can influence the directing of business to the contractor.
              </p>
              <p className={classes.question}>
                Q: A vendor to the company has asked that I attend an annual “industry conference”
                that the vendor sponsors in Florida. The vendor will cover all expenses, including
                transportation, fine dining and lodging, and entertainment. The vendor’s in-house
                experts will discuss recent developments in the industry for a few hours each day,
                followed by complimentary rounds of golf with members of the vendor’s sales team.
                Can I accept this invitation?{' '}
              </p>
              <p>
                A: You should speak with your supervisor and the Compliance Officer. Your attendance
                may provide benefit to the company. However, other factors need to be considered,
                such as your ability to direct business to the vendor, whether other customers have
                been invited and how elaborate the expenses will be. All things considered; this
                offer should be closely scrutinized.{' '}
              </p>
              <p className={classes.question}>
                Q: A vendor sends your department six large, perishable hams.
              </p>
              <p>
                A: The value is not insignificant or symbolic, and therefore the hams should not be
                accepted. Because the hams are perishable, however, they may be donated to a charity
                on the vendor’s behalf. A letter should be sent to prevent future misunderstandings.
              </p>
              <p className={classes.question}>Q: A contractor sends you a bottle of wine.</p>
              <p>
                A: The value of a bottle of wine can vary widely. Regardless, our general business
                practice has historically been to not accept gifts of alcoholic beverages. The wine
                should be returned with an appropriate letter.
              </p>
              <p className={classes.question}>
                Q: A consultant sends you a small gift basket of assorted food items with a note of
                thanks.
              </p>
              <p>
                A: Again, the value can vary widely. If your direct supervisor has confirmed that
                the gift is permitted, it may be accepted. Ideally, the gift basket should be shared
                with the entire work group, which is typically the giver’s intent
              </p>
              <h3>Conflicts of Interest</h3>
              <p>
                Employees have a duty to avoid actual or potential conflicts of interest with the
                company. Generally speaking, a “conflict of interest” is a situation where the
                personal interests of an employee directly or indirectly conflict (or could
                potentially conflict) with the best interests of the company.
              </p>
              <p>
                An employee’s personal interests also include the interests of a spouse or partner,
                family member, friend or related business interest. In all activities within the
                scope of their employment, employees are required to place the corporation’s best
                interest ahead of personal interests or gain.
              </p>
              <p>
                To assist in avoiding a violation, employees must disclose to management all the
                facts of any situation, such as outside employment where a conflict of interest
                could arise. To avoid conflicts of interest, you should obtain permission from your
                direct supervisor and notify Human Resources before engaging in outside employment
                (moonlighting).
              </p>
              <p>
                They may work with the Compliance Officer to identify potential conflicts of
                interest. Moonlighting for competitors is prohibited. Disclosures can be in your own
                best interest as well. When an employee makes decisions that appear based on
                personal benefits, as opposed to the best interests of the company, the employee’s
                reputation for objectivity and integrity is at risk.{' '}
              </p>
              <p>
                Even the appearance of a conflict of interest can be damaging. If you are ever in a
                position where your objectivity may be questioned because of personal interests, you
                should notify your supervisor immediately.
              </p>
              <p className={classes.question}>
                Q: My supervisor asked me to pay a vendor’s invoice for work that I know won’t be
                completed until next quarter. The invoice inaccurately implies that the work has
                been completed. When I said I felt uncomfortable doing this, she said not to worry,
                that the vendor had a long-term relationship with us, and she wanted “to help out
                with his cash flow this one time.” Is this acceptable?{' '}
              </p>
              <p>
                A: No. All financial records must be accurate, and it would be a violation to pay an
                invoice that states the work has been completed when it has not in fact been
                completed. Explain to your supervisor that you cannot do this because it violates
                the Code of Business Conduct and Ethics. If she insists, report the incident to the
                Compliance Division or the Helpline. Although generally prohibited, there may be
                limited circumstances under which the properly documented prepayment of vendors is
                appropriate. Contact the Procurement Department with questions.{' '}
              </p>
              <p className={classes.question}>
                Q: My cousin works for a software firm that intends to respond to the company’s RFP
                for a major telecommunications project. He would like the names of the key decision
                makers, competitors, bid values and advice on how to negotiate terms with the
                company. Can I help him out?
              </p>
              <p>
                A: No. This is proprietary information that should not be used to benefit you, your
                family or other individuals. This information would also put your cousin’s firm at
                an unfair advantage against its competitors. You must not share this type of
                information with anyone outside the company.
              </p>
              <h3>Sub-Contractors and Suppliers</h3>
              <p>
                LANZO does business with numerous vendors, and the company’s purchasing activities
                impact the communities in which we operate.
              </p>
              <p>
                LANZO publishes a Supplier Code of Ethics on its external website. This establishes
                the expectation that vendors will meet our standards for ethical conduct and
                compliance with the law.{' '}
              </p>
              <p>
                Further, the Purchasing Department has established policies and procedures to ensure
                that purchases of goods and services are made in the best interests of the company,
                its shareholders and its ratepayers. These standards are intended to ensure that our
                selection of providers and suppliers conforms to an objective and impartial
                standard. Purchases must be made responsibly, under appropriate oversight,
                respecting our obligations to our community of suppliers and without conflicts of
                interest.{' '}
              </p>
              <p>
                All employees are responsible for meeting these standards. Managers are further
                responsible for promoting these standards and ensuring compliance in their
                departments.{' '}
              </p>
              <p>
                All employees who make purchases on behalf of the company must comply with the
                following ethical standards:{' '}
              </p>
              <ul className={classes.customList}>
                <li>Disclosing potential or perceived conflicts of interest.</li>
                <li>
                  Avoiding the appearance of favoritism or partiality toward particular vendors.{' '}
                </li>
                <li>
                  Avoiding inappropriate gifts and hospitality, with particular care during periods
                  of bidding.{' '}
                </li>
                <li>
                  Never sharing proprietary information, competitor bids or internal cost estimates
                  with vendors.
                </li>
                <li>
                  Following the steps required for the type of purchase being made. When in doubt,
                  contact the Purchasing Department or the Compliance Division for guidance
                </li>
              </ul>
              <h3>Rules on Personal Use of Company Assets</h3>
              <p>
                LANZOD provides employees with equipment, tools and other resources necessary to
                perform their jobs. Employees have a responsibility to maintain and protect these
                resources. Theft, carelessness and waste have a direct impact on the company’s
                financial health and its ability to provide benefits to employees and other
                stakeholders in the community.
              </p>
              <p>
                The company’s assets include physical objects such as buildings and facilities,
                vehicles, equipment, telephones, tools and supplies. They also include electronic
                tools such as computers, telephones, cell phones and email systems. Various types of
                intellectual property are also a company asset and include software and databases,
                proprietary and financial information, patents, copyrights and trademarks. The
                company also is responsible for protecting assets and information entrusted to the
                company by third parties, such as customer information, pricing and bid proposals,
                and licensed intellectual property. Items that are to be disposed of or salvaged,
                such as metal and wood scrap, utility poles and obsolete electronics, are also
                company assets and may not be removed or given away by employees, regardless of
                value, without written authorization by a business area leader. Company assets are
                intended to be used by employees for legitimate business purposes. Personal use of
                company assets is generally prohibited; direct supervisors may grant occasional
                exceptions on a case-by-case basis for low-value assets. In such cases, the employee
                and supervisor are together responsible for ensuring that the company does not bear
                a risk of loss and that the asset is returned promptly in its original condition.
              </p>
              <p>Certain uses of company assets are always prohibited, including: </p>
              <ul className={classes.customList}>
                <li>
                  Uses in violation of law, the Code of Business Conduct and Ethics, a license or
                  any contractual condition (such as restrictions in an insurance policy).
                </li>
                <li>Uses for outside work activities or for personal gain or profit. </li>
                <li>
                  Uses that bear the risk of injury, damage or other loss to any person, property or
                  the asset.{' '}
                </li>
                <li>
                  Uses that could contribute to added cost, inconvenience, reputational damage or
                  other harm to the company.{' '}
                </li>
                <li>
                  Uses that would violate any other applicable company policy, practice or
                  procedure.{' '}
                </li>
                <li>
                  Uses without the actual knowledge and written permission of the employee’s direct
                  supervisor
                </li>
              </ul>
              <h3>Corporate Expenses</h3>
              <p>
                Employees authorized to make purchases on behalf of the company must do so honestly,
                prudently and only when necessary to the business of the company. These purchases
                must be made responsibly, conserving the company’s resources, avoiding waste and
                allocating to appropriate accounts.
              </p>
              <p>
                Purchases must also comply with applicable laws, regulations and internal policies.
                The company’s Purchasing rules, Corporate Credit Card Policy and Business Expense
                Policy are examples. Supporting documentation must always be obtained and submitted.{' '}
              </p>
              <p>
                Managers who approve purchases are personally responsible for ensuring that these
                purchases are legitimate, appropriately documented and coded, and comply with the
                company’s policies and procedures.{' '}
              </p>
              <p>
                Violations of these policies are considered serious infractions and may result in
                disciplinary action, up to and including termination of employment.
              </p>
            </div>
            <div className={classes.secondaryTitle}>
              <h2>Our Responsibilities to Others</h2>
              <h3>Private and Confidential Information</h3>
              <p>
                Many of us at LANZOD are entrusted with sensitive and confidential information. This
                information includes non-public information that could be inappropriately used by a
                third party or harmful to the company if disclosed.
              </p>
              <p>
                Examples of such information include personal information on our customers and
                employees (e.g., Social Security numbers, financial account numbers, medical and
                benefits information) and data related to our business (e.g., critical
                infrastructure, financial information, business plans, competitive bids).{' '}
              </p>
              <p>
                Many of us must utilize this information to perform legitimate work. In the wrong
                hands, however, such information can result in severe harm to customers, employees
                and the company. Information should be considered sensitive and confidential if it
                is not in the public domain and is the property of or entrusted to the care of the
                company.{' '}
              </p>
              <p>
                Such information should be kept secret and not publicized or used for personal
                purposes unless with the company’s express authorization. Such information should
                also be appropriately marked as confidential, in accordance with Corporate
                Security’s Data Asset Classification Security Rule. Never disclose confidential
                information entrusted to us by the company or its customers, except when disclosure
                is authorized or legally mandated. The company entrusts us with this information,
                and we must use this information solely for its intended purpose and never for
                improper personal advantage or for the advantage of others.{' '}
              </p>
              <p>
                In the event an employee leaves the company, the employee is required to return all
                sensitive and confidential information in his or her possession. The obligation to
                keep this information confidential remains in force even after leaving the company.
                Absent specific authorization do not disclose confidential business information you
                have acquired during your employment with LANZOD to any outside party.{' '}
              </p>
              <p>Important safeguards for preventing unauthorized disclosures include:</p>
              <ul className={classes.customList}>
                <li>
                  Not discussing confidential information with people outside the company. This
                  includes vendors, family and friends.
                </li>
                <li>
                  Discussing confidential information with other employees only if they have a need
                  to know for a valid business purpose and are authorized to receive the
                  information.{' '}
                </li>
                <li>
                  Taking appropriate precautions to safeguard the information, such as identifying
                  materials as confidential and keeping them in protected locations. Some types of
                  information are protected by law, where unauthorized loss or disclosure may
                  require that the company take prompt action, such as notice to the affected person
                  or persons.{' '}
                </li>
              </ul>
              <p>
                Two common examples of legally protected information include a person’s Social
                Security number and credit or debit card number. If you suspect that such a loss or
                disclosure has occurred, whether through inadvertent error, theft, fraud or other
                means, you should immediately report it to a member of our executive committee and
                our Corporate Safety Director.{' '}
              </p>
              <h3>Conclusion</h3>
              <p>
                This Code of Business Conduct and Ethics is a collection of key principles that
                apply to all employees and agents of the company. The code has been adopted by our
                Board of Directors and reflects our commitment to adhering to the highest standards
                of ethics and integrity.
              </p>
              <p>
                No code, however comprehensive, can anticipate and address every ethical situation
                you may encounter on the job. The code must be complemented by good judgment and
                common sense. That is why this code addresses the most common dilemmas faced by
                employees and, where necessary, refers to other resources for further information.
                Situations will arise where you need clarification or more information to make the
                right decision. You are responsible for recognizing these situations and acting
                accordingly.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

CorporateRisksConduct.displayName = 'Corporate-Risks-Conduct'

export default withStyles(styles)(CorporateRisksConduct)
