import _ from 'lodash'
import * as Types from './actions.types'
import * as ErrorUtils from '../../../utils/ErrorUtils'

export default function contact(state = getInitialState(), action) {
  switch (action.type) {
    case Types.SHOW_CONTACT_CREATE_POPUP:
      return {
        ...state,
        showCreatePopup: action.show,
        editId: action.editId,
      }
    case Types.CREATE_CONTACT:
    case Types.UPDATE_CONTACT:
      return {
        ...state,
        isSaving: true,
      }
    case Types.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        showCreatePopup: false,
        results: [...state.results, action.payload],
      }
    case Types.UPDATE_CONTACT_SUCCESS: {
      const index = _.findIndex(state.results, {id: action.payload.id})
      if (index > -1) {
        state.results[index] = action.payload
      }
      return {
        ...state,
        isSaving: false,
        showCreatePopup: false,
        results: [...state.results],
      }
    }

    case Types.GET_CONTACTS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case Types.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: [...state.results.filter(address => address.id !== action.id)],
      }
    case Types.UPDATE_CONTACT_FAILURE:
    case Types.CREATE_CONTACT_FAILURE:
    case Types.GET_CONTACTS_FAILURE:
    case Types.GET_PURPOSE_OPTIONS_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors,
      }
    }
    case Types.RESET_CONTACT_ERRORS:
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors: {},
      }
    case Types.GET_PURPOSE_OPTIONS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_PURPOSE_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        purposeOptions: action.payload.results,
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    isSaving: false,
    showCreatePopup: false,
    editId: false,
    errors: {},
    results: [],
    purposeOptions: [],
  }
}
