import _ from 'lodash'
import * as Types from './actions.types'
import * as ErrorUtils from '../../../utils/ErrorUtils'

export default function address(state = getInitialState(), action) {
  switch (action.type) {
    case Types.SHOW_ADDRESS_CREATE_POPUP:
      return {
        ...state,
        showCreatePopup: action.show,
        editId: action.editId,
      }
    case Types.CREATE_ADDRESS:
    case Types.UPDATE_ADDRESS:
    case Types.CREATE_ADDRESS_PURPOSE:
      return {
        ...state,
        isSaving: true,
      }
    case Types.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        showCreatePopup: false,
        editId: false,
        errors: {},
        results: [...state.results, action.payload],
      }
    case Types.UPDATE_ADDRESS_SUCCESS: {
      const index = _.findIndex(state.results, {id: action.payload.id})
      if (index > -1) {
        state.results[index] = action.payload
      }
      return {
        ...state,
        isSaving: false,
        showCreatePopup: false,
        editId: false,
        errors: {},
        results: [...state.results],
      }
    }

    case Types.GET_ADDRESSES:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.payload.results,
      }
    case Types.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: [...state.results.filter(address => address.id !== action.id)],
      }
    case Types.UPDATE_ADDRESS_FAILURE:
    case Types.CREATE_ADDRESS_FAILURE:
    case Types.GET_ADDRESSES_FAILURE:
    case Types.GET_PURPOSE_OPTIONS_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors,
      }
    }
    case Types.RESET_ERRORS:
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors: {},
      }
    case Types.GET_PURPOSE_OPTIONS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_PURPOSE_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        purposeOptions: action.payload.results,
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    isSaving: false,
    showCreatePopup: false,
    editId: false,
    errors: {},
    results: [],
    purposeOptions: [],
  }
}
