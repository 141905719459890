import * as Types from './actions.types'

export function contact(payload) {
  return {type: Types.CONTACT, payload}
}

export function contactSuccess() {
  return {type: Types.CONTACT_SUCCESS}
}

export function contactFailure(error) {
  return {type: Types.CONTACT_FAILURE, error}
}

export function contactReset() {
  return {type: Types.CONTACT_RESET}
}
