import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded'
import Close from '@material-ui/icons/Close'
import photoGalleryHeader from '../../assets/photogalleryheader.png'
import downArrow from '../../assets/downArrow.png'
import * as Actions from './redux/actions'
import PageUtils from '../utils/PageUtils'
import Loading from '../common/Loading'
import Mansory from './Mansory'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {},
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      backgroundSize: 'contain',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 255,
    bottom: 0,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
      height: 290,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      margin: '-50 auto 0',
      padding: 30,
      top: 0,
      left: 0,
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '34px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
    },
    '& p': {
      fontSize: '25px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
    },
  },
  dialog: {
    paperFullScreen: {
      background: 'transparent',
    },
  },
})

const Tile = ({src, index, toggleDialog}) => {
  return (
    <div
      className="tile"
      onClick={() => {
        toggleDialog(index)
      }}
    >
      <img src={src} />
    </div>
  )
}

class Gallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
      currentIndex: 0,
    }
    this.toggleDialog = this.toggleDialog.bind(this)
    this.nextImage = this.nextImage.bind(this)
    this.prevImage = this.prevImage.bind(this)
  }

  toggleDialog(index) {
    this.setState(state => {
      return {openDialog: !state.openDialog, currentIndex: index}
    })
  }

  nextImage() {
    const {results} = this.props
    let currentIndex = this.state.currentIndex + 1
    if (currentIndex > results.length - 1) currentIndex = 0
    this.setState(() => {
      return {currentIndex}
    })
  }

  prevImage() {
    const {results} = this.props
    let currentIndex = this.state.currentIndex - 1
    if (currentIndex < 0) currentIndex = results.length - 1
    this.setState(() => {
      return {currentIndex}
    })
  }

  componentDidMount() {
    this.props.getGallery()
    PageUtils.scrollToTop()
  }

  getGalleryImageList(results) {
    return results.map(item => {
      return {width: item.width, height: item.height, src: item.image.fullSize}
    })
  }

  render() {
    const {classes, results, isFetching} = this.props
    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img
            src={photoGalleryHeader}
            style={{width: '100%', height: '500', objectFit: 'cover'}}
          />
          <div className={classes.headerFloatContainer}>
            <h2>Photo Gallery</h2>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} style={{margin: '120px auto 20px'}} />
            </div>
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            {isFetching ? (
              <Loading />
            ) : (
              <div className="container" style={{marginTop: 20}}>
                <div className="masonry-container">
                  <Mansory>
                    {results.map((item, i) => (
                      <Tile
                        key={i}
                        src={item.image.fullSize}
                        toggleDialog={this.toggleDialog}
                        index={i}
                      />
                    ))}
                  </Mansory>
                </div>
              </div>
            )}
          </Grid>
          <Dialog
            PaperProps={{
              style: {
                backgroundColor: 'rgba(0,0,0,0.7)',
                boxShadow: 'none',
              },
            }}
            open={this.state.openDialog}
            fullScreen={true}
          >
            <DialogContent>
              <div className="slider">
                <div className="slider-nav  slider-nav-left">
                  <ChevronLeftRounded className="slider-nav-icon" onClick={this.prevImage} />
                </div>
                <div className="slider-content">
                  <div className="slider-content-toolbar">
                    <Close
                      className="slider-close"
                      onClick={() => {
                        this.toggleDialog(this.state.currentIndex)
                      }}
                    />
                  </div>
                  <div className="slider-content-main">
                    {results.length > 0 && (
                      <img
                        src={results[this.state.currentIndex].image.fullSize}
                        style={{
                          height: '100%',
                          maxWidth: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="slider-nav slider-nav-right">
                  <ChevronRightRounded className="slider-nav-icon " onClick={this.nextImage} />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={8}>
          <div style={{marginBottom: 10}} />
        </Grid>
      </Grid>
    )
  }
}

Gallery.displayName = 'Gallery'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.gallery,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(Gallery)
