import {push} from 'react-router-redux'
import {getUsers} from '../../publicUser/redux/actions.userList'

export function getUserList(update) {
  return (dispatch, getState) => {
    const {settings} = getState().home
    let newSettings = {...settings}

    if ('page' in update) {
      newSettings = {...settings, ...update}
    } else {
      newSettings = {...settings, page: null, ...update}
    }

    dispatch(getUsers(newSettings))
  }
}

export function resetFilter() {
  return dispatch => {
    dispatch(push('/'))
  }
}
