import {call, put, takeEvery, all} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* applicationFlow() {
  yield all([
    takeEvery(Types.CREATE_APPLICATION, createApplication),
    takeEvery(Types.GET_APPLICATION, getApplication),
    takeEvery(Types.UPDATE_APPLICATION, updateApplication),
    takeEvery(Types.SUBMIT_APPLICATION, submitApplication),
  ])
}

function* createApplication(action) {
  const {payload, params} = action
  try {
    const response = yield call(Api.createApplication, payload)
    yield put(Actions.createApplicationSuccess(response))
    if (params.saveLater) {
      yield put(Actions.openModal())
    } else {
      yield put(push(`/contractor/register/${response.appId}/details`))
    }
  } catch (error) {
    yield put(Actions.createApplicationFailure(error))
  }
}

function* getApplication(action) {
  const {id, params} = action
  try {
    const response = yield call(Api.getApplication, id)

    if (response.isSubmitted) {
      yield put(
        Actions.getApplicationFailure({
          response: {data: {message: ['Application has been submitted']}},
        }),
      )
      yield put(push(`/contractor/continue`))
      return
    }
    yield put(Actions.getApplicationSuccess(response))
    const nextpath = params && params.next ? params.next : ''
    yield put(push(`/contractor/register/${response.appId}/${nextpath}`))
  } catch (error) {
    yield put(Actions.getApplicationFailure(error))
    yield put(push(`/contractor/continue`))
  }
}

function* updateApplication(action) {
  const {id, data, params} = action
  const nextpath = params && params.next ? params.next : 'details'
  try {
    const response = yield call(Api.updateApplication, id, data)
    yield put(Actions.updateApplicationSuccess(response))
    if (params.saveLater) {
      yield put(Actions.openModal())
    } else {
      yield put(push(`/contractor/register/${response.appId}/${nextpath}`))
    }
  } catch (error) {
    yield put(Actions.updateApplicationFailure(error))
  }
}

function* submitApplication(action) {
  const {id} = action

  try {
    const response = yield call(Api.submitApplication, id)
    yield put(Actions.submitApplicationSuccess(response))
    yield put(push(`/contractor/submitted`))
  } catch (error) {
    yield put(Actions.submitApplicationFailure(error))
  }
}
