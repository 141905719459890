import {call, put, takeEvery, all} from 'redux-saga/effects'
import CommonApi from '../api'
import * as Actions from './actions.news'
import * as Types from './actions.news.types'

export default function* careersFlow() {
  yield all([takeEvery(Types.GET_NEWS, getNews)])
}

function* getNews() {
  try {
    const response = yield call(CommonApi.news)
    yield put(Actions.getNewsSuccess(response.results))
  } catch (error) {
    yield put(Actions.getNewsFailure(error))
  }
}
