import * as Types from './actions.types'

export default function constructionTrenchless(state = getInitialState(), action) {
  switch (action.type) {
    case Types.GET_CT_PAGES:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_CT_PAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        construction: action.payload.construction,
        trenchless: action.payload.trenchless,
      }
    case Types.GET_CT_PAGES_FAILURE: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    construction: [],
    trenchless: [],
  }
}
