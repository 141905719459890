import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Hidden, AppBar, Toolbar, Button, Grid} from '@material-ui/core'
import {Link} from 'react-router'
import {compose} from 'redux'
import logo from '../../assets/logow_slogan.png'
import logoNoSlogan from '../../assets/lanzoLogoNoSlogan.png'
import homeIcon from '../../assets/homeIcon.svg'

const styles = theme => ({
  navbar: {
    padding: '10px 0 10px 0',
    backgroundColor: '#fff',
    boxShadow: 'none',
    height: 120,
  },
  navbarTitleLink: {
    color: 'white',
    fontSize: '32px',
  },
  brandImageContainer: {
    flex: 1,
  },
  brandImage: {
    height: 95,
    width: 'auto',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 'auto',
    },
  },
  homeIcon: {
    height: 40,
    width: 'auto',
    margin: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  lanzobutton: {
    width: 150,
    marginLeft: 10,
  },
})

export class ContractorHeader extends React.Component {
  render() {
    const {classes, saveLater, hideSaveLater} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <AppBar color="default" className={classes.navbar} position="fixed">
            <Grid container justify="center">
              <Grid item xs={12} md={10}>
                <Toolbar>
                  <div className={classes.brandImageContainer}>
                    <Link className={classes.navbarTitleLink} to="/">
                      <Hidden smDown={true}>
                        <img src={logo} className={classes.brandImage} />
                      </Hidden>
                      <Hidden mdUp={true}>
                        <img src={logoNoSlogan} className={classes.brandImage} />
                      </Hidden>
                    </Link>
                  </div>
                  <React.Fragment>
                    {!hideSaveLater && (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => {
                          saveLater(true)
                        }}
                      >
                        SAVE FOR LATER
                      </Button>
                    )}
                    <Link className={classes.navbarTitleLink} to="/">
                      <img src={homeIcon} className={classes.homeIcon} />
                    </Link>
                  </React.Fragment>
                </Toolbar>
              </Grid>
            </Grid>
          </AppBar>
        </Grid>
      </Grid>
    )
  }
}

export default compose(withStyles(styles))(ContractorHeader)
