import * as Types from './actions.types'

export function showContactCreatePopup(show, editId = false) {
  return {type: Types.SHOW_CONTACT_CREATE_POPUP, show, editId}
}

export function createContact(payload) {
  return {type: Types.CREATE_CONTACT, payload}
}

export function createContactSuccess(payload) {
  return {type: Types.CREATE_CONTACT_SUCCESS, payload}
}

export function createContactFailure(error) {
  return {type: Types.CREATE_CONTACT_FAILURE, error}
}

export function getContacts(params) {
  return {type: Types.GET_CONTACTS, params}
}

export function getContactsSuccess(payload) {
  return {type: Types.GET_CONTACTS_SUCCESS, payload}
}

export function getContactsFailure(error) {
  return {type: Types.GET_CONTACTS_FAILURE, error}
}

export function updateContact(id, data, previousePurposes) {
  return {type: Types.UPDATE_CONTACT, id, data, previousePurposes}
}

export function updateContactSuccess(payload) {
  return {type: Types.UPDATE_CONTACT_SUCCESS, payload}
}

export function updateContactFailure(error) {
  return {type: Types.UPDATE_CONTACT_FAILURE, error}
}

export function deleteContact(id, appId) {
  return {type: Types.DELETE_CONTACT, id, appId}
}

export function deleteContactSuccess(id) {
  return {type: Types.DELETE_CONTACT_SUCCESS, id}
}

export function deleteContactFailure(error) {
  return {type: Types.DELETE_CONTACT_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_CONTACT_ERRORS}
}

export function getPurposeOptions() {
  return {type: Types.GET_PURPOSE_OPTIONS}
}

export function getPurposeOptionsSuccess(payload) {
  return {type: Types.GET_PURPOSE_OPTIONS_SUCCESS, payload}
}

export function getPurposeOptionsFailure(error) {
  return {type: Types.GET_PURPOSE_OPTIONS_FAILURE, error}
}
