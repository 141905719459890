import * as Types from './actions.types'

export function showAddressCreatePopup(show, editId = false) {
  return {type: Types.SHOW_ADDRESS_CREATE_POPUP, show, editId}
}

export function createAddress(payload) {
  return {type: Types.CREATE_ADDRESS, payload}
}

export function createAddressSuccess(payload) {
  return {type: Types.CREATE_ADDRESS_SUCCESS, payload}
}

export function createAddressFailure(error) {
  return {type: Types.CREATE_ADDRESS_FAILURE, error}
}

export function getAddresses(params) {
  return {type: Types.GET_ADDRESSES, params}
}

export function getAddressesSuccess(payload) {
  return {type: Types.GET_ADDRESSES_SUCCESS, payload}
}

export function getAddressesFailure(error) {
  return {type: Types.GET_ADDRESSES_FAILURE, error}
}

export function updateAddress(id, data, previousePurposes) {
  return {type: Types.UPDATE_ADDRESS, id, data, previousePurposes}
}

export function updateAddressSuccess(payload) {
  return {type: Types.UPDATE_ADDRESS_SUCCESS, payload}
}

export function updateAddressFailure(error) {
  return {type: Types.UPDATE_ADDRESS_FAILURE, error}
}

export function deleteAddress(id, appId) {
  return {type: Types.DELETE_ADDRESS, id, appId}
}

export function deleteAddressSuccess(id) {
  return {type: Types.DELETE_ADDRESS_SUCCESS, id}
}

export function deleteAddressFailure(error) {
  return {type: Types.DELETE_ADDRESS_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_ERRORS}
}

export function getPurposeOptions() {
  return {type: Types.GET_PURPOSE_OPTIONS}
}

export function getPurposeOptionsSuccess(payload) {
  return {type: Types.GET_PURPOSE_OPTIONS_SUCCESS, payload}
}

export function getPurposeOptionsFailure(error) {
  return {type: Types.GET_PURPOSE_OPTIONS_FAILURE, error}
}
