import {call, put, takeEvery, all} from 'redux-saga/effects'
import CareersApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* careersFlow() {
  yield all([takeEvery(Types.JOBS, getJobs)])
  yield all([takeEvery(Types.SUBMIT_APPLICATION, submitApplication)])
}

function* getJobs() {
  try {
    const response = yield call(CareersApi.jobs)
    yield put(Actions.getJobsSuccess(response.results))
  } catch (error) {
    yield put(Actions.getJobsFailure(error))
  }
}

function* submitApplication(action) {
  try {
    yield call(CareersApi.submitApplication, action.payload)
    yield put(Actions.submitApplicationSuccess())
  } catch (error) {
    yield put(Actions.submitApplicationFailure(error))
  }
}
