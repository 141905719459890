import * as Types from './actions.types'

export function createService(payload) {
  return {type: Types.CREATE_SERVICE, payload}
}

export function createServiceSuccess(payload) {
  return {type: Types.CREATE_SERVICE_SUCCESS, payload}
}

export function createServiceFailure(error) {
  return {type: Types.CREATE_SERVICE_FAILURE, error}
}

export function getServices(params) {
  return {type: Types.GET_SERVICES, params}
}

export function getServicesSuccess(payload) {
  return {type: Types.GET_SERVICES_SUCCESS, payload}
}

export function getServicesFailure(error) {
  return {type: Types.GET_SERVICES_FAILURE, error}
}

export function getServiceOptions(params) {
  return {type: Types.GET_SERVICE_OPTIONS, params}
}

export function getServiceOptionsSuccess(payload) {
  return {type: Types.GET_SERVICE_OPTIONS_SUCCESS, payload}
}

export function getServiceOptionsFailure(error) {
  return {type: Types.GET_SERVICE_OPTIONS_FAILURE, error}
}

export function updateService(id, data) {
  return {type: Types.UPDATE_SERVICE, id, data}
}

export function updateServiceSuccess(payload) {
  return {type: Types.UPDATE_SERVICE_SUCCESS, payload}
}

export function updateServiceFailure(error) {
  return {type: Types.UPDATE_SERVICE_FAILURE, error}
}

export function deleteService(id, appId) {
  return {type: Types.DELETE_SERVICE, id, appId}
}

export function deleteServiceSuccess(id) {
  return {type: Types.DELETE_SERVICE_SUCCESS, id}
}

export function deleteServiceFailure(error) {
  return {type: Types.DELETE_SERVICE_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_SERVICE_ERRORS}
}

export function addProductService(payload) {
  return {type: Types.ADD_PRODUCT_SERVICE, payload}
}
