export const CREATE_REGION_INTEREST = 'CREATE_REGION_INTEREST'
export const CREATE_REGION_INTEREST_SUCCESS = 'CREATE_REGION_INTEREST_SUCCESS'
export const CREATE_REGION_INTEREST_FAILURE = 'CREATE_REGION_INTEREST_FAILURE'

export const GET_REGION_INTERESTS = 'GET_REGION_INTERESTS'
export const GET_REGION_INTERESTS_SUCCESS = 'GET_REGION_INTERESTS_SUCCESS'
export const GET_REGION_INTERESTS_FAILURE = 'GET_REGION_INTERESTS_FAILURE'

export const UPDATE_REGION_INTEREST = 'UPDATE_REGION_INTEREST'
export const UPDATE_REGION_INTEREST_SUCCESS = 'UPDATE_REGION_INTEREST_SUCCESS'
export const UPDATE_REGION_INTEREST_FAILURE = 'UPDATE_REGION_INTEREST_FAILURE'

export const DELETE_REGION_INTEREST = 'DELETE_REGION_INTEREST'
export const DELETE_REGION_INTEREST_SUCCESS = 'DELETE_REGION_INTEREST_SUCCESS'
export const DELETE_REGION_INTEREST_FAILURE = 'DELETE_REGION_INTEREST_FAILURE'

export const RESET_REGION_INTEREST_ERRORS = 'RESET_REGION_INTEREST_ERRORS'

export const GET_REGION_INTERESTS_DATA = 'GET_REGION_INTERESTS_DATA'
export const GET_REGION_INTERESTS_DATA_SUCCESS = 'GET_REGION_INTERESTS_DATA_SUCCESS'
export const GET_REGION_INTERESTS_DATA_FAILURE = 'GET_REGION_INTERESTS_DATA_FAILURE'
