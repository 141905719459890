export const CREATE_APPLICATION = 'CREATE_APPLICATION'
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS'
export const CREATE_APPLICATION_FAILURE = 'CREATE_APPLICATION_FAILURE'

export const GET_APPLICATION = 'GET_APPLICATION'
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS'
export const GET_APPLICATION_FAILURE = 'GET_APPLICATION_FAILURE'

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION'
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS'
export const UPDATE_APPLICATION_FAILURE = 'UPDATE_APPLICATION_FAILURE'

export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION'
export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS'
export const SUBMIT_APPLICATION_FAILURE = 'SUBMIT_APPLICATION_FAILURE'

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'
