import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../../common/redux/actions.notifications'

export default function* contactFlow() {
  yield all([
    takeEvery(Types.CREATE_CONTACT, createContact),
    takeEvery(Types.GET_CONTACTS, getContacts),
    takeEvery(Types.UPDATE_CONTACT, updateContact),
    takeEvery(Types.DELETE_CONTACT, deleteContact),
    takeEvery(Types.GET_PURPOSE_OPTIONS, getPurposeOptions),
  ])
}

function* createContact(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createContact, payload)
    yield put(Actions.createContactSuccess(response))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.createContactFailure(error))
  }
}

function* getContacts(action) {
  const {params} = action
  try {
    const response = yield call(Api.getContacts, params)
    yield put(Actions.getContactsSuccess(response))
  } catch (error) {
    yield put(Actions.getContactsFailure(error))
  }
}

function* updateContact(action) {
  const {id, data, previousePurposes} = action
  const newContactPurposes = data.purpose.filter(
    p => !previousePurposes.map(pp => pp.purpose.id).includes(p),
  )
  const removeContactPurpose = previousePurposes
    .filter(p => !data.purpose.includes(p.purpose.id))
    .map(p => p.id)
  try {
    for (const p of newContactPurposes) {
      yield call(Api.createContactPurpose, id, p)
    }
    for (const p of removeContactPurpose) {
      yield call(Api.deleteContactPurpose, p, data.application)
    }
    const response = yield call(Api.updateContact, id, data)
    yield put(Actions.updateContactSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.updateContactFailure(error))
  }
}

function* deleteContact(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteContact, id, appId)
    yield put(Actions.deleteContactSuccess(id))
    yield put(NotificationActions.createSuccess('Deleted successfully'))
  } catch (error) {
    yield put(Actions.deleteContactFailure(error))
  }
}

function* getPurposeOptions() {
  try {
    const response = yield call(Api.getPurposeOptions)
    yield put(Actions.getPurposeOptionsSuccess(response))
  } catch (error) {
    yield put(Actions.getPurposeOptionsFailure(error))
  }
}
