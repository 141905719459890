import * as Types from './actions.types'

export default function videos(state = getInitialState(), action) {
  switch (action.type) {
    case Types.GET_VIDEOS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case Types.GET_VIDEOS_FAILURE: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    results: [],
  }
}
