const styles = theme => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 45,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  redCapTitle: {
    color: '#E63B43',
    fontSize: 34,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  titleLine: {
    width: 247,
    float: 'left',
    border: '3px solid #E63B43',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
      maxWidth: 267,
    },
  },
  sectionTitle: {
    fontWeight: 'bold',
  },
  sectionTitleCenterMobile: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  centerMobile: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  imageContainer: {
    height: 360,
    width: 380,
  },
})

export default styles
