import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import {Container} from './index'

export const Label = styled.label`
  color: #000000;
  font-family: 'century-gothic, sans-serif';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.34px;
  line-height: 20px;
  margin-bottom: 5px;
`

const RequiredLabel = styled.span`
  color: red;
`

export const Input = styled.input`
  height: 36px;
  padding-left: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: #fff;
`

export const SelectInput = styled.select`
  height: 36px;
  padding-left: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: #fff;
`

export const InputField = props => (
  <Container>
    {props.label && (
      <Label>
        {props.required && <RequiredLabel>* </RequiredLabel>}
        {props.label} {props.secondaryLabel}
      </Label>
    )}
    <NumberFormat
      thousandSeparator={true}
      prefix=""
      decimalScale={3}
      disabled={props.disabled}
      style={{
        height: '36px',
        paddingLeft: '20px',
        border: '1px solid #dfdfdf',
        borderRadius: '5px',
        background: props.disabled ? '#d3d3d3' : '#fff',
      }}
      placeholder={props.placeholder}
      onValueChange={e => {
        props.onChange(e.floatValue)
      }}
    />
  </Container>
)

export const SelectField = props => (
  <Container>
    {props.label && (
      <Label>
        {props.required && <RequiredLabel>* </RequiredLabel>}
        {props.label}
      </Label>
    )}
    <SelectInput style={props.style} onChange={props.onChange}>
      {props.children}
      {props.options &&
        props.options.map(option => <option value={option.key}>{option.label}</option>)}
    </SelectInput>
  </Container>
)

export default InputField
