export const SHOW_CONTACT_CREATE_POPUP = 'SHOW_CONTACT_CREATE_POPUP'

export const CREATE_CONTACT = 'CREATE_CONTACT'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE'

export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE'

export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE'

export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE'

export const RESET_CONTACT_ERRORS = 'RESET_CONTACT_ERRORS'

export const GET_PURPOSE_OPTIONS = 'GET_PURPOSE_CONTACT_OPTIONS'
export const GET_PURPOSE_OPTIONS_SUCCESS = 'GET_PURPOSE_CONTACT_OPTIONS_SUCCESS'
export const GET_PURPOSE_OPTIONS_FAILURE = 'GET_PURPOSE_CONTACT_OPTIONS_FAILURE'
