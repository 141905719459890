import React from 'react'
import {ProgressBar, Step} from 'react-step-progress-bar'
import {Grid} from '@material-ui/core'

const Circle = ({accomplished, title, active, extraClass}) => (
  <div
    className="step-circle"
    style={{
      backgroundColor: accomplished ? '#FA1D3A' : '#B5B5B5',
    }}
  >
    {active && <div className={`speech-bubble ${extraClass}`}>{title}</div>}
  </div>
)

export class StepProgressBar extends React.Component {
  render() {
    const {percent} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={10} style={{marginTop: 20, marginBottom: 90}}>
          <ProgressBar percent={percent} unfilledBackground="#B5B5B5" filledBackground="#FF002D">
            <Step transition="scale">
              {({accomplished, position}) => (
                <Circle
                  position={position}
                  accomplished={accomplished}
                  title="Basic Information"
                  active={percent === 0}
                  extraClass="speech-bubble-mobile-left"
                />
              )}
            </Step>
            <Step transition="scale">
              {({accomplished, position}) => (
                <Circle
                  position={position}
                  accomplished={accomplished}
                  active={percent === 34}
                  title="Company Details"
                />
              )}
            </Step>
            <Step transition="scale">
              {({accomplished, position}) => (
                <Circle
                  position={position}
                  accomplished={accomplished}
                  title="Additional Information"
                  active={percent === 67}
                />
              )}
            </Step>
            <Step transition="scale">
              {({accomplished, position}) => (
                <Circle
                  position={position}
                  accomplished={accomplished}
                  title="Attachments"
                  extraClass="speech-bubble-mobile-right"
                  active={percent === 100}
                />
              )}
            </Step>
          </ProgressBar>
        </Grid>
      </Grid>
    )
  }
}

export default StepProgressBar
