import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class SupplierClassificationApi {
  static createSupplierClassification(payload) {
    return axios.post(`${settings.apiBaseURL}/supplier-business-classifications`, payload)
  }

  static getSupplierClassifications(params) {
    return axios.get(`${settings.apiBaseURL}/supplier-business-classifications`, {params})
  }

  static updateSupplierClassification(id, data) {
    return axios.patch(
      `${settings.apiBaseURL}/supplier-business-classifications/${id}?app_id=${data.application}`,
      data,
    )
  }

  static deleteSupplierClassification(id, appId) {
    return axios.delete(
      `${settings.apiBaseURL}/supplier-business-classifications/${id}?app_id=${appId}`,
    )
  }

  static getSupplierClassificationData() {
    return axios.get(`${settings.apiBaseURL}/supplier-business-classifications/all-listed`)
  }
}
