export const CREATE_SERVICE = 'CREATE_SERVICE'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAILURE = 'CREATE_SERVICE_FAILURE'

export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE'

export const GET_SERVICE_OPTIONS = 'GET_SERVICE_OPTIONS'
export const GET_SERVICE_OPTIONS_SUCCESS = 'GET_SERVICE_OPTIONS_SUCCESS'
export const GET_SERVICE_OPTIONS_FAILURE = 'GET_SERVICE_OPTIONS_FAILURE'

export const UPDATE_SERVICE = 'UPDATE_SERVICE'
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS'
export const UPDATE_SERVICE_FAILURE = 'UPDATE_SERVICE_FAILURE'

export const DELETE_SERVICE = 'DELETE_SERVICE'
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS'
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE'

export const RESET_SERVICE_ERRORS = 'RESET_SERVICE_ERRORS'

export const ADD_PRODUCT_SERVICE = 'ADD_PRODUCT_SERVICE'
