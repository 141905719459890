import {fork, all} from 'redux-saga/effects'
import errorFlow from './common/redux/sagas.errors'
import authFlow from './auth/redux/sagas'
import forgotPasswordFlow from './auth/redux/sagas.forgotPw'
import homeFlow from './home/redux/sagas'
import profileFlow from './profile/redux/sagas'
import signupFlow from './auth/redux/sagas.signup'
import publicProfileFlow from './publicUser/redux/sagas'
import contactFlow from './contactus/redux/sagas'
import careersFlow from './careers/redux/sagas'
import ctFlow from './construction_trenchless/redux/sagas'
import cippCalculatorFlow from './cipp_calculator_request/redux/sagas'
import videoFlow from './videos/redux/sagas'
import newsFlow from './common/redux/sagas.news'
import galleryFlow from './gallery/redux/sagas'
import contractorFlow from './contractor/redux/sagas'
import addressFlow from './contractor/address/redux/sagas'
import contractorContactFlow from './contractor/contact/redux/sagas'
import serviceFlow from './contractor/service/redux/sagas'
import businessClassificationFlow from './contractor/business_classification/redux/sagas'
import businessLineFlow from './contractor/business_line/redux/sagas'
import regionInterestFlow from './contractor/region_interest/redux/sagas'
import projectInterestFlow from './contractor/project_interest/redux/sagas'
import attachmentFlow from './contractor/attachment/redux/sagas'
import supplierClassificationFlow from './contractor/supplier_classification/redux/sagas'

export default function* root() {
  yield all([
    fork(errorFlow),
    fork(authFlow),
    fork(forgotPasswordFlow),
    fork(homeFlow),
    fork(profileFlow),
    fork(signupFlow),
    fork(publicProfileFlow),
    fork(contactFlow),
    fork(careersFlow),
    fork(ctFlow),
    fork(cippCalculatorFlow),
    fork(videoFlow),
    fork(newsFlow),
    fork(galleryFlow),
    fork(contractorFlow),
    fork(addressFlow),
    fork(contractorContactFlow),
    fork(serviceFlow),
    fork(businessClassificationFlow),
    fork(businessLineFlow),
    fork(regionInterestFlow),
    fork(projectInterestFlow),
    fork(attachmentFlow),
    fork(supplierClassificationFlow),
  ])
}
