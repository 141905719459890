import React from 'react'
import {Grid} from '@material-ui/core'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PageUtils from '../utils/PageUtils'
import StepProgressBar from './StepProgressBar'
import ContractorHeader from './ContractorHeader'
import BusinessLine from './business_line/BusinessLine'
import * as Actions from './redux/actions'
import SaveForLaterModal from './SaveForLaterModal'
import {SectionHeader} from './BasicInfo'
import RegionInterest from './region_interest/RegionInterest'
import ProjectInterest from './project_interest/ProjectInterest'
import ContractorFooter from './ContractorFooter'
import {createError} from '../common/redux/actions.notifications'
import SupplierClassification from './supplier_classification/SupplierClassification'

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.saveLaterSubmit = false
    this.saveLater = this.saveLater.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.params && this.props.params.id && !this.props.application.id) {
      this.props.getApplication(this.props.params.id, {next: 'additional'})
    }
    PageUtils.scrollToTop()
  }

  saveLater(state) {
    this.saveLaterSubmit = state
  }

  onChange(e, field) {
    this.setState({[field]: e.target.value})
  }

  onSubmit(e) {
    const {projectInterest, regionInterest, businessLine, supplierClassification} = this.props
    e.preventDefault()

    if (
      projectInterest.results.length === 0 ||
      regionInterest.results.length === 0 ||
      supplierClassification.results.length === 0 ||
      businessLine.results.length === 0
    ) {
      this.props.createError('One or more required data are missing')
      return
    }

    this.props.updateApplication(this.props.application.appId, this.state, {
      saveLater: this.saveLaterSubmit,
      next: 'attachments',
    })
  }

  render() {
    const {modalOpen, application} = this.props

    return (
      <Grid container>
        <StepProgressBar percent={67} />
        <SaveForLaterModal
          closeModal={this.props.closeModal}
          application={application}
          modalOpen={modalOpen}
          next="attachments"
        />
        <form onSubmit={this.onSubmit}>
          <ContractorHeader />
          <Grid container justify="center">
            <Grid item xs={12}>
              <SectionHeader>Supplier Profile Attributes</SectionHeader>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader>Type of Business</SectionHeader>
              <h3>Supplier Business Classification</h3>
              <hr />
              <p>At least one entry is required.</p>
            </Grid>

            <SupplierClassification appId={this.props.params && this.props.params.id} />

            <Grid item xs={12}>
              <SectionHeader>Interests</SectionHeader>
              <h3>Industry Lines of Business</h3>
              <hr />
              <p>At least one entry is required.</p>
            </Grid>

            <BusinessLine appId={this.props.params && this.props.params.id} />

            <Grid item xs={12}>
              <SectionHeader>Regions of Interest</SectionHeader>
              <p>At least one entry is required.</p>
            </Grid>

            <RegionInterest appId={this.props.params && this.props.params.id} />

            <Grid item xs={12}>
              <SectionHeader>Projects of Interest</SectionHeader>
              <p>At least one entry is required.</p>
            </Grid>

            <ProjectInterest appId={this.props.params && this.props.params.id} />

            <Grid item xs={12} style={{marginTop: 50}}>
              <Grid container justify="flex-end">
                <Grid item xs={12} lg={6} md={12}>
                  <ContractorFooter
                    saveLater={this.saveLater}
                    previousLink={`/contractor/register/${application.appId}/details`}
                    currentPage={3}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    )
  }
}

AdditionalInfo.displayName = 'Supplier-Attributes'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, createError}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractor,
    regionInterest: state.regionInterest,
    businessLine: state.businessLine,
    projectInterest: state.projectInterest,
    supplierClassification: state.supplierClassification,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AdditionalInfo)
