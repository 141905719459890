import {call, put, takeEvery, all} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import CippCalculatorApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import UserSession from '../../auth/UserSession'

export default function* cippCalculatorFlow() {
  yield all([takeEvery(Types.CIPP_CALCULATOR, cippCalculator)])
}

function* cippCalculator(action) {
  const {payload} = action
  try {
    yield call(CippCalculatorApi.submitCippCalculator, payload)
    yield put(Actions.cippCalculatorSuccess())
    UserSession.setRequestSubmitted(true)
    yield put(push('/cipp-calculator'))
  } catch (error) {
    yield put(Actions.cippCalculatorFailure(error))
  }
}
