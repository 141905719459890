import {call, put, takeEvery, all} from 'redux-saga/effects'
import ContactUsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* contactFlow() {
  yield all([takeEvery(Types.CONTACT, contact)])
}

function* contact(action) {
  const {payload} = action
  try {
    yield call(ContactUsApi.submitContact, payload)
    yield put(Actions.contactSuccess())
  } catch (error) {
    yield put(Actions.contactFailure(error))
  }
}
