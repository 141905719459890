import React from 'react'
import {compose} from 'redux'
import {Grid} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'
import Link from 'react-router/es/Link'
import imgBlog1 from '../../assets/blog-dummy-1.png'
import imgBlog2 from '../../assets/blog-dummy-2.png'
import imgBlog3 from '../../assets/blog-dummy-3.png'
import imgBlog4 from '../../assets/blog-dummy-4.png'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    maxWidth: 1400,
    '& h2': {
      fontSize: '42px',
      fontWeight: 'bold',
      color: '#000',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        color: 'white',
      },
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'black',
      padding: '0 20px',
    },
  },
  blogItem: {
    backgroundSize: 'cover',
    height: '100%',
    position: 'relative',
  },
  blogItemGrid: {
    [theme.breakpoints.down('sm')]: {
      height: '230px !important',
      marginBottom: 20,
    },
  },
  blogItemTextContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 15,
    minHeight: 115,
    '& h3': {
      fontWeight: 'bold',
      fontSize: '17px',
      margin: '0 0 10px 0',
      padding: 0,
    },
    '& p': {
      fontSize: '13px',
      margin: 0,
      padding: 0,
    },
  },
})

// eslint-disable-next-line react/prefer-stateless-function
class Blog extends React.Component {
  render() {
    const {classes, width} = this.props
    const spacing = width !== 'xs' && width !== 'sm' ? 16 : 0
    const blogItems = [
      {
        title: 'Total Package Utility Contractor',
        description:
          'Lanzo has produced over $1.0 B in Heavy Civil, Water, Sewer, Drainage, Ocean Outfall, Trenchless Rehabilitation, HDD Directional Drill, and Treatment Plant Construction.',
        image: imgBlog1,
      },
      {
        title: 'Design Build',
        description:
          'We offer a multi talented staff of trained professionals with ties to major consultant and environmental service companies throughout the United States and Canada.',
        image: imgBlog2,
      },
      {
        title: 'Neighborhood Development',
        description:
          'Build outs with Water, Sewer, Drainage, Roadway and complete Harmonization of residential, commercial and adjacent properties with roadway, canal, and waterfront frontage.',
        image: imgBlog3,
      },
      {
        title: 'Trucking & Hauling',
        description:
          'We provide complete transfer services of all materials both hazardous and non hazardous.',
        image: imgBlog4,
      },
    ]

    if (!spacing) {
      let i = 0
      const limit = 150
      for (i = 0; i < blogItems.length; i++) {
        if (blogItems[i].description.length > limit) {
          blogItems[i].description = blogItems[i].description.substring(0, limit) + '...'
        }
      }
    }

    const blogElements = blogItems.map(item => (
      <Link to="/soon">
        <div className={classes.blogItem} style={{backgroundImage: 'url(' + item.image + ')'}}>
          <div className={classes.blogItemTextContainer}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </div>
      </Link>
    ))

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <h2>Blog</h2>
        </Grid>
        <Grid item container xs={12} spacing={spacing} style={{marginLeft: spacing}}>
          <Grid item xs={12} md={4} style={{height: 600}} className={classes.blogItemGrid}>
            {blogElements[0]}
          </Grid>
          <Grid item container xs={12} md={8} spacing={spacing}>
            <Grid item xs={12} style={{height: 280}} className={classes.blogItemGrid}>
              {blogElements[1]}
            </Grid>
            <Grid item xs={12} md={6} style={{height: 320}} className={classes.blogItemGrid}>
              {blogElements[2]}
            </Grid>
            <Grid item xs={12} md={6} style={{height: 320}} className={classes.blogItemGrid}>
              {blogElements[3]}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Blog.displayName = 'Blog'

export default compose(
  withStyles(styles),
  withWidth(),
)(Blog)
