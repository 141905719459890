import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import PageUtils from '../utils/PageUtils'
import styles from './styles/Page.styles'

class Privacy extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.title}>Privacy Policy</h2>
              <hr className={classes.titleLine} />
            </Grid>
            <Grid item xs={12} style={{marginTop: 15, marginBottom: 50}}>
              <p>
                This website is operated by and on behalf of Lanzo Construction Company - including
                its affiliates, divisions, business units and subsidiaries - ("Lanzo").
              </p>
              <p>
                This privacy policy applies to personal information Lanzo may collect through this
                website, and to other information we may collect in the course of our business. It
                does not apply to personal information relating to Lanzo's own employees. Lanzo
                recognizes and respects the privacy of the individuals whose personal information it
                collects, uses and otherwise processes in the course of its business.
              </p>
              <p className={classes.sectionTitle}>Collection of Your Personal Information</p>
              <p className={classes.sectionTitle}>Personal information you provide</p>
              <p>
                Lanzo collects personal information about individuals when specifically, and
                knowingly provided by such individuals for purposes disclosed or otherwise known to
                them when they provide their information or for the wider purposes set out below.
                This includes, for example, voluntary submission of an email address for our news
                email update list, providing a business card in a meeting, sending us an email which
                includes personal information about you, providing basic details for building
                security purposes when you enter Lanzo premises, or allowing us to photograph, film
                or otherwise record you when you attend an event that we host or sponsor.
              </p>
              <p>
                Unless we explain otherwise at the time, providing personal information that we
                request is optional and disclosures are made voluntarily.
              </p>
              <p className={classes.sectionTitle}>
                Automatic collection of your personal information
              </p>
              <p>
                We collect certain information by automated means when you visit our premises or
                websites. In particular:
              </p>
              <ul className="custom-list">
                <li>
                  Our websites log IP addresses, the type of operating system that the visitor’s
                  computer uses, the type of browser software used by the visitor, and the pages
                  accessed by the visitor.
                </li>
                <li>
                  Lanzo uses this data, in aggregated form, to perform statistical analyses of the
                  collective characteristics and behavior of our visitors and to measure overall
                  user demographics and interests regarding specific areas of the site.
                </li>
                <li>
                  Our websites also use Google Analytics, a web analytics service provided by
                  Google, Inc. (“Google"). Google Analytics uses cookies to help the website analyze
                  how users use the site. The information generated by the cookie about your use of
                  the website (including your IP address) will be transmitted to and stored by
                  Google on servers in the United States. Google will use this information for
                  evaluating your use of the website, compiling reports on website activity, and
                  providing other services relating to website activity and internet usage for us
                  and our affiliates. Google may also transfer this information to third parties
                  where required to do so by law, or where such third parties process the
                  information on Google's behalf. Google will not associate your IP address with any
                  other data held by Google.
                </li>
                <li>
                  You may refuse the use of cookies by selecting the appropriate settings on your
                  browser. However, please note that if you do this you may not be able to use the
                  full functionality of this website. By using this website, you consent to the
                  processing of data about you by Google in the manner and for the purposes set out
                  herein.
                </li>
              </ul>
              <p className={classes.sectionTitle}>
                Collection of your personal information from third party sources
              </p>
              <p>
                If you are involved in a business relationship with us, we may also obtain some
                limited information about you indirectly, for example when your colleagues give us
                your contact details and information about your role, or from publicly available
                sources such as the Internet and/or subscription-based services.
              </p>
              <p className={classes.sectionTitle}>Use of Your Personal Information</p>
              <p>
                We may use the information we collect about you for purposes which are made clear to
                you (or which you already know) when you provide your information or for the
                following purposes:
              </p>
              <p className={classes.sectionTitle}>Business-related purposes</p>
              <ul className="custom-list">
                <li>
                  protecting the security and integrity of our premises, websites and other
                  information technology systems;
                </li>
                <li>
                  responding to and communicating with you about your requests, questions and
                  comments;
                </li>
                <li>
                  managing and developing our relationship with you and the organization that you
                  represent; and
                </li>
                <li>operating, evaluating and improving our business.</li>
              </ul>
              <p className={classes.sectionTitle}>Legal and compliance purposes</p>
              <ul className="custom-list">
                <li>
                  protecting ourselves and our employees and business counterparties against fraud
                  and other criminal activity, and co-operating with law enforcement and other
                  regulatory agencies;
                </li>
                <li>
                  exercising and defending our legal rights and legal claims made against us; and
                </li>
                <li>complying with our legal and regulatory obligations.</li>
              </ul>
              <p>
                Except as otherwise allowed by this policy, Lanzo will not trade, sell, or rent
                personal information but may collect, or provide aggregate statistics about its
                websites and their users to other parties who do not provide services directly to
                Lanzo.
              </p>
              <p>
                Except in rare circumstances where Lanzo is required by law to disclose or otherwise
                process your personal information, Lanzo will only process your personal information
                as necessary for the purposes explained to you when the information is collected or
                otherwise set out in this policy. Lanzo will not collect your personal information
                unless it has concluded that it has a legitimate interest in pursuing the relevant
                purpose. We may occasionally ask for your consent to allow us to process your
                personal information – for example, if we wish to use images of you in published
                materials relating to an event that you attended.
              </p>
              <p className={classes.sectionTitle}>Disclosures</p>
              <p>
                Lanzo entities may share personal information about you between themselves, and they
                may disclose your personal information to technology and other service providers,
                including professional advisors, who hold and process the information on their
                behalf.
              </p>
              <p>
                Lanzo may also disclose your personal information if it has a good faith belief that
                this is necessary for the legal and compliance purposes set out above. For these
                purposes, we may, for example, disclose information about you to courts and
                litigation counterparties, insurers, the police and other competent governmental
                authorities.
              </p>
              <p>
                As we continue to develop our business, we might sell or buy assets. In such
                transactions, personal information is generally one of the transferred business
                assets. Also, if either Lanzo itself or substantially all of Lanzo’s assets were
                acquired, personal information may be one of the transferred assets. Therefore, we
                may disclose and/or transfer personal information to a third-party purchaser in
                these circumstances.
              </p>
              <p className={classes.sectionTitle}>Your data protection rights</p>
              <p>
                You have a right to access the information that Lanzo collects about you, and
                related rights to require your information to be corrected if it is inaccurate, to
                object to our processing of your information and, in some circumstances, to require
                us to delete your information or restrict its processing. If you want to exercise
                any of these rights, or withdraw a consent that you have given to our processing of
                your personal information, please contact us at{' '}
                <a href="mailto:privacy@Lanzo.com">Privacy@Lanzo.com</a>.
              </p>
              <p className={classes.sectionTitle}>Opt Out</p>
              <p>
                If you no longer wish to receive our marketing communications, you may opt out of
                receiving them by following the instructions included in each newsletter or other
                correspondences.
              </p>
              <p className={classes.sectionTitle}>Retention of Your Personal Information</p>
              <p>
                Lanzo will retain your personal information as follows (or longer if required by
                law), and will then promptly delete the information:
              </p>
              <ul className="custom-list">
                <li>
                  We retain personal information in accordance with Lanzo's records management
                  policies (unless we delete the information earlier at your request). You may
                  contact Lanzo at <a href="mailto:privacy@Lanzo.com"> Privacy@Lanzo.com</a> for
                  more information on record retention periods.
                </li>
              </ul>
              <p className={classes.sectionTitle}>Contact Us</p>
              <p>
                If you have any questions or comments regarding this Privacy Policy or our
                information collection practices, please contact us by sending an email to Lanzo’s
                Chief Privacy Officer at: <a href="mailto:privacy@Lanzo.com">Privacy@Lanzo.com</a>,
                or by writing to:
              </p>
              <p>
                Lanzo Construction Company
                <br />
                125 SE 5th Court
                <br />
                Deerfield Beach, FL 33441
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Privacy.displayName = 'Privacy'

export default withStyles(styles)(Privacy)
