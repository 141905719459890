import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import {connect} from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import RoundedInput from '../common/RoundedInput'
import InputValidator from '../common/InputValidator'
import Countries from '../utils/Countries'
import RoundedSelect from '../common/RoundedSelect'
import * as Actions from './redux/actions'
import ContractorHeader from './ContractorHeader'
import StepProgressBar from './StepProgressBar'
import ContractorFooter from './ContractorFooter'
import PageUtils from '../utils/PageUtils'
import SaveForLaterModal from './SaveForLaterModal'

const styles = () => ({
  fieldsContainer: {
    padding: 10,
    '& label': {
      color: 'black',
      fontSize: '16px',
      fontWeight: 600,
      position: 'relative',
      transform: 'inherit',
    },
  },
  formRequired: {
    color: '#FF0000',
  },

  phoneInputContainer: {
    marginTop: 20,
  },
  phoneInput: {
    height: 37,
    width: '100%',
    border: '1px solid #DFDFDF',
    padding: 8,
    borderRadius: 5,
  },
})

export const SectionHeader = ({children}) => (
  <React.Fragment>
    <h2>{children}</h2>
    <hr />
  </React.Fragment>
)

class BasicInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      application: {},
      contact: {},
    }
    this.saveLaterSubmit = false
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.saveLater = this.saveLater.bind(this)
  }

  componentDidMount() {
    if (this.props.params && this.props.params.id && !this.props.application.id) {
      this.props.getApplication(this.props.params.id)
    }
    PageUtils.scrollToTop()
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.props.application.appId) {
      const application = {
        ...this.state.application,
        saveLater: this.saveLaterSubmit,
        contacts: [{...this.state.contact, application: this.props.application.appId}],
      }
      this.props.updateApplication(this.props.application.appId, application, {
        next: 'details',
        saveLater: this.saveLaterSubmit,
      })
    } else {
      const application = {...this.state, saveLater: this.saveLaterSubmit}
      this.props.createApplication(application, {next: 'details', saveLater: this.saveLaterSubmit})
    }
  }

  onChange(e, field, fieldType) {
    e.persist()
    this.setState(state => {
      return {[fieldType]: {...state[fieldType], [field]: e.target.value}}
    })
  }

  saveLater(state) {
    this.saveLaterSubmit = state
  }

  render() {
    const {classes, errors, application, modalOpen} = this.props
    const contact = application.contacts[0] || {}
    this.saveLaterSubmit = false

    return (
      <Grid container>
        <StepProgressBar percent={0} />
        <SaveForLaterModal
          closeModal={this.props.closeModal}
          application={application}
          modalOpen={modalOpen}
          next="details"
        />
        <form onSubmit={this.onSubmit}>
          <ContractorHeader saveLater={this.saveLater} />
          <Grid container>
            <Grid item xs={12}>
              <SectionHeader>Prospective Supplier Registration</SectionHeader>
              <p>* Required Fields </p>
              <p>
                Thank you for expressing interest in registering your company with Lanzo
                Construction Company. Please complete the following form.
              </p>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader>Company Details</SectionHeader>
              <p>
                A valid Dun & Bradstreet DUNS number is required. Failure to provide one, or an
                incorrect number will result in your registration failing.
              </p>
              <Grid container justify="center">
                <Grid item xs={12} md={10}>
                  <Grid container justify="center">
                    <Grid item md={4} xs={12}>
                      <InputValidator
                        errors={errors.companyName}
                        className={classes.fieldsContainer}
                      >
                        <InputLabel shrink htmlFor="company_name">
                          <span className={classes.formRequired}>* </span>
                          Company Name
                        </InputLabel>
                        <RoundedInput
                          id="company_name"
                          required
                          value={this.state.application.companyName || application.companyName}
                          onChange={e => {
                            this.onChange(e, 'companyName', 'application')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputValidator
                        errors={errors.taxCountry}
                        className={classes.fieldsContainer}
                      >
                        <InputLabel shrink htmlFor="tax_country">
                          Tax Country
                        </InputLabel>
                        <RoundedSelect
                          native
                          value={this.state.application.taxCountry || application.taxCountry}
                          onChange={e => {
                            this.onChange(e, 'taxCountry', 'application')
                          }}
                          input={<RoundedInput name="Tax Country" id="tax_country" />}
                        >
                          <option value="" />
                          {Countries.map(country => (
                            <option value={country.name}>{country.name}</option>
                          ))}
                        </RoundedSelect>
                      </InputValidator>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputValidator errors={errors.taxNumber} className={classes.fieldsContainer}>
                        <InputLabel shrink htmlFor="tax_reg_num">
                          Tax Registration Number
                        </InputLabel>
                        <RoundedInput
                          id="tax_reg_num"
                          value={this.state.application.taxNumber || application.taxNumber}
                          onChange={e => {
                            this.onChange(e, 'taxNumber', 'application')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Grid container>
                    <Grid item md={4} xs={12}>
                      <InputValidator
                        errors={errors.taxpayerId}
                        className={classes.fieldsContainer}
                      >
                        <InputLabel shrink htmlFor="tax_id">
                          Taxpayer ID
                        </InputLabel>
                        <RoundedInput
                          id="tax_id"
                          value={this.state.application.taxpayerId || application.taxpayerId}
                          onChange={e => {
                            this.onChange(e, 'taxpayerId', 'application')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputValidator errors={errors.name} className={classes.fieldsContainer}>
                        <InputLabel shrink htmlFor="duns_number">
                          <span className={classes.formRequired}>* </span>
                          DUNS Number
                        </InputLabel>
                        <RoundedInput
                          required
                          value={this.state.application.dunsNumber || application.dunsNumber}
                          id="duns_number"
                          onChange={e => {
                            this.onChange(e, 'dunsNumber', 'application')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <p>
                For more information about DUNS, refer to{' '}
                <a href="http://www.dnb.com" target="_blank" rel="noopener noreferrer">
                  http://www.dnb.com
                </a>
                . For general support, please email <a href="mailto:HR@Lanzo.net"> HR@Lanzo.net</a>.
              </p>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader>Contact Information</SectionHeader>
              <p>
                Please enter accurate contact information. The email address provided will be used
                as the Log In ID for return visits to the site.
              </p>
              <Grid container justify="center">
                <Grid item xs={12} md={10}>
                  <Grid container justify="center">
                    <Grid item md={4} xs={12}>
                      <InputValidator errors={errors.email} className={classes.fieldsContainer}>
                        <InputLabel shrink htmlFor="email">
                          <span className={classes.formRequired}>* </span>
                          Email
                        </InputLabel>
                        <RoundedInput
                          id="email"
                          required
                          value={this.state.contact.email || contact.email}
                          onChange={e => {
                            this.onChange(e, 'email', 'contact')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputValidator errors={errors.firstName} className={classes.fieldsContainer}>
                        <InputLabel shrink htmlFor="firstName">
                          <span className={classes.formRequired}>* </span>
                          First Name
                        </InputLabel>
                        <RoundedInput
                          id="firstName"
                          required
                          value={this.state.contact.firstName || contact.firstName}
                          onChange={e => {
                            this.onChange(e, 'firstName', 'contact')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputValidator errors={errors.lastName} className={classes.fieldsContainer}>
                        <InputLabel shrink htmlFor="lastName">
                          <span className={classes.formRequired}>* </span>
                          Last Name
                        </InputLabel>
                        <RoundedInput
                          id="lastName"
                          required
                          value={this.state.contact.lastName || contact.lastName}
                          onChange={e => {
                            this.onChange(e, 'lastName', 'contact')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Grid container>
                    <Grid item md={4} xs={12}>
                      <InputValidator
                        errors={errors.phoneNumber}
                        className={classes.fieldsContainer}
                      >
                        <InputLabel shrink htmlFor="phoneNumber">
                          <span className={classes.formRequired}>* </span>
                          Phone Number
                        </InputLabel>

                        <PhoneInput
                          value={this.state.phoneNumber || contact.phoneNumber}
                          onChange={phone =>
                            this.setState(state => {
                              return {contact: {...state.contact, phoneNumber: phone}}
                            })
                          }
                        />
                      </InputValidator>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputValidator
                        errors={errors.phoneExtension}
                        className={classes.fieldsContainer}
                      >
                        <InputLabel shrink htmlFor="phoneExtension">
                          Phone Extension
                        </InputLabel>
                        <RoundedInput
                          id="phoneExtension"
                          value={this.state.contact.phoneExtension || contact.phoneExtension}
                          onChange={e => {
                            this.onChange(e, 'phoneExtension', 'contact')
                          }}
                        />
                      </InputValidator>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{marginTop: 50}}>
              <Grid container justify="flex-end">
                <Grid item xs={12} lg={6} md={12}>
                  <ContractorFooter saveLater={this.saveLater} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    )
  }
}

BasicInfo.displayName = 'BasicInfo'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractor,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(BasicInfo)
