import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid, ButtonBase, Hidden, Modal, InputLabel} from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import withWidth from '@material-ui/core/withWidth'
import {withStyles} from '@material-ui/core/styles'
import {Link} from 'react-router'
import PageUtils from '../utils/PageUtils'
import InputValidator from '../common/InputValidator'
import RoundedInput from '../common/RoundedInput'
import RoundedButton from '../common/RoundedButton'
import * as Actions from './redux/actions'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    [theme.breakpoints.down('sm')]: {},
    fontFamily: 'century-gothic, sans-serif',
  },
  header: {
    position: 'relative',
    marginTop: -60,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      backgroundSize: 'contain',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    bottom: 0,
    left: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 830,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: 230,
      padding: 30,
      top: 0,
      left: 0,
      borderTop: '20px solid white',
      borderLeft: '12px solid white',
      borderRight: '12px solid white',
      backgroundColor: '#666',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #E63B43',
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '19px',
      margin: 0,
      padding: 0,
      marginTop: 20,
    },
  },
  topReturnButton: {
    position: 'absolute',
    backgroundColor: '#E63B43',
    textAlign: 'center',
    fontSize: '15px',
    color: 'white',
    padding: '10px 30px',
    fontFamily: 'century-gothic, sans-serif',
    '& .underline': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 100%)',
      bottom: 0,
      left: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '80%',
      padding: 10,
    },
  },
  topBlurb: {
    maxWidth: 1400,
    textAlign: 'center',
    margin: '0 auto 40px auto',
    '& h3': {
      fontSize: '14px',
    },
    '& p': {
      fontSize: '15px',
      margin: 0,
      padding: 0,
      marginBottom: 15,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      padding: '0 12px',
      margin: '0 auto 20px auto',
    },
  },
  middleSection: {
    maxWidth: 1500,
    margin: '0 auto 30px auto',
  },
  sectionItem: {
    boxShadow: '0 2px 18px 2px rgba(0, 0, 0, 0.17)',
    margin: '20px 40px',
    padding: 30,
    '& h3': {
      fontSize: '20px',
      margin: 0,
      padding: 0,
      marginBottom: 30,
    },
    '& .small': {
      fontSize: '14px',
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      padding: 0,
      margin: 0,
      marginBottom: 30,
      paddingLeft: 30,
    },
    '& li:before': {
      content: "''",
      display: 'block',
      float: 'left',
      width: 11,
      height: 11,
      margin: '5px 15px 0 -30px',
      backgroundColor: '#E63B43',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 12,
    },
  },
  bottomBlurb: {
    maxWidth: 1400,
    textAlign: 'center',
    margin: '0 auto 0 auto',
    '& h3': {
      fontSize: '14px',
    },
    '& p': {
      fontSize: '14px',
      margin: 0,
      padding: 0,
      marginBottom: 15,
    },
  },
  applyNowButton: {
    backgroundColor: '#E63B43',
    borderRadius: 3,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '40px',
    padding: '0 50px',
    color: 'white',
    fontFamily: 'Source Sans Pro',
    margin: 20,
  },
  applyModalContainer: {
    position: 'absolute',
    maxWidth: 750,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    backgroundColor: 'white',
    padding: 35,
    overflow: 'scroll',
    maxHeight: '100%',
    '& label': {
      color: 'black',
      fontSize: '16px',
      fontWeight: 600,
      position: 'relative',
      transform: 'inherit',
    },
    '& h3': {
      fontSize: '30px',
      margin: 0,
      padding: 0,
      marginBottom: 20,
    },
  },
  contactFormItemContainer: {
    marginBottom: 25,
  },
  fileSpan: {
    fontSize: 13,
    margin: 0,
    padding: 0,
    display: 'block',
    marginTop: 7,
  },
  closeButton: {
    fontWeight: 'bold',
    marginLeft: 7,
  },
  formRequired: {
    color: '#FF0000',
  },
  thankYouModalContainer: {
    position: 'absolute',
    maxWidth: 800,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    color: 'white',
    textAlign: 'center',
    padding: 30,
    backgroundColor: 'black',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
    '& h3': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: '47px',
      margin: 0,
      padding: 0,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: '21px',
      margin: 0,
      padding: 0,
      marginBottom: 40,
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
  },
})

const FileButton = withStyles(() => ({
  root: {
    borderRadius: 30,
    maxWidth: 146,
    border: '1px solid #E63B43',
    backgroundColor: 'white',
    color: '#E63B43',
    fontSize: '15px',
    fontWeight: 'bold',
    padding: '5px 15px',
  },
  disabled: {
    backgroundColor: '#DFDFDF',
  },
}))(ButtonBase)

const CancelButton = withStyles(() => ({
  root: {
    backgroundColor: 'white',
    color: '#E63B43',
    fontSize: '20px',
    marginLeft: 20,
    padding: '5px 0',
  },
}))(ButtonBase)

const TextMaskInput = props => (
  <MaskedInput
    {...props}
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
  />
)

class Detail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showApplyModal: false,
      payload: {
        name: '',
        email: '',
        coverLetter: '',
      },
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentWillMount() {
    if (!this.props.jobs || !this.props.jobs.length) {
      this.props.getJobs()
    }
  }

  componentDidMount() {
    PageUtils.scrollToTop()
  }

  onChange(e, fieldName) {
    e.persist()
    this.setState(state => {
      return {payload: {...state.payload, [fieldName]: e.target.value}}
    })
  }

  onChangeFile(e, fieldName) {
    e.persist()
    this.setState(state => {
      return {payload: {...state.payload, [fieldName]: e.target.files[0]}}
    })
  }

  onSubmit() {
    this.props.submitApplication(this.state.payload)
  }

  render() {
    const {classes, jobs, isSubmittingApplication, submitApplicationDidSucceed, width} = this.props
    const {payload} = this.state
    const id = this.props.params.id.split('-').slice(-1)[0]

    if (!jobs.length) {
      return null
    }

    let job = jobs.filter(item => item.id == id)
    if (!job.length) {
      return null
    }
    job = job[0]

    const errors = this.props.applicationErrors

    let submitDisabled = true
    if (
      payload.name &&
      payload.email &&
      (payload.coverLetter || payload.coverLetterFile) &&
      payload.resumeFile
    ) {
      submitDisabled = false
    }

    const mobile = !!(width === 'xs' || width === 'sm')

    let coverLetterFileName = payload.coverLetterFile ? payload.coverLetterFile.name : ''
    if (coverLetterFileName.length > 15 && mobile) {
      coverLetterFileName = coverLetterFileName.substring(0, 15) + '...'
    }

    let resumeFileName = payload.resumeFile ? payload.resumeFile.name : ''
    if (resumeFileName.length > 15 && mobile) {
      resumeFileName = resumeFileName.substring(0, 15) + '...'
    }

    return (
      <Grid container className={classes.container}>
        <Modal open={this.state.showApplyModal && !submitApplicationDidSucceed}>
          <Grid container xs={12} className={classes.applyModalContainer}>
            <Grid item xs={12}>
              <h3>{job.title}</h3>
            </Grid>
            <Grid item xs={12}>
              <InputValidator errors={errors.name} className={classes.contactFormItemContainer}>
                <InputLabel shrink htmlFor="name">
                  Name
                  <span className={classes.formRequired}>*</span>
                </InputLabel>
                <RoundedInput
                  id="name"
                  value={payload.name}
                  onChange={e => {
                    this.onChange(e, 'name')
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid item xs={12}>
              <InputValidator errors={errors.name} className={classes.contactFormItemContainer}>
                <InputLabel shrink htmlFor="email">
                  Email
                  <span className={classes.formRequired}>*</span>
                </InputLabel>
                <RoundedInput
                  id="email"
                  type="email"
                  value={payload.email}
                  onChange={e => {
                    this.onChange(e, 'email')
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid item xs={12}>
              <InputValidator errors={errors.name} className={classes.contactFormItemContainer}>
                <InputLabel shrink htmlFor="phone">
                  Phone
                </InputLabel>
                <RoundedInput
                  id="phone"
                  type="tel"
                  inputComponent={TextMaskInput}
                  onChange={e => {
                    this.onChange(e, 'phone')
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid item xs={12}>
              <InputValidator errors={errors.name} className={classes.contactFormItemContainer}>
                <InputLabel shrink htmlFor="coverLetter">
                  Cover Letter
                  <span className={classes.formRequired}>*</span>
                </InputLabel>
                <RoundedInput
                  id="coverLetter"
                  multiline
                  rows="6"
                  style={{marginTop: 5}}
                  placeholder="Type your cover letter or upload now"
                  value={payload.coverLetter}
                  onChange={e => {
                    this.onChange(e, 'coverLetter')
                  }}
                />
              </InputValidator>
            </Grid>
            <Grid
              item
              xs={12}
              container
              className={classes.contactFormItemContainer}
              style={{marginTop: -10}}
            >
              <Grid item xs={6} md={2}>
                <label htmlFor="containedCoverLetterFile">
                  <input
                    id="containedCoverLetterFile"
                    type="file"
                    style={{display: 'none'}}
                    onChange={e => {
                      this.onChangeFile(e, 'coverLetterFile')
                    }}
                    onClick={e => {
                      e.target.value = null
                    }}
                  />
                  <FileButton variant="contained" component="span">
                    Choose File
                  </FileButton>
                </label>
              </Grid>
              <Grid item xs={6} md={10}>
                <span className={classes.fileSpan}>
                  {coverLetterFileName || 'No file chosen'}
                  {payload.coverLetterFile && (
                    <ButtonBase
                      component="span"
                      className={classes.closeButton}
                      onClick={() => {
                        this.setState(state => {
                          return {payload: {...state.payload, coverLetterFile: null}}
                        })
                      }}
                    >
                      X
                    </ButtonBase>
                  )}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputValidator errors={errors.name} className={classes.contactFormItemContainer}>
                <InputLabel shrink htmlFor="resumeFile">
                  Resume
                  <span className={classes.formRequired}>*</span>
                </InputLabel>
                <Grid xs={12} container style={{marginTop: 10}}>
                  <Grid item xs={6} md={2}>
                    <label htmlFor="containedResumeFile">
                      <input
                        id="containedResumeFile"
                        type="file"
                        style={{display: 'none'}}
                        onChange={e => {
                          this.onChangeFile(e, 'resumeFile')
                        }}
                        onClick={e => {
                          e.target.value = null
                        }}
                      />
                      <FileButton variant="contained" component="span">
                        Choose File
                      </FileButton>
                    </label>
                  </Grid>
                  <Grid item xs={6} md={10}>
                    <span className={classes.fileSpan}>
                      {resumeFileName || 'No file chosen'}
                      {payload.resumeFile && (
                        <ButtonBase
                          component="span"
                          className={classes.closeButton}
                          onClick={() => {
                            this.setState(state => {
                              return {payload: {...state.payload, resumeFile: null}}
                            })
                          }}
                        >
                          X
                        </ButtonBase>
                      )}
                    </span>
                  </Grid>
                </Grid>
              </InputValidator>
            </Grid>
            <Grid item xs={12} container style={{marginTop: 20}}>
              <Grid item xs={6} md={3}>
                <RoundedButton
                  disabled={submitDisabled || isSubmittingApplication}
                  onClick={this.onSubmit}
                >
                  {isSubmittingApplication ? 'Sending ...' : 'Continue'}
                </RoundedButton>
              </Grid>
              <Grid item xs={2}>
                <CancelButton
                  onClick={() => {
                    this.setState({
                      showApplyModal: false,
                      payload: {
                        name: '',
                        email: '',
                        coverLetter: '',
                      },
                    })
                  }}
                >
                  Cancel
                </CancelButton>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal open={submitApplicationDidSucceed}>
          <Grid container xs={12} className={classes.thankYouModalContainer} justify="center">
            <Grid item xs={12}>
              <h3>Thank you!</h3>
            </Grid>
            <Grid item xs={12}>
              <p>
                Your application was submitted successfully. You will receive a confirmation email
                soon.
              </p>
            </Grid>
            <Grid item xs={6}>
              <RoundedButton
                onClick={() => {
                  const {props} = this
                  this.setState(
                    {
                      showApplyModal: false,
                      payload: {
                        name: '',
                        email: '',
                        coverLetter: '',
                      },
                    },
                    () => {
                      props.submitApplicationReset()
                      props.router.push('/')
                    },
                  )
                }}
              >
                CONTINUE
              </RoundedButton>
            </Grid>
          </Grid>
        </Modal>
        <Grid
          item
          xs={12}
          className={classes.header}
          style={{backgroundImage: `url(${job.image.fullSize})`}}
        >
          <div className={classes.headerFloatContainer}>
            <h2>{job.title}</h2>
            <p>{job.description}</p>
            <Hidden mdDown={true}>
              <ButtonBase className={classes.topReturnButton} component={Link} to="/careers">
                <span>
                  Return to <span className="underline">Business Contact & Careers</span>
                </span>
              </ButtonBase>
            </Hidden>
          </div>
        </Grid>
        <Hidden smUp={true}>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <ButtonBase className={classes.topReturnButton} component={Link} to="/careers">
              <span>
                Return to <span className="underline">Business Contact & Careers</span>
              </span>
            </ButtonBase>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          dangerouslySetInnerHTML={{__html: job.topBlurb}}
          className={classes.topBlurb}
        />
        <Grid item xs={12} container className={classes.middleSection} justify="center">
          {job.section1 && (
            <Grid
              item
              xs={12}
              md={5}
              dangerouslySetInnerHTML={{__html: job.section1}}
              className={classes.sectionItem}
            />
          )}
          {job.section2 && (
            <Grid
              item
              xs={12}
              md={5}
              dangerouslySetInnerHTML={{__html: job.section2}}
              className={classes.sectionItem}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.bottomBlurb}
          dangerouslySetInnerHTML={{__html: job.bottomBlurb}}
        />
        <Grid item xs={12} className={classes.bottomBlurb}>
          <p>We are an Equal Opportunity Employer.</p>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <ButtonBase
            className={classes.applyNowButton}
            onClick={() => {
              this.setState({showApplyModal: true})
            }}
          >
            APPLY NOW
          </ButtonBase>
        </Grid>
      </Grid>
    )
  }
}

Detail.displayName = 'Careers Detail'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.careers,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withWidth(),
)(Detail)
