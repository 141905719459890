import React from 'react'
import styled from 'styled-components'
import {Button} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Modal from './Modal'

const Title = styled.h2`
  font-family: ProximaNova;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`

const Paragraph = styled.p`
  font-size: 16px;
`

class AlertDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: props.open,
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({modalIsOpen: nextProps.open})
    }
  }

  openModal() {
    this.setState({modalIsOpen: true})
  }

  closeModal() {
    this.setState({modalIsOpen: false})
  }

  render() {
    const {
      trigger,
      triggerLabel,
      confirm,
      cancelLabel,
      okLabel,
      onConfirm,
      onCancel,
      description,
      question,
      title,
    } = this.props
    const TriggerButton = trigger && trigger.component ? trigger.component : Button
    return (
      <div className="multi-input-list">
        <Modal
          open={this.state.modalIsOpen}
          handleClose={this.closeModal}
          title={title && <Title>{title}</Title>}
        >
          <Grid container>
            {description && <Paragraph>{description}</Paragraph>}
            {question && <Paragraph>{question}</Paragraph>}
            <Grid container>
              {confirm && (
                <Grid item md={6} xs={12} style={{padding: '10px'}}>
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="default"
                    onClick={() => {
                      this.closeModal()
                      onCancel && onCancel()
                    }}
                  >
                    {cancelLabel}
                  </Button>
                </Grid>
              )}
              <Grid item md={6} xs={12} style={{padding: '10px'}}>
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.closeModal()
                    onConfirm && onConfirm()
                  }}
                >
                  {okLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {trigger && (
          <TriggerButton onClick={this.openModal} {...trigger.props}>
            {triggerLabel}
          </TriggerButton>
        )}
      </div>
    )
  }
}

export default AlertDialog
