export const CREATE_BUSINESS_CLASSIFICATION = 'CREATE_BUSINESS_CLASSIFICATION'
export const CREATE_BUSINESS_CLASSIFICATION_SUCCESS = 'CREATE_BUSINESS_CLASSIFICATION_SUCCESS'
export const CREATE_BUSINESS_CLASSIFICATION_FAILURE = 'CREATE_BUSINESS_CLASSIFICATION_FAILURE'

export const BULK_CREATE_BUSINESS_CLASSIFICATION = 'BULK_CREATE_BUSINESS_CLASSIFICATION'
export const BULK_CREATE_BUSINESS_CLASSIFICATION_SUCCESS =
  'BULK_CREATE_BUSINESS_CLASSIFICATION_SUCCESS'
export const BULK_CREATE_BUSINESS_CLASSIFICATION_FAILURE =
  'BULK_CREATE_BUSINESS_CLASSIFICATION_FAILURE'

export const GET_BUSINESS_CLASSIFICATIONS = 'GET_BUSINESS_CLASSIFICATIONS'
export const GET_BUSINESS_CLASSIFICATIONS_SUCCESS = 'GET_BUSINESS_CLASSIFICATIONS_SUCCESS'
export const GET_BUSINESS_CLASSIFICATIONS_FAILURE = 'GET_BUSINESS_CLASSIFICATIONS_FAILURE'

export const GET_BUSINESS_CLASSIFICATION_DATA = 'GET_BUSINESS_CLASSIFICATION_DATA'
export const GET_BUSINESS_CLASSIFICATION_DATA_SUCCESS = 'GET_BUSINESS_CLASSIFICATION_DATA_SUCCESS'
export const GET_BUSINESS_CLASSIFICATION_DATA_FAILURE = 'GET_BUSINESS_CLASSIFICATION_DATA_FAILURE'

export const UPDATE_BUSINESS_CLASSIFICATION = 'UPDATE_BUSINESS_CLASSIFICATION'
export const UPDATE_BUSINESS_CLASSIFICATION_SUCCESS = 'UPDATE_BUSINESS_CLASSIFICATION_SUCCESS'
export const UPDATE_BUSINESS_CLASSIFICATION_FAILURE = 'UPDATE_BUSINESS_CLASSIFICATION_FAILURE'

export const BULK_UPDATE_BUSINESS_CLASSIFICATION = 'BULK_UPDATE_BUSINESS_CLASSIFICATION'
export const BULK_UPDATE_BUSINESS_CLASSIFICATION_SUCCESS =
  'BULK_UPDATE_BUSINESS_CLASSIFICATION_SUCCESS'
export const BULK_UPDATE_BUSINESS_CLASSIFICATION_FAILURE =
  'BULK_UPDATE_BUSINESS_CLASSIFICATION_FAILURE'

export const DELETE_BUSINESS_CLASSIFICATION = 'DELETE_BUSINESS_CLASSIFICATION'
export const DELETE_BUSINESS_CLASSIFICATION_SUCCESS = 'DELETE_BUSINESS_CLASSIFICATION_SUCCESS'
export const DELETE_BUSINESS_CLASSIFICATION_FAILURE = 'DELETE_BUSINESS_CLASSIFICATION_FAILURE'

export const RESET_BUSINESS_CLASSIFICATION_ERRORS = 'RESET_BUSINESS_CLASSIFICATION_ERRORS'
export const SET_MINORITY_OPTION = 'SET_MINORITY_OPTION'
