import React from 'react'
import {Grid} from '@material-ui/core'
import IconButton from '../IconButton'
import RoundedInput from '../../common/RoundedInput'
import InputValidator from '../../common/InputValidator'

class Row extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      attachment: props.attachment,
    }
    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.attachment !== this.state.attachment) {
      this.setState({edit: false, attachment: nextProps.attachment})
    }
  }

  onChange(e, field) {
    e.persist()
    this.setState(state => {
      return {attachment: {...state.attachment, [field]: e.target.value}}
    })
  }

  render() {
    const {classes, hideHeader, attachment, errors} = this.props

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Title</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{attachment.title}</p>
          </Grid>
        </Grid>
        <Grid item md={1} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Type</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{attachment.type}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Description</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <InputValidator errors={errors.description} className={classes.fieldsContainer}>
                <RoundedInput
                  value={this.state.attachment.description}
                  onChange={e => {
                    this.onChange(e, 'description')
                  }}
                />
              </InputValidator>
            ) : (
              <p>{attachment.description}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Category</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <InputValidator errors={errors.category} className={classes.fieldsContainer}>
                <RoundedInput
                  value={this.state.attachment.category}
                  onChange={e => {
                    this.onChange(e, 'category')
                  }}
                />
              </InputValidator>
            ) : (
              <p>{attachment.category}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Last Updated</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{attachment.lastUpdated && new Date(attachment.lastUpdated).toLocaleString()}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Usage</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {this.state.edit ? (
              <InputValidator errors={errors.usage} className={classes.fieldsContainer}>
                <RoundedInput
                  value={this.state.attachment.usage}
                  onChange={e => {
                    this.onChange(e, 'usage')
                  }}
                />
              </InputValidator>
            ) : (
              <p>{attachment.usage}</p>
            )}
          </Grid>
        </Grid>

        <Grid item md={1} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            {!this.state.edit && (
              <Grid item xs={4}>
                <Grid justify="center" container>
                  <p>
                    <IconButton
                      type="edit"
                      onClick={() => {
                        this.setState({edit: true})
                        this.props.resetError()
                      }}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            {this.state.edit && (
              <Grid item xs={4}>
                <Grid justify="center" container>
                  <p>
                    <IconButton
                      type="save"
                      onClick={() => {
                        this.props.updateAttachment(attachment.id, {
                          usage: this.state.attachment.usage,
                          description: this.state.attachment.description,
                          application: this.state.attachment.application,
                          category: this.state.attachment.category,
                        })
                      }}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="delete"
                    onClick={() => {
                      this.props.deleteAttachment(attachment.id, attachment.application)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Row
