import * as Types from './actions.types'

export function createApplication(payload, params) {
  return {type: Types.CREATE_APPLICATION, payload, params}
}

export function createApplicationSuccess(payload) {
  return {type: Types.CREATE_APPLICATION_SUCCESS, payload}
}

export function createApplicationFailure(error) {
  return {type: Types.CREATE_APPLICATION_FAILURE, error}
}

export function getApplication(id, params) {
  return {type: Types.GET_APPLICATION, id, params}
}

export function getApplicationSuccess(payload) {
  return {type: Types.GET_APPLICATION_SUCCESS, payload}
}

export function getApplicationFailure(error) {
  return {type: Types.GET_APPLICATION_FAILURE, error}
}

export function updateApplication(id, data, params) {
  return {type: Types.UPDATE_APPLICATION, id, data, params}
}

export function updateApplicationSuccess(payload) {
  return {type: Types.UPDATE_APPLICATION_SUCCESS, payload}
}

export function updateApplicationFailure(error) {
  return {type: Types.UPDATE_APPLICATION_FAILURE, error}
}

export function submitApplication(id) {
  return {type: Types.SUBMIT_APPLICATION, id}
}

export function submitApplicationSuccess(payload) {
  return {type: Types.SUBMIT_APPLICATION_SUCCESS, payload}
}

export function submitApplicationFailure(error) {
  return {type: Types.SUBMIT_APPLICATION_FAILURE, error}
}

export function closeModal() {
  return {type: Types.CLOSE_MODAL}
}

export function openModal() {
  return {type: Types.OPEN_MODAL}
}
