import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* businessLineFlow() {
  yield all([
    takeEvery(Types.CREATE_BUSINESS_LINE, createBusinessLine),
    takeEvery(Types.GET_BUSINESS_LINES, getBusinessLinees),
    takeEvery(Types.UPDATE_BUSINESS_LINE, updateBusinessLine),
    takeEvery(Types.DELETE_BUSINESS_LINE, deleteBusinessLine),
    takeEvery(Types.GET_BUSINESS_LINES_DATA, getBusinessLineData),
  ])
}

function* createBusinessLine(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createBusinessLine, payload)
    yield put(Actions.createBusinessLineSuccess(response))
  } catch (error) {
    yield put(Actions.createBusinessLineFailure(error))
  }
}

function* getBusinessLinees(action) {
  const {params} = action
  try {
    const response = yield call(Api.getBusinessLines, params)
    yield put(Actions.getBusinessLinesSuccess(response))
  } catch (error) {
    yield put(Actions.getBusinessLinesFailure(error))
  }
}

function* updateBusinessLine(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateBusinessLine, id, data)
    yield put(Actions.updateBusinessLineSuccess(response))
  } catch (error) {
    yield put(Actions.updateBusinessLineFailure(error))
  }
}

function* deleteBusinessLine(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteBusinessLine, id, appId)
    yield put(Actions.deleteBusinessLineSuccess(id))
  } catch (error) {
    yield put(Actions.deleteBusinessLineFailure(error))
  }
}

function* getBusinessLineData() {
  try {
    const response = yield call(Api.getBusinessLineData)
    yield put(Actions.getBusinessLineDataSuccess(response))
  } catch (error) {
    yield put(Actions.getBusinessLineDataFailure(error))
  }
}
