import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class ProjectInterestApi {
  static createProjectInterest(payload) {
    return axios.post(`${settings.apiBaseURL}/projects-of-interest`, payload)
  }

  static getProjectInterests(params) {
    return axios.get(`${settings.apiBaseURL}/projects-of-interest`, {params})
  }

  static updateProjectInterest(id, data) {
    return axios.patch(
      `${settings.apiBaseURL}/projects-of-interest/${id}?app_id=${data.application}`,
      data,
    )
  }

  static deleteProjectInterest(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/projects-of-interest/${id}?app_id=${appId}`)
  }

  static getProjectInterestData() {
    return axios.get(`${settings.apiBaseURL}/interest-projects`)
  }
}
