import {Types} from './actions.menu.types'

export default function(state = {open: false}, action) {
  switch (action.type) {
    case Types.TOGGLE_MOBILE_MENU:
      return {...state, open: !state.open}
    default:
      return state
  }
}
