import React from 'react'
import {Grid} from '@material-ui/core'
import IconButton from '../IconButton'
import RoundedInput from '../../common/RoundedInput'
import RoundedSelect from '../../common/RoundedSelect'
import InputValidator from '../../common/InputValidator'

class InputRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      application: props.appId,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(e, field) {
    this.setState({[field]: e.target.value})
  }

  render() {
    const {hideHeader, classes, errors, options, selected, deletePurpose} = this.props
    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={9} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Purpose</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.purpose} className={classes.fieldsContainer}>
              <RoundedSelect
                native
                value={selected || 0}
                disabled={!!selected}
                input={<RoundedInput />}
                onChange={e => {
                  if (this.props.selectPurpose) {
                    this.props.selectPurpose(e.target.value)
                    e.target.blur()
                  }
                }}
              >
                <option value="" />
                {options.map(option => (
                  <option value={option.id}>{option.name}</option>
                ))}
              </RoundedSelect>
            </InputValidator>
          </Grid>
        </Grid>
        <Grid item md={3} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Remove</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                {deletePurpose && (
                  <p>
                    <IconButton
                      type="delete"
                      onClick={() => {
                        deletePurpose(selected)
                      }}
                    />
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default InputRow
