import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import PageUtils from '../utils/PageUtils'
import ContractorHeader from './ContractorHeader'
import * as Actions from './redux/actions'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    '& h2': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 24,
      fontWeight: 'bold',
    },
    '& h1': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 34,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 16,
    },
  },
})

class RegistrationSubmitted extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appId: '',
    }
  }

  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.container} justify="center">
        <ContractorHeader hideSaveLater={true} />
        <Grid item md={6} xs={12} style={{marginTop: 30}}>
          <Grid container justify="center">
            <h1 style={{textAlign: 'center'}}>Supplier Registration Successful</h1>
            <p>You application has been successfully submitted.</p>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

RegistrationSubmitted.displayName = 'RegistrationSubmitted'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractor,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(RegistrationSubmitted)
