import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../../common/redux/actions.notifications'

export default function* addressFlow() {
  yield all([
    takeEvery(Types.CREATE_ADDRESS, createAddress),
    takeEvery(Types.GET_ADDRESSES, getAddresses),
    takeEvery(Types.UPDATE_ADDRESS, updateAddress),
    takeEvery(Types.DELETE_ADDRESS, deleteAddress),
    takeEvery(Types.GET_PURPOSE_OPTIONS, getPurposeOptions),
  ])
}

function* createAddress(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createAddress, payload)
    yield put(Actions.createAddressSuccess(response))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.createAddressFailure(error))
  }
}

function* getAddresses(action) {
  const {params} = action
  try {
    const response = yield call(Api.getAddresses, params)
    yield put(Actions.getAddressesSuccess(response))
  } catch (error) {
    yield put(Actions.getAddressesFailure(error))
  }
}

function* updateAddress(action) {
  const {id, data, previousePurposes} = action
  const newAddressPurposes = data.purpose.filter(
    p => !previousePurposes.map(pp => pp.purpose).includes(p),
  )
  const removeAddressPurpose = previousePurposes
    .filter(p => !data.purpose.includes(p.purpose))
    .map(p => p.id)
  try {
    for (const p of newAddressPurposes) {
      yield call(Api.createAddressPurpose, id, p)
    }
    for (const p of removeAddressPurpose) {
      yield call(Api.deleteAddressPurpose, p, data.application)
    }
    const response = yield call(Api.updateAddress, id, data)
    yield put(Actions.updateAddressSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.updateAddressFailure(error))
  }
}

function* deleteAddress(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteAddress, id, appId)
    yield put(Actions.deleteAddressSuccess(id))
    yield put(NotificationActions.createSuccess('Deleted successfully'))
  } catch (error) {
    yield put(Actions.deleteAddressFailure(error))
  }
}

function* getPurposeOptions() {
  try {
    const response = yield call(Api.getPurposeOptions)
    yield put(Actions.getPurposeOptionsSuccess(response))
  } catch (error) {
    yield put(Actions.getPurposeOptionsFailure(error))
  }
}
