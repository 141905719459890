import * as Types from './actions.types'

export function getGallery() {
  return {type: Types.GET_GALLERY_IMAGES}
}

export function getGallerySuccess(payload) {
  return {type: Types.GET_GALLERY_IMAGES_SUCCESS, payload}
}

export function getGalleryFailure(error) {
  return {type: Types.GET_GALLERY_IMAGES_FAILURE, error}
}
