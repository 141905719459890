import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'

const SearchSelect = ({options, value, onChange, onInputChange, getData}) => {
  const getOptions = inputValue =>
    new Promise(resolve => {
      getData({q: inputValue}).then(response => {
        resolve(response.results)
      })
    })

  return (
    <React.Fragment>
      <AsyncSelect
        cacheOptions
        defaultValue={value}
        loadOptions={(value, callback) => {
          getOptions(value)
            .then(response => {
              callback(response.map(c => ({label: c.name, ...c})))
            })
            .catch(() => {
              callback(options)
            })
        }}
        defaultOptions
        onInputChange={onInputChange}
        onChange={onChange}
      />
    </React.Fragment>
  )
}

SearchSelect.displayName = 'SearchSelect'
SearchSelect.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
}

export default SearchSelect
