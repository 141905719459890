import {call, put, takeEvery, all} from 'redux-saga/effects'
import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../../common/redux/actions.notifications'

export default function* projectFlow() {
  yield all([
    takeEvery(Types.CREATE_PROJECT_INTEREST, createProjectInterest),
    takeEvery(Types.GET_PROJECT_INTERESTS, getProjectInterests),
    takeEvery(Types.UPDATE_PROJECT_INTEREST, updateProjectInterest),
    takeEvery(Types.DELETE_PROJECT_INTEREST, deleteProjectInterest),
    takeEvery(Types.GET_PROJECT_INTERESTS_DATA, getProjectInterestData),
  ])
}

function* createProjectInterest(action) {
  const {payload} = action
  try {
    const response = yield call(Api.createProjectInterest, payload)
    yield put(Actions.createProjectInterestSuccess(response))
    yield put(NotificationActions.createSuccess('Created successfully'))
  } catch (error) {
    yield put(Actions.createProjectInterestFailure(error))
  }
}

function* getProjectInterests(action) {
  const {params} = action
  try {
    const response = yield call(Api.getProjectInterests, params)
    yield put(Actions.getProjectInterestsSuccess(response))
  } catch (error) {
    yield put(Actions.getProjectInterestsFailure(error))
  }
}

function* updateProjectInterest(action) {
  const {id, data} = action
  try {
    const response = yield call(Api.updateProjectInterest, id, data)
    yield put(Actions.updateProjectInterestSuccess(response))
    yield put(NotificationActions.createSuccess('Updated successfully'))
  } catch (error) {
    yield put(Actions.updateProjectInterestFailure(error))
  }
}

function* deleteProjectInterest(action) {
  const {id, appId} = action
  try {
    yield call(Api.deleteProjectInterest, id, appId)
    yield put(Actions.deleteProjectInterestSuccess(id))
    yield put(NotificationActions.createSuccess('Deleted successfully'))
  } catch (error) {
    yield put(Actions.deleteProjectInterestFailure(error))
  }
}

function* getProjectInterestData() {
  try {
    const response = yield call(Api.getProjectInterestData)
    yield put(Actions.getProjectInterestDataSuccess(response))
  } catch (error) {
    yield put(Actions.getProjectInterestDataFailure(error))
  }
}
