export const CREATE_BUSINESS_LINE = 'CREATE_BUSINESS_LINE'
export const CREATE_BUSINESS_LINE_SUCCESS = 'CREATE_BUSINESS_LINE_SUCCESS'
export const CREATE_BUSINESS_LINE_FAILURE = 'CREATE_BUSINESS_LINE_FAILURE'

export const GET_BUSINESS_LINES = 'GET_BUSINESS_LINES'
export const GET_BUSINESS_LINES_SUCCESS = 'GET_BUSINESS_LINES_SUCCESS'
export const GET_BUSINESS_LINES_FAILURE = 'GET_BUSINESS_LINES_FAILURE'

export const UPDATE_BUSINESS_LINE = 'UPDATE_BUSINESS_LINE'
export const UPDATE_BUSINESS_LINE_SUCCESS = 'UPDATE_BUSINESS_LINE_SUCCESS'
export const UPDATE_BUSINESS_LINE_FAILURE = 'UPDATE_BUSINESS_LINE_FAILURE'

export const DELETE_BUSINESS_LINE = 'DELETE_BUSINESS_LINE'
export const DELETE_BUSINESS_LINE_SUCCESS = 'DELETE_BUSINESS_LINE_SUCCESS'
export const DELETE_BUSINESS_LINE_FAILURE = 'DELETE_BUSINESS_LINE_FAILURE'

export const RESET_BUSINESS_LINE_ERRORS = 'RESET_BUSINESS_LINE_ERRORS'

export const GET_BUSINESS_LINES_DATA = 'GET_BUSINESS_LINES_DATA'
export const GET_BUSINESS_LINES_DATA_SUCCESS = 'GET_BUSINESS_LINES_DATA_SUCCESS'
export const GET_BUSINESS_LINES_DATA_FAILURE = 'GET_BUSINESS_LINES_DATA_FAILURE'
