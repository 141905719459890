import React from 'react'

const ExternalLink = props => (
  <a href={props.to} {...props}>
    {props.children}
  </a>
)

ExternalLink.displayName = 'ExternalLink'
export default ExternalLink
