import settings from '../../../../config/settings'
import axios from '../../api/axios'

export default class ClassificationApi {
  static createBusinessClassification(payload) {
    return axios.post(`${settings.apiBaseURL}/business-classifications`, payload)
  }

  static bulkCreateBusinessClassification(payload) {
    return axios.post(`${settings.apiBaseURL}/business-classifications/bulk`, payload)
  }

  static getBusinessClassifications(params) {
    return axios.get(`${settings.apiBaseURL}/business-classifications`, {params})
  }

  static getBusinessClassificationData(params) {
    return axios.get(`${settings.apiBaseURL}/business-classification-options`, {params})
  }

  static updateBusinessClassification(id, data) {
    return axios.patch(
      `${settings.apiBaseURL}/business-classifications/${id}?app_id=${data.application}`,
      data,
    )
  }

  static bulkUpdateBusinessClassification(appId, data) {
    return axios.patch(`${settings.apiBaseURL}/business-classifications/bulk?app_id=${appId}`, data)
  }

  static deleteBusinessClassification(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/business-classifications/${id}?app_id=${appId}`)
  }
}
