import React from 'react'
import {Grid, Paper} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {compose} from 'redux'
import videosHeader from '../../assets/videosheader.png'
import downArrow from '../../assets/downArrow.png'
import PageUtils from '../utils/PageUtils'
import {InputField, SelectField} from '../ui/Input'
import {Image, SectionTitle} from '../ui'
import table1 from '../../assets/table1.png'
import formula1 from '../../assets/formula1.png'
import math1 from '../../assets/math1.png'
import table2 from '../../assets/table2.png'
import condition1 from '../../assets/condition1.png'
import condition2 from '../../assets/condition2.png'
import condition3 from '../../assets/condition3.png'
import radiusStiffness from '../../assets/radius-stiffness.png'
import airportLoad from '../../assets/airport-load.png'
import equationX2 from '../../assets/equation-x-2.png'
import bouyancyFactor from '../../assets/bouyancy-factor.png'
import elasticSupport from '../../assets/elastic-support.png'
import equationX3 from '../../assets/equation-x-3.png'
import equationX4 from '../../assets/equation-x-4.png'
import equationX5 from '../../assets/equation-x-5.png'
import equationX6 from '../../assets/equation-x-6.png'
import equationX7 from '../../assets/equation-x-7.png'
import externalPressure from '../../assets/external-pressure.png'
import thicknessCal from '../../assets/thickness-cal.png'
import thicknessRecommended from '../../assets/thickness-recommended.png'
import thicknessSupplied from '../../assets/thickness-supplied.png'
import hostPipeCapacity from '../../assets/host-pipe-capacity.png'
import psi from '../../assets/psi.png'
import ifPW from '../../assets/ifPW.png'
import equationX1 from '../../assets/equation-x-1.png'
import liveload from '../../assets/liveloads-railroads.png'
import fig4 from '../../assets/fig4.3.png'
import cbr from '../../assets/cbr.png'
import pressure from '../../assets/pressure-coefficient.png'
import axis from '../../assets/axis-pipe.png'
import UserSession from '../auth/UserSession'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {},
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      backgroundSize: 'contain',
    },
  },
  videoTitle: {
    fontFamily: 'century-gothic, sans-serif',
    fontSize: 18,
    fontWeight: 'bold',
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 255,
    bottom: 0,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
      height: 290,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      transform: 'translate(-50%, 50%)',
      marginBottom: '500px',
      marginTop: '-550',
      width: '350px',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '34px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
    },
    '& p': {
      fontSize: '19px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
    },
  },
  videoFrame: {
    width: '100%',
    minHeight: 500,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      minHeight: 300,
    },
  },
  paper: {
    padding: 20,
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 0,
      boxShadow: 'none',
    },
  },
  radiusStiffness: {
    marginBottom: '-15px',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  radiusStiffnessSide: {
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: 0,
    },
  },
  airportLoad: {
    marginBottom: '-12px',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      height: '45px',
    },
  },
  formula1Side: {
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  formula1: {
    marginBottom: '-10px',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-10px',
      height: '50px',
    },
  },
  psi: {
    marginBottom: '-10px',
    height: '30px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: '35px',
    },
  },
  equationX1: {
    height: '80px',
    marginBottom: '-12px',
    [theme.breakpoints.down('sm')]: {
      height: '85px',
      marginBottom: 0,
    },
  },
  equationX1Side: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  equationX2: {
    height: '85px',
    marginBottom: '-12px',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
      marginBottom: 0,
    },
  },
  equationX2Side: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  ifPW: {
    height: '35px',
    marginBottom: '-10px',
    marginLeft: '15px',
  },
  bouyancy: {
    height: '45px',
    marginBottom: '-5px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: '42px',
      marginTop: '5px',
    },
  },
  bouyancySide: {
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  externalPressure: {
    height: '50px',
    marginBottom: '-10px',
    [theme.breakpoints.down('sm')]: {
      height: '42px',
      marginBottom: 0,
      marginTop: '5px',
    },
  },
  elasticSupport: {
    marginTop: '12px',
    height: '38px',
    marginBottom: '-10px',
    marginLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      height: '35px',
      marginBottom: '-10px',
      marginLeft: 0,
      marginTop: 0,
    },
  },
  equationX3: {
    height: '65px',
    marginBottom: '-15px',
    [theme.breakpoints.down('sm')]: {
      height: '65px',
      marginBottom: 0,
    },
  },
  equationX3Side: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  equationX4: {
    height: '45px',
    marginBottom: '-8px',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      marginBottom: 0,
    },
  },
  equationX4Side: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  equationX6: {
    height: '45px',
    marginLeft: '20px',
    marginBottom: '-7px',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  equationX5: {
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      height: '65px',
    },
  },
  equationX5Side: {
    marginTop: '85px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  thicknessDesign: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  equationX7: {
    height: '50px',
    marginLeft: '20px',
    marginBottom: '-10px',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  equationX7Side: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  thicknessCal: {
    height: '70px',
    marginBottom: '-5px',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  thicknessCalSide: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  thicknessRecommended: {
    height: '30px',
    marginBottom: '-10px',
    [theme.breakpoints.down('sm')]: {
      height: '35px',
      marginBottom: 0,
    },
  },
  thicknessRecommendedSide: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  thicknessSupplied: {
    height: '45px',
    marginBottom: '-10px',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      marginBottom: 0,
    },
  },
  thicknessSuppliedSide: {
    marginTop: '45px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  hostPipeCapacity: {
    height: '75px',
    marginBottom: '-18px',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  hostPipeCapacitySide: {
    marginTop: '42px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
})

class Calculator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vmCalc: {
        diameter: 8,
        ovality: 2.0,
        slope: 0.003,
        hpRoughness: 0.015,
        cpRoughness: 0.01,
        modulus: 1200,
        elastic: 400000,
        retent: 50,
        soil: 120,
        safety: 2,
        waterWeight: 62.4,
        waterHeight: 23.0,
        height: 45.0,
        ivp: 0.0,
        ip: 0.0,
        diameterOpening: 2.0,
        cippPr: 0.3,
        strengthFi: 5500,
        strengthTi: 4000,
        eFactor: 7.0,
        modElastConcrete: 4000000,
        poissonsConcrete: 0.15,
        moduSubReact: 250,
        thickPave: 12,
        horizDistCenter: 0,
        wheelLoad: 90000,
        surfaceValue: 'H2O',
        hostPipeCondition: 'Partially Deteriorated',
      },
    }
    this.onChange = this.onChange.bind(this)
    this.onChangeNonNumber = this.onChangeNonNumber.bind(this)
    this.elastiretStr = this.elastiretStr.bind(this)
    this.strengthFltStr = this.strengthFltStr.bind(this)
    this.strengthTltStr = this.strengthTltStr.bind(this)
    this.factorImpactStr = this.factorImpactStr.bind(this)
    this.areaLoadStr = this.areaLoadStr.bind(this)
    this.loadWheelStr = this.loadWheelStr.bind(this)
    this.loadLiveStr = this.loadLiveStr.bind(this)
    this.loadLiveE80Str = this.loadLiveE80Str.bind(this)
    this.radiusRigidPaveStr = this.radiusRigidPaveStr.bind(this)
    this.hrCoefficientStr = this.hrCoefficientStr.bind(this)
    this.cCoefficientStr = this.cCoefficientStr.bind(this)
    this.airportLoadStr = this.airportLoadStr.bind(this)
    this.ovalityReductionFactorStr = this.ovalityReductionFactorStr.bind(this)
    this.extHydroPressureStr = this.extHydroPressureStr.bind(this)
    this.minThickHydroBucklingINStr = this.minThickHydroBucklingINStr.bind(this)
    this.minThickHydroBucklingMMStr = this.minThickHydroBucklingMMStr.bind(this)
    this.minThickOvalityInStr = this.minThickOvalityInStr.bind(this)
    this.quadraticAStr = this.quadraticAStr.bind(this)
    this.quadraticBStr = this.quadraticBStr.bind(this)
    this.quadraticCStr = this.quadraticCStr.bind(this)
    this.sdr2Str = this.sdr2Str.bind(this)
    this.minThickOvalityMMStr = this.minThickOvalityMMStr.bind(this)
    this.totalLiveLoadStr = this.totalLiveLoadStr.bind(this)
    this.waterBouyancyFactorStr = this.waterBouyancyFactorStr.bind(this)
    this.totalExtPressureStr = this.totalExtPressureStr.bind(this)
    this.coefficientElastiSupportStr = this.coefficientElastiSupportStr.bind(this)
    this.minThickLuschersINStr = this.minThickLuschersINStr.bind(this)
    this.minThickLuschersMMStr = this.minThickLuschersMMStr.bind(this)
    this.minThickPipeStiffINStr = this.minThickPipeStiffINStr.bind(this)
    this.minThickPipeStiffMMStr = this.minThickPipeStiffMMStr.bind(this)
    this.minThickIntPressureStr = this.minThickIntPressureStr.bind(this)
    this.dDValStr = this.dDValStr.bind(this)
    this.minThicktprDStr = this.minThicktprDStr.bind(this)
    this.minThickReqDesignINStr = this.minThickReqDesignINStr.bind(this)
    this.minThickReqWithExtINStr = this.minThickReqWithExtINStr.bind(this)
    this.minThickReqDesignMMStr = this.minThickReqDesignMMStr.bind(this)
    this.minThickReqWithExtMMStr = this.minThickReqWithExtMMStr.bind(this)
    this.conditionHostPipeStr = this.conditionHostPipeStr.bind(this)
    this.endUseStr = this.endUseStr.bind(this)
    this.diamStr = this.diamStr.bind(this)
    this.minCIPPThickCalcINStr = this.minCIPPThickCalcINStr.bind(this)
    this.minCIPPThickCalcMMStr = this.minCIPPThickCalcMMStr.bind(this)
    this.minCIPPThickRecommendINStr = this.minCIPPThickRecommendINStr.bind(this)
    this.minCIPPThickRecommendMMStr = this.minCIPPThickRecommendMMStr.bind(this)
    this.nominalCIPPThickStr = this.nominalCIPPThickStr.bind(this)
    this.sdrCIPPStr = this.sdrCIPPStr.bind(this)
    this.diameterInsideCIPPStr = this.diameterInsideCIPPStr.bind(this)
    this.flowAreaHostPipeStr = this.flowAreaHostPipeStr.bind(this)
    this.flowAreaCIPPStr = this.flowAreaCIPPStr.bind(this)
    this.capHostPipeStr = this.capHostPipeStr.bind(this)
    this.capCIPPStr = this.capCIPPStr.bind(this)
    this.percentFlowStr = this.percentFlowStr.bind(this)
    this.numberFormat = this.numberFormat.bind(this)
  }

  numberFormat(number) {
    return Intl.NumberFormat().format(number)
  }

  elastiretStr() {
    const {vmCalc} = this.state
    const toReturn = vmCalc.elastic * (vmCalc.retent / 100)

    return toReturn
  }

  strengthFltStr() {
    const {vmCalc} = this.state
    const toReturn = vmCalc.strengthFi * (vmCalc.retent / 100)
    return toReturn
  }

  strengthTltStr() {
    const {vmCalc} = this.state
    const toReturn = vmCalc.strengthTi * (vmCalc.retent / 100)
    return toReturn
  }

  factorImpactStr() {
    const {vmCalc} = this.state

    let toReturn = 'n/a'

    if (vmCalc.surfaceValue === 'H2O') {
      if (vmCalc.height <= 1) {
        toReturn = 1.3
      } else if (vmCalc.height <= 2) {
        toReturn = 1.2
      } else if (vmCalc.height <= 3) {
        toReturn = 1.1
      } else {
        toReturn = 1.0
      }
    } else {
      toReturn = 'n/a'
    }

    if (toReturn === 'n/a') {
      return toReturn
    }
    return toReturn
  }

  areaLoadStr() {
    const {vmCalc} = this.state

    let toReturn = 'n/a'

    if (vmCalc.surfaceValue === 'H2O') {
      if (vmCalc.height < 1.33) {
        toReturn = (0.83 + 1.75 * vmCalc.height) * (1.67 + 1.75 * vmCalc.height)
      } else if (vmCalc.height < 4.1) {
        toReturn = (0.83 + 1.75 * vmCalc.height) * (5.67 + 1.75 * vmCalc.height)
      } else if (vmCalc.height >= 4.1) {
        toReturn = (4.83 + 1.75 * vmCalc.height) * (5.67 + 1.75 * vmCalc.height)
      } else {
        toReturn = 'n/a'
      }
    }

    if (toReturn === 'n/a') {
      return toReturn
    }

    return toReturn
  }

  loadWheelStr() {
    const {vmCalc} = this.state

    let toReturn = 'n/a'

    if (vmCalc.surfaceValue === 'H2O') {
      if (vmCalc.height < 1.33) {
        toReturn = 16000
      } else if (vmCalc.height < 4.1) {
        toReturn = 32000
      } else if (vmCalc.height >= 4.1) {
        toReturn = 48000
      } else {
        toReturn = 'n/a'
      }
    }

    if (toReturn === 'n/a') {
      return toReturn
    }

    return toReturn
  }

  loadLiveStr() {
    let toReturn = 0

    if (
      this.loadWheelStr() !== 'n/a' &&
      this.areaLoadStr() !== 'n/a' &&
      this.factorImpactStr() !== 'n/a'
    ) {
      const loadWheel = parseInt(this.loadWheelStr(), 10)
      const areaLoad = parseFloat(this.areaLoadStr(), 10)
      const factorImpact = parseFloat(this.factorImpactStr(), 10)

      toReturn = (loadWheel * factorImpact) / areaLoad / 144
    }

    return toReturn
  }

  loadLiveE80Str() {
    const {vmCalc} = this.state
    let toReturn = 'n/a'
    let precision = ''
    const e = Math.E
    const toGetDifference = 27.469
    const theDivisor = -9.255

    if (vmCalc.surfaceValue === 'E80') {
      precision = (vmCalc.height - toGetDifference) / theDivisor
      toReturn = e ** precision
    } else {
      toReturn = 0.0
    }

    return toReturn
  }

  depthCoverTopPipeStr() {
    const {vmCalc} = this.state
    const toReturn = vmCalc.height - vmCalc.thickPave / 12
    return toReturn
  }

  radiusRigidPaveStr() {
    const {vmCalc} = this.state

    const toReturn =
      ((vmCalc.modElastConcrete * vmCalc.thickPave ** 3) /
        (12 * (1 - vmCalc.poissonsConcrete ** 2) * vmCalc.moduSubReact)) **
        0.25 /
      12
    return toReturn
  }

  hrCoefficientStr() {
    const toReturn = this.depthCoverTopPipeStr() / this.radiusRigidPaveStr()
    return toReturn
  }

  cCoefficientStr() {
    const hrCoefficient = this.hrCoefficientStr()

    const toReturn =
      -0.0005 * hrCoefficient ** 3 + 0.0101 * hrCoefficient ** 2 - 0.0756 * hrCoefficient + 0.2162

    return toReturn
  }

  airportLoadStr() {
    const {vmCalc} = this.state
    let toReturn = 0.0
    const cCoefficient = this.cCoefficientStr()
    const radiusRigid = this.radiusRigidPaveStr()

    if (vmCalc.surfaceValue === 'airport') {
      toReturn = (vmCalc.wheelLoad * cCoefficient) / radiusRigid ** 2 / 144
    } else {
      toReturn = 0.0
    }

    return toReturn
  }

  ovalityReductionFactorStr() {
    const {vmCalc} = this.state
    const toReturn = ((1 - vmCalc.ovality / 100) / (1 + vmCalc.ovality / 100) ** 2) ** 3

    return toReturn
  }

  extHydroPressureStr() {
    const {vmCalc} = this.state

    let toReturn = 0.0

    if (vmCalc.waterHeight + vmCalc.diameter / 12 > 0) {
      toReturn = (vmCalc.waterWeight * (vmCalc.waterHeight + vmCalc.diameter / 12)) / 144
    }

    return toReturn
  }

  minThickHydroBucklingINStr() {
    const {vmCalc} = this.state
    let toReturn = 0.0
    const extHydroPressure = this.extHydroPressureStr()
    const elastiret = this.elastiretStr()
    const ovality = this.ovalityReductionFactorStr()

    if (extHydroPressure > 0) {
      toReturn =
        vmCalc.diameter /
        (((2 * vmCalc.eFactor * elastiret * ovality) /
          ((1 - vmCalc.cippPr ** 2) * vmCalc.safety * (vmCalc.ivp + extHydroPressure))) **
          (1 / 3) +
          1)
    }

    return toReturn
  }

  minThickHydroBucklingMMStr() {
    const toReturn = this.minThickHydroBucklingINStr() * 25.4
    return toReturn
  }

  quadraticAStr() {
    const {vmCalc} = this.state
    return ((1.5 * vmCalc.ovality) / 100) * (1 + vmCalc.ovality / 100)
  }

  quadraticBStr() {
    const {vmCalc} = this.state
    return -0.5 * (1 + vmCalc.ovality / 100)
  }

  quadraticCStr() {
    const {vmCalc} = this.state
    const strengthFlt = this.strengthFltStr()
    const extHydroPressure = this.extHydroPressureStr()

    return (strengthFlt * -1) / (extHydroPressure * vmCalc.safety)
  }

  sdr2Str() {
    let toReturn = 0.0
    const extHydroPressure = this.extHydroPressureStr()
    const quadraticA = this.quadraticAStr()
    const quadraticB = this.quadraticBStr()
    const quadraticC = this.quadraticCStr()

    if (extHydroPressure === 0) {
      toReturn = 100
    } else {
      toReturn =
        quadraticB * -1 + (quadraticB ** 2 - 4 * quadraticA * quadraticC) ** 0.5 / (2 * quadraticA)
    }
    return toReturn
  }

  minThickOvalityInStr() {
    const {vmCalc} = this.state
    const toReturn = vmCalc.diameter / this.sdr2Str()

    return toReturn
  }

  minThickOvalityMMStr() {
    const toReturn = this.minThickOvalityInStr() * 25.4
    return toReturn
  }

  totalLiveLoadStr() {
    const loadLive = this.loadLiveStr()
    const loadLiveE80 = this.loadLiveE80Str()
    const airportLoad = this.airportLoadStr()

    const toReturn = loadLive * 1 + loadLiveE80 * 1 + airportLoad * 1

    return toReturn
  }

  waterBouyancyFactorStr() {
    const {vmCalc} = this.state
    let toReturn = 1.0

    if (vmCalc.waterHeight >= 0) {
      if (1 - 0.33 * (vmCalc.waterHeight / vmCalc.height) >= 0.67) {
        toReturn = 1 - 0.33 * (vmCalc.waterHeight / vmCalc.height)
      } else {
        toReturn = 0.67
      }
    }

    return toReturn
  }

  totalExtPressureStr() {
    const {vmCalc} = this.state
    const extHydroPressure = this.extHydroPressureStr()
    const waterBouyancy = this.waterBouyancyFactorStr()
    const totalLiveLoad = this.totalLiveLoadStr()
    const toReturn =
      extHydroPressure * 1 + (vmCalc.soil * vmCalc.height * waterBouyancy) / 144 + totalLiveLoad

    return toReturn
  }

  coefficientElastiSupportStr() {
    const {vmCalc} = this.state

    const toReturn = 1 / (1 + 4 * Math.E ** (-0.065 * vmCalc.height))

    return toReturn
  }

  minThickLuschersINStr() {
    const {vmCalc} = this.state
    const totalExtPressure = this.totalExtPressureStr()
    const elastiret = this.elastiretStr()
    const ovalityReduction = this.ovalityReductionFactorStr()
    const waterBouyancy = this.waterBouyancyFactorStr()
    const coefficientElastiSupport = this.coefficientElastiSupportStr()

    const toReturn =
      (((totalExtPressure * vmCalc.safety) ** 2 * vmCalc.diameter ** 3 * 12) /
        (32 *
          waterBouyancy *
          coefficientElastiSupport *
          vmCalc.modulus *
          elastiret *
          ovalityReduction)) **
      (1 / 3)

    return toReturn
  }

  minThickLuschersMMStr() {
    const toReturn = this.minThickLuschersINStr() * 25.4

    return toReturn
  }

  minThickPipeStiffINStr() {
    const {vmCalc} = this.state

    const toReturn = vmCalc.diameter / (vmCalc.elastic / (0.093 * 12)) ** (1 / 3)

    return toReturn
  }

  minThickPipeStiffMMStr() {
    const toReturn = this.minThickPipeStiffINStr() * 25.4
    return toReturn
  }

  minThickIntPressureStr() {
    let toReturn = 0.0
    const {vmCalc} = this.state
    const strengthFlt = this.strengthFltStr()

    if (vmCalc.ip !== 0) {
      toReturn =
        vmCalc.diameter /
        (((vmCalc.diameter / vmCalc.diameterOpening) ** 2 *
          ((5.33 * strengthFlt) / (vmCalc.ip * vmCalc.safety))) **
          0.5 +
          1)
    }

    return toReturn
  }

  dDValStr() {
    const {vmCalc} = this.state

    const toReturn = vmCalc.diameterOpening / vmCalc.diameter

    return toReturn
  }

  minThicktprDStr() {
    const {vmCalc} = this.state
    const minThickIntPressure = this.minThickIntPressureStr()

    const toReturn = 1.83 * (minThickIntPressure / vmCalc.diameter) ** 0.5

    return toReturn
  }

  minThickReqWithExtINStr() {
    const {vmCalc} = this.state
    let toReturn = 0.0
    const strengthTlt = this.strengthTltStr()

    if (vmCalc.ip !== 0) {
      toReturn = vmCalc.diameter / ((2 * strengthTlt) / (vmCalc.ip * vmCalc.safety) + 2)
    }

    return toReturn
  }

  minThickReqWithExtMMStr() {
    const toReturn = this.minThickReqWithExtINStr() * 25.4

    return toReturn
  }

  minThickReqDesignINStr() {
    let toReturn = 0.0
    const dDVal = this.dDValStr()
    const minThicktprD = this.minThicktprDStr()
    const minThickIntPressure = this.minThickIntPressureStr()
    const minThickReqWithExtIN = this.minThickReqWithExtINStr()

    if (dDVal < minThicktprD) {
      toReturn = minThickIntPressure
    } else {
      toReturn = minThickReqWithExtIN
    }

    return toReturn
  }

  minThickReqDesignMMStr() {
    const toReturn = this.minThickReqDesignINStr() * 25.4

    return toReturn
  }

  conditionHostPipeStr() {
    const {vmCalc} = this.state
    let toReturn = 'Fully Deteriorated'

    if (vmCalc.hostPipeCondition === 'Partially Deteriorated') {
      toReturn = 'Partially Deteriorated'
    }

    return toReturn
  }

  endUseStr() {
    const {vmCalc} = this.state
    let toReturn = 'Gravity Flow'

    if (vmCalc.ip > 0) {
      toReturn = 'Pressure Pipe'
    }

    return toReturn
  }

  diamStr() {
    const {vmCalc} = this.state

    const toReturn = vmCalc.diameter

    return toReturn
  }

  minCIPPThickCalcINStr() {
    let toReturn = 0.0
    const hostPipeCondition = this.conditionHostPipeStr()
    const minThickHydroBucklingIN = this.minThickHydroBucklingINStr()
    const minThickOvalityIn = this.minThickOvalityInStr()
    const minThickReqDesignIN = this.minThickReqDesignINStr()
    const minThickLuschersIN = this.minThickLuschersINStr()
    const minThickPipeStiffIN = this.minThickPipeStiffINStr()

    if (hostPipeCondition === 'Partially Deteriorated') {
      toReturn = Math.max(minThickHydroBucklingIN, minThickOvalityIn, minThickReqDesignIN)
    } else {
      toReturn = Math.max(
        minThickHydroBucklingIN,
        minThickOvalityIn,
        minThickReqDesignIN,
        minThickLuschersIN,
        minThickPipeStiffIN,
      )
    }
    return toReturn
  }

  minCIPPThickCalcMMStr() {
    const toReturn = this.minCIPPThickCalcINStr() * 25.4

    return toReturn
  }

  minCIPPThickRecommendINStr() {
    const {vmCalc} = this.state
    let toReturn = 0.0
    const minCIPPThickCalcIN = this.minCIPPThickCalcINStr()

    if (vmCalc.diameter / minCIPPThickCalcIN > 100) {
      toReturn = vmCalc.diameter / 100
    } else {
      toReturn = minCIPPThickCalcIN
    }

    return toReturn
  }

  minCIPPThickRecommendMMStr() {
    const toReturn = this.minCIPPThickRecommendINStr() * 25.4

    return toReturn
  }

  nominalCIPPThickStr() {
    let toReturn = 4.5
    const minCIPPThickRecommendMM = this.minCIPPThickRecommendMMStr()
    let i = 4.5
    let breakOut = false

    if (minCIPPThickRecommendMM >= 82.5) {
      toReturn = 82.5
    } else if (minCIPPThickRecommendMM > 4.5) {
      while (i <= 81.0 && !breakOut) {
        if (minCIPPThickRecommendMM > i && minCIPPThickRecommendMM < i + 1.5) {
          toReturn = i + 1.5
          breakOut = true
        } else if (minCIPPThickRecommendMM === i) {
          toReturn = i
          breakOut = true
        }
        i += 1.5
      }
    }
    return toReturn
  }

  sdrCIPPStr() {
    const {vmCalc} = this.state
    const toReturn = vmCalc.diameter / (this.nominalCIPPThickStr() / 25.4)

    return toReturn
  }

  diameterInsideCIPPStr() {
    const {vmCalc} = this.state
    const nominalCIPPThick = this.nominalCIPPThickStr()

    const toReturn = vmCalc.diameter - (2 * nominalCIPPThick) / 25.4

    return toReturn
  }

  flowAreaHostPipeStr() {
    const {vmCalc} = this.state

    const toReturn = ((Math.PI / 4) * vmCalc.diameter ** 2) / 144

    return toReturn
  }

  flowAreaCIPPStr() {
    const diameterInsideCIPP = this.diameterInsideCIPPStr()

    const toReturn = ((Math.PI / 4) * diameterInsideCIPP ** 2) / 144

    return toReturn
  }

  capHostPipeStr() {
    const {vmCalc} = this.state
    const flowAreaHostPipe = this.flowAreaHostPipeStr()

    const toReturn =
      (1.486 * flowAreaHostPipe * (vmCalc.diameter / 4 / 12) ** (2 / 3) * vmCalc.slope ** 0.5) /
      vmCalc.hpRoughness

    return toReturn
  }

  capCIPPStr() {
    const {vmCalc} = this.state
    const flowAreaCIPP = this.flowAreaCIPPStr()
    const diameterInsideCIPP = this.diameterInsideCIPPStr()

    const toReturn =
      (1.486 * flowAreaCIPP * (diameterInsideCIPP / 4 / 12) ** (2 / 3) * vmCalc.slope ** 0.5) /
      vmCalc.cpRoughness

    return toReturn
  }

  percentFlowStr() {
    const capCIPP = this.capCIPPStr()
    const capHostPipe = this.capHostPipeStr()

    const toReturn = (capCIPP - capHostPipe) / capHostPipe

    return toReturn * 100
  }

  onChange(value, fieldName) {
    this.setState(state => {
      return {vmCalc: {...state.vmCalc, [fieldName]: value}}
    })
  }

  onChangeNonNumber(e, fieldName) {
    e.persist()
    this.setState(state => {
      return {vmCalc: {...state.vmCalc, [fieldName]: e.target.value}}
    })
  }

  componentDidMount() {
    PageUtils.scrollToTop()
    if (!UserSession.getRequestSubmitted()) {
      this.props.router.push('/cipp-calculator-request')
    }
  }

  render() {
    const {classes} = this.props
    const {vmCalc} = this.state
    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={videosHeader} style={{width: '100%', height: '500', objectFit: 'cover'}} />
          <div className={classes.headerFloatContainer}>
            <h2>CIPP Calculator</h2>
            <p>
              Cured-In-Place Pipe (CIPP) Wall Thickness Design and Hydraulic Capacity Calculations
            </p>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} style={{margin: '80px auto 20px'}} />
            </div>
          </div>
        </Grid>
        <Grid item xs={11}>
          <Paper className={classes.paper}>
            <p style={{padding: '10px'}}>
              <span style={{color: 'red'}}>*</span> Required
            </p>
            <Grid container justify="center">
              <Grid item md={3} xs={12}>
                <InputField label="Project" />
              </Grid>
              <Grid item md={3} xs={12}>
                <InputField label="Line Segment(s)" />
              </Grid>
              <Grid item md={3} xs={12}>
                <InputField label="Location" />
              </Grid>
              <Grid item md={3} xs={12}>
                <InputField label="Owner" />
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>Design Assumptions</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <SelectField
                  required
                  label="Condition of Host Pipe"
                  options={[
                    {key: 'Partially Deteriorated', label: 'Partially Deteriorated'},
                    {key: 'Fully Deteriorated', label: 'Fully Deteriorated'},
                  ]}
                  value={vmCalc.hostPipeCondition}
                  id="hostPipeCondition"
                  onChange={e => {
                    this.onChangeNonNumber(e, 'hostPipeCondition')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Inside Diameter of Host Pipe (in) D ="
                  type="number"
                  required
                  placeholder={vmCalc.diameter}
                  value={vmCalc.diameter}
                  id="diameter"
                  onChange={e => {
                    this.onChange(e, 'diameter')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Ovality of Host Pipe (%) q ="
                  type="number"
                  required
                  placeholder={vmCalc.ovality}
                  value={vmCalc.ovality}
                  id="ovality"
                  onChange={e => {
                    this.onChange(e, 'ovality')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Slope of Host Pipe (ft/ft) S ="
                  type="number"
                  placeholder={vmCalc.slope}
                  value={vmCalc.slope}
                  id="slope"
                  onChange={e => {
                    this.onChange(e, 'slope')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Host Pipe Manning's Roughness (dimensionless) ni ="
                  type="number"
                  placeholder={vmCalc.hpRoughness}
                  value={vmCalc.hpRoughness}
                  id="hpRoughness"
                  onChange={e => {
                    this.onChange(e, 'hpRoughness')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="CIPP Manning's Roughness (dimensionless) nf ="
                  type="number"
                  placeholder={vmCalc.cpRoughness}
                  value={vmCalc.cpRoughness}
                  id="cpRoughness"
                  onChange={e => {
                    this.onChange(e, 'cpRoughness')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  label="Constrained Soil Modules of Native Soil in the Pipe Zone (psi) Msn ="
                  type="number"
                  required
                  placeholder={this.numberFormat(vmCalc.modulus)}
                  value={vmCalc.modulus}
                  id="modulus"
                  onChange={value => {
                    this.onChange(value, 'modulus')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={4} xs={12}>
                <Image src={math1} />
              </Grid>
              <Grid item md={8} xs={12}>
                <Image src={table1} />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Flexural Modules of Elasticity of CIPP, Initial (psi) E ="
                  type="number"
                  value={vmCalc.elastic}
                  required
                  placeholder={`${this.numberFormat(
                    vmCalc.elastic,
                  )}  |  Minimum Value is 250,000 psi per ASTM F1216`}
                  id="elastic"
                  onChange={e => {
                    this.onChange(e, 'elastic')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Long-Term Retention of Mechanical Properties (%)"
                  type="number"
                  required
                  value={vmCalc.retent}
                  placeholder={`${vmCalc.retent}%  | Default Value is 50%`}
                  id="retent"
                  onChange={e => {
                    this.onChange(e, 'retent')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="elastiretStr"
                  label="Flexural Modules of Elasticity of CIPP, Long-Term (psi) EL ="
                  placeholder={`${this.numberFormat(
                    this.elastiretStr().toFixed(0),
                  )}  |  Determined from Long-Term Retention %`}
                  disabled={true}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Design Safety Factor N ="
                  type="number"
                  required
                  value={vmCalc.safety}
                  placeholder={`${vmCalc.safety}  |  Default Value is 2.0`}
                  id="safety"
                  onChange={e => {
                    this.onChange(e, 'safety')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Unit Weight of Soil (pcf) δs ="
                  type="number"
                  required
                  value={vmCalc.soil}
                  placeholder={`${
                    vmCalc.soil
                  }  |  Default Value is 120. Applies to Fully Deteriorated Designs Only`}
                  id="soil"
                  onChange={e => {
                    this.onChange(e, 'soil')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Unit Weight of Water (pcf) δw ="
                  type="number"
                  value={vmCalc.waterWeight}
                  placeholder={vmCalc.waterWeight}
                  id="waterWeight"
                  onChange={e => {
                    this.onChange(e, 'waterWeight')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Depth of Cover (ft) H ="
                  required
                  type="number"
                  value={vmCalc.height}
                  placeholder={`${vmCalc.height}  |  Measured from Ground Surface to Top of Pipe`}
                  id="height"
                  onChange={e => {
                    this.onChange(e, 'height')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Height of Groundwater Hw ="
                  type="number"
                  required
                  value={vmCalc.waterHeight}
                  placeholder={`${
                    vmCalc.waterHeight
                  }  |  Measured From Top of Pipe; Note: if water table is below top of pipe, input a negative number!`}
                  id="waterHeight"
                  onChange={e => {
                    this.onChange(e, 'waterHeight')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Internal Vacuum Pressure (psi) Pv ="
                  type="number"
                  value={vmCalc.ivp}
                  placeholder={`${vmCalc.ivp}  |  Default Value is 0`}
                  id="ivp"
                  onChange={e => {
                    this.onChange(e, 'ivp')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Internal Pressure (psi) P ="
                  type="number"
                  value={vmCalc.ip}
                  placeholder={`${
                    vmCalc.ip
                  }  |  Pressure Pipe Applications only! If no Pressure, Input 0`}
                  id="ip"
                  onChange={e => {
                    this.onChange(e, 'ip')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Diameter of Hole or Opening in Original Pipe Wall (in) d ="
                  type="number"
                  value={vmCalc.diameterOpening}
                  placeholder={`${
                    vmCalc.diameterOpening
                  }  |  Default Value is 2.0. Pressure Pipe Applications Only! If no Pressure, Input 0`}
                  id="diameterOpening"
                  onChange={e => {
                    this.onChange(e, 'diameterOpening')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Poisson's Ratio of CIPP v ="
                  type="number"
                  value={vmCalc.cippPr}
                  placeholder={`${vmCalc.cippPr}  |  Average Value for CIPP per ASTM F1216`}
                  id="cippPr"
                  onChange={e => {
                    this.onChange(e, 'cippPr')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Flexural Strength of CIPP, Initial (psi) σi ="
                  type="number"
                  required
                  value={vmCalc.strengthFi}
                  placeholder={`${this.numberFormat(
                    vmCalc.strengthFi,
                  )}  |  Minimum Value is 4,500 psi per ASTM F1216`}
                  id="strengthFi"
                  onChange={e => {
                    this.onChange(e, 'strengthFi')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="strengthFltStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Flexural Strength of CIPP, Long-term (psi) σL ="
                  placeholder={`${this.numberFormat(
                    this.strengthFltStr().toFixed(0),
                  )}  |  Determined from Long-term Retention %`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Tensile Strength of CIPP, Initial (psi) σT ="
                  type="number"
                  required
                  value={vmCalc.strengthTi}
                  placeholder={`${this.numberFormat(
                    vmCalc.strengthTi,
                  )} |  Pressure Pipe Applications Only!`}
                  id="strengthTi"
                  onChange={e => {
                    this.onChange(e, 'strengthTi')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="strengthTltStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Tensile Strength of CIPP, Long-term (psi) σTL ="
                  placeholder={`${this.numberFormat(
                    this.strengthTltStr().toFixed(0),
                  )}  |  Determined from Long-term Retention %`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Enhancement Factor (Dimensionless) K ="
                  type="number"
                  value={vmCalc.eFactor}
                  placeholder={`${vmCalc.eFactor}  |  Minimum Value Recommended per ASTM F1216`}
                  id="eFactor"
                  onChange={e => {
                    this.onChange(e, 'eFactor')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectField
                  required
                  label="Surface Live Loading Condition"
                  options={[
                    {key: 'H2O', label: 'H2O'},
                    {key: 'E80', label: 'E80'},
                    {key: 'airport', label: 'airport'},
                  ]}
                  value={vmCalc.surfaceValue}
                  id="surfaceValue"
                  onChange={e => {
                    this.onChangeNonNumber(e, 'surfaceValue')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>H2O Calculations</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="factorImpactStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  label="Impact Factor for Traffic Load (dimensionless) lf ="
                  disabled={true}
                  placeholder={`${
                    this.factorImpactStr() === 'n/a'
                      ? this.factorImpactStr()
                      : this.numberFormat(this.factorImpactStr().toFixed(1))
                  }  |  From AASHTO Standard Specifications for Highway Bridges, 12th Edition`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="areaLoadStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Distributed Load Area Over Pipe at Depth H (ft^2) ALL ="
                  placeholder={`${
                    this.areaLoadStr() === 'n/a'
                      ? this.areaLoadStr()
                      : this.numberFormat(this.areaLoadStr().toFixed(1))
                  }  |  See Table and Figures Below`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="loadWheelStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Total Applied Surface Wheel Load (lb) PLW ="
                  placeholder={`${
                    this.loadWheelStr() === 'n/a'
                      ? this.loadWheelStr()
                      : this.numberFormat(this.loadWheelStr().toFixed(0))
                  }  |  Based on Critical Loading Cofiguration (See Table Below)`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="loadLiveStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="H2O Live Load Transferred to Pipe at Depth H (psi) WLH ="
                  placeholder={`${
                    this.loadLiveStr() === 'n/a'
                      ? this.loadLiveStr()
                      : this.numberFormat(this.loadLiveStr().toFixed(1))
                  }  |  WL = (P*l f )/144*A    LL)`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={10}>
                <Image src={table2} />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={10} xs={12}>
                <Grid container justify="center">
                  <Grid item md={4} xs={12}>
                    <Image src={condition1} height={200} />
                    <p>
                      <b>Condition 1</b> - Distributed Load Area - Single Dual Wheel{' '}
                    </p>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Image src={condition2} height={200} />
                    <p>
                      <b>Condition 2</b> - Distributed Load Area - Two H2O Trucks Passing{' '}
                    </p>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Image src={condition3} height={200} />
                    <p>
                      <b>Condition 3</b> - Distributed Load Area - Alternate Loads in Passing Mode
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>E80 Calculations (Railway Loads)</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item xs={12}>
                <InputField
                  id="loadLiveE80Str"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="E80 Live Load Transferred to Pipe at Depth H (psi) WLR ="
                  placeholder={`${
                    this.loadLiveE80Str() === 'n/a'
                      ? this.loadLiveE80Str()
                      : this.numberFormat(this.loadLiveE80Str().toFixed(2))
                  }  |  From Graph Below`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={10}>
                <Grid container justify="center">
                  <Grid item xs={6}>
                    <Image src={liveload} />
                  </Grid>
                  <Grid item xs={6}>
                    <Image src={fig4} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>Airport Loads</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Modules of Elasticity of Concrete (psi) Ec ="
                  type="number"
                  value={vmCalc.modElastConcrete}
                  placeholder={`${this.numberFormat(
                    vmCalc.modElastConcrete,
                  )}  |  Default Value is 4,000,000`}
                  id="modElastConcrete"
                  onChange={e => {
                    this.onChange(e, 'modElastConcrete')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Poisson's Ratio of Concrete (Dimensionless) µ ="
                  type="number"
                  value={vmCalc.poissonsConcrete}
                  placeholder={`${vmCalc.poissonsConcrete}  |  Default Value is .15`}
                  id="poissonsConcrete"
                  onChange={e => {
                    this.onChange(e, 'poissonsConcrete')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Modules of Subgrade Reaction (lb/in^3) k ="
                  type="number"
                  value={vmCalc.moduSubReact}
                  placeholder={`${
                    vmCalc.moduSubReact
                  }  |  Based on Critical Loading Cofiguration (See Table Below)`}
                  id="moduSubReact"
                  onChange={e => {
                    this.onChange(e, 'moduSubReact')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Thickness of Concrete Pavement (in) h ="
                  type="number"
                  required
                  value={vmCalc.thickPave}
                  placeholder={vmCalc.thickPave}
                  id="thickPave"
                  onChange={e => {
                    this.onChange(e, 'thickPave')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="depthCoverTopPipeStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Depth of Cover, Top of Pipe to Bottom of Slab (ft) H ="
                  placeholder={this.numberFormat(this.depthCoverTopPipeStr().toFixed(1))}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="Horizontal Distance from Pipe Centerline (ft) X ="
                  type="number"
                  value={vmCalc.horizDistCenter}
                  placeholder={`${vmCalc.horizDistCenter}  |  Assumed = 0 (Worst Case)`}
                  id="horizDistCenter"
                  onChange={e => {
                    this.onChange(e, 'horizDistCenter')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="radiusRigidPaveStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Radius of Stiffness of the Rigid Pavement (ft) Rs ="
                  placeholder={this.numberFormat(this.radiusRigidPaveStr().toFixed(2))}
                  secondaryLabel={<img src={radiusStiffness} className={classes.radiusStiffness} />}
                />
              </Grid>

              <Grid item md={6} xs={12} className={classes.radiusStiffnessSide}>
                <InputField
                  label="Wheel Load (lbs) PLA ="
                  type="number"
                  required
                  value={vmCalc.wheelLoad}
                  placeholder={this.numberFormat(vmCalc.wheelLoad)}
                  id="wheelLoad"
                  onChange={e => {
                    this.onChange(e, 'wheelLoad')
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={8} xs={12}>
                <Grid container justify="center">
                  <p>
                    Worst-case loading scenario is for 2 loads spaced 0.8R apart and is calculated
                    below. For alternate loading conditions, see Concrete Pipe Handbook, 5th
                    Edition.
                  </p>
                </Grid>
                <Grid container justify="center">
                  <Grid item md={8} xs={12}>
                    <Image src={pressure} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Image src={axis} />
                    <InputField
                      id="hrCoefficientStr"
                      style={{backgroundColor: '#d3d3d3'}}
                      disabled={true}
                      placeholder={`${this.numberFormat(
                        this.hrCoefficientStr().toFixed(3),
                      )}  |  H/R s=`}
                    />
                    <InputField
                      id="cCoefficientStr"
                      style={{backgroundColor: '#d3d3d3'}}
                      disabled={true}
                      placeholder={`${this.numberFormat(this.cCoefficientStr().toFixed(3))}  |  C=`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12}>
                <Image src={cbr} />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="airportLoadStr"
                  label="Airport Live Load Transferred to Pipe at Depth H (psi) PLA ="
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={this.numberFormat(this.airportLoadStr().toFixed(2))}
                  secondaryLabel={
                    <img src={airportLoad} height={45} className={classes.airportLoad} />
                  }
                />
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>Wall Thickness Design</SectionTitle>
              <SectionTitle style={{fontSize: 19, margin: 0}}>
                Partially Deteriorated Gravity Pipe Condition
              </SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="ovalityReductionFactorStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Ovality Reduction Factor (dimensionless) C ="
                  placeholder={this.numberFormat(this.ovalityReductionFactorStr().toFixed(2))}
                  secondaryLabel={<img src={formula1} className={classes.formula1} />}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.formula1Side}>
                <InputField
                  id="extHydroPressureStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="External Hydrostatic Pressure From Groundwater (psi) Pw ="
                  placeholder={this.numberFormat(this.extHydroPressureStr().toFixed(2))}
                  secondaryLabel={<img src={psi} className={classes.psi} />}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickHydroBucklingINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required, Hydrostatic Buckling t1 ="
                  placeholder={`${this.numberFormat(
                    this.minThickHydroBucklingINStr().toFixed(2),
                  )}  |  in`}
                  secondaryLabel={<img src={equationX1} className={classes.equationX1} />}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.equationX1Side}>
                <InputField
                  label=""
                  id="minThickHydroBucklingMMStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.minThickHydroBucklingMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickOvalityInStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required, Ovality Check t2 ="
                  placeholder={`${this.numberFormat(
                    this.minThickOvalityInStr().toFixed(2),
                  )}  |  in`}
                  secondaryLabel={<img src={equationX2} className={classes.equationX2} />}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.equationX2Side}>
                <InputField
                  label=""
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  id="minThickOvalityMMStr"
                  placeholder={`${this.numberFormat(
                    this.minThickOvalityMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  label="SDR2 ="
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  id="sdr2Str"
                  placeholder={this.numberFormat(this.sdr2Str().toFixed(0))}
                  secondaryLabel={<img src={ifPW} className={classes.ifPW} />}
                />
              </Grid>
            </Grid>

            <Grid container style={{marginBottom: '-25px'}}>
              <SectionTitle>Fully Deteriorated Gravity Pipe Condition</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12} className={classes.bouyancySide}>
                <InputField
                  id="totalLiveLoadStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Total Live Load Transferred to Pipe at Depth H (psi) Ws ="
                  placeholder={`${this.numberFormat(
                    this.totalLiveLoadStr().toFixed(2),
                  )}  |  From Live Load Calculations`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="waterBouyancyFactorStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Water Bouyancy Factor (dimensionless) Rw ="
                  placeholder={this.numberFormat(this.waterBouyancyFactorStr().toFixed(2))}
                  secondaryLabel={<img src={bouyancyFactor} className={classes.bouyancy} />}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="totalExtPressureStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Total External Pressure on Pipe (psi) qt ="
                  placeholder={this.numberFormat(this.totalExtPressureStr().toFixed(2))}
                  secondaryLabel={
                    <img src={externalPressure} className={classes.externalPressure} />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="coefficientElastiSupportStr"
                  label="Coefficient of Elastic Support (in-lb) B' ="
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={this.numberFormat(this.coefficientElastiSupportStr().toFixed(3))}
                  secondaryLabel={<img src={elasticSupport} className={classes.elasticSupport} />}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickLuschersINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required, Luscher's Buckling Equation t3 ="
                  placeholder={`${this.numberFormat(
                    this.minThickLuschersINStr().toFixed(2),
                  )}  |  in`}
                  secondaryLabel={<img src={equationX3} className={classes.equationX3} />}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.equationX3Side}>
                <InputField
                  label=""
                  id="minThickLuschersMMStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.minThickLuschersMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickPipeStiffINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required, Pipe Stiffness t4 ="
                  placeholder={`${this.numberFormat(
                    this.minThickPipeStiffINStr().toFixed(2),
                  )}  |  in`}
                  secondaryLabel={<img src={equationX4} className={classes.equationX4} />}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.equationX4Side}>
                <InputField
                  label=""
                  disabled={true}
                  style={{backgroundColor: '#d3d3d3'}}
                  id="minThickPipeStiffMMStr"
                  placeholder={`${this.numberFormat(
                    this.minThickPipeStiffMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container style={{padding: '10px'}}>
              <SectionTitle>Partially Deteriorated Pressure Pipe Condition</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickIntPressureStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required to Withstand Internal Pressure tpr ="
                  placeholder={`${this.numberFormat(
                    this.minThickIntPressureStr().toFixed(2),
                  )}  |  in`}
                  secondaryLabel={<img src={equationX6} className={classes.equationX6} />}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="dDValStr"
                  disabled={true}
                  style={{backgroundColor: '#d3d3d3'}}
                  label="Minimum Thickness Required to Withstand Internal Pressure d/D ="
                  placeholder={`${this.numberFormat(this.dDValStr().toFixed(2))}  |  d/D`}
                  secondaryLabel={<img src={equationX5} className={classes.equationX5} />}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.equationX5Side}>
                <InputField
                  id="minThicktprDStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label=""
                  placeholder={`${this.numberFormat(
                    this.minThicktprDStr().toFixed(2),
                  )}  |  1.83(tpr/D)^1/2`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickReqDesignINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required by Design Check tpr1 ="
                  placeholder={`${this.numberFormat(
                    this.minThickReqDesignINStr().toFixed(2),
                  )}  |  Tpr1=in`}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.thicknessDesign}>
                <InputField
                  label=""
                  id="minThickReqDesignMMStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.minThickReqDesignMMStr().toFixed(2),
                  )}  |  Tpr2=mm`}
                />
              </Grid>
            </Grid>

            <Grid container style={{padding: '10px'}}>
              <SectionTitle>Fully Deteriorated Pressure Pipe Condition</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minThickReqWithExtINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum Thickness Required to Withstand all External Loads and the Full Internal Pressure tpr2 ="
                  placeholder={`${this.numberFormat(
                    this.minThickReqWithExtINStr().toFixed(2),
                  )}  |  Tpr2=in`}
                  secondaryLabel={<img src={equationX7} className={classes.equationX7} />}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.equationX7Side}>
                <InputField
                  id="minThickReqWithExtMMStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.minThickReqWithExtMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>CIPP Wall Thickness Design Summary</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  label="Host Pipe Condition"
                  id="conditionHostPipeStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={this.conditionHostPipeStr()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  label="CIPP End Use Application"
                  id="endUseStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={this.endUseStr()}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="diamStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="CIPP Outside Diameter (Host Pipe Inside Diameter) D ="
                  placeholder={`${this.numberFormat(this.diamStr())}  |  D = in`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minCIPPThickCalcINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum CIPP Thickness Calculated tcalc ="
                  placeholder={`${this.numberFormat(
                    this.minCIPPThickCalcINStr().toFixed(2),
                  )}  |  Tcalc = in`}
                  secondaryLabel={<img src={thicknessCal} className={classes.thicknessCal} />}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.thicknessCalSide}>
                <InputField
                  label=""
                  id="minCIPPThickCalcMMStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.minCIPPThickCalcMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={12} xs={12}>
                <InputField
                  id="minCIPPThickRecommendINStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum CIPP Thickness Recommended tmin ="
                  placeholder={`${this.numberFormat(
                    this.minCIPPThickRecommendINStr().toFixed(2),
                  )}  |  Tmin = in`}
                  secondaryLabel={
                    <img src={thicknessRecommended} className={classes.thicknessRecommended} />
                  }
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.thicknessRecommendedSide}>
                <InputField
                  label=""
                  id="minCIPPThickRecommendMMStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.minCIPPThickRecommendMMStr().toFixed(2),
                  )}  |  mm`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="nominalCIPPThickStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Minimum CIPP Thickness to be Supplied tcipp ="
                  placeholder={`${this.numberFormat(this.nominalCIPPThickStr())}  |  tCIPP = mm`}
                  secondaryLabel={
                    <img src={thicknessSupplied} className={classes.thicknessSupplied} />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.thicknessSuppliedSide}>
                <InputField
                  label=""
                  id="sdrCIPPStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(
                    this.sdrCIPPStr().toFixed(0),
                  )}  |  SDRcipp = SDR`}
                />
              </Grid>
            </Grid>

            <Grid container>
              <SectionTitle>Hydraulic Calculations</SectionTitle>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="diameterInsideCIPPStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="CIPP Inside Diameter (in) Df ="
                  placeholder={`${this.numberFormat(
                    this.diameterInsideCIPPStr().toFixed(1),
                  )}  |  Df =`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="flowAreaHostPipeStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Flow Area of Host Pipe (ft^2) Ai ="
                  placeholder={`${this.numberFormat(
                    this.flowAreaHostPipeStr().toFixed(2),
                  )}  |  Ai=`}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12} className={classes.hostPipeCapacitySide}>
                <InputField
                  id="flowAreaCIPPStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Flow Area of CIPP (ft^2) Af ="
                  placeholder={`${this.numberFormat(this.flowAreaCIPPStr().toFixed(2))}  |  Ai=`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="capHostPipeStr"
                  label="Capacity of Host Pipe (cfs) Qi ="
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  placeholder={`${this.numberFormat(this.capHostPipeStr().toFixed(2))}  |  Qi=`}
                  secondaryLabel={
                    <img src={hostPipeCapacity} className={classes.hostPipeCapacity} />
                  }
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Grid item md={6} xs={12}>
                <InputField
                  id="capCIPPStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="Capacity of CIPP (cfs) Qf ="
                  placeholder={`${this.numberFormat(this.capCIPPStr().toFixed(2))}  |  Qf=full`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="percentFlowStr"
                  style={{backgroundColor: '#d3d3d3'}}
                  disabled={true}
                  label="% Increase/Decrease in Flow Capacity ΔQ ="
                  placeholder={`${this.numberFormat(this.percentFlowStr().toFixed(0))}%  |  Q =`}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={8}>
          <div style={{marginBottom: 50}} />
        </Grid>
      </Grid>
    )
  }
}

Calculator.displayName = 'Calculator'

function mapStateToProps(state) {
  return {
    ...state.cippCalculator,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Calculator)
