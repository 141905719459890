import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import PageUtils from '../utils/PageUtils'
import sustainability from '../../assets/sustainability.jpg'
import downArrow from '../../assets/downArrow.png'

const columnWidth = 831

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {},
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
  headerImage: {
    width: '100%',
    height: '500',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150',
      width: '100%',
      marginTop: 48,
    },
  },
  downArrow: {
    margin: '50 auto 20',
    [theme.breakpoints.down('sm')]: {
      margin: '50 auto 20',
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 210,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 20,
      top: 0,
      left: 0,
      width: '100%',
      height: 380,
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #FFFFFF',
      [theme.breakpoints.down('sm')]: {
        fontSize: 27,
      },
    },
    '& p': {
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '18pt',
      },
    },
  },
  blurb: {
    margin: '0 auto',
    padding: '30px 0 35px',
    fontSize: '15',
    fontFamily: 'century-gothic, sans-serif',
    lineHeight: '1.3',
    width: '1700',
    [theme.breakpoints.down('sm')]: {},
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #e63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginLeft: 20,
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '.34',
      fontStyle: 'normal',
      fontWeight: 'normal',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginLeft: 20,
        marginRight: 20,
      },
    },
  },
  policy: {
    margin: '0 auto',
    padding: '30px 0 55px',
    fontFamily: 'century-gothic, sans-serif',
    width: '1700',
    '& h2': {
      display: 'inline-block',
      fontSize: '25px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      paddingBottom: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        width: 330,
        marginLeft: 20,
      },
    },
  },
  blurbText: {
    margin: '0 auto',
    width: columnWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  black: {
    backgroundColor: '#171717',
    color: 'white',
  },
  white: {
    backgroundColor: '#fff',
  },

  noBorder: {
    borderBottom: 'none',
  },

  blurbList: {
    maxWidth: columnWidth,
    margin: 'auto',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      margin: '15 20 0 15',
      fontSize: 15,
      fontFamily: 'century-gothic, sans-serif',
    },
  },
  topBlurb: {
    margin: '110 auto 20',
    [theme.breakpoints.down('sm')]: {
      margin: '20 auto',
    },
  },
})

class Sustainability extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={sustainability} className={classes.headerImage} />
          <div className={classes.headerFloatContainer}>
            <h2>Sustainability at LANZO</h2>
            <p>
              Sustainability is delivered in our projects using a full spectrum approach that
              involves our core engineering, procurement and construction functions and strategic
              services. This approach leverages unique knowledge and capabilities to coneptualize,
              design, and build the best projects for our customers and society.{' '}
            </p>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} className={classes.downArrow} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.topBlurb}>
              <div className={classes.blurbList}>
                <ul className="custom-list">
                  <li>
                    LANZO sets ambitious long-term goals and targets to increase the company’s
                    contributions to global sustainability by 2025. The goals and targets are part
                    of LANZO’s comprehensive strategy to further integrate sustainability across the
                    company and into our major projects.{' '}
                  </li>
                  <li>
                    LANZO is proud to be a member of the Building Responsibly initiative, a
                    coalition of global engineering and construction companies dedicated to
                    improving worker welfare standards.{' '}
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
          <div className={classes.blurbText}>
            <h2>Our Solutions</h2>
            <p>
              Building a more sustainable future means having a relentless commitment to innovation.
              By changing the way in which we design, source and build, we can conserve natural
              resources more efficiently; generate more valuable social and economic opportunities
              for people, businesses and societies; and deliver greater cost benefits for our
              customers.
            </p>
            <p>
              Through LANZO’s 2025 sustainability target to use sustainable alternatives to reduce
              our environmental footprint on 100 percent of our key projects and non-project
              facilities, we are challenging ourselves to drive innovation and cost efficiencies in
              project development and delivery to bring greater value to customers.
            </p>
            <p>
              Learn about our solutions to some of the toughest, complex sustainability challenges
              across the globe including helping our customers deliver cleaner energy; supporting
              meaningful economic transitions when our projects are complete; helping address water
              security; and creating smarter, greener infrastructure to meet the demands
              of increased urbanization.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.white)}>
          <div className={classes.blurbText}>
            <h2>Our Commitment</h2>
            <p>
              The sustainability mission of LANZO is to protect people and the environment, partner
              with communities and society, and promote economic development.
            </p>
            <p>To this end, we will:</p>
            <div className={classes.blurbList}>
              <ul className="custom-list">
                <li>
                  Apply our proven processes, experience and innovation in engineering,
                  environmental safety and health, procurement, construction, and operations to
                  develop, design, and execute projects with care for the environment, as well as
                  for the safety and well-being of the people who can be affected by our projects.{' '}
                </li>
                <li>
                  Be supportive to the communities that host our projects and offices and use
                  inclusive, participatory engagement by which local cultures and values are
                  respected, dialogue is promoted, and mutual trust is built.{' '}
                </li>
                <li>
                  Build and enhance the capacity of workers and businesses through local procurement
                  and hiring and by stimulating long-term economic development beyond the projects
                  we deliver.{' '}
                </li>
              </ul>
            </div>
            <p>
              While these efforts are influenced by local issues and project complexity, LANZO is
              committed to enhancing the positive effects of our projects and avoiding or mitigating
              the negative ones. This commitment is embodied in LANZO’s{' '}
              <a href="#">Vision, Values, and Covenants;"</a> Environmental, Safety and Health
              Management System; and <a href="#">Code of Conduct.</a>
              These, and other supporting policies and processes, are aimed at ensuring that LANZO
              is improving sustainability outcomes for all customers and stakeholders wherever we
              operate in the world.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} className={classNames(classes.policy, classes.black)}>
          <div className={classes.blurbText}>
            <h2>This Policy Applies to all LANZO Employes, Projects, and Facilities:</h2>
            <div className={classes.blurbList}>
              <ul className="custom-list">
                <li>
                  Projects: Identify and assess sustainability issues that can affect projects
                  during development, design, construction and operation; identify and implement
                  activities to manage these issues consistent with customer goals and requirements,
                  LANZO policies and standards, and relevant external guidelines.{' '}
                </li>
                <li>
                  Conservation: Implement activities that are economically and operationally
                  practicable to protect the environment, including reducing energy use and air
                  emissions, water and resource consumption, and waste generation.{' '}
                </li>
                <li>
                  Stakeholders: Engage with key stakeholders to build understanding and maintain
                  trust; implement programs that improve the quality of life where we work or help
                  address broader societal issues.{' '}
                </li>
                <li>
                  Workplace: Create and maintain workplaces that promote LANZO’s ethics, quality,
                  and environmental, safety and health standards.{' '}
                </li>
                <li>
                  Employees: Treat all employees with respect and dignity and promote diversity in
                  the workplace consistent with LANZO’s Vision, Values, and Covenants.{' '}
                </li>
                <li>
                  Continuous Improvement: Strengthen LANZO’s sustainability through education,
                  innovation, and the appropriate use of relevant industry standards and external
                  guidelines, such as the International Finance Corporation Performance Standards on
                  Environmental and Social Sustainability and the United Nations Guiding Principles
                  on Business and Human Rights.{' '}
                </li>
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

Sustainability.displayName = 'Sustainability'

export default withStyles(styles)(Sustainability)
