import React from 'react'
import {IndexRoute, Route} from 'react-router'
import {authenticateOnEnter} from './utils/RoutingUtils'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import Home from './home/Home.jsx'
import Login from './auth/Login.jsx'
import LoginSubcontractor from './contractor/LoginSubcontractor.jsx'
import Signup from './auth/Signup.jsx'
import ForgotPasswordRequest from './auth/ForgotPasswordRequest.jsx'
import ForgotPasswordConsume from './auth/ForgotPasswordConsume.jsx'
import Profile from './profile/Profile.jsx'
import ProfileInfo from './profile/ProfileInfo.jsx'
import ProfileSecurity from './profile/ProfileSecurity.jsx'
import ConfirmEmail from './profile/ConfirmEmail.jsx'
import ChangeEmailConfirm from './profile/ChangeEmailConfirm.jsx'
import ChangeEmailVerify from './profile/ChangeEmailVerify.jsx'
import PublicUser from './publicUser/PublicUser.jsx'
import Soon from './home/Soon'
import Privacy from './home/Privacy'
import Terms from './home/Terms'
import Safety from './home/Safety'
import About from './home/About'
import Sustainability from './home/Sustainability'
import EngineeringServices from './home/EngineeringServices'
import Contact from './contactus/Contact'
import Careers from './careers/Home'
import CareersDetail from './careers/Detail'
import Values from './home/Values'
import CTPage from './construction_trenchless/Page'
import Subcontractor from './home/Subcontractor'
import OurHistory from './home/OurHistory'
import CIPPCalculatorRequest from './cipp_calculator_request/CIPPCalculatorRequest'
import Calculator from './calculator/Calculator'
import Videos from './videos/Video'
import CorporatePolicies from './home/CorporatePolicies'
import CorporateRisksConduct from './home/CorporateRisksConduct'
import Gallery from './gallery/Gallery'
import RegistrationContinue from './contractor/RegistrationContinue'
import AdditionalInfo from './contractor/AdditionalInfo'
import Contractor from './contractor/Contractor'
import BasicInfo from './contractor/BasicInfo'
import CompanyDetail from './contractor/CompanyDetail'
import Attachments from './contractor/Attachments'
import RegistrationSubmitted from './contractor/RegistrationSubmitted'

export default function routing(store) {
  const authenticate = authenticateOnEnter.bind(this, store)

  return (
    <Route path="/" component={App}>
      <IndexRoute component={Home} />
      <Route path="/corporate-policies" component={CorporatePolicies} />
      <Route path="/corporate-risks-conduct" component={CorporateRisksConduct} />
      <Route path="/login" component={Login} />
      <Route path="/contractor/login" component={LoginSubcontractor} />
      <Route path="/signup" component={Signup} />
      <Route path="/soon" component={Soon} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms" component={Terms} />
      <Route path="/about-us" component={About} />
      <Route path="/core-values" component={Values} />
      <Route path="/videos" component={Videos} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/cipp-calculator" component={Calculator} />
      <Route path="/our-history" component={OurHistory} />
      <Route path="/safety" component={Safety} />
      <Route path="/subcontractor" component={Subcontractor} />
      <Route path="/contractor/continue" component={Contractor}>
        <IndexRoute component={RegistrationContinue} />
      </Route>
      <Route path="/contractor/submitted" component={Contractor}>
        <IndexRoute component={RegistrationSubmitted} />
      </Route>
      <Route path="/contractor/register(/:id)" component={Contractor}>
        <IndexRoute component={BasicInfo} />
        <Route path="details" component={CompanyDetail} />
        <Route path="additional" component={AdditionalInfo} />
        <Route path="attachments" component={Attachments} />
      </Route>
      <Route path="/sustainability" component={Sustainability} />
      <Route path="/cipp-calculator-request" component={CIPPCalculatorRequest} />
      <Route path="/engineering-services" component={EngineeringServices} />
      <Route path="/contactus" component={Contact} />
      <Route path="/careers" component={Careers} />
      <Route path="/careers/:id" component={CareersDetail} />
      <Route path="/construction/:slug" component={CTPage} />
      <Route path="/trenchless/:slug" component={CTPage} />
      <Route path="/forgot" component={ForgotPasswordRequest} />
      <Route path="/forgot-password/:token" component={ForgotPasswordConsume} />
      <Route path="/profile" component={Profile} onEnter={authenticate}>
        <IndexRoute component={ProfileInfo} />
        <Route path="security" component={ProfileSecurity} />
      </Route>
      <Route path="/confirm-email/:id/:token" component={ConfirmEmail} />
      <Route path="/change-email/:id/:token" component={ChangeEmailConfirm} />
      <Route path="/change-email-verify/:id/:token" component={ChangeEmailVerify} />
      <Route path="/users/:id" component={PublicUser} />
      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}
