import React from 'react'
import {Grid} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import IconButton from '../IconButton'
import RoundedInput from '../../common/RoundedInput'
import InputValidator from '../../common/InputValidator'

class InputRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      application: props.appId,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(e, field) {
    this.setState({[field]: e.target.value})
  }

  render() {
    const {hideHeader, classes, errors} = this.props

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={3} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Attachment</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.attachment} className={classes.fieldsContainer}>
              <RoundedInput
                type="file"
                onChange={e => {
                  this.setState({attachment: e.target.files[0]})
                }}
              />
            </InputValidator>
          </Grid>
        </Grid>

        <Grid item md={3} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Description</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.description} className={classes.fieldsContainer}>
              <RoundedInput
                onChange={e => {
                  this.onChange(e, 'description')
                }}
              />
            </InputValidator>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Category</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.category} className={classes.fieldsContainer}>
              <RoundedInput
                onChange={e => {
                  this.onChange(e, 'category')
                }}
              />
            </InputValidator>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Usage</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.usage} className={classes.fieldsContainer}>
              <RoundedInput
                onChange={e => {
                  this.onChange(e, 'usage')
                }}
              />
            </InputValidator>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="save"
                    onClick={() => {
                      this.props.createAttachment(this.state)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <Cancel
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      this.props.hideForm()
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default InputRow
