import React from 'react'
import {Grid} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import Select from 'react-select'
import InputValidator from '../../common/InputValidator'

class InputRow extends React.Component {
  componentDidMount() {
    this.props.getServiceOptions()
  }

  render() {
    const {hideHeader, classes, errors, options} = this.props

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={10} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Product and Services</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <InputValidator errors={errors.code} className={classes.fieldsContainer}>
              <Select
                isSearchable={true}
                onChange={option => {
                  this.props.createService({
                    application: this.props.appId,
                    productService: option.id,
                  })
                }}
                options={options.map(opt => ({label: `${opt.code} - ${opt.name}`, ...opt}))}
              />
            </InputValidator>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <Cancel
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      this.props.hideForm()
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default InputRow
