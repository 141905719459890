import React from 'react'
import saveIcon from '../../assets/saveIcon.svg'
import editIcon from '../../assets/pencil.svg'
import trashIcon from '../../assets/trashcan.svg'

const images = {
  edit: editIcon,
  save: saveIcon,
  delete: trashIcon,
}

const IconButton = ({type, onClick}) => {
  return (
    <span onClick={onClick}>
      <img src={images[type || 'edit']} width={22} style={{cursor: 'pointer'}} />
    </span>
  )
}

export default IconButton
