import React from 'react'
import Button from '@material-ui/core/Button'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import ExternalLink from './ExternalLink'
import SlideMenu from './SlideMenu'

function MobileMenu({menus = [], open, context, toggleMobileMenu}) {
  return (
    <div style={{marginTop: 5, display: open ? 'block' : 'none'}}>
      {menus.map((menu, i) => {
        if (menu.url) {
          return (
            <Button
              key={i}
              component={ExternalLink}
              to={menu.url}
              style={{
                fontSize: 15,
                fontWeight: 600,
                position: 'relative',
                width: '100%',
                borderRadius: 0,
                justifyContent: 'left',
                backgroundColor: '#E63B43',
                color: 'white',
                paddingLeft: 20,
                height: 50,
              }}
            >
              {menu.label}
              <KeyboardArrowRight style={{position: 'absolute', right: 20}} />
            </Button>
          )
        }
        return (
          <SlideMenu
            activeIcon={menu.label}
            context={context}
            menus={menu.subMenu.map(item => {
              return {
                label: item.title,
                url: item.path || menu.basePath + item.urlTitleSlug,
                onOptionSelect: toggleMobileMenu,
              }
            })}
          />
        )
      })}
    </div>
  )
}

export default MobileMenu
