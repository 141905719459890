import _ from 'lodash'
import * as Types from './actions.types'
import * as ErrorUtils from '../../../utils/ErrorUtils'

export default function projectInterest(state = getInitialState(), action) {
  switch (action.type) {
    case Types.CREATE_PROJECT_INTEREST:
    case Types.UPDATE_PROJECT_INTEREST:
      return {
        ...state,
        isSaving: true,
      }
    case Types.CREATE_PROJECT_INTEREST_SUCCESS:
      return {
        ...state,
        isSaving: false,
        results: [...state.results, action.payload],
      }
    case Types.UPDATE_PROJECT_INTEREST_SUCCESS: {
      const index = _.findIndex(state.results, {id: action.payload.id})
      if (index > -1) {
        state.results[index] = action.payload
      }
      return {
        ...state,
        isSaving: false,
        results: [...state.results],
      }
    }

    case Types.GET_PROJECT_INTERESTS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_PROJECT_INTERESTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case Types.DELETE_PROJECT_INTEREST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: [...state.results.filter(address => address.id !== action.id)],
      }
    case Types.GET_PROJECT_INTERESTS_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        projectInterestData: [...action.payload.results],
      }
    case Types.UPDATE_PROJECT_INTEREST_FAILURE:
    case Types.CREATE_PROJECT_INTEREST_FAILURE:
    case Types.GET_PROJECT_INTERESTS_DATA_FAILURE:
    case Types.GET_PROJECT_INTERESTS_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors,
      }
    }
    case Types.RESET_PROJECT_INTEREST_ERRORS:
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors: {},
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    isSaving: false,
    errors: {},
    results: [],
    projectInterestData: [],
  }
}
