import {withStyles} from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'

export default withStyles(() => ({
  root: {
    borderRadius: 5,
    width: '100%',
    backgroundColor: '#E63B43',
    color: 'white',
    fontSize: '15px',
    fontWeight: 'bold',
    padding: 10,
  },
  disabled: {
    backgroundColor: '#DFDFDF',
  },
}))(ButtonBase)
