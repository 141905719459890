import * as Types from './actions.types'

export function createSupplierClassification(payload) {
  return {type: Types.CREATE_SUPPLIER_CLASSIFICATION, payload}
}

export function createSupplierClassificationSuccess(payload) {
  return {type: Types.CREATE_SUPPLIER_CLASSIFICATION_SUCCESS, payload}
}

export function createSupplierClassificationFailure(error) {
  return {type: Types.CREATE_SUPPLIER_CLASSIFICATION_FAILURE, error}
}

export function getSupplierClassifications(params) {
  return {type: Types.GET_SUPPLIER_CLASSIFICATIONS, params}
}

export function getSupplierClassificationsSuccess(payload) {
  return {type: Types.GET_SUPPLIER_CLASSIFICATIONS_SUCCESS, payload}
}

export function getSupplierClassificationsFailure(error) {
  return {type: Types.GET_SUPPLIER_CLASSIFICATIONS_FAILURE, error}
}

export function updateSupplierClassification(id, data) {
  return {type: Types.UPDATE_SUPPLIER_CLASSIFICATION, id, data}
}

export function updateSupplierClassificationSuccess(payload) {
  return {type: Types.UPDATE_SUPPLIER_CLASSIFICATION_SUCCESS, payload}
}

export function updateSupplierClassificationFailure(error) {
  return {type: Types.UPDATE_SUPPLIER_CLASSIFICATION_FAILURE, error}
}

export function deleteSupplierClassification(id, appId) {
  return {type: Types.DELETE_SUPPLIER_CLASSIFICATION, id, appId}
}

export function deleteSupplierClassificationSuccess(id) {
  return {type: Types.DELETE_SUPPLIER_CLASSIFICATION_SUCCESS, id}
}

export function deleteSupplierClassificationFailure(error) {
  return {type: Types.DELETE_SUPPLIER_CLASSIFICATION_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_SUPPLIER_CLASSIFICATION_ERRORS}
}

export function getSupplierClassificationData() {
  return {type: Types.GET_SUPPLIER_CLASSIFICATIONS_DATA}
}

export function getSupplierClassificationDataSuccess(payload) {
  return {type: Types.GET_SUPPLIER_CLASSIFICATIONS_DATA_SUCCESS, payload}
}

export function getSupplierClassificationDataFailure(error) {
  return {type: Types.GET_SUPPLIER_CLASSIFICATIONS_DATA_FAILURE, error}
}
