import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import videosHeader from '../../assets/videosheader.png'
import downArrow from '../../assets/downArrow.png'
import * as Actions from './redux/actions'
import PageUtils from '../utils/PageUtils'

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {},
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '0 60px',
      backgroundSize: 'contain',
    },
  },
  videoTitle: {
    fontFamily: 'century-gothic, sans-serif',
    fontSize: 18,
    fontWeight: 'bold',
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '40px 50px 40px 50px',
    width: 600,
    top: 255,
    bottom: 0,
    left: '50%',
    backgroundColor: 'rgba(230, 59, 67, 0.86)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, 50%)',
      width: 953,
      height: 290,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      margin: '-50 auto 0',
      padding: 30,
      top: 0,
      left: 0,
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '34px',
      fontWeight: 'bold',
      fontStyle: 'italic',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
    },
    '& p': {
      fontSize: '25px',
      lineHeight: '32px',
      letterSpacing: '.42',
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      marginTop: 20,
    },
  },
  videoFrame: {
    width: '100%',
    minHeight: 500,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      minHeight: 300,
    },
  },
})

class Video extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  componentWillMount() {
    this.props.getVideos()
  }

  render() {
    const {classes, results} = this.props
    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={videosHeader} style={{width: '100%', height: '500', objectFit: 'cover'}} />
          <div className={classes.headerFloatContainer}>
            <h2>Video Library</h2>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src={downArrow} style={{margin: '120px auto 20px'}} />
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{marginBottom: 50}} />
        </Grid>
        {results.map((video, i) => (
          <Grid item xs={10} md={8} key={i} style={{marginBottom: 50}}>
            <Grid container>
              <p className={classes.videoTitle}>{video.title}</p>
              <iframe
                src={video.vimeoLink}
                className={classes.videoFrame}
                frameBorder="0"
                title="Test"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={8}>
          <div style={{marginBottom: 50}} />
        </Grid>
      </Grid>
    )
  }
}

Video.displayName = 'Video'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.videos,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(Video)
