import React from 'react'
import {Grid} from '@material-ui/core'
import Blog from './Blog'
import Projects from './Projects'
import Service from './Service'

class Home extends React.Component {
  render() {
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Service />
        </Grid>

        <Grid item xs={12}>
          <Projects />
        </Grid>
        <Grid item xs={12}>
          <Blog />
        </Grid>
      </Grid>
    )
  }
}

Home.displayName = 'Home'

export default Home
