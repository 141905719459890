import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

export default function jobs(state = getInitialState(), action) {
  switch (action.type) {
    case Types.JOBS:
      return {
        ...getInitialState(),
        isFetching: true,
      }
    case Types.JOBS_SUCCESS:
      return {
        ...getInitialState(),
        jobs: action.payload,
      }
    case Types.JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case Types.SUBMIT_APPLICATION:
      return {
        ...state,
        isSubmittingApplication: true,
      }
    case Types.SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        isSubmittingApplication: false,
        submitApplicationDidSucceed: true,
      }
    case Types.SUBMIT_APPLICATION_FAILURE: {
      const applicationErrors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isSubmittingApplication: false,
        submitApplicationDidSucceed: false,
        applicationErrors,
      }
    }
    case Types.SUBMIT_APPLICATION_RESET:
      return {
        ...state,
        isSubmittingApplication: false,
        submitApplicationDidSucceed: false,
        applicationErrors: {},
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    jobs: [],
    isSubmittingApplication: false,
    submitApplicationDidSucceed: false,
    applicationErrors: {},
  }
}
