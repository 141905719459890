import * as Types from './actions.types'

export function createRegionInterest(payload) {
  return {type: Types.CREATE_REGION_INTEREST, payload}
}

export function createRegionInterestSuccess(payload) {
  return {type: Types.CREATE_REGION_INTEREST_SUCCESS, payload}
}

export function createRegionInterestFailure(error) {
  return {type: Types.CREATE_REGION_INTEREST_FAILURE, error}
}

export function getRegionInterests(params) {
  return {type: Types.GET_REGION_INTERESTS, params}
}

export function getRegionInterestsSuccess(payload) {
  return {type: Types.GET_REGION_INTERESTS_SUCCESS, payload}
}

export function getRegionInterestsFailure(error) {
  return {type: Types.GET_REGION_INTERESTS_FAILURE, error}
}

export function updateRegionInterest(id, data) {
  return {type: Types.UPDATE_REGION_INTEREST, id, data}
}

export function updateRegionInterestSuccess(payload) {
  return {type: Types.UPDATE_REGION_INTEREST_SUCCESS, payload}
}

export function updateRegionInterestFailure(error) {
  return {type: Types.UPDATE_REGION_INTEREST_FAILURE, error}
}

export function deleteRegionInterest(id, appId) {
  return {type: Types.DELETE_REGION_INTEREST, id, appId}
}

export function deleteRegionInterestSuccess(id) {
  return {type: Types.DELETE_REGION_INTEREST_SUCCESS, id}
}

export function deleteRegionInterestFailure(error) {
  return {type: Types.DELETE_REGION_INTEREST_FAILURE, error}
}

export function resetError() {
  return {type: Types.RESET_REGION_INTEREST_ERRORS}
}

export function getRegionInterestData() {
  return {type: Types.GET_REGION_INTERESTS_DATA}
}

export function getRegionInterestDataSuccess(payload) {
  return {type: Types.GET_REGION_INTERESTS_DATA_SUCCESS, payload}
}

export function getRegionInterestDataFailure(error) {
  return {type: Types.GET_REGION_INTERESTS_DATA_FAILURE, error}
}
