import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Button, withStyles} from '@material-ui/core'
import {compose} from 'redux'
import InputValidator from '../common/InputValidator.jsx'
import styles from '../common/styles/CardLayout.styles'
import LoginLayout from './LoginLayout'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      canSubmit: false,
      usernameError: false,
      passwordError: false,
    }

    this.popup = null

    this.changeUser = this.changeUser.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.login = this.login.bind(this)
  }

  login(e) {
    e.preventDefault()

    this.setState({
      usernameError: "Username doesn't exists.",
    })
  }

  changeUser(event) {
    const username = event.target.value
    this.setState(prevState => {
      return {
        username,
        canSubmit: prevState.password && username,
      }
    })
  }

  changePassword(event) {
    const password = event.target.value
    this.setState(prevState => {
      return {
        password,
        canSubmit: prevState.username && password,
      }
    })
  }

  render() {
    const {username, password, usernameError, passwordError, canSubmit} = this.state
    const {classes} = this.props

    return (
      <div className={classes.page}>
        <LoginLayout title="Log In" classes={classes}>
          <form noValidate className={classes.login}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <p style={{margin: 0, marginBottom: 5}}>Username</p>
                <InputValidator errors={usernameError}>
                  <input
                    type="email"
                    required
                    value={username}
                    onChange={this.changeUser}
                    className={classes.inputField}
                  />
                </InputValidator>
              </Grid>

              <Grid item xs={12}>
                <p style={{margin: 0, marginBottom: 5}}>Password</p>
                <InputValidator errors={passwordError}>
                  <input
                    type="password"
                    name="password"
                    required
                    value={password}
                    onChange={this.changePassword}
                    className={classes.inputField}
                  />
                </InputValidator>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={this.login}
                    disabled={!canSubmit}
                    className={classes.button}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </LoginLayout>
      </div>
    )
  }
}

Login.displayName = 'Login'

Login.propTypes = {}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
}

export default compose(withStyles(styles))(Login)
