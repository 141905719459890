import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid, ButtonBase, InputLabel, Checkbox} from '@material-ui/core'
import {withResizeDetector} from 'react-resize-detector'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import * as Actions from './redux/actions'
import RoundedInput from '../../common/RoundedInput'
import InputValidator from '../../common/InputValidator'
import RoundedSelect from '../../common/RoundedSelect'
import Titles from '../../utils/Titles'
import InputRow from '../purpose/InputRow'

const styles = theme => ({
  container: {
    position: 'absolute',
    maxWidth: 950,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    backgroundColor: '#F2F2F2',
    borderRadius: 6,
    padding: 35,
    '& h2': {
      margin: 0,
      padding: 0,
      marginBottom: 15,
    },
    overflow: 'scroll',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  spanRequired: {
    color: '#E63B43',
  },
  localError: {
    color: '#E63B43',
    fontWeight: 'bold',
  },
  divider: {
    margin: 0,
    width: '100%',
  },
  roundedButton: {
    backgroundColor: '#E63B43',
    width: 65,
    height: 25,
    margin: '0 7px',
    borderRadius: 4,
    fontSize: 12,
    color: 'white',
    fontWeight: 600,
    float: 'right',
  },
  fieldContainer: {
    padding: '7px 0',
  },
  labelContainer: {
    padding: '7px 0 7px 0',
    textAlign: 'right',
    position: 'relative',
    '& label': {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0,-50%)',
      right: 10,
      color: 'black',
      fontWeight: 'bold',
      display: 'inline-block',
    },
  },
  phoneInput: {
    margin: 0,
  },
  tableContainer: {
    border: '1px solid #979797',
    borderBottom: 0,
    width: '100%',
    marginTop: 20,
    '& p': {
      fontSize: 12,
    },
  },
  sectionInputContainer: {
    padding: 20,
    minHeight: 30,
  },
  column: {
    flex: 1,
    borderRight: '1px solid black',
  },
  actionColumn: {
    width: 100,
    justifyContent: 'flex-end',
  },
  columnContent: {
    padding: 10,
  },
  columnHeader: {
    backgroundColor: '#d8d8d8',
    padding: 5,
    '& p': {
      fontFamily: 'century-gothic, sans-serif',
      fontSize: 16,
      fontWeight: 'bold',
      margin: 0,
    },
  },
  sectionHeader: {
    backgroundColor: '#d8d8d8',
    padding: 5,
    '& p': {
      margin: 0,
      fontWeight: 'bold',
    },
  },
  sectionColumn: {
    borderRight: '1px solid #979797',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    },
  },
})

const mobileSize = 768

export const Divider = ({classes, width}) => {
  if (width > mobileSize) return null

  return (
    <Grid container justify="center">
      <hr className={classes.divider} />
    </Grid>
  )
}

const inputFields = [
  {
    type: 'select',
    label: 'Contact Title',
    name: 'contactTitle',
    required: false,
  },
  {
    type: 'text',
    label: 'First Name',
    name: 'firstName',
    required: true,
  },
  {
    type: 'text',
    label: 'Middle Name',
    name: 'middleName',
    required: false,
  },
  {
    type: 'text',
    label: 'Last Name',
    name: 'lastName',
    required: true,
  },
  {
    type: 'text',
    label: 'Alternate Name',
    name: 'alternateName',
    required: false,
  },
  {
    type: 'text',
    label: 'Job Title',
    name: 'jobTitle',
    required: false,
  },
  {
    type: 'text',
    label: 'Department',
    name: 'department',
    required: false,
  },
  {
    type: 'email',
    label: 'Contact Email',
    name: 'email',
    required: true,
  },
  {
    type: 'text',
    label: 'URL',
    name: 'url',
    required: false,
  },
  {
    type: 'phone',
    label: 'Phone Number',
    name: 'phoneNumber',
    required: true,
  },
  {
    type: 'text',
    label: 'Phone Extension',
    name: 'phoneExtension',
    required: false,
  },
  {
    type: 'phone',
    label: 'Alt. Phone Number',
    name: 'alternatePhoneNumber',
    required: false,
  },
  {
    type: 'phone',
    label: 'Fax Number',
    name: 'faxNumber',
    required: false,
  },
  {
    type: 'checkbox',
    label: 'Req. User Account',
    name: 'requiresUserAccount',
    required: false,
  },
]

class ContactDirectoryPopup extends React.Component {
  constructor(props) {
    super(props)
    const payload = {}
    let selectedPurposes = []
    let previousPurposes = []
    if (props.editId) {
      const items = props.results.filter(item => item.id == props.editId)
      if (items.length > 0) {
        for (const field of inputFields) {
          if (items[0][field.name]) {
            payload[field.name] = items[0][field.name]
          }
        }
        selectedPurposes = items[0].contactPurpose.map(p => p.purpose.id)
        previousPurposes = items[0].contactPurpose
      }
    }
    this.state = {
      payload,
      selectedPurposes,
      previousPurposes,
      localErrors: false,
    }
    this.onChange = this.onChange.bind(this)
    this.selectPurpose = this.selectPurpose.bind(this)
    this.deletePurpose = this.deletePurpose.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  onChange(e, field, sendingValue = false) {
    if (!sendingValue) {
      e.persist()
    }
    this.setState(prevState => ({
      payload: {...prevState.payload, [field]: sendingValue ? e : e.target.value},
    }))
  }

  submitForm() {
    const {payload, selectedPurposes, previousPurposes} = this.state
    const {appId} = this.props
    let localErrors = {}
    for (const field of inputFields) {
      if (field.required && !(field.name in payload)) {
        localErrors = {...localErrors, [field.name]: 'Please fill out required field'}
      }
    }
    if (Object.keys(localErrors).length > 0) {
      this.setState({localErrors})
    } else {
      this.setState({localErrors: false}, () => {
        if (this.props.editId) {
          const post = {
            ...payload,
            application: appId,
            purpose: selectedPurposes,
          }
          this.props.updateContact(this.props.editId, post, previousPurposes)
        } else {
          const post = {
            contact: {
              ...payload,
              application: appId,
            },
            purpose: selectedPurposes.map(id => ({purpose: id})),
          }
          this.props.createContact(post)
        }
      })
    }
  }

  selectPurpose(purposeId) {
    this.setState(prevState => ({
      selectedPurposes: [...prevState.selectedPurposes, parseInt(purposeId, 10)],
    }))
  }

  deletePurpose(purposeId) {
    this.setState(prevState => ({
      selectedPurposes: prevState.selectedPurposes.filter(purpose => purpose != purposeId),
    }))
  }

  render() {
    const {classes, width, purposeOptions} = this.props
    const {state} = this
    const {selectedPurposes, localErrors} = state

    const filteredPurposes = purposeOptions.filter(option => !selectedPurposes.includes(option.id))

    const errors = localErrors || this.props.errors

    return (
      <Grid container className={classes.container}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <h2>Create Contact</h2>
          </Grid>
          <Grid item xs={6}>
            <ButtonBase className={classes.roundedButton} onClick={this.submitForm}>
              <span>SAVE</span>
            </ButtonBase>
            <ButtonBase
              style={{backgroundColor: '#4F5A63'}}
              className={classes.roundedButton}
              onClick={() => {
                this.props.showContactCreatePopup(false)
              }}
            >
              <span>CANCEL</span>
            </ButtonBase>
          </Grid>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <p>
            <span className={classes.spanRequired}>*</span> Indicates required field
          </p>
        </Grid>
        <Grid item container xs={12} style={{marginBottom: 20}}>
          <Grid item container xs={12} md={6} style={{paddingRight: width > mobileSize ? 15 : 0}}>
            {inputFields.map((field, index) => {
              if (index > 8) {
                return null
              }
              return (
                <React.Fragment>
                  <Grid item xs={4} className={classes.labelContainer}>
                    <InputLabel htmlFor={field.name}>
                      {field.required && <span style={{color: '#E63B43', marginRight: 2}}>*</span>}
                      {field.label}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8} className={classes.fieldContainer}>
                    <InputValidator errors={errors[field.name]}>
                      {field.type == 'select' ? (
                        <RoundedSelect
                          native
                          value={state.payload[field.name] || ''}
                          onChange={e => {
                            this.onChange(e, field.name)
                          }}
                          input={<RoundedInput name={field.name} id={field.name} />}
                        >
                          <option value="" />
                          {Titles.map(title => (
                            <option value={title}>{title}</option>
                          ))}
                        </RoundedSelect>
                      ) : (
                        <RoundedInput
                          type={field.type}
                          value={state.payload[field.name] || ''}
                          name={field.name}
                          id={field.name}
                          onChange={e => {
                            this.onChange(e, field.name)
                          }}
                        />
                      )}
                    </InputValidator>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            style={{paddingLeft: width > mobileSize ? 15 : 0}}
            alignContent="flex-start"
          >
            {inputFields.map((field, index) => {
              if (index <= 8) {
                return null
              }
              return (
                <React.Fragment>
                  <Grid item xs={4} className={classes.labelContainer}>
                    <InputLabel htmlFor={field.name}>
                      {field.required && <span style={{color: '#E63B43', marginRight: 2}}>*</span>}
                      {field.label}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8} className={classes.fieldContainer}>
                    <InputValidator errors={errors[field.name]}>
                      {field.type == 'phone' ? (
                        <PhoneInput
                          value={state.payload[field.name] || ''}
                          name={field.name}
                          id={field.name}
                          className={classes.phoneInput}
                          onChange={phone => {
                            this.onChange(phone, field.name, true)
                          }}
                        />
                      ) : field.type == 'checkbox' ? (
                        <Checkbox
                          style={{width: 30, marginTop: -3}}
                          checked={
                            !!(state.payload[field.name] && state.payload[field.name] === true)
                          }
                          onChange={e => {
                            this.onChange(e.target.checked, field.name, true)
                          }}
                        />
                      ) : (
                        <RoundedInput
                          type={field.type}
                          value={state.payload[field.name] || ''}
                          name={field.name}
                          id={field.name}
                          onChange={e => {
                            this.onChange(e, field.name)
                          }}
                        />
                      )}
                    </InputValidator>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <h2>Contact Purpose</h2>
        </Grid>
        <hr className={classes.divider} />
        <Grid item xs={12}>
          <InputValidator errors={errors.purposes}>
            <div className={classes.tableContainer}>
              {selectedPurposes.map((selectedId, index) => (
                <React.Fragment>
                  <InputRow
                    {...this.props}
                    options={purposeOptions}
                    selected={selectedId}
                    hideHeader={index > 0}
                    deletePurpose={this.deletePurpose}
                  />
                  <Divider classes={classes} width={width} />
                </React.Fragment>
              ))}
              {filteredPurposes.length > 0 && (
                <React.Fragment>
                  <InputRow
                    {...this.props}
                    hideHeader={selectedPurposes.length > 0}
                    options={filteredPurposes}
                    selectPurpose={this.selectPurpose}
                  />
                  <Divider classes={classes} width={width} />
                </React.Fragment>
              )}
            </div>
          </InputValidator>
        </Grid>
      </Grid>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions}, dispatch)
}

function mapStateToProps(state) {
  return {
    ...state.contractorContact,
    appId: state.contractor.application.appId,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withResizeDetector,
)(ContactDirectoryPopup)
