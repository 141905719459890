export const SHOW_ADDRESS_CREATE_POPUP = 'SHOW_ADDRESS_CREATE_POPUP'

export const CREATE_ADDRESS = 'CREATE_ADDRESS'
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS'
export const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE'

export const GET_ADDRESSES = 'GET_ADDRESSES'
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS'
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE'

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS'
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE'

export const DELETE_ADDRESS = 'DELETE_ADDRESS'
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS'
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE'

export const RESET_ERRORS = 'RESET_ERRORS'

export const GET_PURPOSE_OPTIONS = 'GET_PURPOSE_ADDRESS_OPTIONS'
export const GET_PURPOSE_OPTIONS_SUCCESS = 'GET_PURPOSE_ADDRESS_OPTIONS_SUCCESS'
export const GET_PURPOSE_OPTIONS_FAILURE = 'GET_PURPOSE_ADDRESS_OPTIONS_FAILURE'
