import * as Types from './actions.news.types'

export default function news(state = getInitialState(), action) {
  switch (action.type) {
    case Types.GET_NEWS:
      return {
        ...getInitialState(),
        isFetching: true,
      }
    case Types.GET_NEWS_SUCCESS:
      return {
        ...getInitialState(),
        news: action.payload,
      }
    case Types.GET_NEWS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    news: [],
  }
}
