import React from 'react'
import {Button, Grid} from '@material-ui/core'
import {Link} from 'react-router'
import Modal from '../common/Modal'

const SaveForLaterModal = ({modalOpen, application, closeModal, next}) => {
  const nextPath = next || ''

  return (
    <Modal open={modalOpen}>
      <Grid container justify="center" direction="column">
        <h2 style={{textAlign: 'center'}}>Save this ID For Later Use:</h2>
        <p style={{textAlign: 'center'}}>{application.appId}</p>
        <h2 style={{textAlign: 'center'}}>Check Email for a copy of this ID.</h2>
        <Grid container justify="center">
          <Button
            component={Link}
            to={`/contractor/register/${application.appId}/${nextPath}`}
            variant="contained"
            color="primary"
            type="submit"
            style={{width: 124}}
            onClick={closeModal}
          >
            CONTINUE
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default SaveForLaterModal
