import React from 'react'
import {Button, Grid} from '@material-ui/core'
import {Link} from 'react-router'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  stepCounter: {
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  footerContainer: {
    padding: 10,
  },
})

const ContractorFooter = ({classes, saveLater, previousLink, currentPage, nextLabel}) => (
  <Grid container>
    <Grid item md={3} xs={12} />
    <Grid item md={3} xs={12} className={classes.footerContainer}>
      <Button
        fullWidth={true}
        variant="contained"
        color="primary"
        type="submit"
        onClick={() => {
          saveLater(true)
        }}
      >
        SAVE FOR LATER
      </Button>
    </Grid>
    <Grid item md={2} xs={4} className={classes.footerContainer}>
      <Button
        disabled={!previousLink}
        to={previousLink}
        fullWidth={true}
        variant="contained"
        color="primary"
        component={Link}
      >
        BACK
      </Button>
    </Grid>
    <Grid item md={2} xs={4} className={classes.footerContainer}>
      <Grid container justify="center" alignItems="center">
        <b className={classes.stepCounter}>Step {currentPage || 1} of 4</b>
      </Grid>
    </Grid>
    <Grid item md={2} xs={4} className={classes.footerContainer}>
      <Button
        fullWidth={true}
        variant="contained"
        color="primary"
        type="submit"
        onClick={() => {
          saveLater(false)
        }}
      >
        {nextLabel || 'NEXT'}
      </Button>
    </Grid>
  </Grid>
)

ContractorFooter.displayName = 'ContractorFooter'
export default withStyles(styles)(ContractorFooter)
