import settings from '../../../../config/settings'
import axios from '../../api/axios'
import axiosFile from '../../api/fileAxios'

export default class AttachmentApi {
  static createAttachment(payload) {
    return axiosFile.post(`${settings.apiBaseURL}/attachments`, payload)
  }

  static getAttachments(params) {
    return axios.get(`${settings.apiBaseURL}/attachments`, {params})
  }

  static updateAttachment(id, data) {
    return axios.patch(`${settings.apiBaseURL}/attachments/${id}?app_id=${data.application}`, data)
  }

  static deleteAttachment(id, appId) {
    return axios.delete(`${settings.apiBaseURL}/attachments/${id}?app_id=${appId}`)
  }
}
