import React from 'react'
import {Grid} from '@material-ui/core'
import IconButton from '../IconButton'

class Row extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      supplierClassification: props.supplierClassification,
    }
    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.supplierClassification !== this.state.supplierClassification) {
      this.setState({supplierClassification: nextProps.supplierClassification})
    }
  }

  onChange(e, field) {
    e.persist()
    this.setState(state => {
      return {supplierClassification: {...state.supplierClassification, [field]: e.target.value}}
    })
  }

  render() {
    const {classes, hideHeader, supplierClassification} = this.props

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={4} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Classification</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{supplierClassification.classificationDisplay}</p>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Comments</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{supplierClassification.comment}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Delete</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={12}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="delete"
                    onClick={() => {
                      this.props.deleteSupplierClassification(
                        supplierClassification.id,
                        supplierClassification.application,
                      )
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Row
