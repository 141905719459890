import React from 'react'
import Helmet from 'react-helmet'
import {Grid, Hidden} from '@material-ui/core'
import splash from '../../assets/loginSplashSubcontractor.png'
import logo from '../../assets/logow_slogan.png'

class LoginLayoutSubcontractor extends React.Component {
  render() {
    return (
      <div>
        <Helmet title="Supplier/Subcontractor Portal" />

        <Grid container style={{padding: 0, maxWidth: 1000, margin: '0 auto'}}>
          <Grid item xs={12} md={7}>
            <Grid container style={{marginTop: 50}}>
              <img
                src={logo}
                alt="Logo"
                style={{height: '100px', objectFit: 'contain', float: 'right'}}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} md={7}>
                {this.props.children}
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown={true}>
            <Grid item xs={12} md={4}>
              <img
                src={splash}
                alt="Splash"
                className="login-image"
                style={{height: '600px', marginBottom: 20}}
              />
            </Grid>
          </Hidden>
        </Grid>
        <div className="push" />
      </div>
    )
  }
}

LoginLayoutSubcontractor.displayName = 'LoginLayoutSubcontractor'

export default LoginLayoutSubcontractor
