import _ from 'lodash'
import * as Types from './actions.types'
import * as ErrorUtils from '../../../utils/ErrorUtils'

export default function service(state = getInitialState(), action) {
  switch (action.type) {
    case Types.CREATE_SERVICE:
    case Types.UPDATE_SERVICE:
      return {
        ...state,
        isSaving: true,
      }
    case Types.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        results: [...state.results, action.payload],
      }
    case Types.UPDATE_SERVICE_SUCCESS: {
      const index = _.findIndex(state.results, {id: action.payload.id})
      if (index > -1) {
        state.results[index] = action.payload
      }
      return {
        ...state,
        isSaving: false,
        results: [...state.results],
      }
    }

    case Types.GET_SERVICES:
    case Types.GET_SERVICE_OPTIONS:
      return {
        ...state,
        isFetching: true,
      }
    case Types.GET_SERVICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      }
    case Types.GET_SERVICE_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        options: [...action.payload.results],
      }
    case Types.ADD_PRODUCT_SERVICE:
      return {
        ...state,
        isFetching: false,
        results: [...state.results, action.payload],
      }
    case Types.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: [...state.results.filter(address => address.id !== action.id)],
      }
    case Types.UPDATE_SERVICE_FAILURE:
    case Types.CREATE_SERVICE_FAILURE:
    case Types.GET_SERVICE_OPTIONS_FAILURE:
    case Types.GET_SERVICES_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors,
      }
    }
    case Types.RESET_SERVICE_ERRORS:
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        errors: {},
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    isSaving: false,
    errors: {},
    options: [],
    results: [],
  }
}
