import {call, put, takeEvery, all} from 'redux-saga/effects'
import CTApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* constructionTrenchlessFlow() {
  yield all([takeEvery(Types.GET_CT_PAGES, getPages)])
}

function* getPages() {
  try {
    const response = yield call(CTApi.getPages)
    yield put(Actions.getCTPagesSuccess(response))
  } catch (error) {
    yield put(Actions.getCTPagesFailure(error))
  }
}
