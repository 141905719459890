import React from 'react'
import {Grid} from '@material-ui/core'
import {Check} from '@material-ui/icons'
import IconButton from '../IconButton'

class Row extends React.Component {
  render() {
    const {classes, hideHeader, contact, hideBorder} = this.props

    return (
      <Grid
        container
        justify="center"
        style={{borderBottom: hideBorder ? 'none' : '1px solid #979797'}}
      >
        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>First Name</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{contact.firstName}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Last Name</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{contact.lastName}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Phone</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{contact.phoneNumber}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Email</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{contact.email}</p>
          </Grid>
        </Grid>

        <Grid item md={2} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Requires User Account</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p> {contact.requiresUserAccount && <Check />} </p>
          </Grid>
        </Grid>
        <Grid item md={2} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="edit"
                    onClick={() => {
                      this.props.showContactCreatePopup(true, contact.id)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="delete"
                    onClick={() => {
                      this.props.deleteContact(contact.id, contact.application)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Row
