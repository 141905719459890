import React from 'react'
import {Grid} from '@material-ui/core'
import IconButton from '../IconButton'

class Row extends React.Component {
  render() {
    const {classes, hideHeader, address} = this.props
    const purposeList = address.addressPurpose.map(purpose => purpose.purposeDisplay).join(',')

    return (
      <Grid container justify="center" style={{borderBottom: '1px solid #979797'}}>
        <Grid item md={3} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Address Name</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{address.name}</p>
          </Grid>
        </Grid>

        <Grid item md={3} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Address Details</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>
              {address.addressLine1} - {address.cityTownLocality}, {address.country}
            </p>
          </Grid>
        </Grid>

        <Grid item md={3} xs={12} className={classes.sectionColumn}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Purpose</p>
            </div>
          )}
          <Grid
            justify="center"
            alignItems="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <p>{purposeList}</p>
          </Grid>
        </Grid>

        <Grid item md={3} xs={12}>
          {!hideHeader && (
            <div className={classes.sectionHeader}>
              <p>Action</p>
            </div>
          )}
          <Grid
            justify="center"
            container
            style={{padding: 10}}
            className={classes.sectionInputContainer}
          >
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="edit"
                    onClick={() => {
                      this.props.showAddressCreatePopup(true, address.id)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid justify="center" container>
                <p>
                  <IconButton
                    type="delete"
                    onClick={() => {
                      this.props.deleteAddress(address.id, address.application)
                    }}
                  />
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Row
