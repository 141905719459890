import React from 'react'
import {Grid, ButtonBase} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {Link} from 'react-router'
import PageUtils from '../utils/PageUtils'
import subcontractor from '../../assets/subcontractor.png'
import bluepipe from '../../assets/bluepipe.png'
import settings from '../../../config/settings'

/*const columnWidth = 831*/

const styles = theme => ({
  container: {
    margin: '0 auto 30px auto',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.down('sm')]: {},
  },
  header: {
    position: 'relative',
    marginTop: -40,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center center',
      height: 500,
      marginBottom: 200,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
  headerImage: {
    width: '100%',
    height: '500',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150',
      width: '100%',
      marginTop: 48,
    },
  },
  headerFloatContainer: {
    position: 'absolute',
    padding: '24px 22px 28px 25px',
    width: 390,
    top: 120,
    left: '8.75%',
    backgroundColor: 'rgba(0, 0, 0, 0.46)',
    color: 'white',
    fontFamily: 'century-gothic, sans-serif',
    [theme.breakpoints.up('md')]: {
      width: 390,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 20,
      top: 0,
      left: 0,
      width: '100%',
      color: 'black',
      backgroundColor: 'white',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '19px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #E63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        borderBottom: 'none',
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '.42',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        lineHeight: '18pt',
      },
    },
  },
  sectionTwoContainer: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0 , 0, 0.84)',
    color: 'white',
    top: 600,
    padding: '24px 22px 168px 25px',
    left: '48%',
    fontFamily: 'century-gothic, sans-serif',
    '& p': {
      fontSize: 15,
      lineHeight: '20px',
    },
    '& ul': {
      lineHeight: '20px',
    },
    '& li::before': {
      top: 0,
    },
    [theme.breakpoints.up('md')]: {
      width: 626,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: '0%',
      position: 'static',
      padding: '24px 22px 25px 25px ',
    },
  },
  listTwo: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  importantNote: {
    position: 'absolute',
    width: 636,
    color: 'white',
    backgroundColor: 'rgba(230, 59, 67, .91)',
    fontFamily: 'century-gothic, sans-serif',
    fontSize: 18,
    padding: '24px 24px 12px 25px',
    top: 1135,
    marginLeft: -120,
    '& p': {
      fontSize: 18,
      lineHeight: '18pt',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      position: 'static',
      backgroundColor: 'white',
      color: 'black',
      fontSize: 15,
    },
  },
  imageContainer: {
    width: 720,
    height: 720,
    marginTop: -163,
    marginLeft: -490,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    fontWeight: '600',
  },
  threeButtons: {
    backgroundColor: '#E63B43',
    width: 100,
    padding: '10px 5px 12px',
    borderRadius: 4,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
    },
  },
  blurbText: {
    margin: '50 auto',
    width: 1200,
    fontFamily: 'century-gothic, sans-serif',
    '& h3': {
      marginTop: 50,
      fontSize: 24,
    },
    '& h4': {
      marginTop: 50,
      fontStyle: 'italic',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  blurb: {
    margin: '0 auto',
    padding: '30px 0 35px',
    fontSize: '15',
    fontFamily: 'century-gothic, sans-serif',
    lineHeight: '1.3',
    width: '1700',
    marginTop: '45px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    '& h2': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      paddingBottom: 8,
      borderBottom: '7px solid #e63B43',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginLeft: 20,
      },
    },
    '& h3': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
      },
    },
    '& h4': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: 45,
      },
    },
    '& p': {
      fontSize: '15px',
      lineHeight: '21px',
      letterSpacing: '.34',
      fontStyle: 'normal',
      fontWeight: 'normal',
      margin: 0,
      padding: 0,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginLeft: 20,
        marginRight: 20,
      },
    },
  },
  customList: {
    top: 0,
  },
  black: {
    backgroundColor: '#171717',
    color: 'white',
  },
})

class Subcontractor extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container justify="center">
        <Grid item xs={12} className={classes.header}>
          <img src={subcontractor} className={classes.headerImage} />
          <div className={classes.headerFloatContainer}>
            <h2>Become a Supplier/Subcontractor</h2>
            <p>
              Welcome to the Suppliers & Contractors Portal. This portal provides the most current
              information about sources of supply and services to our Procurement and Contract
              personnel.{' '}
            </p>
            <div className={classes.headerButtons}>
              <ButtonBase
                className={classes.threeButtons}
                component={Link}
                to={
                  settings.features && settings.features.subcontractorRegister
                    ? '/contractor/continue'
                    : '/soon'
                }
              >
                <span>REGISTER</span>
              </ButtonBase>
              <ButtonBase
                className={classes.threeButtons}
                component={Link}
                target="_blank"
                to="/contractor/login"
              >
                <span>LOGIN</span>
              </ButtonBase>
              <ButtonBase className={classes.threeButtons} component={Link} to="/contactus">
                <span>CONTACT US</span>
              </ButtonBase>
            </div>
          </div>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.sectionTwo} />
          <div className={classes.sectionTwoContainer}>
            <h2>BECOME A LANZO SUPPLIER</h2>
            <p>
              If you supply materials or provide services that we are likely to purchase, we invite
              you to register your company and update that information periodically. The more
              complete and current your company’s information in our global database, the more
              visible your company will be to our projects.
            </p>
            <ul className="custom-list">
              <li>
                To register, it is necessary for your company to have previously registered with Dun
                and Bradstreet and obtained a DUNS number. The person registering your company must
                have a valid email address, which will be used as his/her access ID to the Portal.
                If your company is already in our supplier/contractor database, you will be asked to
                contact us for assistance and verification.
              </li>
              <li>
                Registration places your company in our database of suppliers and contractors and
                gives your company visibility among procurement professionals working on our
                projects nationwide. In addition to providing us general information and
                qualification documents, you have the ability to search for specific active projects
                and express your company’s interest and value proposition in a report sent directly
                to the project team.
              </li>
            </ul>
          </div>
          <div className={classes.imageContainer}>
            <img src={bluepipe} style={{height: '100%'}} />
          </div>
          <div className={classes.importantNote}>
            <h2>IMPORTANT NOTE</h2>
            <p>
              Registration and also expressing interest in a project using the Project Opportunities
              feature helps provide visibility of your company to active projects. It also provides
              the names of project contacts. It does not automatically place you on a potential
              bidders list. Lanzo does not maintain a companywide “formal” approved bidder list.
              Bidders are pre-qualified independently for each project based on project-specific
              requirements. You will only be contacted by a Lanzo project management or the
              estimation group to review your qualifications when you are being considered for work
              on a project.
            </p>
            <p />
          </div>
        </Grid>
        <Grid item xs={12} className={classNames(classes.blurb, classes.black)}>
          <div className={classes.blurbText}>
            <h2>Expectations of Conduct</h2>
            <p>
              Please review <u>Lanzo’s expectations of subcontractor and supplier conduct</u> in
              relation to compliance with laws and regulations, conflict minerals, equal
              opportunity, ethics, human trafficking and anti-slavery, import/export, restricted
              parties, safety, supply chain diversity, sustainability, and sub-suppliers.
            </p>
            <h3>Supplier & Subcontractor Rules, Regulations & Commitments</h3>
            <p>
              Lanzo works only with qualified suppliers and subcontractors who commit to safely and
              responsibly deliver quality goods and services―on time and within budget.  Although
              quality, cost, and timeliness are important, we will not solicit and award purchase
              orders and subcontracts to suppliers and subcontractors that do not meet our standards
              for safety, ethics, conduct, and protection of human rights.  Lanzo is committed to
              the highest safety, ethical, and human rights standards in the industry, and we expect
              the same dedication from our supplier and subcontractor organizations.
            </p>
            <p>
              Our suppliers and subcontractors must comply with all terms of the purchase order
              and/or subcontract and will immediately notify Lanzo of any actual or potential
              violations.  To the extent that the purchase order or subcontract does not contain
              specific requirements that append or modify the below, we expect our suppliers and
              subcontractors at all tiers of performance to commit to the following:
            </p>
            <h4>Compliance with Laws and Regulations</h4>
            <p>
              Our suppliers and subcontractors must be familiar with and follow all applicable laws
              and regulations at all times in the performance of the work. Our suppliers and
              subcontractors must also ensure that lower-tier suppliers and subcontractors give an
              identical representation in their subcontracts and purchase orders for any portion of
              the work. “Laws” include all applicable national, federal, state, and local laws,
              ordinances, and regulations of the United States of America and any other national or
              international laws with jurisdiction that cover the work.  Of special note is
              following all laws with regard to anti-corruption such as the bribery of public
              officials and private sector employees.
            </p>
            <h4>Conflict Minerals</h4>
            <p>
              Although Lanzo is not a manufacturer and is a privately held company, we expect our
              suppliers to adhere to Section 1502 of the Dodd-Frank Wall Street Reform and Consumer
              Protection Act, which aims to prohibit the use of conflict minerals (gold, tungsten,
              tantalum, and tin) from the Democratic Republic of the Congo or adjoining countries.
              Suppliers shall conduct due diligence to preclude the sales or installation of any
              materials or equipment that contain conflict minerals.
            </p>
            <h4>Equal Opportunity</h4>
            <p>
              Lanzo is an equal opportunity employer and employment decisions are made without
              regard to race, color, gender, age, religion, national origin, ancestry, physical or
              mental disability, veteran status, sexual orientation, gender identity, caste, and
              other grounds for discrimination prohibited by applicable law. Lanzo expects that
              suppliers and subcontractors will have employment policies and practices that prohibit
              employment decisions being made with regard to any legally protected characteristics.
            </p>
            <p>
              For work performed in the United States, and to the extent the supplier or
              subcontractor is not otherwise exempt, the suppliers and subcontractors must abide by
              the requirements of 41 CFR §§ 60-1.4(a), 60-300.5(a), and 60-741.5(a). These
              regulations prohibit discrimination against qualified individuals based on their
              status as protected veterans or individuals with disabilities and prohibit
              discrimination against all individuals based on their race, color, religion, sex,
              sexual orientation, gender identity, or national origin. Moreover, these regulations
              require that the covered parties take affirmative action to employ and advance
              individuals in employment, without regard to race, color, religion, sex, sexual
              orientation, gender identity, national origin, protected veteran status, or
              disability.
            </p>
            <h4>Ethics</h4>
            <p>
              Lanzo is committed to the highest standards of ethical conduct and seeks to do
              business only with suppliers and subcontractors who share these values.  We conduct
              our business transactions fairly, properly, and impartially and expect the same from
              our suppliers and subcontractors.  Any conduct that fails to meet our standard of
              ethics, integrity, and responsible conduct may preclude suppliers and subcontractors
              from being invited to participate in a solicitation.
            </p>
            <h4>Human Trafficking and Anti-Slavery</h4>
            <p>
              Lanzo is committed to respecting all human rights everywhere we operate, consistent
              with Lanzo’s Vision, Values and Covenants and the spirit and intent of the United
              Nations Guiding Principles on Business and Human Rights.   To that end, Lanzo will not
              tolerate or condone any activities that support human trafficking, slavery, servitude,
              or forced or compulsory labor. We expect our suppliers and subcontractors to obey the
              laws that require them to treat workers fairly and provide a safe and healthy work
              environment. Lanzo expects suppliers and subcontractors to adhere to these standards
              and expects them to hold their own suppliers and subcontractors to the same
              standards.  In addition, Lanzo expects suppliers and subcontractors to:
            </p>
            <div className={classes.listTwo}>
              <ul className="custom-list">
                <li>Employ workers at or above the applicable minimum age requirement.</li>
                <li>
                  Maintain a workplace free from threats of violence, physical abuse, or other
                  conduct that fails to respect the safety and dignity of a worker.
                </li>
                <li>
                  Comply with applicable wage laws and, upon end of employment, pay for return
                  transportation costs for workers recruited from outside the country.
                </li>
                <li>
                  Not charge workers recruitment fees or utilize firms charging workers such fees,
                  and not utilize fraudulent or misleading recruitment practices.
                </li>
                <li>Not withhold a worker’s passport or immigration documents.</li>
                <li>
                  Provide workers a process for escalating and reporting concerns without
                  retaliation.
                </li>
              </ul>
            </div>
            <p>
              We fully expect that our suppliers and subcontractors will ensure that lower-tier
              suppliers and subcontractors give an identical representation in their subcontracts
              and purchase orders for the performed work.
            </p>
            <h4>Import/Export</h4>
            <p>
              Throughout the supply chain, our suppliers and subcontractors must comply with all
              applicable export and import laws and regulations, including the international
              sanctions programs administrated by the U.S. Department of Treasury as well as any
              other applicable government agency in the U.S. or abroad.
            </p>
            <h4>Restricted Parties</h4>
            <p>
              All suppliers and subcontractors must acknowledge that the U.S. Government, other
              country governments, and international organizations publish Restricted Parties Lists
              (“Lists”) that identify parties (such as known or suspected terrorists, money
              launderers, and drug traffickers) restricted from certain or all types of
              transactions.  Our suppliers and subcontractors must review such Lists prior to
              initiating transactions with any lower-tier or subcontractor, including financial
              institutions, for all or any portion of the goods or services to ensure such
              lower-tier or subcontractor and any relevant owners are not identified on any
              applicable Lists.
            </p>
            <h4>Safety</h4>
            <p>
              Lanzo has a “Zero Accidents” policy at its construction sites.  Our suppliers and
              subcontractors must comply with the site safety requirements of each Lanzo jobsite. At
              suppliers’ facilities, suppliers must operate facilities in a safe manner to protect
              Lanzo personnel, its agents, and suppliers’ employees at all times.  In all locations,
              suppliers and subcontractors must provide a safe work environment in accordance with
              all applicable international and national laws, regulations, and standards.
            </p>
            <h4>Supply Chain Diversity</h4>
            <p>
              Lanzo is committed to a diverse workforce and a diverse supply chain.  Within the
              United States, our suppliers and subcontractors must assist Small, Disadvantaged,
              Women-Owned, Veteran- Owned, Service-Disabled Veteran-Owned, and HUB Zone Business
              concerns to obtain business opportunities by identifying and encouraging not just
              within their own company but also the lower- tier supplier and subcontractor companies
              to participate to the greatest extent possible, consistent with their qualifications,
              quality of work, and obligations as may be required by 48 CFR 52.219-9 (subcontracting
              plan regarding small business concerns).  For all work regardless of locations, our
              suppliers and subcontractors must strive to identify, source, develop, and otherwise
              assist local suppliers and subcontractors in performance of the work.
            </p>
            <h4>Sustainability</h4>
            <p>
              We strive to lead the engineering and construction industry in designing and
              constructing facilities that are environmentally sound, are socially responsible, and
              leave a sustainable and positive impact on the local communities.  We similarly expect
              our suppliers and subcontractors to develop work practices to adhere to safe work
              practices, mitigate adverse impact to the environment, develop local suppliers and
              subcontractors, and advance the economics of the local community.
            </p>
            <h4>Lower-Tier Suppliers Throughout the Supply Chain</h4>
            <p>
              In addition to the above commitment, we expect our suppliers and subcontractors to
              secure the same commitment from lower tier suppliers and subcontractors. The above
              requirements are mandatory and reflect, in part, Lanzo’s corporate values/ethos and
              social responsibility on such issues.
            </p>
          </div>
        </Grid>
      </Grid>
    )
  }
}

Subcontractor.displayName = 'Subcontractor'

export default withStyles(styles)(Subcontractor)
